import * as FDN from "src/core";
import {
  formatDate as ptfdnFormatDate,
  formatTime as ptfdnFormatTime,
  formatDateTime as ptfdnFormatDateTime,
  TFormatDateTimeDate,
  IFormatDateOptions,
  IFormatTimeOptions,
  IFormatDateTimeOptions,
} from "@tschirpke/ptfdn-frontend-components";
import { IAdventure, IPicture } from "src/types";
import { differenceInYears } from "date-fns";
import Config from "../Config";

/**
 *
 * HELPER FOR DATES AND TIMES
 *
 */

export const formatDate = (date: TFormatDateTimeDate, options?: IFormatDateOptions): string => {
  let dateStr = ptfdnFormatDate(date, options);
  dateStr = dateStrI18n(dateStr);
  return dateStr;
};

export const formatTime = (date: TFormatDateTimeDate, options?: IFormatTimeOptions): string => {
  return ptfdnFormatTime(date, options);
};

export const formatDateTime = (date: TFormatDateTimeDate, options?: IFormatDateTimeOptions): string => {
  let dateStr = ptfdnFormatDateTime(date, options);
  dateStr = dateStrI18n(dateStr);
  return dateStr;
};

const dateStrI18n = (dateStr: string): string => {
  dateStr = dateStr.replace("Today", FDN.I18n.t(`main.date.today`));
  dateStr = dateStr.replace("Tomorrow", FDN.I18n.t(`main.date.tomorrow`));
  dateStr = dateStr.replace("Yesterday", FDN.I18n.t(`main.date.yesterday`));
  return dateStr;
};

export const getAdventurePictureUrl = (picture: IPicture, size: "S" | "M" | "L" = "L"): string | null | undefined => {
  if (picture[size]) return picture[size];
  return null;
};

export function nl2br(str: string, breakTag = "<br />") {
  return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
}

export function hasOwnProperty<X extends object, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export const getAge = (birthday: Date, date = new Date()) => {
  const age = differenceInYears(date, birthday);
  return age;
};

export const formatFileSize = (size: number): string => {
  let fSize = "";
  let unit = "";

  const locale = Config.get("currencyLocale") as string;

  if (size >= 10000000000) {
    fSize = FDN.formatNumber(size / 1000000000, 0, 1, locale);
    unit = "GB";
  } else if (size >= 1000000) {
    fSize = FDN.formatNumber(size / 1000000, 0, 2, locale);
    unit = "MB";
  } else if (size >= 1000) {
    fSize = FDN.formatNumber(size / 1000, 0, 0, locale);
    unit = "KB";
  } else {
    fSize = FDN.formatNumber(size / 1, 0, 0, locale);
    unit = "B";
  }

  return `${fSize} ${unit}`;
};

export const setTimeToBeginningOfDay = (date: Date): Date => {
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const setTimeToEndOfDay = (date: Date): Date => {
  date.setHours(23);
  date.setMinutes(59);
  date.setSeconds(59);
  date.setMilliseconds(0);
  return date;
};

export const hasAdventureHotels = (adventure: IAdventure): boolean => {
  if (!adventure.hotelsByNight || Object.keys(adventure.hotelsByNight).length === 0) return false;

  for (const night in adventure.hotelsByNight) {
    if (adventure.hotelsByNight[night].length > 0) return true;
  }

  return false;
};

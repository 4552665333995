import * as React from "react";
import * as FDN from "src/core";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { IOrder, IStripePayment } from "src/types";
import { TActionsPayment } from "src/services/PaymentService";
import UrlService from "src/core/UrlService";

interface ICheckoutFormProps {
  order: IOrder;
  stripePayment: IStripePayment;
  actions: TActionsPayment;
}

const CheckoutForm: React.FunctionComponent<ICheckoutFormProps> = ({ order, stripePayment }) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = React.useState<string>();
  const [isProcessing, setIsProcessing] = React.useState(false);

  const redirectUrl = `${window.location.protocol}//${window.location.host}${UrlService.url(
    "payment.orderPaymentCompletion",
    {
      orderNr: order.orderNr as string,
      orderToken: order.orderToken as string,
    }
  )}`;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsProcessing(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: redirectUrl,
      },
    });

    if (error) setMessage(error.message);

    setIsProcessing(false);
  };

  return (
    <div className="__stripe-checkout-form">
      <form id="payment-form" onSubmit={handleSubmit}>
        <FDN.Box>
          <PaymentElement />
        </FDN.Box>
        {message && (
          <div id="payment-message">
            <FDN.Icon icon="exclamation-triangle" left /> {message}
          </div>
        )}
        <div className="payment-buttons">
          <button disabled={isProcessing} id="submit" className="primary button">
            <span id="button-text">
              {isProcessing
                ? "Please wait ..."
                : FDN.I18n.t("payment.buttons.payNow.label", {
                    amount: stripePayment.amount
                      ? FDN.formatNumber(stripePayment.amount, 2, 2)
                      : "?",
                  })}
            </span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default CheckoutForm;

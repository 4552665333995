import React from "react";
import { IAdventureHotel, IHotel } from "src/types";
import HotelStars from "../HotelStars";
import { AdventureHotelsActions } from "src/services/admin/useAdventureHotels";
import { Icon } from "@tschirpke/ptfdn-frontend-components";
import { classNames } from "src/core/helpers/design";
import { I18n } from "src/core";

interface HotelsListProps {
  hotels: IHotel[];
  selectedHotel?: IAdventureHotel;
  night: number;
  actions: AdventureHotelsActions;
}

const HotelsList = ({ hotels, night, actions }: HotelsListProps) => {
  const hotelsForNight = actions.getHotelsForNight(night);

  return (
    <div className="w-full overflow-x-auto">
      <ul>
        {hotels.map((hotel) => {
          const adventureHotel = hotelsForNight?.find((h) => h.hotelIdentifier === hotel.identifier);

          return (
            <li key={hotel.identifier} className="flex items-center justify-between border-b border-gray-200">
              <button
                className={classNames(
                  "flex w-full text-left items-center gap-4 p-4 cursor-pointer hover:bg-hover",
                  (adventureHotel?.rooms?.length ?? 0) > 0 && "bg-green-50"
                )}
                onClick={() => actions.selectHotel(hotel)}
              >
                <div className="w-7">
                  {actions.isHotelAssignedToAdventure(hotel.identifier) && <Icon icon="check" />}
                </div>
                <div className="w-28 h-auto">
                  {hotel.mainPicture?.S && <img src={hotel.mainPicture.S} alt={hotel.name} />}
                </div>
                <div className="flex-grow">
                  <div className="font-semibold text-primary">
                    {hotel.name} {hotel.stars && <HotelStars stars={hotel.stars} />}
                  </div>
                  <div className="text-sm text-gray.500">
                    {hotel.street ? <>{hotel.street},</> : null}{" "}
                    {hotel.zip || hotel.city ? (
                      <>
                        {hotel.zip} {hotel.city},
                      </>
                    ) : null}{" "}
                    {hotel.country}
                  </div>
                </div>
                <div className="px-4 flex items-center justify-center">
                  {(adventureHotel?.rooms?.length ?? 0) > 0 && (
                    <div className="text-green-500 text-xs font-medium">
                      {I18n.t("adminHotels.adventure.addHotel.form.roomsSelected.label", {
                        number: adventureHotel?.rooms?.length ?? 0,
                      })}
                    </div>
                  )}
                </div>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default HotelsList;

import React, { useEffect, useState } from "react";
import { IFile } from "src/types";
import { FOnUploadCompleted, FRemoveFile, FSelectFiles, FUploadFile, FUploadFiles } from "./types";
import { cloneDeep } from "lodash";
import { FileUploadFile } from "../ImageUpload/types";
import * as FDN from "src/core";
import useServiceCore from "src/services/CoreService";
import axios from "axios";

export interface TActionsFileUpload {
  selectFiles: FSelectFiles;
  removeFile: FRemoveFile;
  uploadFiles: FUploadFiles;
}

const useServiceFileUpload = (
  onUploadCompleted: FOnUploadCompleted,
  maxFiles: number,
  allowedFileTypes?: string[],
  uploadType = "misc",
  alreadyUploadedFiles?: IFile[]
) => {
  const [waitingFiles, setWaitingFiles] = useState<FileUploadFile[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<IFile[]>(alreadyUploadedFiles || []);
  const [uploadProgresses, setUploadProgresses] = useState<{ [key: string]: number }>({});

  const { api } = useServiceCore();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    //
  };

  useEffect(() => {
    if (uploadedFiles && uploadedFiles !== alreadyUploadedFiles) {
      onUploadCompleted(uploadedFiles);

      setWaitingFiles((waitingFiles) =>
        waitingFiles.filter((w) => uploadedFiles.find((u) => u.uploadUuid === w.uuid))
      );
    }
  }, [uploadedFiles]);

  const selectFiles: FSelectFiles = (selectedFiles) => {
    const updatedFiles = [...waitingFiles, ...selectedFiles];
    setWaitingFiles(cloneDeep(updatedFiles));
  };

  const removeFile: FRemoveFile = (type, identifier, force = false) => {
    if (type === "waiting") {
      const index = waitingFiles.findIndex((f) => f.uuid === identifier);

      if ((index || index === 0) && waitingFiles[index]) {
        const doRemoveFromWaiting = () => {
          const updatedFiles = cloneDeep(waitingFiles);
          updatedFiles.splice(index, 1);
          setWaitingFiles(updatedFiles);
        };

        if (force === true) doRemoveFromWaiting();
        else if (window.confirm(FDN.I18n.t("fileupload.buttons.removeFile.confirm")))
          doRemoveFromWaiting();
      }
    } else {
      const index = uploadedFiles.findIndex((f) => f.identifier === identifier);

      if ((index || index === 0) && uploadedFiles[index]) {
        const doRemoveFromUploaded = () => {
          const updatedFiles = cloneDeep(uploadedFiles);
          updatedFiles.splice(index, 1);
          setUploadedFiles(updatedFiles);
        };

        if (force === true) doRemoveFromUploaded();
        else if (window.confirm(FDN.I18n.t("fileupload.buttons.removeFile.confirm")))
          doRemoveFromUploaded();
      }
    }
  };

  const uploadFiles: FUploadFiles = () => {
    if (!waitingFiles) return;

    for (let i = 0; i < waitingFiles.length; i++) {
      uploadFile(waitingFiles[i], i, (uploadedFile) => {
        if (uploadedFile) {
          setUploadedFiles((prev) => [...prev, uploadedFile]);
          removeFile("waiting", waitingFiles[i].uuid, true);
        }
      });
    }
  };

  const uploadFile: FUploadFile = (file, index, callback) => {
    if (!file) return;

    let apiUrl: string | null = "";

    if (uploadType === "reqinfo")
      apiUrl = api.getEndpoint("files.publicUpload", { type: uploadType });
    else apiUrl = api.getEndpoint("files.upload", { type: uploadType });

    if (!apiUrl) return;

    const formData = new FormData();

    const fileContent: Blob = file.croppedImage ? file.croppedImage : file.original;
    formData.append("files", fileContent, file.name);

    const apiOptions = api.getApiOptions();
    apiOptions.onUploadProgress = (progressEvent) => {
      //console.log("UPLOADING", progressEvent);
      if (progressEvent?.progress) {
        uploadProgresses[index] = Math.floor(progressEvent.progress * 100);
        setUploadProgresses(cloneDeep(uploadProgresses));
      }
    };

    FDN.Logger.info("[UPLOAD]", "FileUplodService: Uploading file...", file.name);

    uploadProgresses[index] = 0;
    setUploadProgresses(cloneDeep(uploadProgresses));

    axios
      .post(apiUrl, formData, apiOptions)
      .then((response) => {
        if (response?.data?.body?.files && response.data.body.files.length > 0 && callback) {
          const uploadedFile = response?.data?.body?.files[0] as IFile;
          if (uploadedFile) uploadedFile.uploadUuid = file.uuid;
          callback(uploadedFile);
        }
      })
      .catch((e) => {
        FDN.Logger.error("[UPLOAD]", e);
      });
  };

  const actions: TActionsFileUpload = {
    selectFiles,
    removeFile,
    uploadFiles,
  };

  return { waitingFiles, uploadedFiles, uploadProgresses, actions };
};

export default useServiceFileUpload;

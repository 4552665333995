import * as React from "react";
import * as FDN from "src/core";
import { IUserNotifications } from "src/components/context/NotificationsContext/types";
import { TActions } from "src/types";
import UserNotificationsList from "./parts/UserNotificationsList";
import { useNavigate } from "react-router-dom";

const tabs: FDN.ITabs = {
  all: {
    label: FDN.I18n.t("usernotifications.popup.tabs.all.label"),
  },
  unread: {
    label: FDN.I18n.t("usernotifications.popup.tabs.unread.label"),
  },
};

const defaultTab = "all";

interface IUserNotificationsProps {
  userNotifications?: IUserNotifications;
  menuRef: React.RefObject<HTMLDivElement>;
  actions: TActions;
}

const UserNotifications: React.FunctionComponent<IUserNotificationsProps> = ({
  userNotifications,
  menuRef,
  actions,
}) => {
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  userNotifications = actions.filterUserNotifications(selectedTab, userNotifications);

  return (
    <>
      <div className="__usernotifications-modal" onClick={() => actions.toggleMenu()} />
      <div className="__usernotifications-wrapper">
        <div className="__usernotifications" ref={menuRef}>
          <h3>{FDN.I18n.t("usernotifications.popup.title")}</h3>
          <div className="__usernotifications-tabs">
            <FDN.Tabs
              pills
              tabs={tabs}
              selected={selectedTab}
              onSelect={(tabKey) => setSelectedTab(tabKey)}
              onUpdateUrl={(params) => navigate({ search: params })}
            />
          </div>
          <FDN.TabsContent>
            {!userNotifications?.filteredNotifications ||
            userNotifications?.filteredNotifications?.length === 0 ? (
              <div className="__usernotifications-no-notifications">
                {FDN.I18n.t("usernotifications.popup.no")}
              </div>
            ) : (
              <UserNotificationsList userNotifications={userNotifications} actions={actions} />
            )}
          </FDN.TabsContent>
        </div>
      </div>
    </>
  );
};

export default UserNotifications;

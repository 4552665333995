import * as React from "react";
import * as FDN from "src/core";
import Api from "src/api/Api";
import { AppContext } from "src/components/context/AppContext/AppContext";
import { useParams } from "react-router-dom";
import UrlService from "src/core/UrlService";
import AuthApi from "src/api/AuthApi";
import { NotificationsContext } from "src/components/context/NotificationsContext/NotificationsContext";
import Config from "src/core/Config";
import StatusCode from "src/config/statuscodes";
import { WelcomeCheckTokenStatus } from "src/types";
import PasswordSetForm from "src/components/pages/user/myaccount/PasswordSetForm";

export interface IInputValues {
  password1: string;
  password2: string;
}

const PasswordResetPage: React.FunctionComponent = () => {
  const [checkTokenStatus, setCheckTokenStatus] = React.useState<WelcomeCheckTokenStatus>();
  const [errorEmpty, setErrorEmpty] = React.useState(false);
  const [errorPasswordTooShort, setErrorPasswordTooShort] = React.useState(false);
  const [errorNotEqual, setErrorNotEqual] = React.useState(false);
  const [inputValues, setInputValues] = React.useState<IInputValues>({
    password1: "",
    password2: "",
  });

  const APP = React.useContext(AppContext);
  const NOTIFICATIONS = React.useContext(NotificationsContext);
  const logoLoginUrl = APP.getSetting("logoLoginUrl");

  const api = new Api(APP, NOTIFICATIONS);

  const params = useParams();
  const token = params.token;

  React.useEffect(() => {
    if (!token) {
      setCheckTokenStatus(StatusCode.INVALID_TOKEN);
      return;
    }

    AuthApi.checkToken(api, token).then((response) => {
      const statusCode = response?.statusCode as string;

      if (
        [
          StatusCode.INVALID_TOKEN as string,
          StatusCode.TOKEN_EXPIRED as string,
          StatusCode.VALID_TOKEN as string,
        ].includes(statusCode)
      ) {
        setCheckTokenStatus(statusCode as WelcomeCheckTokenStatus);
      }
    });
  }, [token]);

  const onEdit = (property: string, value: string) => {
    setInputValues({ ...inputValues, [property]: value });
  };

  const checkInput = () => {
    if (!inputValues.password1 || !inputValues.password2) {
      setErrorEmpty(true);
      return false;
    } else setErrorEmpty(false);

    if (inputValues.password1 !== inputValues.password2) {
      setErrorNotEqual(true);
      return false;
    } else setErrorNotEqual(false);

    const passwordMinLength = Config.get("auth.passwordMinLength") as number;

    if (inputValues.password1.length < passwordMinLength) {
      setErrorPasswordTooShort(true);
      return false;
    } else setErrorPasswordTooShort(false);

    return true;
  };

  const onSave = () => {
    if (!checkInput() || !token) return false;

    AuthApi.setPassword(api, token, inputValues.password1).then((response) => {
      const statusCode = response?.statusCode as string;

      if ([StatusCode.INVALID_TOKEN as string, StatusCode.SUCCESS as string].includes(statusCode)) {
        setCheckTokenStatus(statusCode as WelcomeCheckTokenStatus);
      }
    });
  };

  const formErrors = {
    "login.emptyInput": errorEmpty,
    "login.notEqual": errorNotEqual,
    "login.passwordTooShort": errorPasswordTooShort,
  };

  return (
    <FDN.LayoutWithoutTopbar page="login">
      <FDN.Grid>
        <FDN.Row margin="xy">
          <FDN.Cell md={12} mdo={6}>
            {checkTokenStatus &&
            [StatusCode.INVALID_TOKEN, StatusCode.TOKEN_EXPIRED].includes(checkTokenStatus) ? (
              <div className="center-on-page">
                <div className="alert callout">{FDN.I18n.t("errors.welcome.invalidToken")}</div>
              </div>
            ) : (
              <>
                {logoLoginUrl && (
                  <div className="__login-logo">
                    <img src={logoLoginUrl} alt="" />
                  </div>
                )}
                <h1>{FDN.I18n.t("passwordreset.passwordreset.title")}</h1>
                <div className="__login-intro lead">
                  {FDN.I18n.t("passwordreset.passwordreset.intro")}
                </div>
                {checkTokenStatus === StatusCode.VALID_TOKEN && (
                  <>
                    <FDN.FormErrors errors={formErrors} />

                    <PasswordSetForm
                      password1={inputValues.password1}
                      password2={inputValues.password2}
                      onEdit={onEdit}
                    />
                    <FDN.FormButtons
                      onSave={onSave}
                      saveLabel={FDN.I18n.t("passwordsetform.buttons.onSave.label")}
                      marginBottom="20px"
                    />
                    <div>&nbsp;</div>
                  </>
                )}
                {checkTokenStatus === StatusCode.SUCCESS && (
                  <FDN.CalloutBox type="success" style={{ marginTop: "20px" }}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: FDN.I18n.t("welcome.onSave.success.text", {
                          link: `<a href="${UrlService.url("auth.login")}">${FDN.I18n.t(
                            "welcome.onSave.success.link"
                          )}</a>`,
                        }),
                      }}
                    />
                  </FDN.CalloutBox>
                )}
              </>
            )}
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </FDN.LayoutWithoutTopbar>
  );
};

export default PasswordResetPage;

import React, { useState } from "react";
import { IOrders, TFunction } from "../types";
import useServiceCore from "./CoreService";
import BookingApi from "src/api/BookingApi";
import StatusCode from "src/config/statuscodes";
import * as FDN from "src/core";

export interface TActionsMyOrders {
  deleteMe?: TFunction;
}

const useServiceMyOrders = () => {
  const [orders, setOrders] = useState<IOrders>();

  const { APP, api } = useServiceCore();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    BookingApi.getMyOrders(api).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const orders = response?.body?.orders as IOrders;

        setOrders(orders);
        APP.setPageTitle(FDN.I18n.t("myorders.pageTitle"));
      }
    });
  };

  const actions: TActionsMyOrders = {};

  return { orders, actions };
};

export default useServiceMyOrders;

import * as React from "react";
import * as FDN from "src/core";
import { TFunction } from "src/types";

interface ISingleImageUploadWithImageWrapperProps {
  imageUrl?: string | null;
  uploadType?: string;
  editMode?: boolean;
  cropAspect?: number;
  vertical?: boolean;
  onUpdate: (imageUrl: string) => void;
  onDelete: TFunction;
}

const SingleImageUploadWithImageWrapper: React.FunctionComponent<
  ISingleImageUploadWithImageWrapperProps
> = ({ imageUrl, uploadType, editMode, cropAspect, vertical, onUpdate, onDelete }) => {
  if (!uploadType) uploadType = "misc";

  const onUploadFinished = (imageUrl: string) => {
    if (imageUrl && onUpdate) onUpdate(imageUrl);
  };

  const onConfirmDelete = () => {
    if (window.confirm(FDN.I18n.t("imageupload.onDelete.confirm"))) onDelete();
  };

  return (
    <div
      className={`__imageupload-single-image-with-image-wrapper-upload ${
        vertical === true ? `vertical` : ``
      }`}
    >
      <FDN.GridRow full margin="xy">
        {editMode === true && (
          <FDN.Cell
            sm={24}
            md={vertical === true ? 24 : 12}
            className="__imageupload-single-image-with-image-wrapper-upload-cell-upload"
          >
            <FDN.SingleImageUpload
              button={
                <button className="small primary button">
                  {FDN.I18n.t("imageupload.button.upload.label")}
                </button>
              }
              crop={{
                unit: "px",
                x: 0,
                y: 0,
                width: 100,
                height: 100,
              }}
              cropOptions={{
                aspect: cropAspect,
                keepSelection: true,
              }}
              uploadType={uploadType}
              onUploadFinished={onUploadFinished}
            />
            {editMode === true && imageUrl ? (
              <button
                onClick={onConfirmDelete}
                className="clickable-text __imageupload-single-image-with-image-wrapper-delete-button"
              >
                {FDN.I18n.t("imageupload.button.delete.label")}
              </button>
            ) : null}
          </FDN.Cell>
        )}
        <FDN.Cell
          sm={24}
          md={vertical === true || !editMode ? 24 : 12}
          className="__imageupload-single-image-with-image-wrapper-upload-cell-image"
        >
          {imageUrl ? (
            <img src={imageUrl} />
          ) : (
            <div className="text-center">{FDN.I18n.t("imageupload.noimage")}</div>
          )}
        </FDN.Cell>
      </FDN.GridRow>
    </div>
  );
};

export default SingleImageUploadWithImageWrapper;

import { isDate } from "lodash";
import * as React from "react";
import { BookingContext } from "src/components/context/BookingContext/BookingContext";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { IOrder } from "src/types";

const BookingSidebar: React.FunctionComponent = () => {
  const BOOKING = React.useContext(BookingContext);
  const { order } = BOOKING;

  if (!order) return <FDN.Loading box />;

  return (
    <div className="__booking-sidebar">
      <FDN.Box noMargin noPadding>
        <div className="__booking-sidebar-content">
          <h3>{FDN.I18n.t("booking.sidebar.title")}</h3>
        </div>
        <ContentDate order={order} />
        {order.street && order.zip && order.city && order.country && order.email && order.phone ? (
          <ContentPersonal order={order} />
        ) : null}
        {order.calculation?.total ? <ContentPayment order={order} /> : null}
      </FDN.Box>
    </div>
  );
};

interface ISidebarContentProps {
  order: IOrder;
}

const ContentDate: React.FunctionComponent<ISidebarContentProps> = ({ order }) => {
  return (
    <div className="__booking-sidebar-content">
      {order.startDate && (
        <ContentLine
          label={FDN.I18n.t("booking.sidebar.startDate.label")}
          value={new Date(order.startDate)}
        />
      )}
      {order.nights ? (
        <ContentLine label={FDN.I18n.t("booking.sidebar.nights.label")} value={order.nights} />
      ) : null}
      {order.endDate && (
        <ContentLine
          label={FDN.I18n.t("booking.sidebar.endDate.label")}
          value={new Date(order.endDate)}
        />
      )}
    </div>
  );
};
const ContentPayment: React.FunctionComponent<ISidebarContentProps> = ({ order }) => {
  const currency = Config.get("currency");

  return (
    <div className="__booking-sidebar-content">
      {order.calculation.total ? (
        <ContentLine
          label={FDN.I18n.t("booking.sidebar.priceTotal.label")}
          value={`${currency} ${FDN.formatNumber(order.calculation.total, 2, 2)}`}
        />
      ) : null}
      {order.calculation.now ? (
        <ContentLine
          label={FDN.I18n.t("booking.sidebar.priceNow.label")}
          value={`${currency} ${FDN.formatNumber(order.calculation.now, 2, 2)}`}
        />
      ) : null}
      {order.calculation.later ? (
        <ContentLine
          label={FDN.I18n.t("booking.sidebar.priceLater.label")}
          value={`${currency} ${FDN.formatNumber(order.calculation.later, 2, 2)}`}
        />
      ) : null}
    </div>
  );
};

const ContentPersonal: React.FunctionComponent<ISidebarContentProps> = ({ order }) => {
  return (
    <div className="__booking-sidebar-content __booking-sidebar-content-text text-center">
      <div>
        <strong>{FDN.I18n.t("booking.sidebar.personal.label")}</strong>
      </div>
      <div>
        {order.firstname || order.lastname
          ? `${order.firstname || ""} ${order.lastname || ""}`
          : null}
      </div>
      <div>{order.street ? `${order.street}` : null}</div>
      <div>{order.streetAdditional ? `${order.streetAdditional}` : null}</div>
      <div>{order.zip || order.city ? `${order.zip || ""} ${order.city || ""}` : null}</div>
      <div>{order.country ? `${order.country}` : null}</div>
      <div>
        <br />
      </div>
      <div>
        <strong>{FDN.I18n.t("booking.sidebar.contact.label")}</strong>
      </div>
      <div>{order.email ? `${order.email}` : null}</div>
      <div>{order.phone ? `${order.phone}` : null}</div>
    </div>
  );
};

interface IContentLineProps {
  label: string;
  value: string | number | Date;
}

const ContentLine: React.FunctionComponent<IContentLineProps> = ({ label, value }) => {
  let formattedValue = "";
  if (isDate(value)) formattedValue = FDN.formatDate(value, { useHumanReadableDate: false });
  else if (typeof value === "number") formattedValue = `${value}`;
  else formattedValue = value;

  return (
    <div className="__booking-sidebar-content-line">
      <div className="__booking-sidebar-content-line-label">{label}</div>
      <div className="__booking-sidebar-content-line-value">{formattedValue}</div>
    </div>
  );
};

export default BookingSidebar;

import * as React from "react";
import * as FDN from "src/core";
import { FileUploadFile } from "../../ImageUpload/types";
import { useDropzone } from "react-dropzone";
import { AllowedFileTypes } from "../types";
import { TActionsFileUpload } from "../FileUploadService";
import { v4 } from "uuid";

interface IDropAreaProps {
  maxFiles?: number;
  allowedFileTypes: AllowedFileTypes[];
  actions: TActionsFileUpload;
  children?: React.ReactNode;
}

const DropArea: React.FunctionComponent<IDropAreaProps> = ({ maxFiles, actions, children }) => {
  const fileUploadType = maxFiles === 1 ? "single" : "multi";

  const onDrop = (acceptedFiles: File[]) => {
    const selectedFiles: FileUploadFile[] = [];

    for (const acceptedFile of acceptedFiles) {
      const selectedFile: FileUploadFile = {
        uuid: v4(),
        name: acceptedFile.name,
        size: acceptedFile.size,
        mime: acceptedFile.type,
        original: acceptedFile,
        uploadProgress: 0,
      };
      selectedFiles.push(selectedFile);
    }

    actions.selectFiles(selectedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles,
  });

  return (
    <>
      {children ? (
        <div className="__fileupload-no-dropzone" {...getRootProps()}>
          <input {...getInputProps()} />
          {children}
        </div>
      ) : (
        <div className="__filemultiupload-droparea" {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="__filemultupload-droparea-icon">
            <FDN.Icon icon="upload" />
          </div>
          <div className="__filemultupload-droparea-text">
            <div>
              <strong>{FDN.I18n.t(`filemultiupload.droparea.${fileUploadType}.title`)}</strong>
            </div>
            <div>{FDN.I18n.t(`filemultiupload.droparea.${fileUploadType}.text`)}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default DropArea;

import React from "react";
import * as FDN from "src/core";
import { IAdventureHotel } from "src/types";
import HotelPictureSlider from "./HotelPictureSlider";
import HotelStars from "src/components/pages/admin/adventures/parts/hotels/HotelStars";
import { nl2br } from "src/core/helpers/helpers";

interface HotelPreviewPopupProps {
  hotel: IAdventureHotel;
  night: number;
  hotelsForThisNight: IAdventureHotel[];
  onClose: () => void;
}

const HotelPreviewPopup = ({ hotel, night, hotelsForThisNight, onClose }: HotelPreviewPopupProps) => {
  if (!hotel || !hotel.info) return null;

  return (
    <FDN.Popup
      size="large"
      onClose={() => onClose()}
      title={
        <>
          {FDN.I18n.t("adminHotels.night")} {night}
        </>
      }
    >
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={10}>
            <HotelPictureSlider hotel={hotel.info} />
          </FDN.Cell>
          <FDN.Cell sm={24} md={14}>
            <h3>
              {hotel.info.name}{" "}
              {hotel.info.stars ? (
                <>
                  <br className="show-for-small-only" />
                  <HotelStars stars={hotel.info.stars} />{" "}
                </>
              ) : null}
            </h3>
            <div dangerouslySetInnerHTML={{ __html: nl2br(hotel.info.description || "") }} />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>

      {hotelsForThisNight.length > 1 && (
        <div className="border-t border-darkPurple-300 mt-8 pt-2 px-2">
          <h3>{FDN.I18n.t("adventures.hotels.upradePossibilites.title")}</h3>
          <div className="grid grid-cols-2 gap-4 md:grid-cols-6">
            {hotelsForThisNight.map((h, i) => {
              if (i === 0) return null;

              return (
                <div key={h.identifier} className="border border-darkPurple-300">
                  <div
                    className="w-full h-28 bg-cover bg-center"
                    style={{ backgroundImage: `url(${h?.info?.mainPicture?.S})` }}
                  />

                  {h && h.info && (
                    <div className="py-2 px-2">
                      <span className="font-medium">{h.info.name}</span>{" "}
                      {h.info.stars && (
                        <div>
                          <HotelStars stars={h.info.stars} />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </FDN.Popup>
  );
};

export default HotelPreviewPopup;

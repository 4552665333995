import * as React from "react";
import * as FDN from "src/core";
import { TActions, IUserroleState } from "src/types";

interface IUserRolePermissionsProps {
  editMode: boolean;
  userroleData: IUserroleState;
  actions: TActions;
  permissionGroups: { [key: string]: any };
}

const UserRolePermissions: React.FunctionComponent<IUserRolePermissionsProps> = ({
  editMode,
  userroleData,
  actions,
  permissionGroups,
}) => {
  return (
    <>
      <FDN.Grid full>
        <FDN.Row margin="x">
          <FDN.Cell sm={24} md={8}>
            <PermissionsGroup
              group={permissionGroups.general}
              groupKey="general"
              userrole={userroleData}
              hasEditPermission={editMode}
              togglePermission={actions.togglePermission}
            />
            <PermissionsGroup
              group={permissionGroups.admin}
              groupKey="admin"
              userrole={userroleData}
              hasEditPermission={editMode}
              togglePermission={actions.togglePermission}
            />
          </FDN.Cell>
          <FDN.Cell sm={24} md={8}>
            <PermissionsGroup
              group={permissionGroups.adminDashboard}
              groupKey="adminDashboard"
              userrole={userroleData}
              hasEditPermission={editMode}
              togglePermission={actions.togglePermission}
            />
            <PermissionsGroup
              group={permissionGroups.adminSettings}
              groupKey="adminSettings"
              userrole={userroleData}
              hasEditPermission={editMode}
              togglePermission={actions.togglePermission}
            />
            <PermissionsGroup
              group={permissionGroups.adminEmailTemplates}
              groupKey="adminEmailTemplates"
              userrole={userroleData}
              hasEditPermission={editMode}
              togglePermission={actions.togglePermission}
            />
            <PermissionsGroup
              group={permissionGroups.adminAccounts}
              groupKey="adminAccounts"
              userrole={userroleData}
              hasEditPermission={editMode}
              togglePermission={actions.togglePermission}
            />
            <PermissionsGroup
              group={permissionGroups.adminUserRoles}
              groupKey="adminUserRoles"
              userrole={userroleData}
              hasEditPermission={editMode}
              togglePermission={actions.togglePermission}
            />
          </FDN.Cell>
          <FDN.Cell sm={24} md={8}>
            <PermissionsGroup
              group={permissionGroups.adminAdventures}
              groupKey="adminAdventures"
              userrole={userroleData}
              hasEditPermission={editMode}
              togglePermission={actions.togglePermission}
            />
            <PermissionsGroup
              group={permissionGroups.adminOrders}
              groupKey="adminOrders"
              userrole={userroleData}
              hasEditPermission={editMode}
              togglePermission={actions.togglePermission}
            />
            <PermissionsGroup
              group={permissionGroups.adminDrivers}
              groupKey="adminDrivers"
              userrole={userroleData}
              hasEditPermission={editMode}
              togglePermission={actions.togglePermission}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </>
  );
};

interface IGroupPermissions {
  permissions: string[];
  icon: string;
}

interface IPermissionsGroup {
  userrole: IUserroleState;
  group: IGroupPermissions;
  groupKey: string;
  hasEditPermission: boolean;
  togglePermission: (property: string) => void;
}

const PermissionsGroup: React.FunctionComponent<IPermissionsGroup> = ({
  userrole,
  group,
  groupKey,
  hasEditPermission,
  togglePermission,
}) => {
  return (
    <FDN.Box small>
      <p>
        <strong>
          <FDN.Icon icon={group.icon} left />
          {FDN.I18n.t(`adminUserroles.permissions.${groupKey}.title`)}
        </strong>
      </p>
      {group.permissions.map((permission) => {
        return (
          <button
            onClick={() => {
              if (hasEditPermission === true) togglePermission(permission);
            }}
            className={`__admin-userrole-toggle-permission ${
              !hasEditPermission && `no-edit-permission`
            } ${userrole.permissions.includes(permission) ? `checked` : `unchecked`}`}
            key={permission}
          >
            <FDN.Icon icon="check" className="icon-checked" />
            <FDN.Icon icon="times" className="icon-unchecked" />{" "}
            {FDN.I18n.t(`adminUserroles.permission.${permission}.label`)}
          </button>
        );
      })}
    </FDN.Box>
  );
};

export default UserRolePermissions;

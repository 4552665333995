import React from "react";
import * as FDN from "src/core";
import { AdventureHotelsActions } from "src/services/admin/useAdventureHotels";
import { IAdventure, IAdventureHotel, IHotel } from "src/types";
import HotelStars from "../HotelStars";
import Config from "src/core/Config";
import { classNames } from "src/core/helpers/design";

interface SelectedHotelProps {
  adventure: IAdventure;
  selectedHotel: IAdventureHotel;
  hotels: IHotel[];
  actions: AdventureHotelsActions;
}

const SelectedHotel = ({ adventure, selectedHotel, actions }: SelectedHotelProps) => {
  // const hotel = hotels.find((hotel) => hotel.identifier === selectedHotel.hotelIdentifier);
  const hotel = selectedHotel.info;

  if (!hotel) return null;

  return (
    <div className="h-full overflow-y-auto">
      <h3>
        {hotel.name} {hotel.stars && <HotelStars stars={hotel.stars} />}
      </h3>
      <div className="text-sm text-gray-500 mb-2">
        {hotel.street ? <>{hotel.street},</> : null}{" "}
        {hotel.zip || hotel.city ? (
          <>
            {hotel.zip} {hotel.city},
          </>
        ) : null}{" "}
        {hotel.country}
      </div>
      {hotel.description && (
        <div className="text-xs text-gray-400 max-h-24 overflow-y-auto mb-2 pr-4">{hotel.description}</div>
      )}
      <div className="pt-2 mt-4 border-t">
        {(selectedHotel.rooms?.length ?? 0) > 0 && (
          <div className="flex justify-center items-center">
            <div className="border rounded my-4 px-6 py-2 bg-slate-50">
              <FDN.Input
                type="checkbox"
                editMode={true}
                value={selectedHotel.isDefault ?? false}
                onUpdate={() => actions.toggleDefaultHotel()}
                label={FDN.I18n.t("adminHotels.adventure.addHotel.isDefault.label")}
              />
            </div>
          </div>
        )}
        <h3 className="font-medium text-darkPurple-500">{FDN.I18n.t("adminHotels.adventure.addHotel.rooms.title")}</h3>
        <div className="pb-4">{FDN.I18n.t("adminHotels.adventure.addHotel.rooms.text")}</div>

        <div className="text-center py-3">
          <div className="text-gray-500 text-sm font-semibold">
            {FDN.I18n.t("adminHotels.adventure.addHotel.form.roomsSelected.label", {
              number: selectedHotel.rooms?.length ?? 0,
            })}
          </div>
          <div className="text-gray-500 text-xs font-base">
            {FDN.I18n.t("adminHotels.adventure.addHotel.form.roomsAvailable.label", {
              number: hotel.rooms?.length ?? 0,
            })}
          </div>
        </div>

        <div className="flex justify-between gap-4">
          <div className="w-10">&nbsp;</div>
          <div className="flex-grow">&nbsp;</div>
          <div className="w-[286px] text-center text-xs text-gray-500">
            {FDN.I18n.t("adminHotels.adventure.addHotel.form.additionalCharge.label")}
          </div>
        </div>
        <div className="border">
          {hotel.rooms?.map((room, index) => {
            const selectedHotelRoom = selectedHotel.rooms?.find(
              (hotelRoom) => hotelRoom.roomIdentifier === room.identifier
            );

            return (
              <div
                key={room.identifier}
                className={classNames(
                  "flex items-center justify-between gap-4 px-2 py-2 duration-200",
                  index !== 0 && "border-t",
                  selectedHotelRoom && "bg-emerald-50"
                )}
              >
                <div className="flex-grow">
                  <button
                    onClick={() => actions.toggleHotelRoom(room)}
                    className="flex items-center justify-start gap-2 pl-2 cursor-pointer duration-200 group hover:scale-110"
                  >
                    <div className="text-lg">
                      {selectedHotelRoom ? <FDN.Icon icon="check-square-o" /> : <FDN.Icon icon="square-o" />}
                    </div>
                    <div className="flex-grow text-base font-medium text-darkPurple-500 group-hover:underline">
                      {room.title}
                    </div>
                  </button>
                </div>

                <div className="w-[130px]">
                  {selectedHotelRoom && (
                    <FDN.Input
                      type="currency"
                      options={{
                        currency: Config.get("currency") as string,
                        locale: Config.get("currencyLocale") as string,
                      }}
                      editMode={true}
                      value={selectedHotelRoom.pricePerNightFull || "0.00"}
                      label={FDN.I18n.t("adminHotels.adventure.addHotel.form.additionalCharge.default.label")}
                      onUpdate={(value) =>
                        actions.editSelectedHotelRoomPrice(selectedHotelRoom.roomIdentifier, "default", value)
                      }
                    />
                  )}
                </div>
                <div className="w-[130px]">
                  {selectedHotelRoom && (
                    <FDN.Input
                      type="currency"
                      options={{
                        currency: Config.get("currency") as string,
                        locale: Config.get("currencyLocale") as string,
                      }}
                      editMode={true}
                      value={selectedHotelRoom.pricePerNightSingleFull || "0.00"}
                      label={FDN.I18n.t("adminHotels.adventure.addHotel.form.additionalCharge.single.label")}
                      onUpdate={(value) =>
                        actions.editSelectedHotelRoomPrice(selectedHotelRoom.roomIdentifier, "single", value)
                      }
                    />
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {adventure.nights && adventure.nights > 1 && selectedHotel.rooms && selectedHotel.rooms.length > 0 && (
          <div className="border rounded-md px-4 py-4 bg-slate-50 my-4">
            <div className="font-semibold text-brightPurple pb-2">
              {FDN.I18n.t("adminHotels.adventure.addHotel.hotelInNights.title")}
            </div>
            <div className="flex justify-center items-center gap-2 pt-4">
              {Array.from({ length: adventure.nights }, (_, i) => i + 1).map((night) => {
                return (
                  <button
                    key={night}
                    className="font-semibold text-gray-500 px-2 py-1 cursor-pointer hover:bg-purple-100 rounded-md duration-200 leading-5"
                    onClick={() => {
                      if (actions.isHotelAssignedToAdventure(selectedHotel.hotelIdentifier, night))
                        actions.removeHotelFromNight(selectedHotel, night);
                      else actions.addHotelToNight(selectedHotel, night);
                    }}
                  >
                    <FDN.Icon
                      icon={
                        actions.isHotelAssignedToAdventure(selectedHotel.hotelIdentifier, night)
                          ? "check-square-o"
                          : "square-o"
                      }
                    />{" "}
                    {FDN.I18n.t("adminHotels.night")} {night}
                  </button>
                );
              })}
            </div>
          </div>
        )}
        <div className="pt-2 text-center">
          <button
            className="text-red-400 cursor-pointer hover:underline"
            onClick={() => actions.removeHotelFromNight()}
          >
            {FDN.I18n.t("adminHotels.adventure.addHotel.buttons.removeHotel.label")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectedHotel;

import * as React from "react";
import * as FDN from "src/core";
import MyAccountHeader from "./myaccount/MyAccountHeader";
import TabSecurity from "./usersettings/TabSecurity";
import useServiceMySettings from "src/services/MySettingsService";
import { useNavigate } from "react-router-dom";

const tabs: { [key: string]: FDN.ITabsTab } = {
  // general: {
  //   label: FDN.I18n.t("usersettings.tabs.general.label"),
  //   icon: "user",
  // },
  // privacy: {
  //   label: FDN.I18n.t("usersettings.tabs.privacy.label"),
  //   icon: "key",
  // },
  security: {
    label: FDN.I18n.t("usersettings.tabs.security.label"),
    icon: "shield",
  },
};

const defaultTab = "security";

const MySettingsPage: React.FunctionComponent = () => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  const { actions, userSettings } = useServiceMySettings();

  const navigate = useNavigate();

  if (!userSettings) return <FDN.Loading />;

  return (
    <FDN.LayoutWithTopbar page="myaccount">
      <FDN.PageContent>
        <MyAccountHeader page="settings" />

        <FDN.Tabs
          tabs={tabs}
          editMode={true}
          selected={selectedTab}
          onSelect={(tabKey) => setSelectedTab(tabKey)}
          onUpdateUrl={(params) => navigate({ search: params })}
        />
        <FDN.TabsContent>
          {selectedTab === "security" && (
            <TabSecurity userSettings={userSettings} actions={actions} />
          )}
        </FDN.TabsContent>
      </FDN.PageContent>
    </FDN.LayoutWithTopbar>
  );
};

export default MySettingsPage;

import * as React from "react";
import ButtonImage from "src/assets/icons/WhatsAppButtonGreenSmall.png";

export const formatNumberForWhatsApp = (number: string): string => {
  if (number.slice(0, 2) === "00") {
    number = number.slice(2, number.length);
  }
  if (number.slice(0, 1) === "+") {
    number = number.slice(1, number.length);
  }

  return number;
};

export const getWhatsAppUrl = (number: string): string => {
  number = formatNumberForWhatsApp(number);
  return `https://wa.me/${number}`;
};

interface IWhatsappButtonProps {
  number: string;
}

const WhatsappButton: React.FunctionComponent<IWhatsappButtonProps> = ({ number }) => {
  const link = getWhatsAppUrl(number);

  return (
    <div className="__whatsappbutton">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <img src={ButtonImage} alt="Chat on WhatsApp" />
      </a>
    </div>
  );
};

export default WhatsappButton;

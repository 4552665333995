import React, { useState } from "react";
import { IDriver, IDrivers } from "../../types";
import useServiceCore from "../CoreService";
import StatusCode from "src/config/statuscodes";
import * as FDN from "src/core";
import AdminApi from "src/api/AdminApi";
import { cloneDeep } from "lodash";
import { userHasPermission } from "src/core/AdminService/helpers";

export interface TActionsAdminDrivers {
  selectDriver: (identifier: string) => void;
  onEdit: <P extends keyof IDriver, V extends IDriver[P]>(property: P, value: V) => void;
  onCancelEdit: () => void;
  onSaveEdit: () => void;
}

const useServiceAdminDrivers = () => {
  const [drivers, setDrivers] = useState<IDrivers>();
  const [driver, setDriver] = useState<IDriver>();

  const [editMode, setEditMode] = useState(false);

  const [virginDriver, setVirginDriver] = useState<IDriver>();
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const { APP, NOTIFICATIONS, api } = useServiceCore();
  const user = APP.getUser();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    AdminApi.adminDrivers(api).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const drivers = response?.body?.drivers as IDrivers;
        setDrivers(drivers);

        const virginDriver = response?.body?.virginDriver as IDriver;
        setVirginDriver(virginDriver);

        APP.setPageTitle(FDN.I18n.t("adminDrivers.pageTitle"));
      }
    });
  };

  const selectDriver = (identifier: string) => {
    if (identifier === "new") {
      if (userHasPermission(user, "adminDriversAdd")) {
        setDriver(cloneDeep(virginDriver));
        setEditMode(true);
      }
    } else {
      if (!drivers) return;
      const editDriver = drivers.find((d) => d.identifier === identifier);
      if (editDriver) {
        setDriver(cloneDeep(editDriver));
        if (userHasPermission(user, "adminDriversEdit")) setEditMode(true);
      }
    }
  };

  function onEdit<P extends keyof IDriver, V extends IDriver[P]>(property: P, value: V) {
    if (!driver) return;

    driver[property] = value;

    setDriver(cloneDeep(driver));
    setUnsavedChanges(true);
  }

  const onCancelEdit = () => {
    const doCancel = () => {
      setEditMode(false);
      setUnsavedChanges(false);
      setDriver(undefined);
      NOTIFICATIONS.hideDialog();
    };

    if (unsavedChanges)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  const onSaveEdit = () => {
    if (!driver || !editMode) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    AdminApi.adminSaveDriver(api, driver.identifier, driver).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const drivers = response?.body?.drivers as IDrivers;
        setDrivers(drivers);

        setEditMode(false);
        setUnsavedChanges(false);
        setDriver(undefined);

        NOTIFICATIONS.hideSaving();
        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("adminDrivers.popup.onSave.success.title"),
          FDN.I18n.t("adminDrivers.popup.onSave.success.text")
        );
      }
    });
  };

  const actions: TActionsAdminDrivers = {
    selectDriver,
    onEdit,
    onCancelEdit,
    onSaveEdit,
  };

  return { APP, drivers, driver, editMode, actions };
};

export default useServiceAdminDrivers;

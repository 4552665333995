import React, { useState } from "react";
import * as FDN from "src/core";
import {
  IAdventure,
  IFile,
  LocationCountries,
  TActions,
  IHotel,
  IHotels,
  IUpgrades,
  IUpgrade,
  IUpgradePrice,
  IPicture,
  AdventureHotelsByNight,
} from "src/types";
import useServiceCore from "../CoreService";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import { useParams } from "react-router-dom";
import { cloneDeep } from "lodash";
import UrlService from "src/core/UrlService";
import { userHasPermission } from "src/core/AdminService/helpers";
import { FOnPictureUploadsCompleted } from "src/components/main/FileManager";
import { v4 } from "uuid";
import localStorageKeys from "src/config/localstorage.json";

const editLockType = "adventure";

const useServiceAdminAdventure = () => {
  const { APP, NOTIFICATIONS, api } = useServiceCore();

  const user = APP.getUser();

  const [adventure, setAdventure] = React.useState<IAdventure | null>(null);
  const [original, setOriginal] = React.useState<IAdventure | null>(null);

  const [locationCountries, setLocationCountries] = useState<LocationCountries>();

  const [hotelAddPopupVisible, setHotelAddPopupVisible] = useState(false);
  const [hotelEditPopupVisible, setHotelEditPopupVisible] = useState(false);

  const [upgradeAddPopupVisible, setUpgradeAddPopupVisible] = useState(false);
  const [upgradeEditPopupVisible, setUpgradeEditPopupVisible] = useState(false);

  const [hotels, setHotels] = React.useState<IHotel[]>();
  const [hotel, setHotel] = useState<IHotel>();
  const [virginHotel, setVirginHotel] = useState<IHotel>();
  // const [virginHotelPrice, setVirginHotelPrice] = useState<IHotelPrice>();

  const [upgrades, setUpgrades] = React.useState<IUpgrades>();
  const [upgrade, setUpgrade] = useState<IUpgrade>();
  const [virginUpgrade, setVirginUpgrade] = useState<IUpgrade>();
  const [virginUpgradePrice, setVirginUpgradePrice] = useState<IUpgradePrice>();

  //const [editMode, setEditMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const params = useParams();
  const adventureIdentifier = params.identifier as string;

  React.useEffect(() => {
    init();
  }, []);

  React.useEffect(() => {
    if (adventure && !unsavedChanges) {
      if (adventure.identifier === "new") {
        if (!userHasPermission(user, "adminAdventuresAdd"))
          window.location.href = UrlService.url("admin.adventures.index");
        else toggleEditMode(true);

        APP.setPageTitle(FDN.I18n.t("adminAdventures.adventure.new.title"));
      } else {
        APP.setPageTitle(adventure.title);
      }
    }
  }, [adventure]);

  const init = () => {
    AdminApi.adminAdventure(api, adventureIdentifier).then((response) => {
      APP.setPageTitle(FDN.I18n.t("adminAdventures.pageTitle"));
      if (response?.statusCode === StatusCode.SUCCESS) {
        const adventure = response?.body?.adventure as IAdventure;
        setAdventure(adventure);
        APP.setPageTitle(adventure.title);

        setOriginal(cloneDeep(response?.body?.adventure as IAdventure));

        setLocationCountries(response?.body?.countries as LocationCountries);

        const virginHotel = response?.body?.virginHotel as IHotel;
        virginHotel.identifier = "new";
        setVirginHotel(virginHotel);

        // const virginHotelPrice = response?.body?.virginHotel as IHotelPrice;
        // virginHotelPrice.identifier = `new`;
        // setVirginHotelPrice(virginHotelPrice);

        const virginUpgrade = response?.body?.virginUpgrade as IUpgrade;
        virginUpgrade.identifier = "new";
        setVirginUpgrade(virginUpgrade);

        const virginUpgradePrice = response?.body?.virginUpgrade as IUpgradePrice;
        virginUpgradePrice.identifier = `new`;
        setVirginUpgradePrice(virginUpgradePrice);
      }
    });
  };

  const toggleEditMode = (value?: boolean) => {
    let checkForEditMode = editMode;

    if (value) checkForEditMode = !value;

    if (checkForEditMode === true) {
      if (adventure && adventure.identifier) AdminApi.deleteEditLock(api, editLockType, adventure.identifier);
      setEditMode(false);
    } else {
      const callback = () => {
        if (!adventure?.identifier) return;
        AdminApi.requestEditLock(api, NOTIFICATIONS, editLockType, adventure.identifier).then((result) => {
          if (result === StatusCode.EDITLOCK_OWN || result === StatusCode.EDITLOCK_NOT_NEEDED) {
            setEditMode(true);
          }
        });
      };

      if (adventure && adventure.identifier) showAdventureCachePopup(callback);
    }
  };

  const showAdventureCachePopup = (callback: () => void) => {
    const lsKey = `${localStorageKeys.ADMIN_ADVENTURE_CACHE}_${adventure?.identifier}`;
    const cachedAdventureJson = window.localStorage.getItem(lsKey);

    let cachedAdventure: IAdventure | null = null;

    if (cachedAdventureJson) cachedAdventure = JSON.parse(cachedAdventureJson) as IAdventure;

    if (cachedAdventure && adventure && cachedAdventure !== adventure) {
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: FDN.I18n.t("adminAdventures.cache.cachedAdventureFound.confirm", {
          modifiedAt: FDN.formatDateTime(cachedAdventure.modifiedAt, { seconds: true, useHumanReadableDate: true }),
        }),
        buttons: [
          {
            type: "cancel",
            onClick: () => {
              NOTIFICATIONS.hideDialog();
              callback();
            },
          },
          {
            type: "ok",
            onClick: () => {
              setAdventure(cachedAdventure);
              NOTIFICATIONS.hideDialog();
              callback();
            },
          },
        ],
      });
    } else {
      callback();
    }
  };

  function onEdit<P extends keyof IAdventure, V extends IAdventure[P]>(property: P, value: V) {
    if (!adventure) return;
    adventure[property] = value;

    if (property === "pictures" && value) {
      const pictureIdentifiers: string[] = [];
      for (const picture of adventure.pictures) {
        pictureIdentifiers.push(picture.identifier);
      }
      adventure.pictureIdentifiers = pictureIdentifiers;
    } else if (property === "hotelsByNight") {
      const hbn = value as AdventureHotelsByNight;
      if (hbn && 0 in hbn) delete hbn[0];
      adventure.hotelsByNight = hbn;
    }

    setAdventure(cloneDeep(adventure));
    setUnsavedChanges(true);

    const adventureToCache = cloneDeep(adventure);
    adventureToCache.modifiedAt = new Date();
    const jsonAdventure = JSON.stringify(adventureToCache);
    const lsKey = `${localStorageKeys.ADMIN_ADVENTURE_CACHE}_${adventure.identifier}`;
    window.localStorage.setItem(lsKey, jsonAdventure);
  }

  const onUpdatePicturesOrder = (pictureIdentifiers: string[]) => {
    onEdit("pictureIdentifiers", pictureIdentifiers);
  };

  const onRemovePictures = (identifier: string) => {
    if (!adventure) return;

    const doConfirm = () => {
      const updatedFiles: IFile[] = [];

      for (const file of adventure.pictures) {
        if (file.identifier !== identifier) updatedFiles.push(file);
      }

      actions.onEdit("pictures", updatedFiles);
      NOTIFICATIONS.hideDialog();
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("filemanager.onRemove.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
          },
        },
        {
          type: "ok",
          onClick: () => {
            if (doConfirm) doConfirm();
          },
        },
      ],
    });
  };

  const duplicateAdventure = () => {
    if (!adventure) return;

    if (window.confirm(FDN.I18n.t("adminAdventures.buttons.duplicate.confirm"))) {
      AdminApi.duplicateAdventure(api, adventure?.identifier).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          const duplicatedAdventure = response?.body?.adventure as IAdventure;
          if (duplicatedAdventure && duplicatedAdventure.identifier) {
            window.location.href = UrlService.url("admin.adventures.show", {
              identifier: duplicatedAdventure.identifier,
            });
          }
        }
      });
    }
  };

  const onCancel = () => {
    const doCancel = () => {
      if (adventure)
        AdminApi.deleteEditLock(api, editLockType, adventure.identifier).then(() => {
          if (adventure.identifier === "new") window.location.href = UrlService.url("admin.adventures.index");
          else {
            setAdventure(cloneDeep(original));
            toggleEditMode();
            NOTIFICATIONS.hideDialog();

            const lsKey = `${localStorageKeys.ADMIN_ADVENTURE_CACHE}_${adventure.identifier}`;
            window.localStorage.removeItem(lsKey);
          }
        });
    };

    if (unsavedChanges)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  const onSave = () => {
    if (!adventure) return;

    //console.log("saving adventure...", adventure);

    NOTIFICATIONS.showSaving({ type: "save" });

    AdminApi.saveAdminAdventure(api, adventure.identifier, adventure).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const savedAdventure = response?.body?.adventure;
        if (savedAdventure) {
          const lsKey = `${localStorageKeys.ADMIN_ADVENTURE_CACHE}_${adventure.identifier}`;
          window.localStorage.removeItem(lsKey);

          if (adventure.identifier === "new")
            window.location.href = UrlService.url("admin.adventures.show", {
              identifier: savedAdventure.identifier,
            });
          else {
            setAdventure(savedAdventure);
            setOriginal(cloneDeep(savedAdventure));
            toggleEditMode();
            NOTIFICATIONS.hideSaving();
            NOTIFICATIONS.showNotification(
              "success",
              FDN.I18n.t("adminAdventures.onSave.success.title"),
              FDN.I18n.t("adminAdventures.onSave.success.text")
            );
          }
        }
      }
    });
  };

  const onPictureUploadsCompleted: FOnPictureUploadsCompleted = (files) => {
    console.log("updating adventure pictures...");

    if (!adventure) return;

    const updatedPictures: IFile[] = adventure.pictures || [];

    for (const file of files) {
      const alreadyUploaded = updatedPictures.find((p) => p.identifier === file.identifier);
      if (!alreadyUploaded) updatedPictures.push(file);
    }

    //const updatedPictures = adventure.pictures ? [...adventure.pictures, ...files] : files;
    const updatedPictureIdentifiers = updatedPictures.map((picture) => picture.identifier);

    console.log("updPic", updatedPictures);
    console.log("updPicIden", updatedPictureIdentifiers);

    adventure.pictures = updatedPictures;
    adventure.pictureIdentifiers = updatedPictureIdentifiers;
    setAdventure(cloneDeep(adventure));
  };

  const onEditIncluded = (index: number, text: string) => {
    if (!adventure || !adventure.included) return;

    if (index in adventure.included) adventure.included[index] = text;

    setAdventure(cloneDeep(adventure));
  };

  const onAddIncluded = () => {
    if (!adventure) return;
    if (!adventure.included) adventure.included = [];

    adventure.included.push("");
    setAdventure(cloneDeep(adventure));
  };

  const onRemoveIncluded = (index: number) => {
    if (!adventure || !adventure.included) return;

    if (index in adventure.included) adventure.included.splice(index, 1);

    setAdventure(cloneDeep(adventure));
  };

  const showHotelAddPopup = () => {
    AdminApi.adminHotels(api).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const hotels = response?.body?.hotels as IHotels;

        if (!hotels || hotels.length === 0) showHotelEditPopup("new");
        else setHotels(hotels);

        setHotelAddPopupVisible(true);
      }
    });
  };

  const hideHotelAddPopup = () => {
    setHotelAddPopupVisible(false);
  };

  const showHotelEditPopup = (hotel: IHotel | "new") => {
    if (hotel === "new") setHotel(cloneDeep(virginHotel));
    else setHotel(cloneDeep(hotel));

    setHotelEditPopupVisible(true);
    hideHotelAddPopup();
  };

  const hideHotelEditPopup = () => {
    if (window.confirm(FDN.I18n.t("adminAdventures.hotels.editPopup.onClose.confirm"))) {
      setHotel(undefined);
      setHotelEditPopupVisible(false);
    }
  };

  function onEditHotel<P extends keyof IHotel, V extends IHotel[P]>(property: P, value: V) {
    if (!hotel) return;
    hotel[property] = value;

    if (property === "pictureIdentifiers" && hotel.pictureIdentifiers && hotel.pictureIdentifiers.length > 0) {
      const updatedPictures: IPicture[] = [];
      for (const picIdentifier of hotel.pictureIdentifiers) {
        const pic = hotel.pictures.find((p) => p.identifier === picIdentifier);
        if (pic) updatedPictures.push(pic);
      }
      hotel.pictures = updatedPictures;

      const mainPictureIdentifier = hotel.pictureIdentifiers[0];
      hotel.mainPicture = hotel.pictures.find((p) => p.identifier === mainPictureIdentifier);
    }

    setHotel(cloneDeep(hotel));
  }

  const onUpdateHotelPicturesOrder = (pictureIdentifiers: string[]) => {
    onEditHotel("pictureIdentifiers", pictureIdentifiers);
  };

  const onRemoveHotelPictures = (identifier: string) => {
    if (!hotel) return;

    const doConfirm = () => {
      const updatedFiles: IFile[] = [];

      for (const file of hotel.pictures) {
        if (file.identifier !== identifier) updatedFiles.push(file);
      }

      actions.onEditHotel("pictures", updatedFiles);
      NOTIFICATIONS.hideDialog();
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("filemanager.onRemove.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
          },
        },
        {
          type: "ok",
          onClick: () => {
            if (doConfirm) doConfirm();
          },
        },
      ],
    });
  };

  const onHotelPictureUploadsCompleted: FOnPictureUploadsCompleted = (files) => {
    if (!hotel) return;

    const updatedPictures: IFile[] = hotel.pictures || [];

    for (const file of files) {
      const alreadyUploaded = updatedPictures.find((p) => p.identifier === file.identifier);
      if (!alreadyUploaded) updatedPictures.push(file);
    }

    //const updatedPictures = hotel.pictures ? [...hotel.pictures, ...files] : files;
    const updatedPictureIdentifiers = updatedPictures.map((picture) => picture.identifier);

    console.log("updPic", updatedPictures);
    console.log("updPicIden", updatedPictureIdentifiers);

    hotel.pictures = updatedPictures;
    hotel.pictureIdentifiers = updatedPictureIdentifiers;
    setHotel(cloneDeep(hotel));
  };

  // const addHotelToAdventure = (hotel: IHotel) => {
  //   if (!virginHotelPrice || !adventure) return;

  //   let hotelPrice: IHotelPrice | undefined = adventure.hotelPrices?.find(
  //     (hp) => hp.hotelIdentifier === hotel.identifier
  //   );

  //   if (!hotelPrice) {
  //     hotelPrice = cloneDeep(virginHotelPrice);
  //     hotelPrice.identifier = `new_${v4()}`;
  //     hotelPrice.adventureIdentifier = adventure.identifier;
  //     hotelPrice.hotelIdentifier = hotel.identifier;
  //     hotelPrice.priceFull = hotel.priceFull;
  //     hotelPrice.priceSingleFull = hotel.priceSingleFull;

  //     // if (!adventure.hotelPrices || adventure.hotelPrices?.length === 0)
  //     //   adventure.defaultHotel = hotel.identifier;

  //     if (!adventure.hotels) adventure.hotels = [];
  //     if (!adventure.hotelPrices) adventure.hotelPrices = [];

  //     adventure.hotels.push(hotel);
  //     adventure.hotelPrices.push(hotelPrice);
  //     setAdventure(cloneDeep(adventure));
  //   }

  //   return adventure;
  // };

  // const removeHotelFromAdventure = (hotelIdentifier: string) => {
  //   if (!adventure || !adventure.hotels || !adventure.hotelPrices) return;

  //   adventure.hotels = adventure.hotels.filter((h) => h.identifier !== hotelIdentifier);
  //   adventure.hotelPrices = adventure.hotelPrices.filter((hp) => hp.hotelIdentifier !== hotelIdentifier);

  //   setAdventure(cloneDeep(adventure));
  // };

  // const onEditHotelPrice = (
  //   hotelPriceIdentifier: string,
  //   property: "priceFull" | "priceSingleFull",
  //   value: number | boolean
  // ) => {
  //   if (!adventure || !adventure.hotelPrices) return;

  //   for (const hotelPrice of adventure.hotelPrices) {
  //     if (hotelPrice.identifier === hotelPriceIdentifier) {
  //       if (property === "priceFull") hotelPrice.priceFull = value as number;
  //       else if (property === "priceSingleFull") hotelPrice.priceSingleFull = value as number;
  //     }
  //   }

  //   setAdventure(cloneDeep(adventure));
  // };

  const duplicateHotel = (hotel: IHotel) => {
    if (window.confirm(FDN.I18n.t("adminAdventures.hotels.list.buttons.duplicate.confirm"))) {
      const newHotel = cloneDeep(hotel);
      newHotel.identifier = "new";

      showHotelEditPopup(newHotel);
    }
  };

  const onCancelHotel = () => {
    const doCancel = () => {
      setHotel(undefined);
      setHotelEditPopupVisible(false);
      showHotelAddPopup();
      NOTIFICATIONS.hideDialog();
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => doCancel(),
        },
      ],
    });
  };

  const onSaveHotel = () => {
    if (!hotel) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    const hotelIdentifier = hotel.identifier;
    AdminApi.adminSaveHotel(api, hotelIdentifier, hotel).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const hotels = response?.body?.hotels as IHotels;
        setHotels(cloneDeep(hotels));
        //setAdventure(response?.body?.adventure as IAdventure);

        const updatedHotel = response?.body?.hotel;
        //console.log("updated hotel", updatedHotel);
        if (updatedHotel && updatedHotel.identifier) {
          // const adventure = addHotelToAdventure(updatedHotel);
          // if (adventure) {
          //   adventure.hotels = hotels;
          //   setAdventure(cloneDeep(adventure));
          // }
        }

        setHotel(undefined);
        if (hotelIdentifier !== "new") showHotelAddPopup();
        NOTIFICATIONS.hideSaving();
      }
    });
  };

  const showUpgradeAddPopup = () => {
    AdminApi.adminUpgrades(api).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const upgrades = response?.body?.upgrades as IUpgrades;

        if (!upgrades || upgrades.length === 0) showUpgradeEditPopup("new");
        else setUpgrades(cloneDeep(upgrades));

        setUpgradeAddPopupVisible(true);
      }
    });
  };

  const hideUpgradeAddPopup = () => {
    setUpgradeAddPopupVisible(false);
  };

  const showUpgradeEditPopup = (upgrade: IUpgrade | "new") => {
    if (upgrade === "new") setUpgrade(cloneDeep(virginUpgrade));
    else setUpgrade(upgrade);

    setUpgradeEditPopupVisible(true);
    hideUpgradeAddPopup();
  };

  const hideUpgradeEditPopup = () => {
    if (window.confirm(FDN.I18n.t("adminAdventures.upgrades.editPopup.onClose.confirm"))) {
      setUpgrade(undefined);
      setUpgradeEditPopupVisible(false);
    }
  };

  function onEditUpgrade<P extends keyof IUpgrade, V extends IUpgrade[P]>(property: P, value: V) {
    if (!upgrade) return;
    upgrade[property] = value;

    if (property === "pictureIdentifiers" && upgrade.pictureIdentifiers && upgrade.pictureIdentifiers.length > 0) {
      const mainPictureIdentifier = upgrade.pictureIdentifiers[0];
      upgrade.mainPicture = upgrade.pictures.find((p) => p.identifier === mainPictureIdentifier);
    }

    setUpgrade(cloneDeep(upgrade));
  }

  const onUpdateUpgradePicturesOrder = (pictureIdentifiers: string[]) => {
    onEditUpgrade("pictureIdentifiers", pictureIdentifiers);
  };

  const onRemoveUpgradePictures = (identifier: string) => {
    if (!upgrade) return;

    const doConfirm = () => {
      const updatedFiles: IFile[] = [];

      for (const file of upgrade.pictures) {
        if (file.identifier !== identifier) updatedFiles.push(file);
      }

      actions.onEditUpgrade("pictures", updatedFiles);
      NOTIFICATIONS.hideDialog();
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("filemanager.onRemove.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
          },
        },
        {
          type: "ok",
          onClick: () => {
            if (doConfirm) doConfirm();
          },
        },
      ],
    });
  };

  const onUpgradePictureUploadsCompleted: FOnPictureUploadsCompleted = (files) => {
    if (!upgrade) return;

    const updatedPictures: IFile[] = upgrade.pictures || [];

    for (const file of files) {
      const alreadyUploaded = updatedPictures.find((p) => p.identifier === file.identifier);
      if (!alreadyUploaded) updatedPictures.push(file);
    }

    //const updatedPictures = upgrade.pictures ? [...upgrade.pictures, ...files] : files;
    const updatedPictureIdentifiers = updatedPictures.map((picture) => picture.identifier);

    console.log("updPic", updatedPictures);
    console.log("updPicIden", updatedPictureIdentifiers);

    upgrade.pictures = updatedPictures;
    upgrade.pictureIdentifiers = updatedPictureIdentifiers;
    setUpgrade(cloneDeep(upgrade));
  };

  const addUpgradeToAdventure = (upgrade: IUpgrade) => {
    if (!virginUpgradePrice || !adventure) return;

    let upgradePrice: IUpgradePrice | undefined = adventure.upgradePrices?.find(
      (hp) => hp.upgradeIdentifier === upgrade.identifier
    );

    if (!upgradePrice) {
      upgradePrice = cloneDeep(virginUpgradePrice);
      upgradePrice.identifier = `new_${v4()}`;
      upgradePrice.adventureIdentifier = adventure.identifier;
      upgradePrice.upgradeIdentifier = upgrade.identifier;
      upgradePrice.priceFull = upgrade.priceFull;
      upgradePrice.priceSingleFull = upgrade.priceSingleFull;

      // if (!adventure.upgradePrices || adventure.upgradePrices?.length === 0)
      //   adventure.defaultUpgrade = upgrade.identifier;

      if (!adventure.upgrades) adventure.upgrades = [];
      if (!adventure.upgradePrices) adventure.upgradePrices = [];

      adventure.upgrades.push(upgrade);
      adventure.upgradePrices.push(upgradePrice);
      setAdventure(cloneDeep(adventure));
    }

    return adventure;
  };

  const removeUpgradeFromAdventure = (upgradeIdentifier: string) => {
    if (!adventure || !adventure.upgrades || !adventure.upgradePrices) return;

    adventure.upgrades = adventure.upgrades.filter((h) => h.identifier !== upgradeIdentifier);
    adventure.upgradePrices = adventure.upgradePrices.filter((hp) => hp.upgradeIdentifier !== upgradeIdentifier);

    setAdventure(cloneDeep(adventure));
  };

  const onEditUpgradePrice = (
    upgradePriceIdentifier: string,
    property: "priceFull" | "priceSingleFull",
    value: number | boolean
  ) => {
    if (!adventure || !adventure.upgradePrices) return;

    for (const upgradePrice of adventure.upgradePrices) {
      if (upgradePrice.identifier === upgradePriceIdentifier) {
        if (property === "priceFull") upgradePrice.priceFull = value as number;
        else if (property === "priceSingleFull") upgradePrice.priceSingleFull = value as number;
      }
    }

    setAdventure(cloneDeep(adventure));
  };

  const duplicateUpgrade = (upgrade: IUpgrade) => {
    if (window.confirm(FDN.I18n.t("adminAdventures.upgrades.list.buttons.duplicate.confirm"))) {
      const newUpgrade = cloneDeep(upgrade);
      newUpgrade.identifier = "new";

      showUpgradeEditPopup(newUpgrade);
    }
  };

  const onCancelUpgrade = () => {
    const doCancel = () => {
      setUpgrade(undefined);
      setUpgradeEditPopupVisible(false);
      showUpgradeAddPopup();
      NOTIFICATIONS.hideDialog();
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => doCancel(),
        },
      ],
    });
  };

  const onSaveUpgrade = () => {
    if (!upgrade) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    const upgradeIdentifier = upgrade.identifier;
    AdminApi.adminSaveUpgrade(api, upgradeIdentifier, upgrade).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const upgrades = response?.body?.upgrades as IUpgrades;
        setUpgrades(upgrades);
        //setAdventure(response?.body?.adventure as IAdventure);

        const updatedUpgrade = response?.body?.upgrade;
        //console.log("updated upgrade", updatedUpgrade);
        if (updatedUpgrade && updatedUpgrade.identifier) {
          const adventure = addUpgradeToAdventure(updatedUpgrade);
          if (adventure) {
            adventure.upgrades = upgrades;

            setAdventure(cloneDeep(adventure));
          }
        }

        setUpgrade(undefined);
        if (upgradeIdentifier !== "new") showUpgradeAddPopup();
        NOTIFICATIONS.hideSaving();
      }
    });
  };

  const updateHotelsAssignedToAdventure = (hotelsByNight: AdventureHotelsByNight) => {
    console.log("updating hotelsByNight...", hotelsByNight);

    onEdit("hotelsByNight", hotelsByNight);
  };

  const actions: TActions = {
    onEdit,
    onRemovePictures,
    onUpdatePicturesOrder,
    onPictureUploadsCompleted,
    toggleEditMode,
    onCancel,
    onSave,
    duplicateAdventure,
    onEditIncluded,
    onAddIncluded,
    onRemoveIncluded,
    showHotelAddPopup,
    hideHotelAddPopup,
    showHotelEditPopup,
    hideHotelEditPopup,
    onEditHotel,
    onUpdateHotelPicturesOrder,
    onRemoveHotelPictures,
    onHotelPictureUploadsCompleted,
    // addHotelToAdventure,
    // removeHotelFromAdventure,
    duplicateHotel,
    // onEditHotelPrice,
    onCancelHotel,
    onSaveHotel,
    showUpgradeAddPopup,
    hideUpgradeAddPopup,
    showUpgradeEditPopup,
    hideUpgradeEditPopup,
    onEditUpgrade,
    onUpdateUpgradePicturesOrder,
    onRemoveUpgradePictures,
    onUpgradePictureUploadsCompleted,
    addUpgradeToAdventure,
    removeUpgradeFromAdventure,
    duplicateUpgrade,
    onEditUpgradePrice,
    onCancelUpgrade,
    onSaveUpgrade,
    updateHotelsAssignedToAdventure,
  };

  return {
    actions,
    editMode,
    adventure,
    locationCountries,
    hotelAddPopupVisible,
    hotelEditPopupVisible,
    hotel,
    hotels,
    upgradeAddPopupVisible,
    upgradeEditPopupVisible,
    upgrade,
    upgrades,
  };
};

export default useServiceAdminAdventure;

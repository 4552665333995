import React from "react";
import RichTextEditor from "src/components/main/RichTextEditor";
import * as FDN from "src/core";
import { UseOrderHotelEmailActions } from "src/services/admin/useOrderHotelEmail";
import { IOrder, IOrderHotel, OrderHotelEmailSent } from "src/types";

interface PopupSendEmailProps {
  order: IOrder;
  hotel: IOrderHotel;
  emailToHotel: Partial<OrderHotelEmailSent | undefined>;
  actions: UseOrderHotelEmailActions;
}

const PopupSendEmail = ({ order, hotel, emailToHotel, actions }: PopupSendEmailProps) => {
  return (
    <FDN.Popup title={FDN.I18n.t("adminHotels.order.email.popup.title")} onClose={actions.onCancel}>
      {!emailToHotel ? (
        <FDN.Loading box />
      ) : (
        <>
          <div dangerouslySetInnerHTML={{ __html: FDN.I18n.t("adminHotels.order.email.popup.text") }} />
          <div className="pt-4">
            <FDN.Grid>
              <FDN.Row margin="xy">
                <FDN.Cell sm={24}>
                  <FDN.Input
                    type="email"
                    value={emailToHotel.to ?? ""}
                    label={FDN.I18n.t("adminHotels.order.email.popup.form.email.label")}
                    editMode
                    onUpdate={(value) => actions.onEditEmail("to", value)}
                  />
                </FDN.Cell>
              </FDN.Row>
              <FDN.Row margin="xy">
                <FDN.Cell sm={24}>
                  <FDN.Input
                    type="text"
                    value={emailToHotel.subject ?? ""}
                    label={FDN.I18n.t("adminHotels.order.email.popup.form.subject.label")}
                    editMode
                    onUpdate={(value) => actions.onEditEmail("subject", value)}
                  />
                </FDN.Cell>
              </FDN.Row>
              <FDN.Row margin="xy">
                <FDN.Cell sm={24}>
                  <RichTextEditor
                    type="email"
                    size="big"
                    returnContentType="html"
                    initialHtml={emailToHotel.body ?? ""}
                    onUpdate={(editorState) => actions.onEditEmail("body", editorState as string)}
                  />
                </FDN.Cell>
              </FDN.Row>
            </FDN.Grid>

            <div className="pt-4">
              <FDN.FormButtons
                smallButtons
                onCancel={actions.onCancel}
                onSave={actions.onSendEmail}
                saveLabel={FDN.I18n.t("adminHotels.order.email.popup.buttons.onSendEmail.label")}
                saveDisabled={!emailToHotel.to || !emailToHotel.subject || !emailToHotel.body}
              />
            </div>
          </div>
        </>
      )}
    </FDN.Popup>
  );
};

export default PopupSendEmail;

import * as React from "react";
import { IUserNotifications } from "src/components/context/NotificationsContext/types";
import UserNotificationsListItem from "./UserNotificationsListItem";
import { TActions } from "src/types";

interface IUserNotificationsListProps {
  userNotifications: IUserNotifications;
  actions: TActions;
}

const UserNotificationsList: React.FunctionComponent<IUserNotificationsListProps> = ({
  userNotifications,
  actions,
}) => {
  return (
    <ul className="__usernotifications-list">
      {userNotifications.filteredNotifications?.map((userNotification) => {
        return (
          <li key={userNotification.identifier}>
            <UserNotificationsListItem userNotification={userNotification} actions={actions} />
          </li>
        );
      })}
    </ul>
  );
};

export default UserNotificationsList;

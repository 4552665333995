import * as React from "react";
import { useParams } from "react-router-dom";
import BookingApi from "src/api/BookingApi";
import StatusCode from "src/config/statuscodes";
import * as FDN from "src/core";
import { nl2br } from "src/core/helpers/helpers";
import useServiceCore from "src/services/CoreService";
import { IAdventure, IOrder } from "src/types";
import ConfirmationReview from "./parts/ConfirmationReview";

const BookingConfirmation: React.FunctionComponent = () => {
  const [order, setOrder] = React.useState<IOrder>();
  const [adventure, setAdventure] = React.useState<IAdventure>();

  const params = useParams();
  const orderNr = params.orderNr;
  const orderToken = params.orderToken;

  const { api, APP } = useServiceCore();

  React.useEffect(() => {
    if (!orderNr || !orderToken) return;

    BookingApi.getOrderForConfirmation(api, orderNr, orderToken).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setOrder(response?.body?.order as IOrder);
        setAdventure(response?.body?.adventure as IAdventure);
        APP.setPageTitle(FDN.I18n.t("booking.confirmation.title"));
      }
    });
  }, []);

  if (!order || !adventure) return <FDN.Loading />;

  return (
    <FDN.LayoutWithTopbar page="booking-confirmation">
      <FDN.PageContent>
        <div className="__booking-confirmation">
          <FDN.Grid>
            <FDN.Row>
              <FDN.Cell sm={24} md={18} mdo={3} lg={12} lgo={6}>
                <FDN.CalloutBox type="success">
                  <h1>{FDN.I18n.t("booking.confirmation.title")}</h1>
                  <p
                    dangerouslySetInnerHTML={{ __html: FDN.I18n.t("booking.confirmation.intro") }}
                  />
                </FDN.CalloutBox>

                <ConfirmationReview order={order} adventure={adventure} />
                <AdventurePaymentPolicy adventure={adventure} />

                <div>&nbsp;</div>
              </FDN.Cell>
            </FDN.Row>
          </FDN.Grid>
        </div>
      </FDN.PageContent>
    </FDN.LayoutWithTopbar>
  );
};

interface IAdventureBodyProps {
  adventure: IAdventure;
}

const AdventurePaymentPolicy: React.FunctionComponent<IAdventureBodyProps> = ({ adventure }) => {
  if (!adventure.paymentPolicy) return null;

  return (
    <div className="__public-adventure-paymentpolicy">
      <h3>{FDN.I18n.t("adventures.paymentPolicy.title")}</h3>
      <div
        className="__public-adventure-paymentpolicy-text"
        dangerouslySetInnerHTML={{ __html: nl2br(adventure.paymentPolicy || "") }}
      />
    </div>
  );
};

export default BookingConfirmation;

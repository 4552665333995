import React from "react";
import * as FDN from "src/core";
import { TActions } from "src/types";
import useServiceCore from "../CoreService";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import UrlService from "src/core/UrlService";

type DashboardState = { [key: string]: any };

const useServiceAdminDashboard = () => {
  const { APP, api } = useServiceCore();

  const [dashboard, setDashboard] = React.useState<DashboardState | null>(null);

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    AdminApi.adminDashboard(api).then((response) => {
      APP.setPageTitle(FDN.I18n.t("adminDashboard.pageTitle"));
      if (response?.statusCode === StatusCode.SUCCESS) {
        if (response?.body?.redirectToAdminArea) {
          window.location.href = UrlService.url(
            `admin.${response?.body?.redirectToAdminArea}.index`
          );
          return;
        }

        setDashboard(response?.body?.dashboard as DashboardState);
      }
    });
  };

  const actions: TActions = {};

  return { actions, dashboard };
};

export default useServiceAdminDashboard;

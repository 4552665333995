import * as React from "react";
import { BookingContext } from "src/components/context/BookingContext/BookingContext";
import * as FDN from "src/core";
import { getAdventurePictureUrl, nl2br } from "src/core/helpers/helpers";
import { IAdventure, IOrder } from "src/types";
import BookingPageTitle from "../parts/BookingPageTitle";
import BookingPaymentPlanSummary from "../parts/BookingPaymentPlanSummary";
import UrlService from "src/core/UrlService";
import useServiceCore from "src/services/CoreService";
import HotelStars from "src/components/pages/admin/adventures/parts/hotels/HotelStars";
import Config from "src/core/Config";

interface IBookingPreviewProps {
  adventure: IAdventure;
}

const BookingPreview: React.FunctionComponent<IBookingPreviewProps> = ({ adventure }) => {
  const BOOKING = React.useContext(BookingContext);
  const { order } = BOOKING;

  if (!order) return <FDN.Loading box />;

  return (
    <div className="__booking-form-page-preview" id="booking_preview">
      <BookingPageTitle page="preview" order={order} />
      <div>&nbsp;</div>
      <PreviewAdventure order={order} adventure={adventure} />
      <PreviewDate order={order} adventure={adventure} />
      <PreviewParticipants order={order} adventure={adventure} />
      <PreviewHotelByNight order={order} adventure={adventure} />
      <PreviewUpgrades order={order} adventure={adventure} />
      <PreviewPaymentPlan order={order} adventure={adventure} />
      <PreviewTerms order={order} adventure={adventure} />
      <PreviewMissingFields />
      <PreviewButtons order={order} adventure={adventure} />
    </div>
  );
};

interface IPreviewBox {
  order: IOrder;
  adventure: IAdventure;
}

const PreviewAdventure: React.FunctionComponent<IPreviewBox> = ({ adventure }) => {
  return (
    <>
      <h3>{FDN.I18n.t("booking.form.group.preview.box.adventure.title")}</h3>
      <FDN.Box>
        <div className="__booking-form-preview-box-adventure">
          {adventure.mainPicture && (
            <div
              className="__booking-form-preview-box-adventure-picture"
              style={{
                backgroundImage: `url("${getAdventurePictureUrl(adventure.mainPicture, "M")}")`,
              }}
            ></div>
          )}
          <div>
            <h4>{adventure.title}</h4>
            <div>{adventure.description}</div>
          </div>
        </div>
      </FDN.Box>
    </>
  );
};

const PreviewDate: React.FunctionComponent<IPreviewBox> = ({ order }) => {
  return (
    <>
      <h3>{FDN.I18n.t("booking.form.group.preview.box.date.title")}</h3>
      <FDN.Box>
        <div className="__booking-form-preview-box-date">
          <div>{FDN.formatDate(order.startDate)}</div>
          {order.nights && order.endDate ? (
            <>
              <div className="__booking-form-preview-box-date-arrow">
                <FDN.Icon icon="long-arrow-right" />
              </div>
              <div>
                <FDN.Icon icon="moon-o" left /> {order.nights} {FDN.I18n.t("booking.sidebar.nights.label")}
              </div>
              <div className="__booking-form-preview-box-date-arrow">
                <FDN.Icon icon="long-arrow-right" />
              </div>
              <div>{FDN.formatDate(order.endDate)}</div>
            </>
          ) : null}
        </div>
      </FDN.Box>
    </>
  );
};

const PreviewParticipants: React.FunctionComponent<IPreviewBox> = ({ order }) => {
  return (
    <>
      <h3>{FDN.I18n.t("booking.form.group.preview.box.participants.title")}</h3>
      {order.participants.map((participant, index) => {
        return (
          <FDN.Box noMargin key={index} style={{ marginBottom: "5px" }}>
            <FDN.Grid full>
              <FDN.Row>
                <FDN.Cell sm={24} md={18}>
                  {`${participant.salutation || ""} ${participant.firstname || ""} ${participant.lastname || ""}`}
                </FDN.Cell>
                <FDN.Cell sm={24} md={6} className="text-right">
                  {FDN.formatDate(participant.birthday)}
                </FDN.Cell>
              </FDN.Row>
            </FDN.Grid>
          </FDN.Box>
        );
      })}
    </>
  );
};

const PreviewHotelByNight: React.FunctionComponent<IPreviewBox> = ({ order }) => {
  if (!order.hotelsByNight) return null;

  const currency = Config.get("currency") as string;

  return (
    <>
      <h3>{FDN.I18n.t("booking.form.group.preview.box.hotel.title")}</h3>
      {Object.keys(order.hotelsByNight).map((night) => {
        if (!order.hotelsByNight) return null;

        const hotel = order.hotelsByNight[parseInt(night)];
        if (!hotel || !hotel.info) return null;

        const descriptionPreview =
          hotel.info.description && hotel.info.description.length > 120
            ? `${hotel.info.description.slice(0, 117)}...`
            : undefined;

        let extraCost = <>{FDN.I18n.t("booking.form.group.hotel.roomIncluded.label")}</>;
        if (order.participants.length === 1 && hotel.room?.pricePerNightSingleFull)
          extraCost = (
            <>
              {currency}
              {FDN.formatNumber(hotel.room.pricePerNightSingleFull, 2, 2)}
            </>
          );
        if (order.participants.length > 1 && hotel.room?.pricePerNightFull)
          extraCost = (
            <>
              {currency}
              {FDN.formatNumber(hotel.room.pricePerNightFull, 2, 2)}
            </>
          );

        const styles: React.CSSProperties = {
          backgroundImage: hotel.info.mainPicture
            ? `url("${getAdventurePictureUrl(hotel.info.mainPicture, "L")}")`
            : undefined,
        };

        return (
          <div key={night} className={`booking-hotel-entry mb-1`}>
            <FDN.Box noMargin>
              <div className="booking-hotel-entry-box">
                <div className="booking-hotel-entry-content-wrapper">
                  <div className="booking-hotel-entry-pictures" style={styles}></div>
                  <div className="booking-hotel-entry-content">
                    <div className="text-xs font-medium uppercase">
                      {FDN.I18n.t("adminHotels.night")} {night}
                    </div>
                    <div className="booking-hotel-entry-name">
                      <span>{hotel.info.name}</span>
                      {hotel.info.stars ? <HotelStars stars={hotel.info.stars} /> : null}
                    </div>
                    <div className="booking-hotel-entry-description">
                      {descriptionPreview ? (
                        <div dangerouslySetInnerHTML={{ __html: nl2br(descriptionPreview, " | ") }} />
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: nl2br(hotel.info.description || "", " | ") }} />
                      )}
                    </div>
                    {hotel.room && (
                      <div className="pt-2">
                        <div className="rounded-md border px-2 py-1 flex">
                          <div className="flex-grow font-medium text-xs">
                            <FDN.Icon icon="bed" /> {FDN.I18n.t("booking.form.group.hotel.selectedRoom.title")}:{" "}
                            {hotel.room.title}
                          </div>
                          <div className="text-right font-medium text-xs">{extraCost}</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </FDN.Box>
          </div>
        );
      })}
    </>
  );
};

// const PreviewHotel: React.FunctionComponent<IPreviewBox> = ({ order, adventure }) => {
//   if (!adventure.hotels || !adventure.hotelPrices) return null;

//   const hotelPrice = adventure.hotelPrices.find((hp) => hp.identifier === order.selectedHotelPrice);

//   if (!hotelPrice) return null;

//   const hotel = adventure.hotels.find((h) => h.identifier === hotelPrice.hotelIdentifier);

//   if (!hotel) return null;

//   const descriptionPreview =
//     hotel.description && hotel.description.length > 200
//       ? `${hotel.description.slice(0, 200)}...`
//       : undefined;

//   const styles: React.CSSProperties = {
//     backgroundImage: hotel.mainPicture
//       ? `url("${getAdventurePictureUrl(hotel.mainPicture, "L")}")`
//       : undefined,
//   };
//   return (
//     <>
//       <h3>{FDN.I18n.t("booking.form.group.preview.box.hotel.title")}</h3>
//       <div className={`booking-hotel-entry`}>
//         <FDN.Box>
//           <div className="booking-hotel-entry-box">
//             <div className="booking-hotel-entry-content-wrapper">
//               <div className="booking-hotel-entry-pictures" style={styles}></div>
//               <div className="booking-hotel-entry-content">
//                 <div className="booking-hotel-entry-name">
//                   <span>{hotel.name}</span>
//                   {hotel.stars ? <HotelStars stars={hotel.stars} /> : null}
//                 </div>
//                 <div className="booking-hotel-entry-description">
//                   {descriptionPreview ? (
//                     <div dangerouslySetInnerHTML={{ __html: nl2br(descriptionPreview, " | ") }} />
//                   ) : (
//                     <div
//                       dangerouslySetInnerHTML={{ __html: nl2br(hotel.description || "", " | ") }}
//                     />
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </FDN.Box>
//       </div>
//     </>
//   );
// };

const PreviewUpgrades: React.FunctionComponent<IPreviewBox> = ({ order, adventure }) => {
  if (!adventure.upgrades || !adventure.upgradePrices || adventure.upgradePrices.length === 0) return null;

  return (
    <>
      <h3>{FDN.I18n.t("booking.form.group.preview.box.upgrades.title")}</h3>
      {order.selectedUpgradePrices?.map((upgradePriceIdentifier) => {
        if (!adventure.upgradePrices || !adventure.upgrades) return null;

        const upgradePrice = adventure.upgradePrices.find((up) => up.identifier === upgradePriceIdentifier);
        if (!upgradePrice) return null;

        const upgrade = adventure.upgrades.find((u) => u.identifier === upgradePrice.upgradeIdentifier);
        if (!upgrade) return null;

        const descriptionPreview =
          upgrade.description && upgrade.description.length > 200
            ? `${upgrade.description.slice(0, 200)}...`
            : undefined;

        const styles: React.CSSProperties = {
          backgroundImage: upgrade.mainPicture
            ? `url("${getAdventurePictureUrl(upgrade.mainPicture, "L")}")`
            : undefined,
        };

        return (
          <div className={`booking-upgrade-entry`} key={upgradePriceIdentifier}>
            <FDN.Box>
              <div className="booking-upgrade-entry-box">
                <div className="booking-upgrade-entry-content-wrapper">
                  <div className="booking-upgrade-entry-pictures" style={styles}></div>
                  <div className="booking-upgrade-entry-content">
                    <div className="booking-upgrade-entry-name">
                      <span>{upgrade.name}</span>
                    </div>
                    <div className="booking-upgrade-entry-description">
                      {descriptionPreview ? (
                        <div dangerouslySetInnerHTML={{ __html: nl2br(descriptionPreview, " | ") }} />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: nl2br(upgrade.description || "", " | "),
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </FDN.Box>
          </div>
        );
      })}
    </>
  );
};

const PreviewPaymentPlan: React.FunctionComponent<IPreviewBox> = ({ order, adventure }) => {
  const BOOKING = React.useContext(BookingContext);

  return (
    <>
      <h3>{FDN.I18n.t("booking.form.group.preview.box.paymentplan.title")}</h3>
      <FDN.Box>
        <BookingPaymentPlanSummary
          order={order}
          calculation={order.calculation || BOOKING.calculatePrice()}
          adventure={adventure}
        />
      </FDN.Box>
    </>
  );
};

const PreviewTerms: React.FunctionComponent<IPreviewBox> = ({ order }) => {
  const BOOKING = React.useContext(BookingContext);
  const { missingFields, onEdit } = BOOKING;

  const [showTerms, setShowTerms] = React.useState(false);

  return (
    <div className={`${missingFields?.includes("termsAccepted") ? `missing` : ``} __booking-form-page-preview-terms`}>
      <label style={{ cursor: "pointer" }}>
        <input
          type="checkbox"
          checked={order.termsAccepted || false}
          onChange={() => onEdit("termsAccepted", order.termsAccepted === true ? false : true)}
        />{" "}
        <span>
          {FDN.I18n.t("booking.terms.text")}{" "}
          <button onClick={() => setShowTerms(true)}>{FDN.I18n.t("booking.terms.terms")}</button>{" "}
          {FDN.I18n.t("booking.terms.glue")}{" "}
          <a href={UrlService.url("dataprivacy")} target="_blank" rel="noopener noreferrer">
            {FDN.I18n.t("booking.terms.privacypolicy")}
          </a>
        </span>
      </label>
      {showTerms === true && (
        <FDN.Popup
          size="small"
          onClose={() => setShowTerms(false)}
          title={FDN.I18n.t("adventures.paymentPolicy.title")}
        >
          <div dangerouslySetInnerHTML={{ __html: nl2br(order.adventurePaymentPolicy as string) }} />
        </FDN.Popup>
      )}
    </div>
  );
};

const PreviewMissingFields: React.FunctionComponent = () => {
  const BOOKING = React.useContext(BookingContext);
  const { missingFields, completedPages, orderPages, goToPage } = BOOKING;

  if (missingFields && missingFields.length > 0) {
    return (
      <div className="__booking-form-page-preview-missingfields">
        <FDN.CalloutBox type="alert">
          <p>{FDN.I18n.t("booking.missingFields.text")}</p>
          <ul>
            {orderPages?.map((orderPageIndex) => {
              if (completedPages?.includes(orderPageIndex)) return null;
              return (
                <li key={orderPageIndex}>
                  <button onClick={() => goToPage(orderPageIndex)}>
                    {FDN.I18n.t(`booking.nav.items.${orderPageIndex}.label`)}
                  </button>
                </li>
              );
            })}
          </ul>
        </FDN.CalloutBox>
      </div>
    );
  }

  return null;
};

const PreviewButtons: React.FunctionComponent<IPreviewBox> = () => {
  const BOOKING = React.useContext(BookingContext);
  const { api } = useServiceCore();

  return (
    <div className="__booking-form-page-preview-buttons">
      <FDN.FormButtons
        // cancelLabel={FDN.I18n.t("booking.buttons.onCancel.label")}
        // cancelIcon="refresh"
        // onCancel={() => BOOKING.clearCart()}
        saveLabel={FDN.I18n.t("booking.buttons.onSave.label")}
        saveIcon="shopping-cart"
        onSave={() => BOOKING.bookNow(api)}
      />
    </div>
  );
};

export default BookingPreview;

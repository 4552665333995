import * as React from "react";
import * as FDN from "src/core";
import Config from "src/core/Config";
import UrlService from "src/core/UrlService";
import { Link } from "react-router-dom";
import useServiceCore from "src/services/CoreService";
import packageJSON from "../../../package.json";
import { hasAccessToAdminArea } from "src/core/AdminService/helpers";
import Trustpilot from "../pages/public/parts/Trustpilot";

const Footer: React.FunctionComponent = () => {
  const { APP } = useServiceCore();
  const { apiVersion } = APP;
  const user = APP.getUser();

  return (
    <footer>
      <FDN.SingleRowCell margin="xy">
        <div className="flex flex-col md:flex-row justify-between md:items-center text-center md:text-left">
          <div className="md:flex-grow px-6 md:pl-6 md:pr-0 pb-6 md:pb-0">
            <div
              dangerouslySetInnerHTML={{
                __html: (Config.get("footer.copyright") as string).replace(
                  "{year}",
                  new Date().getFullYear().toString()
                ),
              }}
            />
            {user && hasAccessToAdminArea(user) ? (
              <div>
                <small>
                  v{packageJSON.version} (API v{apiVersion})
                </small>
              </div>
            ) : null}
          </div>
          <div className="px-6 md:pr-10 md:pl-0 pb-6 md:pb-0">
            <Trustpilot className="w-32" />
          </div>
          <div
            className="flex justify-center md:justify-start gap-3 px-6 md:pr-10 pb-6 md:pb-0 md:pl-0"
            style={{ fontSize: "32px" }}
          >
            <a
              href="https://www.instagram.com/click2adventure"
              target="_blank"
              rel="noopener noreferrer"
              className="h-full flex items-center text-orange-600"
            >
              <FDN.Icon icon="instagram" />
            </a>
            <a
              href="https://www.facebook.com/safaribooking"
              target="_blank"
              rel="noopener noreferrer"
              className="h-full flex items-center text-blue-500"
            >
              <FDN.Icon icon="facebook-square" />
            </a>
          </div>
          <div className="text-xs px-6 md:pr-10 md:pl-0 pb-6 md:pb-0 text-center md:text-right">
            <div>
              <Link to={UrlService.url("legal")} className="hover:underline">
                {FDN.I18n.t("main.legal")}
              </Link>
            </div>
            <div>
              <Link to={UrlService.url("dataprivacy")} className="hover:underline">
                {FDN.I18n.t("main.dataprivacy")}
              </Link>
            </div>
          </div>
        </div>
      </FDN.SingleRowCell>
    </footer>
  );
};

export default Footer;

import * as React from "react";
import * as FDN from "src/core";
import useServiceCore from "src/services/CoreService";

const LegalPage: React.FunctionComponent = () => {
  const { api } = useServiceCore();

  React.useEffect(() => {
    api.get("hello");
  }, []);

  return (
    <FDN.LayoutWithTopbar page="legal">
      <FDN.PageContent>
        <div className="legal-infobox-wrapper">
          <div className="legal-infobox">
            <h1>Legal</h1>

            <p>This site is created, hosted, published and owned by</p>

            <p>
              Click 2 Adventure LLC
              <br />
              Headquarters
              <br />
              2280 W Oakland Park Blvd
              <br />
              Oakland Park, 33311 Florida
            </p>

            <p>+1 484 473-2040</p>

            <p>management@click2adventure.com</p>
          </div>
        </div>
      </FDN.PageContent>
    </FDN.LayoutWithTopbar>
  );
};

export default LegalPage;

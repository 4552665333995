import React from "react";
import { IPageContent } from "src/types";
import { PageContentActions } from "./usePageContent";
import { classNames } from "src/core/helpers/design";
import RichTextEditor from "src/components/main/RichTextEditor";
import { FormButtons } from "src/core";

interface PageContentProps {
  identifier: string;
  pageContents: Record<string, IPageContent>;
  editMode: boolean;
  actions: PageContentActions;
}

const PageContent = ({ identifier, pageContents, editMode, actions }: PageContentProps) => {
  const pageContent = pageContents[identifier] ?? undefined;

  if (!pageContent) return null;

  const isEditModeAvailable = actions.isEditModeAvailable();

  if (!pageContent.content) pageContent.content = "";

  if (editMode === true) {
    return (
      <div className="py-6">
        <FormButtons smallButtons onCancel={actions.onCancel} onSave={actions.onSave} />

        <RichTextEditor
          size="big"
          returnContentType="html"
          initialHtml={pageContent.content || ""}
          onUpdate={(editorState) => actions.onEdit(pageContent.identifier, editorState as string)}
        />
        <FormButtons smallButtons onCancel={actions.onCancel} onSave={actions.onSave} />
      </div>
    );
  } else {
    return (
      <div
        className={classNames(
          isEditModeAvailable &&
            "group relative cursor-pointer hover:py-4 hover:px-6 hover:border-dashed hover:border hover:border-gray-400 hover:bg-white hover:opacity-95 hover:rounded-lg"
        )}
        onClick={() => actions.updateEditMode(true)}
      >
        <div className="hidden group-hover:flex opacity-80 rounded-lg absolute top-0 left-0 h-full w-full bg-white justify-center items-center text-lg font-medium italic">
          Click to edit content
        </div>
        <div dangerouslySetInnerHTML={{ __html: pageContent.content }} />
      </div>
    );
  }
};

export default PageContent;

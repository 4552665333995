import * as React from "react";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { IAdventure } from "src/types";

interface IAdventureSidebarProps {
  adventure: IAdventure;
}

const AdventureSidebar: React.FunctionComponent<IAdventureSidebarProps> = ({ adventure }) => {
  return (
    <>
      <SidebarPrice adventure={adventure} />
    </>
  );
};

const SidebarPrice: React.FunctionComponent<IAdventureSidebarProps> = ({ adventure }) => {
  const currency = Config.get("currency");

  const priceFull = `${currency}${
    adventure.priceFull ? FDN.formatNumber(adventure.priceFull, 0, 2) : null
  }`;
  const priceDownPayment = `<span class="__public-adventure-sidebar-price-downpayment-price">${currency}${
    adventure.priceDownPayment ? FDN.formatNumber(adventure.priceDownPayment, 0, 2) : null
  }</span>`;
  const priceDownPaymentRemaining = `<span class="__public-adventure-sidebar-price-downpayment-price">${currency}${
    adventure.priceRemaining ? FDN.formatNumber(adventure.priceRemaining, 0, 2) : null
  }</span>`;

  return (
    <FDN.Box noMargin>
      <div className="__public-adventure-sidebar-price">
        <div className="__public-adventure-sidebar-price-price">
          <div className="__public-adventure-sidebar-price-from">
            {FDN.I18n.t("adventures.price.from")}
          </div>
          <div className="__public-adventure-sidebar-price-fullprice">{priceFull}</div>
          <div className="__public-adventure-sidebar-price-perperson">
            {FDN.I18n.t("adventures.price.perPerson")}
          </div>
        </div>
        {adventure.priceDownPayment && adventure.priceRemaining && adventure.priceFull ? (
          <>
            <hr />
            <div
              className="__public-adventure-sidebar-price-downpayment"
              dangerouslySetInnerHTML={{
                __html: FDN.I18n.t("adventures.price.priceDownPayment", {
                  downPayment: priceDownPayment,
                  remaining: priceDownPaymentRemaining,
                  days: adventure.payRemainingDaysBefore,
                }),
              }}
            />
            {/*<div className="__public-adventure-sidebar-price-orsave">
              <div
                className="__public-adventure-sidebar-price-orsave-text1"
                dangerouslySetInnerHTML={{
                  __html: FDN.I18n.t("adventures.price.orSave.text1"),
                }}
              />
              <div
                className="__public-adventure-sidebar-price-orsave-text2"
                dangerouslySetInnerHTML={{
                  __html: FDN.I18n.t("adventures.price.orSave.text2", {
                    amount: `${currency} ${
                      adventure.priceDownPayment + adventure.priceRemaining - adventure.priceFull
                    }`,
                  }),
                }}
              />
              </div>*/}
          </>
        ) : null}
        <hr />
        <div className="__public-adventure-sidebar-price-booknow">
          <button
            className="primary button"
            onClick={() => {
              document.getElementById("booking")?.scrollIntoView();
            }}
          >
            {FDN.I18n.t("adventures.buttons.booknow.label")}
          </button>
        </div>
      </div>
    </FDN.Box>
  );
};

export default AdventureSidebar;

import * as React from "react";
import * as FDN from "src/core";
import { IAdventure, TActions } from "src/types";
import HotelNight from "../parts/hotels/HotelNight";
import AddHotel from "../parts/hotels/AddHotel";
import useAdventureHotels from "src/services/admin/useAdventureHotels";

interface IAdventureHotelsListByNightProps {
  adventure: IAdventure;
  editMode: boolean;
  adventureActions: TActions;
}

const AdventureHotelsListByNight: React.FunctionComponent<IAdventureHotelsListByNightProps> = ({
  adventure,
  editMode,
  adventureActions,
}) => {
  const { filteredHotels, isAddHotelPopupVisible, selectedHotel, selectedNight, search, countries, cities, actions } =
    useAdventureHotels({
      adventure,
      adventureActions,
    });

  // TODO: DELETE LATER!!!!
  // editMode = true;

  React.useEffect(() => {
    actions.loadHotels();
  }, []);

  if (!filteredHotels) return <FDN.Loading box />;

  return (
    <div>
      <div className="w-full flex flex-col">
        {!adventure.hotelsByNight || Object.keys(adventure.hotelsByNight).length === 0 ? (
          <div className="text-center italic">{FDN.I18n.t("adminHotels.adventure.nights.saveFirst.label")}</div>
        ) : null}
        {Object.keys(adventure.hotelsByNight ?? {}).map((night, index) => {
          return (
            <HotelNight
              key={index}
              editMode={editMode}
              night={parseInt(night)}
              hotels={adventure.hotelsByNight[parseInt(night)]}
              actions={actions}
            />
          );
        })}
      </div>
      {isAddHotelPopupVisible && filteredHotels && selectedNight && (
        <AddHotel
          adventure={adventure}
          hotels={filteredHotels}
          selectedHotel={selectedHotel}
          night={selectedNight}
          search={search}
          countries={countries}
          cities={cities}
          actions={actions}
        />
      )}
    </div>
  );
};

export default AdventureHotelsListByNight;

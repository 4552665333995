import { useEffect, useState } from "react";
import LocalStorageService from "src/core/LocalStorageService";
import { cloneDeep } from "lodash";
import { TActions } from "src/types";
import useServiceCore from "src/services/CoreService";

export enum CookieBannerView {
  NONE = "none",
  SMALL = "small",
  MEDIUM = "medium",
  SETTINGS = "settings",
}

export interface ICookieSettings {
  saved: boolean;
  essential: boolean;
  stats: boolean;
  marketing: boolean;
  videos: boolean;
  external: boolean;
}

const initCookieSettings: ICookieSettings = {
  saved: false,
  essential: true,
  stats: false,
  marketing: false,
  videos: false,
  external: false,
};

const useServiceCookieBanner = () => {
  const [cookieSettings, setCookieSettings] = useState<ICookieSettings | null>(null);
  const [view, setView] = useState<CookieBannerView>(CookieBannerView.NONE);

  const { APP } = useServiceCore();
  const showCookieBanner = APP.getSetting("showCookieBanner") as boolean;

  useEffect(() => {
    const savedCookieSettings = LocalStorageService.get("COOKIEBANNER_SETTINGS");

    try {
      if (savedCookieSettings)
        setCookieSettings(JSON.parse(savedCookieSettings) as ICookieSettings);
      else setCookieSettings(cloneDeep(initCookieSettings));
    } catch {
      setCookieSettings(cloneDeep(initCookieSettings));
    }
  }, []);

  useEffect(() => {
    if (view === CookieBannerView.NONE && showCookieBanner === true)
      setView(CookieBannerView.SMALL);

    if (view === CookieBannerView.SETTINGS || !cookieSettings) return;

    if (cookieSettings.saved === false && view === CookieBannerView.SMALL)
      setView(CookieBannerView.MEDIUM);

    if (
      cookieSettings.saved === true &&
      [CookieBannerView.MEDIUM, CookieBannerView.SETTINGS].includes(view)
    )
      setView(CookieBannerView.SMALL);
  }, [cookieSettings]);

  const switchView = () => {
    setView(CookieBannerView.SETTINGS);
  };

  const toggleSetting = (property: keyof ICookieSettings) => {
    if (!cookieSettings) return;
    cookieSettings[property] = !cookieSettings[property];
    setCookieSettings(cloneDeep(cookieSettings));
  };

  const acceptOnlyEssential = () => {
    const cookieSettings = cloneDeep(initCookieSettings);
    cookieSettings.saved = true;
    setCookieSettings(cookieSettings);
    writeToLocalStorageAndReload(cookieSettings);
  };

  const acceptAllSettings = () => {
    if (!cookieSettings) return;
    for (const cookieSettingKey of Object.keys(cookieSettings)) {
      cookieSettings[cookieSettingKey as keyof ICookieSettings] = true;
    }
    setCookieSettings(cloneDeep(cookieSettings));
    writeToLocalStorageAndReload(cookieSettings);
  };

  const saveSettings = () => {
    if (!cookieSettings) return;
    writeToLocalStorageAndReload(cookieSettings);
  };

  const writeToLocalStorageAndReload = (cookieSettings: ICookieSettings) => {
    LocalStorageService.set("COOKIEBANNER_SETTINGS", cookieSettings);
    window.location.reload();
  };

  const actions: TActions = {
    switchView,
    toggleSetting,
    acceptOnlyEssential,
    acceptAllSettings,
    saveSettings,
  };

  return { showCookieBanner, cookieSettings, view, actions };
};

export default useServiceCookieBanner;

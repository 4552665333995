import * as React from "react";
import * as FDN from "src/core";
import { IAdventure, IAdventures } from "src/types";
import { Slide } from "react-slideshow-image";
import { Link } from "react-router-dom";
import UrlService from "src/core/UrlService";
import { getAdventurePictureUrl } from "src/core/helpers/helpers";

interface IFeaturedHeroProps {
  adventures: IAdventures;
}

const FeaturedHero: React.FunctionComponent<IFeaturedHeroProps> = ({ adventures }) => {
  return (
    <div className="__public-featured-hero">
      <Slide duration={4000} indicators={true}>
        {adventures.map((adventure, index) => {
          return <SlideWithContent key={index} adventure={adventure} />;
        })}
      </Slide>
    </div>
  );
};

interface IHeroSlideProps {
  adventure: IAdventure;
}

const SlideWithContent: React.FunctionComponent<IHeroSlideProps> = ({ adventure }) => {
  const styles: React.CSSProperties = {
    backgroundImage: adventure.mainPicture
      ? `url("${getAdventurePictureUrl(adventure.mainPicture)}")`
      : undefined,
  };

  let titlePreTitle = <></>;
  if (adventure.countryName) {
    titlePreTitle = <>{adventure.countryName}</>;

    if (adventure.regionName)
      titlePreTitle = (
        <>
          {titlePreTitle} <FDN.Icon icon="angle-right" /> {adventure.regionName}
        </>
      );
  }

  return (
    <div className="each-slide-effect">
      <div style={styles} className="__public-featured-hero-slide">
        <div className="__public-featured-hero-slide-content">
          <div className="__public-featured-hero-slide-text">
            <div className="__public-featured-hero-slide-pretitle">{titlePreTitle}</div>
            <h1>{adventure.title}</h1>
            <div className="__public-featured-hero-slide-description">{adventure.description}</div>
            <div className="__public-featured-hero-slide-button">
              <Link
                to={UrlService.url("public.adventure", { slug: adventure.slug })}
                className="primary button"
              >
                {FDN.I18n.t("adventures.more")}
                <FDN.Icon icon="angle-double-right" right />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedHero;

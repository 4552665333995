import * as React from "react";
import * as FDN from "src/core";
import { IAdventure, IUpgrade, IUpgradePrice, TActions } from "src/types";
import Config from "src/core/Config";
import { getAdventurePictureUrl } from "src/core/helpers/helpers";

interface IAdventureUpgradesListProps {
  adventure: IAdventure;
  editMode: boolean;
  actions: TActions;
}

const AdventureUpgradesList: React.FunctionComponent<IAdventureUpgradesListProps> = ({
  adventure,
  editMode,
  actions,
}) => {
  const adventureUpgrades = adventure.upgrades || [];

  return (
    <div className="admin-adventure-upgrades-list">
      {adventureUpgrades.length === 0 ? (
        <div className="text-center" style={{ padding: "40px 0" }}>
          {FDN.I18n.t("adminAdventures.upgrades.noUpgradesInThisAdventure")}
        </div>
      ) : (
        <ul>
          {adventure.upgradePrices?.map((upgradePrice) => {
            const upgrade = adventure.upgrades?.find(
              (u) => u.identifier === upgradePrice.upgradeIdentifier
            );
            if (!upgrade) return null;

            return (
              <li key={upgrade.identifier}>
                <ListItem
                  adventure={adventure}
                  upgrade={upgrade}
                  upgradePrice={upgradePrice}
                  editMode={editMode}
                  actions={actions}
                />
              </li>
            );
          })}
        </ul>
      )}
      {editMode === true && (
        <div className="admin-adventure-upgrades-list-add">
          <button onClick={actions.showUpgradeAddPopup}>
            <FDN.Icon icon="plus-circle" left />{" "}
            {FDN.I18n.t("adminAdventures.upgrades.buttons.add.label")}
          </button>
        </div>
      )}
    </div>
  );
};

interface IListItemProps {
  adventure: IAdventure;
  upgrade: IUpgrade;
  upgradePrice: IUpgradePrice;
  editMode: boolean;
  actions: TActions;
}

const ListItem: React.FunctionComponent<IListItemProps> = ({
  upgrade,
  upgradePrice,
  editMode,
  actions,
}) => {
  const styles: React.CSSProperties = {
    backgroundImage: upgrade.mainPicture
      ? `url("${getAdventurePictureUrl(upgrade.mainPicture)}")`
      : undefined,
  };

  return (
    <FDN.Box noPadding>
      <div className={`admin-upgrades-addpopup-list-item`}>
        <div className="admin-upgrades-addpopup-list-item-picture" style={styles}></div>
        <div className="admin-upgrades-addpopup-list-item-content">
          <div className="admin-upgrades-addpopup-list-item-name">{upgrade.name}</div>

          {upgrade.street || upgrade.zip || upgrade.city ? (
            <div className="admin-upgrades-addpopup-list-item-address">
              {upgrade.street ? <>{upgrade.street},</> : null}{" "}
              {upgrade.zip || upgrade.city ? (
                <>
                  {upgrade.zip} {upgrade.city},
                </>
              ) : null}{" "}
              {upgrade.country}
            </div>
          ) : null}
        </div>
        <div className="admin-upgrades-addpopup-list-item-price">
          <div>
            <FDN.Input
              type="currency"
              options={{
                currency: Config.get("currency") as string,
                locale: Config.get("currencyLocale") as string,
              }}
              editMode={editMode}
              value={upgradePrice.priceFull || "0.00"}
              label={FDN.I18n.t("adminAdventures.upgrades.upgrade.priceFull.label")}
              onUpdate={(value) =>
                actions.onEditUpgradePrice(upgradePrice.identifier, "priceFull", value)
              }
            />
          </div>
          <div>
            <FDN.Input
              type="currency"
              options={{
                currency: Config.get("currency") as string,
                locale: Config.get("currencyLocale") as string,
              }}
              editMode={editMode}
              value={upgradePrice.priceSingleFull || "0.00"}
              label={FDN.I18n.t("adminAdventures.upgrades.upgrade.priceSingleFull.label")}
              onUpdate={(value) =>
                actions.onEditUpgradePrice(upgradePrice.identifier, "priceSingleFull", value)
              }
            />
          </div>
        </div>
        <div className="admin-upgrades-addpopup-list-item-buttons">
          <div style={{ visibility: editMode ? "visible" : "hidden" }}>
            <div>
              <button
                className="tiny primary button"
                onClick={() => actions.removeUpgradeFromAdventure(upgrade.identifier)}
              >
                <FDN.Icon icon="minus" left />{" "}
                {FDN.I18n.t("adminAdventures.upgrades.list.buttons.unselect.label")}{" "}
              </button>
            </div>
            <div>
              <button
                className="tiny primary hollow button"
                onClick={() => actions.showUpgradeEditPopup(upgrade)}
              >
                <FDN.Icon icon="edit" left />{" "}
                {FDN.I18n.t("adminAdventures.upgrades.list.buttons.edit.label")}{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
    </FDN.Box>
  );
};

export default AdventureUpgradesList;

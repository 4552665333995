import * as FDN from "src/core";
import { TFunction } from "src/components/../types";
import { INotificationsContext } from "src/components/context/NotificationsContext/NotificationsContext";
import { IDialogButton } from "src/components/context/NotificationsContext/types";

export const castButtons = (buttons: IDialogButton[], type: string) => {
  buttons.map((button) => {
    if (button.type === "cancel") {
      if (type === "okcancel") {
        button.icon = "times";
        if (!button.label) button.label = FDN.I18n.t("buttons.cancel");
      } else if (type === "yesno") {
        button.icon = "times";
        if (!button.label) button.label = FDN.I18n.t("buttons.no");
      }
    } else if (button.type === "ok") {
      if (type === "okcancel") {
        button.icon = "check";
        if (!button.label) button.label = FDN.I18n.t("buttons.ok");
      } else if (type === "yesno") {
        button.icon = "check";
        if (!button.label) button.label = FDN.I18n.t("buttons.yes");
      }
    }
  });

  return buttons;
};

export const showDefaultCancelDialog = (
  NOTIFICATIONS: INotificationsContext,
  unsavedChanges?: boolean,
  doConfirm?: TFunction,
  doNotConfirm?: TFunction
) => {
  if (unsavedChanges)
    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
            if (doNotConfirm) doNotConfirm();
          },
        },
        {
          type: "ok",
          onClick: () => {
            if (doConfirm) doConfirm();
          },
        },
      ],
    });
  else if (doConfirm) doConfirm();
};

import React from "react";
import Logo from "src/assets/images/trustpilot.png";
import { classNames } from "src/core/helpers/design";

interface TrustpilotProps {
  className?: string;
  style?: React.CSSProperties;
}

const Trustpilot = ({ className, style }: TrustpilotProps) => {
  return (
    <a
      href="https://www.trustpilot.com/review/click2adventure.com"
      target="_blank"
      rel="noopener noreferrer"
      className={classNames("inline-block text-center", className)}
      style={style}
    >
      <div className="text-xs text-slate-400">Read our reviews on</div>
      <img src={Logo} alt="Trustpilot" />
    </a>
  );
};

export default Trustpilot;

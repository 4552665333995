import React from "react";
import { IAdventureHotel } from "src/types";
import HotelStars from "./HotelStars";
import * as FDN from "src/core";
import { AdventureHotelsActions } from "src/services/admin/useAdventureHotels";
import { classNames } from "src/core/helpers/design";

interface HotelNightProps {
  night: number;
  editMode: boolean;
  hotels: IAdventureHotel[];
  actions: AdventureHotelsActions;
}

const HotelNight = ({ night, editMode, hotels, actions }: HotelNightProps) => {
  return (
    <div>
      <div className="flex flex-row justify-start border bg-white rounded-md mb-4">
        <div className="w-[15%] pl-4 flex items-center bg-slate-50 border-r border-gray-200">
          <div>
            <div className="font-medium text-brightPurple">Night {night}</div>
            {editMode && (
              <div className="mt-2">
                <FDN.Button tiny hollow primary onClick={() => actions.openAddHotelPopup(night)}>
                  {FDN.I18n.t("adminHotels.adventure.nights.buttons.selectHotels.label")}
                </FDN.Button>
              </div>
            )}
          </div>
        </div>
        <div className="w-[85%]">
          <div className="overflow-x-auto w-full">
            {!editMode && hotels.length === 0 && (
              <div className="text-center italic pt-8 py-8">
                {FDN.I18n.t("adminHotels.adventure.nights.noHotelsSelected.label")}
              </div>
            )}
            <div className="flex flex-row justify-start">
              {hotels.map((hotel) => {
                if (!hotel.info) return null;

                return (
                  <div
                    key={hotel.identifier}
                    className={classNames(
                      "w-60 flex-none border-r border-dashed border-r-gray-400 px-4 py-4",
                      hotel.isDefault && "bg-gray-100"
                    )}
                  >
                    <button
                      className={classNames(
                        "border bg-white rounded-md shadow text-left w-full",
                        editMode && "cursor-pointer duration-300 hover:bg-hover hover:scale-105"
                      )}
                      onClick={() => {
                        if (editMode) actions.selectHotel(hotel.info ?? undefined, night);
                      }}
                    >
                      {hotel.isDefault && (
                        <div className="text-center font-medium text-gray-500 pt-1">
                          {FDN.I18n.t("adminHotels.adventure.nights.defaultHotel.title")}
                        </div>
                      )}
                      <div>
                        {hotel.info.mainPicture?.S && (
                          <img src={hotel.info.mainPicture?.S} alt={hotel.info.name} className="w-full h-auto" />
                        )}
                      </div>
                      <div className="px-2 pt-2">
                        <div className="font-medium text-brightPurple">{hotel.info.name}</div>
                        {hotel.info.stars && (
                          <div className="pt-1">
                            <HotelStars stars={hotel.info.stars} />
                          </div>
                        )}
                      </div>
                      <div className="px-2 py-1 text-xs">
                        <ul style={{ listStyle: "initial" }} className="pl-4">
                          {hotel.rooms?.map((room) => {
                            return (
                              <li key={room.roomIdentifier} className="">
                                <span className="font-medium">{room.title}</span>
                                <span className="text-gray-500">
                                  {" "}
                                  - {room.pricePerNightFull}€ / {room.pricePerNightSingleFull}€
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </button>
                    {editMode && (
                      <div className="text-center mt-2">
                        <button
                          className="text-red-400 text-xs cursor-pointer hover:underline"
                          onClick={() => {
                            actions.removeHotelFromNight(hotel, night);
                          }}
                        >
                          {FDN.I18n.t("adminHotels.adventure.addHotel.buttons.removeHotel.label")}
                        </button>
                      </div>
                    )}
                  </div>
                );
              })}
              {editMode && (
                <div className="w-60 flex-none border-r border-dashed border-r-gray-400 px-4 py-4 min-h-40">
                  <button
                    className="flex justify-center items-center w-full h-full text-slate-400 text-xl cursor-pointer hover:bg-slate-50"
                    onClick={() => actions.openAddHotelPopup(night)}
                  >
                    <FDN.Icon icon="plus-circle" />
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {editMode && <div className="py-8 text-center flex justify-center items-center"></div>}
      </div>
    </div>
  );
};

export default HotelNight;

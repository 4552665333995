import React from "react";
import * as FDN from "src/core";
import { AdventureHotelsActions } from "src/services/admin/useAdventureHotels";
import { SearchHotelsFilter } from "src/types";

interface HotelsListSearchProps {
  search: SearchHotelsFilter;
  countries?: string[];
  cities?: string[];
  actions: AdventureHotelsActions;
}

const HotelsListSearch = ({ search, countries, cities, actions }: HotelsListSearchProps) => {
  if (!countries || !cities) return <FDN.Loading box />;

  return (
    <FDN.Box>
      <FDN.Grid>
        <FDN.Row margin="xy">
          <FDN.Cell md={12}>
            <FDN.Input
              value={search.text ?? ""}
              label={FDN.I18n.t("adminHotels.search.form.text.label")}
              onUpdate={(value) => actions.updateSearch("text", value)}
              editMode={true}
            />
          </FDN.Cell>
          <FDN.Cell md={11}>
            <FDN.Input
              type="select"
              selectValues={countries}
              value={search.country ?? ""}
              label={FDN.I18n.t("adminHotels.search.form.country.label")}
              onUpdate={(value) => actions.updateSearch("country", value)}
              editMode={true}
            />
          </FDN.Cell>
          {/* <FDN.Cell md={8}>
            <FDN.Input
              type="select"
              selectValues={cities}
              value={search.city ?? ""}
              label={FDN.I18n.t("adminHotels.search.form.city.label")}
              onUpdate={(value) => actions.updateSearch("city", value)}
              editMode={true}
            />
          </FDN.Cell> */}
        </FDN.Row>
      </FDN.Grid>
    </FDN.Box>
  );
};

export default HotelsListSearch;

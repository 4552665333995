import * as React from "react";
import StripePayment from "src/components/thirdparty/stripe/components/StripePayment";
import * as FDN from "src/core";
import Config from "src/core/Config";
import useServicePayment, { TActionsPayment } from "src/services/PaymentService";
import { IOrder, IStripePayment } from "src/types";

type ICalloutBoxTypes = "info" | "warning" | "alert" | "success";

const PaymentPage: React.FunctionComponent = () => {
  const { order, publicKey, orderNotFound, paymentProviderError, stripePayment, actions } =
    useServicePayment();

  if (orderNotFound === true)
    return (
      <div className="__payment">
        <FDN.LayoutWithTopbar page="payment">
          <FDN.PageContent>
            <FDN.Grid>
              <FDN.Row margin="xy">
                <FDN.Cell md={12} mdo={6}>
                  <FDN.CalloutBox type="alert">
                    <p>
                      <strong>{FDN.I18n.t("payment.orderNotFound.title")}</strong>
                    </p>
                    <div>{FDN.I18n.t("payment.orderNotFound.text")}</div>
                  </FDN.CalloutBox>
                </FDN.Cell>
              </FDN.Row>
            </FDN.Grid>
          </FDN.PageContent>
        </FDN.LayoutWithTopbar>
      </div>
    );

  if (!order) return <FDN.Loading text={FDN.I18n.t("payment.checking")} />;

  return (
    <div className="__payment">
      <FDN.LayoutWithTopbar page="payment">
        <FDN.PageContent>
          <FDN.Grid>
            <FDN.Row margin="xy">
              <FDN.Cell md={12} mdo={6}>
                {paymentProviderError === true ? (
                  <FDN.CalloutBox type="alert">
                    <div>{FDN.I18n.t("payment.paymentProviderError")}</div>
                  </FDN.CalloutBox>
                ) : (
                  <>
                    {["INIT", "PAID", "NOW_PENDING", "LATER_PENDING"].includes(
                      order.paymentStatus
                    ) ? (
                      <PaymentStatusNoPayment order={order} />
                    ) : null}
                  </>
                )}
              </FDN.Cell>
            </FDN.Row>
          </FDN.Grid>
          {["NOW_REQUIRED", "LATER_REQUIRED", "NOW_PAID"].includes(order.paymentStatus) ? (
            <FDN.Grid>
              <FDN.Row>
                <FDN.Cell lg={18} lgo={3}>
                  <PaymentStatusPayment
                    order={order}
                    stripePayment={stripePayment}
                    publicKey={publicKey}
                    actions={actions}
                  />
                </FDN.Cell>
              </FDN.Row>
            </FDN.Grid>
          ) : null}
        </FDN.PageContent>
      </FDN.LayoutWithTopbar>
    </div>
  );
};

interface IPaymentStatusNoPaymentProps {
  order: IOrder;
}

const PaymentStatusNoPayment: React.FunctionComponent<IPaymentStatusNoPaymentProps> = ({
  order,
}) => {
  let calloutType: ICalloutBoxTypes = "info";
  if (order.paymentStatus === "PAID") calloutType = "success";
  if (order.paymentStatus === "INIT") calloutType = "warning";

  return (
    <FDN.CalloutBox type={calloutType}>
      <p>
        <strong>{FDN.I18n.t(`payment.status.${order.paymentStatus}.title`)}</strong>
      </p>
      <div>{FDN.I18n.t(`payment.status.${order.paymentStatus}.text`)}</div>
    </FDN.CalloutBox>
  );
};

interface IPaymentStatusPaymentProps {
  order: IOrder;
  stripePayment?: IStripePayment;
  publicKey?: string;
  actions: TActionsPayment;
}

const PaymentStatusPayment: React.FunctionComponent<IPaymentStatusPaymentProps> = ({
  order,
  stripePayment,
  publicKey,
  actions,
}) => {
  if (!stripePayment)
    return <FDN.Loading text={FDN.I18n.t("payment.connectingToPaymentProvider")} box />;
  return (
    <>
      <h1 className="text-center">{FDN.I18n.t("payment.form.title")}</h1>
      <p className="__payment-form-securedconnection success-shallow-background success-text">
        <FDN.Icon icon="lock" left /> {FDN.I18n.t("payment.form.intro")}
      </p>
      <FDN.Grid>
        <FDN.Row margin="xy">
          <FDN.Cell md={10}>
            <PaymentAdventure order={order} />
            <PaymentParticipants order={order} />
            <PaymentPaymentPlan order={order} stripePayment={stripePayment} />
          </FDN.Cell>
          <FDN.Cell md={14}>
            <StripePayment
              order={order}
              stripePayment={stripePayment}
              publicKey={publicKey}
              actions={actions}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </>
  );
};

interface IPaymentBox {
  order: IOrder;
}

const PaymentAdventure: React.FunctionComponent<IPaymentBox> = ({ order }) => {
  return (
    <>
      <FDN.Box>
        <div className="__payment-adventure-text">
          <h4>{order.adventureTitle}</h4>
          <div>{order.adventureDescription}</div>
          <div></div>
        </div>
      </FDN.Box>
    </>
  );
};

interface IPaymentBox {
  order: IOrder;
}

const PaymentParticipants: React.FunctionComponent<IPaymentBox> = ({ order }) => {
  return (
    <div className="__payment-participants">
      <FDN.Box>
        <h4>{FDN.I18n.t("payment.form.participants.title")}</h4>
        <ol>
          {order.participants.map((participant, index) => {
            return (
              <li key={index}>
                {participant.salutation || ""} {participant.firstname || ""}{" "}
                {participant.lastname || ""}
              </li>
            );
          })}
        </ol>
      </FDN.Box>
    </div>
  );
};

interface IPaymentPaymentPlanBox {
  order: IOrder;
  stripePayment: IStripePayment;
}

const PaymentPaymentPlan: React.FunctionComponent<IPaymentPaymentPlanBox> = ({ order }) => {
  const currency = Config.get("currency") as string;

  return (
    <div className="__payment-paymentplan">
      <FDN.Box>
        <h4>{FDN.I18n.t("payment.form.paymentPlan.title")}</h4>
        <PaymentPaymentPlanLine
          type="total"
          label={FDN.I18n.t("payment.form.participants.paymentPlan.total")}
          value={`${currency} ${FDN.formatNumber(order.calculation.total, 2, 2)}`}
        />
        {order.calculation.total === order.calculation.now ? (
          <>
            <PaymentPaymentPlanLine
              type="downpayment"
              label={FDN.I18n.t("payment.form.participants.paymentPlan.paynow")}
              value={`${currency} ${FDN.formatNumber(order.calculation.now, 2, 2)}`}
            />
          </>
        ) : (
          <>
            <PaymentPaymentPlanLine
              type="downpayment"
              label={FDN.I18n.t("payment.form.participants.paymentPlan.downpayment")}
              value={`${currency} ${FDN.formatNumber(order.calculation.now, 2, 2)}`}
            />
            <PaymentPaymentPlanLine
              type="remaining"
              label={FDN.I18n.t("payment.form.participants.paymentPlan.remaining")}
              value={`${currency} ${FDN.formatNumber(order.calculation.later, 2, 2)}`}
            />
          </>
        )}
      </FDN.Box>
    </div>
  );
};

interface IPaymentPlanLine {
  type: "total" | "paynow" | "downpayment" | "remaining" | "paid";
  label: string;
  value: string;
}

const PaymentPaymentPlanLine: React.FunctionComponent<IPaymentPlanLine> = ({
  type,
  label,
  value,
}) => {
  return (
    <div className={`payment-plan-line payment-plan-line-type-${type}`}>
      <div className="payment-plan-line-label">{label}</div>
      <div className="payment-plan-line-value">{value}</div>
    </div>
  );
};

export default PaymentPage;

import * as React from "react";
import * as FDN from "src/core";
import { TActions } from "src/types";
import { IFileManagerAllowedTypes, IFileManagerParent } from "../types";
import FileMultiUpload from "../../FileMultiUpload";

interface IFileUploadProps {
  editMode: boolean;
  parent: IFileManagerParent;
  allow: IFileManagerAllowedTypes[];
  actions: TActions;
}

const FileUpload: React.FunctionComponent<IFileUploadProps> = ({
  editMode,

  allow,
  actions,
}) => {
  if (!editMode) return null;

  return (
    <div className="__fm-fileupload">
      <FDN.FormGroup title={FDN.I18n.t("filemanager.droparea.title")}>
        <FileMultiUpload allow={allow} maxFiles={20} actions={actions} />
      </FDN.FormGroup>
    </div>
  );
};

export default FileUpload;

import { useState } from "react";
import { IOrder, IOrderHotel, OrderHotelEmailSent } from "src/types";
import useServiceCore from "../CoreService";
import { I18n } from "src/core";

type FnOnEditEmail = <P extends keyof Partial<OrderHotelEmailSent>>(
  property: P,
  value: Partial<OrderHotelEmailSent>[P]
) => void;

export interface UseOrderHotelEmailActions {
  selectHotel: (hotel: IOrderHotel) => void;
  onEditEmail: FnOnEditEmail;
  onCancel: () => void;
  onSendEmail: () => void;
}

const useOrderHotelEmail = (order: IOrder) => {
  const [selectedHotel, setSelectedHotel] = useState<IOrderHotel | undefined>();
  const [emailToHotel, setEmailToHotel] = useState<Partial<OrderHotelEmailSent | undefined>>();

  const { api, NOTIFICATIONS } = useServiceCore();

  const selectHotel = (hotel: IOrderHotel) => {
    setSelectedHotel(hotel);

    api
      .get("admin.orders.initEmailToHotel", { identifier: order.identifier, orderHotelIdentifier: hotel.identifier })
      .then((response) => {
        const initEmailToHotel = response?.body?.emailToHotel as Partial<OrderHotelEmailSent>;
        setEmailToHotel(initEmailToHotel);
      });
  };

  const onEditEmail: FnOnEditEmail = (property, value) => {
    setEmailToHotel((prev) => ({
      ...prev,
      [property]: value,
    }));
  };

  const onCancel = () => {
    const doCancel = () => {
      setEmailToHotel(undefined);
      setSelectedHotel(undefined);
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: I18n.t("main.form.buttons.onCancel.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
          },
        },
        {
          type: "ok",
          onClick: () => {
            if (doCancel) doCancel();
            NOTIFICATIONS.hideDialog();
          },
        },
      ],
    });
  };

  const onSendEmail = () => {
    if (!selectedHotel || !emailToHotel) return;

    const doSend = () => {
      NOTIFICATIONS.showSaving({ type: "email" });

      api
        .post(
          "admin.orders.sendEmailToHotel",
          {
            identifier: order.identifier,
            orderHotelIdentifier: selectedHotel.identifier,
          },
          { email: emailToHotel }
        )
        .then(() => {
          window.location.reload();
        });
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: I18n.t("adminHotels.order.email.popup.buttons.onSendEmail.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
          },
        },
        {
          type: "ok",
          onClick: () => {
            if (doSend) doSend();
            NOTIFICATIONS.hideDialog();
          },
        },
      ],
    });
  };

  const actions: UseOrderHotelEmailActions = {
    selectHotel,
    onEditEmail,
    onCancel,
    onSendEmail,
  };

  return {
    selectedHotel,
    emailToHotel,
    actions,
  };
};

export default useOrderHotelEmail;

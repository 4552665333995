import * as React from "react";
import * as FDN from "src/core";
import {
  IAdventure,
  FOnEdit,
  LocationCountries,
  LocationCountry,
  LocationRegion,
  TActions,
} from "src/types";
import { cloneDeep } from "lodash";
import AdminApi from "src/api/AdminApi";
import useServiceCore from "src/services/CoreService";
import StatusCode from "src/config/statuscodes";

const virginCountry: LocationCountry = {
  name: "",
  description: "",
  picture: null,
};

const virginRegion: LocationRegion = {
  name: "",
  description: "",
  picture: null,
  country: null,
};

interface IAdventureLocationSelectorProps {
  adventure: IAdventure;
  editMode: boolean;
  countries?: LocationCountries;
  actions: TActions;
}

type TFormStatus = "COUNTRY_EXISTS" | "REGION_EXISTS" | undefined;

const AdventureLocationSelector: React.FunctionComponent<IAdventureLocationSelectorProps> = ({
  adventure,
  editMode,
  countries,
  actions,
}) => {
  const [locationCountries, setLocationCountries] = React.useState<LocationCountries | undefined>(
    countries
  );

  const [addCountry, setAddCountry] = React.useState<LocationCountry>();
  const [addRegion, setAddRegion] = React.useState<LocationRegion>();

  const [formStatus, setFormStatus] = React.useState<TFormStatus>();

  const { api, NOTIFICATIONS } = useServiceCore();

  const onEditCountry: FOnEdit = (property, value) => {
    if (!addCountry) return;
    addCountry[property] = value;
    setAddCountry(cloneDeep(addCountry));
  };

  const onEditRegion: FOnEdit = (property, value) => {
    if (!addRegion) return;
    addRegion[property] = value;
    setAddRegion(cloneDeep(addRegion));
  };

  const showPopupAdd = (type: "COUNTRY" | "REGION") => {
    if (type === "COUNTRY") setAddCountry(cloneDeep(virginCountry));
    else if (type === "REGION") {
      if (!adventure.country) return;
      const addRegion = cloneDeep(virginRegion);
      addRegion.country = adventure.country;
      setAddRegion(addRegion);
    }
  };

  const onCancelAdd = () => {
    setAddCountry(undefined);
    setAddRegion(undefined);
  };

  const onSaveAddCountry = () => {
    if (!addCountry) return;
    AdminApi.adminAdventureAddCountry(api, addCountry).then((response) => {
      //console.log("re", response?.statusCode, response?.body?.country?.identifier);

      if (response?.statusCode === StatusCode.EXISTS) {
        setFormStatus("COUNTRY_EXISTS");
      } else if (
        response?.statusCode === StatusCode.SUCCESS &&
        response?.body?.country?.identifier
      ) {
        setLocationCountries(response?.body?.countries as LocationCountries);
        actions.onEdit("country", response?.body?.country.identifier);
        onCancelAdd();
        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("adminAdventures.info.addCountryPopup.onSave.success.title"),
          FDN.I18n.t("adminAdventures.info.addCountryPopup.onSave.success.text")
        );
      }
    });
  };

  const onSaveAddRegion = () => {
    if (!addRegion || !adventure.country) return;
    AdminApi.adminAdventureAddRegion(api, adventure.country, addRegion).then((response) => {
      //console.log("re", response?.statusCode, response?.body?.country?.identifier);

      if (response?.statusCode === StatusCode.EXISTS) {
        setFormStatus("REGION_EXISTS");
      } else if (
        response?.statusCode === StatusCode.SUCCESS &&
        response?.body?.region?.identifier
      ) {
        setLocationCountries(response?.body?.countries as LocationCountries);
        actions.onEdit("region", response?.body?.region.identifier);
        onCancelAdd();
        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("adminAdventures.info.addRegionPopup.onSave.success.title"),
          FDN.I18n.t("adminAdventures.info.addRegionPopup.onSave.success.text")
        );
      }
    });
  };

  actions.showPopupAdd = showPopupAdd;
  actions.onEditCountry = onEditCountry;
  actions.onEditRegion = onEditRegion;
  actions.onCancelAdd = onCancelAdd;
  actions.onSaveAddCountry = onSaveAddCountry;
  actions.onSaveAddRegion = onSaveAddRegion;

  const selectValuesCountries: { [key: string]: string } = {
    none: "",
    ADD: FDN.I18n.t("adminAdventures.info.form.country.add"),
  };

  if (locationCountries) {
    for (const country of locationCountries) {
      if (country.identifier) selectValuesCountries[country.identifier] = country.name;
    }
  }

  let regionIsDisabled = true;

  const selectValuesRegions: { [key: string]: string } = {
    none: "",
    ADD: FDN.I18n.t("adminAdventures.info.form.region.add"),
  };

  if (locationCountries && adventure.country) {
    regionIsDisabled = false;

    const selectedCountry = locationCountries.find((c) => c.identifier === adventure.country);

    if (selectedCountry) {
      for (const region of selectedCountry.regions) {
        if (region.identifier) selectValuesRegions[region.identifier] = region.name;
      }
    }
  } else {
    regionIsDisabled = true;
  }

  return (
    <>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              type="select"
              selectValues={selectValuesCountries}
              editMode={editMode}
              value={adventure.country || "none"}
              showValue={selectValuesCountries[adventure.country || "none"] || ""}
              label={FDN.I18n.t("adminAdventures.info.form.country.label")}
              onUpdate={(value) => {
                if (value === "ADD") {
                  actions.showPopupAdd("COUNTRY");
                  return;
                }

                if (value === "none") value = null;
                actions.onEdit("country", value);
              }}
            />
          </FDN.Cell>
        </FDN.Row>
        {!regionIsDisabled && (
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <FDN.Input
                type="select"
                selectValues={selectValuesRegions}
                editMode={editMode}
                value={adventure.region || "none"}
                showValue={selectValuesRegions[adventure.region || "none"] || ""}
                label={FDN.I18n.t("adminAdventures.info.form.region.label")}
                onUpdate={(value) => {
                  if (value === "ADD") {
                    actions.showPopupAdd("REGION");
                    return;
                  }

                  if (value === "none") value = null;
                  actions.onEdit("region", value);
                }}
              />
            </FDN.Cell>
          </FDN.Row>
        )}
      </FDN.Grid>
      {addCountry && <AddCountry country={addCountry} formStatus={formStatus} actions={actions} />}
      {addRegion && locationCountries && (
        <AddRegion
          region={addRegion}
          formStatus={formStatus}
          locationCountries={locationCountries}
          adventure={adventure}
          actions={actions}
        />
      )}
    </>
  );
};

interface IAddCountryProps {
  country: LocationCountry;
  formStatus: TFormStatus;
  actions: TActions;
}

const AddCountry: React.FunctionComponent<IAddCountryProps> = ({
  country,
  formStatus,
  actions,
}) => {
  return (
    <FDN.Popup
      size="small"
      title={FDN.I18n.t("adminAdventures.info.addCountryPopup.title")}
      onClose={actions.onCancelAdd}
    >
      {formStatus === "COUNTRY_EXISTS" && (
        <FDN.CalloutBox type="warning">
          {FDN.I18n.t("adminAdventures.info.addCountryPopup.formStatus.COUNTRY_EXISTS.text")}
        </FDN.CalloutBox>
      )}
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              type="text"
              editMode={true}
              label={FDN.I18n.t("adminAdventures.info.addCountryPopup.form.name.label")}
              value={country.name}
              onUpdate={(value) => actions.onEditCountry("name", value)}
            />
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              type="textarea"
              rows={3}
              editMode={true}
              label={FDN.I18n.t("adminAdventures.info.addCountryPopup.form.description.label")}
              value={country.description}
              onUpdate={(value) => actions.onEditCountry("description", value)}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
      <FDN.FormButtons
        onCancel={actions.onCancelAdd}
        onSave={actions.onSaveAddCountry}
        saveLabel={FDN.I18n.t("adminAdventures.info.addCountryPopup.buttons.add.label")}
      />
    </FDN.Popup>
  );
};
interface IAddRegionProps {
  region: LocationRegion;
  formStatus: TFormStatus;
  locationCountries: LocationCountries;
  adventure: IAdventure;
  actions: TActions;
}

const AddRegion: React.FunctionComponent<IAddRegionProps> = ({
  region,
  formStatus,
  locationCountries,
  adventure,
  actions,
}) => {
  const selectedCountry = locationCountries.find((c) => c.identifier === adventure.country);
  if (!selectedCountry) return null;

  return (
    <FDN.Popup
      size="small"
      title={FDN.I18n.t("adminAdventures.info.addRegionPopup.title")}
      onClose={actions.onCancelAdd}
    >
      {formStatus === "REGION_EXISTS" && (
        <FDN.CalloutBox type="warning">
          {FDN.I18n.t("adminAdventures.info.addRegionPopup.formStatus.REGION_EXISTS.text")}
        </FDN.CalloutBox>
      )}
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              type="text"
              editMode={false}
              label={FDN.I18n.t("adminAdventures.info.addRegionPopup.form.country.label")}
              value={selectedCountry.name}
            />
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              type="text"
              editMode={true}
              label={FDN.I18n.t("adminAdventures.info.addRegionPopup.form.name.label")}
              value={region.name}
              onUpdate={(value) => actions.onEditRegion("name", value)}
            />
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              type="textarea"
              rows={3}
              editMode={true}
              label={FDN.I18n.t("adminAdventures.info.addRegionPopup.form.description.label")}
              value={region.description}
              onUpdate={(value) => actions.onEditRegion("description", value)}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
      <FDN.FormButtons
        onCancel={actions.onCancelAdd}
        onSave={actions.onSaveAddRegion}
        saveLabel={FDN.I18n.t("adminAdventures.info.addRegionPopup.buttons.add.label")}
      />
    </FDN.Popup>
  );
};

export default AdventureLocationSelector;

import { BrowserRouter, Routes, Route } from "react-router-dom";
import React from "react"; // To avoid "react must be in scope" error in eslint

import "/node_modules/@tschirpke/ptfdn-frontend-components/src/styles.scss";
import "./stylesheets/app.scss";

import { AppContextProvider } from "./components/context/AppContext/AppContext";
import { NotificationsContextProvider } from "./components/context/NotificationsContext/NotificationsContext";

import routes from "./config/routes.json";

import IndexPage from "./components/pages/public/IndexPage";

import LoginPage from "./components/pages/auth/login/LoginPage";
import MyAccountPage from "./components/pages/user/MyAccountPage";
import Error404 from "./components/pages/errors/Error404";
import AdminDashboard from "./components/pages/admin/dashboard/AdminDashboard";
import AdminSettings from "./components/pages/admin/settings/AdminSettings";
import AdminUserRoles from "./components/pages/admin/userroles/AdminUserRoles";
import AdminAccounts from "./components/pages/admin/accounts/AdminAccounts";
import AdminAccount from "./components/pages/admin/accounts/AdminAccount";
import SignupPage from "./components/pages/auth/signup/SignupPage";
import WelcomePage from "./components/pages/auth/welcome/WelcomePage";
import PasswordResetPage from "./components/pages/auth/passwordreset/PasswordResetPage";
import PasswordForgottenPage from "./components/pages/auth/passwordreset/PasswordForgottenPage";
import MySettingsPage from "./components/pages/user/MySettingsPage";
import DataPrivacyPage from "./components/pages/content/dataprivacy/DataPrivacyPage";
import LegalPage from "./components/pages/content/legal/LegalPage";
import { SocketContextProvider } from "./components/context/SocketContext/SocketContext";
import AdminEmailTemplates from "./components/pages/admin/emailtemplates/AdminEmailTemplates";
import AdminEmailTemplate from "./components/pages/admin/emailtemplates/AdminEmailTemplate";
import AdminAdventure from "./components/pages/admin/adventures/AdminAdventure";
import AdminAdventures from "./components/pages/admin/adventures/AdminAdventures";
import Adventure from "./components/pages/public/adventures/Adventure";
import { BookingContextProvider } from "./components/context/BookingContext/BookingContext";
import PaymentPage from "./components/pages/public/adventures/payment/PaymentPage";
import BookingConfirmation from "./components/pages/public/adventures/booking/BookingConfirmation";
import PdfBookingConfirmation from "./components/pages/public/adventures/booking/pdf/PdfBookingConfirmation";
import PaymentPageCompletion from "./components/pages/public/adventures/payment/PaymentPageCompletion";
import MyOrders from "./components/pages/user/orders/MyOrders";
import BookingReqInfo from "./components/pages/public/adventures/booking/BookingReqInfo";
import MyOrder from "./components/pages/user/orders/MyOrder";
import AdminOrders from "./components/pages/admin/orders/AdminOrders";
import AdminOrder from "./components/pages/admin/orders/AdminOrder";
import AdminDrivers from "./components/pages/admin/drivers/AdminDrivers";
import AboutUsPage from "./components/pages/content/aboutus/AboutUsPage";
import AdminHotels from "./components/pages/admin/hotels/AdminHotels";

function App() {
  return (
    <div className="App">
      <AppContextProvider>
        <BookingContextProvider>
          <SocketContextProvider>
            <BrowserRouter>
              <NotificationsContextProvider>
                <Routes>
                  <Route path={routes.index} element={<IndexPage />} />

                  <Route path={routes.aboutus} element={<AboutUsPage />} />

                  <Route path={routes.public.adventure} element={<Adventure />} />
                  <Route path={routes.public.privateAdventure} element={<Adventure />} />

                  <Route path={routes.auth.passwordforgotten} element={<PasswordForgottenPage />} />
                  <Route path={routes.auth.passwordreset} element={<PasswordResetPage />} />
                  <Route path={routes.auth.login} element={<LoginPage />} />

                  <Route path={routes.auth.signup} element={<SignupPage />} />

                  <Route path={routes.auth.welcome} element={<WelcomePage />} />

                  <Route path={routes.user.myaccount} element={<MyAccountPage />} />
                  <Route path={routes.user.settings} element={<MySettingsPage />} />

                  <Route path={routes.user.myorders.index} element={<MyOrders />} />
                  <Route path={routes.user.myorders.show} element={<MyOrder />} />

                  <Route path={routes.admin.dashboard.index} element={<AdminDashboard />} />

                  <Route path={routes.admin.accounts.account} element={<AdminAccount />} />
                  <Route path={routes.admin.accounts.index} element={<AdminAccounts />} />

                  <Route path={routes.admin.userroles.show} element={<AdminUserRoles />} />

                  <Route path={routes.admin.userroles.index} element={<AdminUserRoles />} />

                  <Route path={routes.admin.settings.index} element={<AdminSettings />} />

                  <Route path={routes.admin.emailtemplates.show} element={<AdminEmailTemplate />} />
                  <Route path={routes.admin.emailtemplates.index} element={<AdminEmailTemplates />} />

                  <Route path={routes.admin.adventures.show} element={<AdminAdventure />} />
                  <Route path={routes.admin.adventures.index} element={<AdminAdventures />} />

                  <Route path={routes.admin.orders.show} element={<AdminOrder />} />
                  <Route path={routes.admin.orders.index} element={<AdminOrders />} />

                  <Route path={routes.admin.drivers.show} element={<AdminDrivers />} />
                  <Route path={routes.admin.drivers.index} element={<AdminDrivers />} />

                  <Route path={routes.admin.hotels.index} element={<AdminHotels />} />

                  <Route path={routes.legal} element={<LegalPage />} />
                  <Route path={routes.dataprivacy} element={<DataPrivacyPage />} />

                  <Route path={routes.booking.confirmation} element={<BookingConfirmation />} />
                  <Route path={routes.booking.pdf} element={<PdfBookingConfirmation />} />
                  <Route path={routes.booking.reqinfo} element={<BookingReqInfo />} />

                  <Route path={routes.payment.orderPayment} element={<PaymentPage />} />
                  <Route path={routes.payment.orderPaymentCompletion} element={<PaymentPageCompletion />} />

                  <Route path={"*"} element={<Error404 />} />
                </Routes>
              </NotificationsContextProvider>
            </BrowserRouter>
          </SocketContextProvider>
        </BookingContextProvider>
      </AppContextProvider>
    </div>
  );
}

export default App;

import * as React from "react";
import * as FDN from "src/core";
import UrlService from "src/core/UrlService";
import useServiceMyOrder from "src/services/MyOrderService";
import { IAdventure, IOrder } from "src/types";
import ConfirmationReview from "../../public/adventures/booking/parts/ConfirmationReview";
import { nl2br } from "src/core/helpers/helpers";
import AdminBackButton from "../../admin/layouts/AdminBackButton";
import OrderFiles from "./parts/OrderFiles";
import WhatsappButton from "src/components/main/WhatsappButton";

const MyOrder: React.FunctionComponent = () => {
  const { order, adventure, selectedTab, tabs, actions } = useServiceMyOrder();

  if (!order || !adventure) return <FDN.Loading />;

  return (
    <div className="__myorder">
      <FDN.LayoutWithTopbar page="myorder">
        <FDN.PageContent>
          <FDN.Grid>
            <FDN.Row>
              <FDN.Cell sm={24} md={24} lg={12} lgo={6}>
                <AdminBackButton url={UrlService.url("user.myorders.index")} />
                <h1>{`# ${order.orderNr} | ${order.adventureTitle}`}</h1>
                <div>&nbsp;</div>
                <FDN.Tabs
                  tabs={tabs}
                  selected={selectedTab}
                  onSelect={(tabKey) => actions.setSelectedTab(tabKey)}
                >
                  {selectedTab === "info" && <TabContentInfo order={order} adventure={adventure} />}
                  {selectedTab === "driver" && (
                    <TabContentDriver order={order} adventure={adventure} />
                  )}
                  {selectedTab === "files" && (
                    <TabContentFiles order={order} adventure={adventure} />
                  )}
                </FDN.Tabs>
              </FDN.Cell>
            </FDN.Row>
          </FDN.Grid>
        </FDN.PageContent>
      </FDN.LayoutWithTopbar>
    </div>
  );
};

interface ITabContentProps {
  order: IOrder;
  adventure: IAdventure;
}

const TabContentInfo: React.FunctionComponent<ITabContentProps> = ({ order, adventure }) => {
  return (
    <>
      <ConfirmationReview order={order} adventure={adventure} />
      <div className="__public-adventure-paymentpolicy">
        <h3>{FDN.I18n.t("adventures.paymentPolicy.title")}</h3>
        <div
          className="__public-adventure-paymentpolicy-text"
          dangerouslySetInnerHTML={{ __html: nl2br(order.adventurePaymentPolicy || "") }}
        />
      </div>
    </>
  );
};

const TabContentDriver: React.FunctionComponent<ITabContentProps> = ({ order }) => {
  return (
    <div className="myorders-driver">
      <h3>{FDN.I18n.t("myorders.driver.title")}</h3>
      <p>{FDN.I18n.t("myorders.driver.intro")}</p>
      <FDN.Box>
        {!order.driver ? (
          <div>
            <em>{FDN.I18n.t("myorders.driver.noAssignedDriver")}</em>
          </div>
        ) : (
          <div>
            <div className="myorders-driver-name">{order.driver.displayname}</div>
            {order.driver.whatsapp ? (
              <div>
                <div>{order.driver.whatsapp}</div>
                <div className="myorders-driver-whatsapp">
                  <WhatsappButton number={order.driver.whatsapp} />
                </div>
              </div>
            ) : null}
          </div>
        )}
      </FDN.Box>
    </div>
  );
};

const TabContentFiles: React.FunctionComponent<ITabContentProps> = ({ order }) => {
  return (
    <>
      <h3>{FDN.I18n.t("myorders.files.title")}</h3>
      <p>{FDN.I18n.t("myorders.files.text")}</p>
      <OrderFiles order={order} />
    </>
  );
};

export default MyOrder;

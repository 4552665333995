import * as React from "react";
import FileManager from "src/components/main/FileManager";
import * as FDN from "src/core";
import Config from "src/core/Config";
import UrlService from "src/core/UrlService";
import { TActionsAdminHotels } from "src/services/admin/useHotels";
import { IAdventure, IHotel, LocationCountries, LocationRegions } from "src/types";

const tabs: FDN.ITabs = {
  info: {
    icon: "info-circle",
    label: FDN.I18n.t("adminAdventures.hotels.editPopup.tabs.info.label"),
  },
  pictures: {
    icon: "picture-o",
    label: FDN.I18n.t("adminAdventures.hotels.editPopup.tabs.pictures.label"),
  },
  rooms: {
    icon: "bed",
    label: FDN.I18n.t("adminAdventures.hotels.editPopup.tabs.rooms.label"),
  },
  adventures: {
    icon: "sun-o",
    label: FDN.I18n.t("adminAdventures.hotels.editPopup.tabs.adventures.label"),
  },
};

const defaultTab = "info";

interface IHotelFormProps {
  hotel: IHotel;
  adventures?: IAdventure[];
  locationCountries?: LocationCountries;
  actions: TActionsAdminHotels;
}

const HotelForm: React.FunctionComponent<IHotelFormProps> = ({ hotel, adventures, locationCountries, actions }) => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  return (
    <div className="admin-adventure-hotels-form">
      <FDN.Popup
        title={hotel.identifier === "new" ? FDN.I18n.t("adminAdventures.hotels.form.title") : hotel.name}
        size="large"
        onClose={actions.onCancelEdit}
      >
        <FDN.Tabs tabs={tabs} selected={selectedTab} onSelect={(tabKey) => setSelectedTab(tabKey)}>
          <p className="text-center warning-text">
            <FDN.Icon icon="exclamation-triangle" /> {FDN.I18n.t("adminAdventures.hotels.editPopup.intro")}
          </p>
          {selectedTab === "info" && <TabContentInfo hotel={hotel} actions={actions} />}
          {selectedTab === "pictures" && <TabContentPictures hotel={hotel} actions={actions} />}
          {selectedTab === "rooms" && <TabContentRooms hotel={hotel} actions={actions} />}
          {selectedTab === "adventures" && (
            <TabContentAdventures
              hotel={hotel}
              actions={actions}
              adventures={adventures}
              locationCountries={locationCountries}
            />
          )}
        </FDN.Tabs>
        <FDN.FormButtons
          onDelete={actions.onDelete}
          deleteDisabled={adventures && adventures.length > 0}
          onCancel={actions.onCancelEdit}
          onSave={actions.onSaveEdit}
          saveLabel={
            hotel.identifier === "new"
              ? FDN.I18n.t("adminAdventures.hotels.editPopup.buttons.onAdd.label")
              : FDN.I18n.t("adminAdventures.hotels.editPopup.buttons.onSave.label")
          }
        />
      </FDN.Popup>
    </div>
  );
};

const TabContentInfo: React.FunctionComponent<IHotelFormProps> = ({ hotel, actions }) => {
  const selectValuesStars = {
    0: "---",
    10: "1",
    15: "1 1/2",
    20: "2",
    25: "2 1/2",
    30: "3",
    35: "3 1/2",
    40: "4",
    45: "4 1/2",
    50: "5",
    55: "5 1/2",
  };

  return (
    <FDN.Grid full>
      <FDN.Row margin="xy">
        <FDN.Cell md={12}>
          <FDN.FormGroup title={FDN.I18n.t("adminAdventures.hotels.formgroup.name")}>
            <FDN.Grid full>
              <FDN.Row margin="xy">
                <FDN.Cell sm={24}>
                  <FDN.Input
                    type="text"
                    value={hotel.name || ""}
                    label={FDN.I18n.t("adminAdventures.hotels.form.name.label")}
                    editMode={true}
                    onUpdate={(value) => actions.onEdit("name", value)}
                  />
                </FDN.Cell>
              </FDN.Row>
              {/* <FDN.Row margin="xy">
                <FDN.Cell sm={24}>
                  <FDN.Input
                    type="text"
                    value={hotel.room || ""}
                    label={FDN.I18n.t("adminAdventures.hotels.form.room.label")}
                    editMode={true}
                    onUpdate={(value) => actions.onEdit("room", value)}
                  />
                </FDN.Cell>
              </FDN.Row> */}
              <FDN.Row margin="xy">
                <FDN.Cell sm={24}>
                  <FDN.Input
                    type="textarea"
                    rows={6}
                    value={hotel.description || ""}
                    label={FDN.I18n.t("adminAdventures.hotels.form.description.label")}
                    editMode={true}
                    onUpdate={(value) => actions.onEdit("description", value)}
                  />
                </FDN.Cell>
              </FDN.Row>
            </FDN.Grid>
          </FDN.FormGroup>
          <FDN.FormGroup title={FDN.I18n.t("adminAdventures.hotels.formgroup.stars")}>
            <FDN.Grid full>
              <FDN.Row margin="xy">
                <FDN.Cell sm={24}>
                  <FDN.Input
                    type="select"
                    selectValues={selectValuesStars}
                    value={hotel.stars || undefined}
                    label={FDN.I18n.t("adminAdventures.hotels.form.stars.label")}
                    editMode={true}
                    onUpdate={(value) => actions.onEdit("stars", value === "---" ? undefined : value)}
                  />
                </FDN.Cell>
              </FDN.Row>
            </FDN.Grid>
          </FDN.FormGroup>
          <FDN.FormGroup title={FDN.I18n.t("adminAdventures.hotels.formgroup.address")}>
            <FDN.SingleRowCell full margin="xy">
              <FDN.Input
                editable={true}
                editMode={true}
                value={hotel.street}
                label={FDN.I18n.t("adminAccounts.form.street.label")}
                placeholder={FDN.I18n.t("adminAccounts.form.street.placeholder")}
                onUpdate={(value) => actions.onEdit("street", value)}
              />
            </FDN.SingleRowCell>
            <FDN.SingleRowCell full margin="xy">
              <FDN.Input
                editable={true}
                editMode={true}
                value={hotel.streetAdditional}
                label={FDN.I18n.t("adminAccounts.form.streetAdditional.label")}
                placeholder={FDN.I18n.t("adminAccounts.form.streetAdditional.placeholder")}
                onUpdate={(value) => actions.onEdit("streetAdditional", value)}
              />
            </FDN.SingleRowCell>
            <FDN.GridRow full margin="xy">
              <FDN.Cell sm={24} md={8}>
                <FDN.Input
                  editable={true}
                  editMode={true}
                  value={hotel.zip}
                  label={FDN.I18n.t("adminAccounts.form.zip.label")}
                  placeholder={FDN.I18n.t("adminAccounts.form.zip.placeholder")}
                  onUpdate={(value) => actions.onEdit("zip", value)}
                />
              </FDN.Cell>
              <FDN.Cell sm={24} md={16}>
                <FDN.Input
                  editable={true}
                  editMode={true}
                  value={hotel.city}
                  label={FDN.I18n.t("adminAccounts.form.city.label")}
                  placeholder={FDN.I18n.t("adminAccounts.form.city.placeholder")}
                  onUpdate={(value) => actions.onEdit("city", value)}
                />
              </FDN.Cell>
            </FDN.GridRow>
            <FDN.SingleRowCell full margin="xy">
              <FDN.Input
                type="country"
                editable={true}
                editMode={true}
                options={{
                  locale: Config.get("currencyLocale") as string,
                }}
                value={hotel.country}
                label={FDN.I18n.t("adminAccounts.form.country.label")}
                placeholder={FDN.I18n.t("adminAccounts.form.country.placeholder")}
                onUpdate={(value) => actions.onEdit("country", value)}
              />
            </FDN.SingleRowCell>
            <FDN.GridRow full margin="xy">
              <FDN.Cell sm={24} md={24}>
                <FDN.Input
                  editable={true}
                  editMode={true}
                  value={hotel.email}
                  label={FDN.I18n.t("adminAccounts.form.email.label")}
                  placeholder={FDN.I18n.t("adminAccounts.form.email.placeholder")}
                  onUpdate={(value) => actions.onEdit("email", value)}
                />
              </FDN.Cell>
            </FDN.GridRow>
          </FDN.FormGroup>
          {/* <FDN.FormGroup title={FDN.I18n.t("adminAdventures.hotels.formgroup.price")}>
            <FDN.SingleRowCell full margin="xy">
              <FDN.Input
                type="currency"
                options={{
                  currency: Config.get("currency") as string,
                  locale: Config.get("currencyLocale") as string,
                }}
                editable={true}
                editMode={true}
                value={hotel.priceFull}
                label={FDN.I18n.t("adminAdventures.hotels.form.price.priceFull.label")}
                onUpdate={(value) => actions.onEdit("priceFull", value)}
              />
            </FDN.SingleRowCell>
            <FDN.SingleRowCell full margin="xy">
              <FDN.Input
                type="currency"
                options={{
                  currency: Config.get("currency") as string,
                  locale: Config.get("currencyLocale") as string,
                }}
                editable={true}
                editMode={true}
                value={hotel.priceSingleFull}
                label={FDN.I18n.t("adminAdventures.hotels.form.price.priceSingleFull.label")}
                onUpdate={(value) => actions.onEdit("priceSingleFull", value)}
              />
            </FDN.SingleRowCell>
          </FDN.FormGroup> */}
        </FDN.Cell>
      </FDN.Row>
    </FDN.Grid>
  );
};

const TabContentRooms: React.FunctionComponent<IHotelFormProps> = ({ hotel, actions }) => {
  return (
    <FDN.Grid full>
      <FDN.Row margin="xy">
        <FDN.Cell md={24}>
          {hotel.rooms?.map((room, i) => {
            return (
              <div key={room.identifier}>
                <div className="flex justify-start gap-1 items-center">
                  <div className="text-brightPurple text-lg font-medium">Room #{i + 1}</div>
                  <button
                    onClick={() => actions.onRemoveRoom(room.identifier)}
                    className="text-red-500 hover:text-white hover:bg-red-500 px-1.5 py-1 rounded-md cursor-pointer"
                  >
                    <FDN.Icon icon="trash" />
                  </button>
                </div>
                <FDN.Box>
                  <div className="font-semibold text-brightPurple pb-2">{room.title}</div>
                  <FDN.Grid full>
                    <FDN.Row margin="xy">
                      <FDN.Cell md={18}>
                        <div className="pb-4">
                          <FDN.Input
                            type="text"
                            value={room.title}
                            label={FDN.I18n.t("adminHotels.rooms.form.title.label")}
                            editMode={true}
                            onUpdate={(value) => actions.onEditRoom(room.identifier, "title", value)}
                          />
                        </div>
                        <FDN.Input
                          type="textarea"
                          rows={1}
                          value={room.description}
                          label={FDN.I18n.t("adminHotels.rooms.form.description.label")}
                          editMode={true}
                          onUpdate={(value) => actions.onEditRoom(room.identifier, "description", value)}
                        />
                      </FDN.Cell>
                      <FDN.Cell md={6}>
                        <div className="pb-4">
                          <FDN.Input
                            type="currency"
                            options={{
                              currency: Config.get("currency") as string,
                              locale: Config.get("currencyLocale") as string,
                            }}
                            value={room.priceFull}
                            label={FDN.I18n.t("adminHotels.rooms.form.priceFull.label")}
                            editMode={true}
                            onUpdate={(value) => actions.onEditRoom(room.identifier, "priceFull", value)}
                          />
                        </div>
                        <FDN.Input
                          type="currency"
                          options={{
                            currency: Config.get("currency") as string,
                            locale: Config.get("currencyLocale") as string,
                          }}
                          value={room.priceSingleFull}
                          label={FDN.I18n.t("adminHotels.rooms.form.priceSingleFull.label")}
                          editMode={true}
                          onUpdate={(value) => actions.onEditRoom(room.identifier, "priceSingleFull", value)}
                        />
                      </FDN.Cell>
                    </FDN.Row>
                  </FDN.Grid>
                </FDN.Box>
              </div>
            );
          })}

          <div className="flex justify-center items-center pt-4 text-brightPurple">
            <button
              onClick={() => actions.onAddRoom()}
              className="flex justify-start items-center gap-1 cursor-pointer hover:bg-brightPurple hover:text-white rounded-md px-4 py-1"
            >
              <FDN.Icon icon="plus-circle" className="text-xl" />{" "}
              {FDN.I18n.t("adminHotels.rooms.form.buttons.addRoom.label")}
            </button>
          </div>
        </FDN.Cell>
      </FDN.Row>
    </FDN.Grid>
  );
};

const TabContentAdventures: React.FunctionComponent<IHotelFormProps> = ({ adventures, locationCountries }) => {
  if (!locationCountries) return null;

  return (
    <FDN.Grid full>
      <FDN.Row margin="xy">
        <FDN.Cell md={24}>
          <h3>{FDN.I18n.t("adminHotels.adventures.title")}</h3>
          <p>{FDN.I18n.t("adminHotels.adventures.text")}</p>

          {!adventures && <FDN.Loading box />}
          {adventures && adventures.length === 0 && (
            <div className="text-center italic py-12">{FDN.I18n.t("adminHotels.adventures.notUsed.label")}</div>
          )}
          {adventures && adventures.length > 0 && (
            <>
              {adventures.map((adventure) => {
                let titlePreTitle = <></>;
                if (locationCountries && adventure.country) {
                  const country = locationCountries.find((c) => c.identifier === adventure.country);
                  if (country) {
                    titlePreTitle = <>{country.name}</>;

                    if (adventure.region && country.regions) {
                      const regions = country.regions as LocationRegions;
                      const region = regions.find((r) => r.identifier === adventure.region);
                      if (region)
                        titlePreTitle = (
                          <>
                            {titlePreTitle} <FDN.Icon icon="angle-right" /> {region.name}
                          </>
                        );
                    }
                  }
                }

                return (
                  <a
                    href={`${UrlService.url("admin.adventures.show", { identifier: adventure.identifier })}?tab=hotels`}
                    target="_blank"
                    rel="noopener noreferrer"
                    key={adventure.identifier}
                    className="block w-full mb-4 border bg-white rounded px-4 py-4 duration-200 cursor-pointer hover:bg-hover"
                  >
                    <div className="flex justify-between gap-2">
                      <div className="w-28">
                        {adventure.mainPicture?.S && <img src={adventure.mainPicture.S} alt={adventure.title} />}
                      </div>
                      <div className="flex-grow">
                        {titlePreTitle}
                        <div className="font-semibold text-brightPurple">{adventure.title}</div>
                      </div>
                    </div>
                  </a>
                );
              })}
            </>
          )}
        </FDN.Cell>
      </FDN.Row>
    </FDN.Grid>
  );
};

const TabContentPictures: React.FunctionComponent<IHotelFormProps> = ({ hotel, actions }) => {
  return (
    <div className="admin-adventure-tab-pictures">
      <FileManager
        allow={["images"]}
        parent="hotel"
        files={hotel.pictures || []}
        editMode={true}
        mainPictureIndex={0}
        allowEdit={false}
        allowRemove={true}
        actions={actions}
      />
    </div>
  );
};

export default HotelForm;

import React, { useState } from "react";
import { IAdventure, IOrder, TFunction } from "../types";
import useServiceCore from "./CoreService";
import BookingApi from "src/api/BookingApi";
import StatusCode from "src/config/statuscodes";
import * as FDN from "src/core";
import { useParams } from "react-router-dom";
import UrlService from "src/core/UrlService";

const tabs: FDN.ITabs = {
  info: {
    label: FDN.I18n.t("myorders.tabs.info.label"),
    icon: "info-circle",
  },
  driver: {
    label: FDN.I18n.t("myorders.tabs.driver.label"),
    icon: "cab",
  },
  files: {
    label: FDN.I18n.t("myorders.tabs.files.label"),
    icon: "files-o",
  },
};

const defaultTab = "info";

export interface TActionsMyOrder {
  setSelectedTab: TFunction;
}

const useServiceMyOrder = () => {
  const [order, setOrder] = useState<IOrder>();
  const [adventure, setAdventure] = useState<IAdventure>();

  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const { APP, api } = useServiceCore();

  const params = useParams();
  const { orderNr } = params;

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    BookingApi.getMyOrder(api, orderNr as string).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const order = response?.body?.order as IOrder;
        setOrder(order);

        const adventure = response?.body?.adventure as IAdventure;
        setAdventure(adventure);

        APP.setPageTitle(`Order # ${order.orderNr} - ${order.adventureTitle}`);
      } else if (response?.statusCode === StatusCode.NOT_FOUND) {
        window.location.href = UrlService.url("user.myorders.index");
      }
    });
  };

  const actions: TActionsMyOrder = {
    setSelectedTab,
  };

  return { order, adventure, selectedTab, tabs, actions };
};

export default useServiceMyOrder;

import * as React from "react";
import WhatsappButton from "src/components/main/WhatsappButton";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { TActionsAdminDrivers } from "src/services/admin/DriversService";
import { IDriver } from "src/types";

interface IEditDriverPopupProps {
  driver: IDriver;
  editMode: boolean;
  actions: TActionsAdminDrivers;
}

const EditDriverPopup: React.FunctionComponent<IEditDriverPopupProps> = ({
  driver,
  editMode,
  actions,
}) => {
  return (
    <div className="__admin-drivers-edit-popup">
      <FDN.Popup
        size="small"
        onClose={actions.onCancelEdit}
        title={
          driver.identifier === "new"
            ? FDN.I18n.t("adminDrivers.popup.new.title")
            : driver.displayname
        }
      >
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={12}>
              <FDN.Input
                type="text"
                label={FDN.I18n.t("adminDrivers.popup.form.firstname.label")}
                value={driver.firstname}
                editMode={editMode}
                onUpdate={(value) => actions.onEdit("firstname", value)}
              />
            </FDN.Cell>
            <FDN.Cell sm={24} md={12}>
              <FDN.Input
                type="text"
                label={FDN.I18n.t("adminDrivers.popup.form.lastname.label")}
                value={driver.lastname}
                editMode={editMode}
                onUpdate={(value) => actions.onEdit("lastname", value)}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={12}>
              <FDN.Input
                type="text"
                label={FDN.I18n.t("adminDrivers.popup.form.whatsapp.label")}
                value={driver.whatsapp}
                editMode={editMode}
                onUpdate={(value) => actions.onEdit("whatsapp", value)}
              />
              <div className="__admin-drivers-edit-popup-whatsappbutton">
                {driver.whatsapp ? <WhatsappButton number={driver.whatsapp} /> : null}
              </div>
            </FDN.Cell>
            <FDN.Cell sm={24} md={12}>
              <FDN.Input
                type="country"
                options={{
                  locale: Config.get("currencyLocale") as string,
                }}
                label={FDN.I18n.t("adminDrivers.popup.form.country.label")}
                value={driver.country}
                editMode={editMode}
                onUpdate={(value) => actions.onEdit("country", value)}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
        {editMode === true ? (
          <FDN.FormButtons onCancel={actions.onCancelEdit} onSave={actions.onSaveEdit} />
        ) : null}
      </FDN.Popup>
    </div>
  );
};

export default EditDriverPopup;

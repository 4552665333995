import React from "react";
import * as FDN from "src/core";
import { BookingContext } from "src/components/context/BookingContext/BookingContext";
import Config from "src/core/Config";
import { IAdventureHotel } from "src/types";
import HotelPictureSlider from "../../parts/HotelPictureSlider";
import HotelStars from "src/components/pages/admin/adventures/parts/hotels/HotelStars";
import { nl2br } from "src/core/helpers/helpers";
import { classNames } from "src/core/helpers/design";

interface IHotelEntryPopupProps {
  hotels: IAdventureHotel[];
  night: number;
  onClose: () => void;
}

const HotelEntryPopup: React.FunctionComponent<IHotelEntryPopupProps> = ({ hotels, night, onClose }) => {
  const BOOKING = React.useContext(BookingContext);
  const { order, getSelectedHotelForNight, showUpgradesForNight } = BOOKING;

  const [showMoreFor, setShowMoreFor] = React.useState<string[]>([]);

  const toggleShowMoreFor = (identifier: string) => {
    if (showMoreFor.includes(identifier)) setShowMoreFor(showMoreFor.filter((id) => id !== identifier));
    else setShowMoreFor([...showMoreFor, identifier]);
  };

  if (!order) return null;

  const selectedHotel = getSelectedHotelForNight(night);
  if (!selectedHotel) return null;

  const hotelInfo = selectedHotel.info;

  if (!hotelInfo) return null;

  return (
    <>
      <div className="block lg:hidden">
        <FDN.Popup
          size="fullscreen"
          onClose={onClose}
          title={FDN.I18n.t("booking.form.group.hotel.selectHotel.title", { night })}
        >
          <h3 className="text-center">{FDN.I18n.t("booking.form.group.hotel.selectedHotel.title")}</h3>

          <HotelEntryContent
            hotel={selectedHotel}
            showAsSelectedHotel={true}
            night={night}
            bgClassName="bg-green-50"
            showMoreFor={showMoreFor}
            toggleShowMoreFor={toggleShowMoreFor}
          />

          <hr />
          <div className="">
            <h3 className="text-center">{FDN.I18n.t("booking.form.group.hotel.availableUpgrades.title")}</h3>

            {hotels.map((hotel) => {
              if (!hotel.info) return null;

              return (
                <div key={hotel.identifier} className="pb-4">
                  <HotelEntryContent
                    hotel={hotel}
                    showAsSelectedHotel={false}
                    night={night}
                    bgClassName="bg-white"
                    showMoreFor={showMoreFor}
                    toggleShowMoreFor={toggleShowMoreFor}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex-col gap-1 flex lg:hidden">
            <div className="flex-1">
              {night > 1 && (
                <FDN.Button primary expanded icon="chevron-left" onClick={() => showUpgradesForNight(night - 1)}>
                  {FDN.I18n.t("booking.form.group.hotel.prevNight.label")}
                </FDN.Button>
              )}
            </div>
            <div className="flex-1">
              <FDN.Button hollow primary expanded icon="times" onClick={onClose}>
                {FDN.I18n.t("buttons.close")}
              </FDN.Button>
            </div>

            <div className="flex-1">
              {night < (order.nights ?? 0) && (
                <FDN.Button primary expanded icon="chevron-right" onClick={() => showUpgradesForNight(night + 1)}>
                  {FDN.I18n.t("booking.form.group.hotel.nextNight.label")}
                </FDN.Button>
              )}
            </div>
          </div>
          <div className="justify-between hidden lg:flex">
            <div className="flex-1 text-left flex justify-center lg:justify-start items-center">
              {night > 1 && (
                <FDN.Button primary icon="chevron-left" onClick={() => showUpgradesForNight(night - 1)}>
                  {FDN.I18n.t("booking.form.group.hotel.prevNight.label")}
                </FDN.Button>
              )}
            </div>
            <div className="flex-1 text-right flex justify-center lg:justify-end items-center gap-2">
              <FDN.Button hollow primary icon="times" onClick={onClose}>
                {FDN.I18n.t("buttons.close")}
              </FDN.Button>

              {night < (order.nights ?? 0) && (
                <FDN.Button primary icon="chevron-right" onClick={() => showUpgradesForNight(night + 1)}>
                  {FDN.I18n.t("booking.form.group.hotel.nextNight.label")}
                </FDN.Button>
              )}
            </div>
          </div>
        </FDN.Popup>
      </div>

      <div className="hidden lg:block">
        <FDN.Popup
          size="medium"
          onClose={onClose}
          title={FDN.I18n.t("booking.form.group.hotel.selectHotel.title", { night })}
        >
          <h3 className="text-center">{FDN.I18n.t("booking.form.group.hotel.selectedHotel.title")}</h3>

          <HotelEntryContent
            hotel={selectedHotel}
            showAsSelectedHotel={true}
            night={night}
            bgClassName="bg-green-50"
            showMoreFor={showMoreFor}
            toggleShowMoreFor={toggleShowMoreFor}
          />

          <hr />
          <div className="">
            <h3 className="text-center">{FDN.I18n.t("booking.form.group.hotel.availableUpgrades.title")}</h3>

            {hotels.map((hotel) => {
              if (!hotel.info) return null;

              return (
                <div key={hotel.identifier} className="pb-4">
                  <HotelEntryContent
                    hotel={hotel}
                    showAsSelectedHotel={false}
                    night={night}
                    bgClassName="bg-white"
                    showMoreFor={showMoreFor}
                    toggleShowMoreFor={toggleShowMoreFor}
                  />
                </div>
              );
            })}
          </div>
          <div className="flex-col gap-1 flex lg:hidden">
            <div className="flex-1">
              {night > 1 && (
                <FDN.Button primary expanded icon="chevron-left" onClick={() => showUpgradesForNight(night - 1)}>
                  {FDN.I18n.t("booking.form.group.hotel.prevNight.label")}
                </FDN.Button>
              )}
            </div>
            <div className="flex-1">
              <FDN.Button hollow primary expanded icon="times" onClick={onClose}>
                {FDN.I18n.t("buttons.close")}
              </FDN.Button>
            </div>

            <div className="flex-1">
              {night < (order.nights ?? 0) && (
                <FDN.Button primary expanded icon="chevron-right" onClick={() => showUpgradesForNight(night + 1)}>
                  {FDN.I18n.t("booking.form.group.hotel.nextNight.label")}
                </FDN.Button>
              )}
            </div>
          </div>
          <div className="justify-between hidden lg:flex">
            <div className="flex-1 text-left flex justify-center lg:justify-start items-center">
              {night > 1 && (
                <FDN.Button primary icon="chevron-left" onClick={() => showUpgradesForNight(night - 1)}>
                  {FDN.I18n.t("booking.form.group.hotel.prevNight.label")}
                </FDN.Button>
              )}
            </div>
            <div className="flex-1 text-right flex justify-center lg:justify-end items-center gap-2">
              <FDN.Button hollow primary icon="times" onClick={onClose}>
                {FDN.I18n.t("buttons.close")}
              </FDN.Button>

              {night < (order.nights ?? 0) && (
                <FDN.Button primary icon="chevron-right" onClick={() => showUpgradesForNight(night + 1)}>
                  {FDN.I18n.t("booking.form.group.hotel.nextNight.label")}
                </FDN.Button>
              )}
            </div>
          </div>
        </FDN.Popup>
      </div>
    </>
  );
};

interface IHotelEntryContentProps {
  hotel: IAdventureHotel;
  showAsSelectedHotel: boolean;
  night: number;
  bgClassName: string;
  showMoreFor: string[];
  toggleShowMoreFor: (identifier: string) => void;
}

const HotelEntryContent: React.FunctionComponent<IHotelEntryContentProps> = ({
  hotel,
  showAsSelectedHotel,
  night,
  bgClassName,
  showMoreFor,
  toggleShowMoreFor,
}) => {
  const BOOKING = React.useContext(BookingContext);
  const { order, getPriceDifferenceForRoom, getSelectedHotelForNight, selectRoom } = BOOKING;

  const currency = Config.get("currency") as string;

  if (!hotel.info || !order) return null;

  const selectedHotel = getSelectedHotelForNight(night);
  if (!selectedHotel) return null;

  const selectedRoom = selectedHotel.room;
  if (!selectedRoom) return null;

  const extraCost =
    order.participants.length === 1 ? selectedRoom.pricePerNightSingleFull ?? 0 : selectedRoom.pricePerNightFull ?? 0;

  return (
    <div className={classNames(bgClassName, "border rounded-md px-6 py-6")}>
      <div className="flex flex-col lg:flex-row justify-between gap-4">
        <div className="w-full lg:w-48">
          {hotel.info.mainPicture?.S && <HotelPictureSlider hotel={hotel.info} height="120px" />}
        </div>
        <div className="flex-grow">
          <div className="text-brightPurple font.medium">
            {hotel.info.name} {hotel.info.stars && <HotelStars stars={hotel.info.stars} />}
          </div>

          {hotel.info.description && hotel.info.description.length > 200 && !showMoreFor.includes(hotel.identifier) ? (
            <div>
              <div
                className="text-xs text-gray-500 pt-2"
                dangerouslySetInnerHTML={{ __html: `${nl2br(hotel.info.description.slice(0, 193))}...` }}
              />
              <button
                onClick={() => toggleShowMoreFor(hotel.identifier)}
                className="color-brightred text-xs cursor-pointer hover:underline"
              >
                {FDN.I18n.t("booking.form.group.hotel.showMore")}
              </button>
            </div>
          ) : (
            <div>
              <div
                className="text-xs text-gray-500 pt-2"
                dangerouslySetInnerHTML={{ __html: nl2br(hotel.info.description || "") }}
              />
              {hotel.info.description && (
                <button
                  onClick={() => toggleShowMoreFor(hotel.identifier)}
                  className="color-brightred text-xs cursor-pointer hover:underline"
                >
                  {FDN.I18n.t("booking.form.group.hotel.showLess")}
                </button>
              )}
            </div>
          )}

          {selectedRoom && showAsSelectedHotel && (
            <>
              <div className="text-brightPurple text-xs font-medium mt-4 pl-4 pb-1">
                {FDN.I18n.t("booking.form.group.hotel.selectedRoom.title")}
              </div>

              <div className={classNames("bg-green-50", "block w-full text-left border rounded px-2 py-2 mt-1")}>
                <div className="flex justify-between">
                  <div className="w-8 text-center flex justify-center items-center text-green-400">
                    <FDN.Icon icon="check" />
                  </div>
                  <div className="flex-grow">{selectedRoom.title}</div>
                  <div className="text-right">
                    {extraCost === 0 ? (
                      <span className="text-sm font-medium">
                        {FDN.I18n.t("booking.form.group.hotel.roomIncluded.label")}
                      </span>
                    ) : (
                      <>
                        {currency}
                        {FDN.formatNumber(extraCost, 2, 2)}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}

          {hotel.rooms && !showAsSelectedHotel && (
            <>
              <div className="text-brightPurple text-xs font-medium mt-4 pl-4 pb-1">
                {FDN.I18n.t("booking.form.group.hotel.availableRooms.title")}
              </div>
              <div className="">
                {hotel.rooms.map((room) => {
                  const extraCost = getPriceDifferenceForRoom(room, night);

                  const isSelectedRoom =
                    hotel.hotelIdentifier === selectedHotel.hotelIdentifier &&
                    room.roomIdentifier === selectedRoom.roomIdentifier;

                  let extraCostText = <></>;

                  if (isSelectedRoom && extraCost === 0) {
                    extraCostText = (
                      <span className="text-slate-500 text-sm font-medium">
                        {FDN.I18n.t("booking.form.group.hotel.roomSelected.label")}
                      </span>
                    );
                  } else if (extraCost > 0) {
                    extraCostText = (
                      <span className="text-slate-500">
                        + {currency}
                        {FDN.formatNumber(extraCost, 2, 2)}
                      </span>
                    );
                  } else if (extraCost < 0) {
                    extraCostText = (
                      <span className="text-slate-500">
                        - {currency}
                        {FDN.formatNumber(extraCost * -1, 2, 2)}
                      </span>
                    );
                  } else if (extraCost === 0) {
                    extraCostText = <span className="text-slate-500">+ {currency}0</span>;
                  }

                  return (
                    <button
                      key={room.roomIdentifier}
                      className={classNames("block w-full text-left rounded mt-1 cursor-pointer group")}
                      onClick={() => selectRoom(hotel, room, night)}
                    >
                      <div
                        className={classNames(
                          "flex justify-between border rounded-md px-2 py-2 duration-200",
                          isSelectedRoom && "bg-green-50 text-green-500 border-green-300",
                          "group-hover:border-green-400 group-hover:bg-green-50"
                        )}
                      >
                        <div className="w-8 text-center flex justify-center items-center">
                          {isSelectedRoom ? (
                            <span className="text-green-400">
                              <FDN.Icon icon="check" />
                            </span>
                          ) : (
                            <>
                              <span className="text-gray-400 inline-block group-hover:hidden">
                                <FDN.Icon icon="square-o" />
                              </span>
                              <span className="text-green-400 hidden group-hover:inline-block">
                                <FDN.Icon icon="check" />
                              </span>
                            </>
                          )}
                        </div>
                        <div className="flex-grow flex items-center">{room.title}</div>
                        <div className="text-right text-sm font-medium">{extraCostText}</div>
                      </div>
                    </button>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default HotelEntryPopup;

import * as React from "react";
import * as FDN from "src/core";
import AdminLayout from "../layouts/AdminLayout";
import useServiceAdminDashboard from "src/services/admin/DashboardService";
import AdminHeader from "../layouts/AdminHeader";
import { AppContext } from "src/components/context/AppContext/AppContext";

const AdminDashboard: React.FunctionComponent = () => {
  const { dashboard } = useServiceAdminDashboard();
  const APP = React.useContext(AppContext);
  const user = APP.getUser();

  if (!dashboard || !user) return <FDN.Loading />;

  return (
    <AdminLayout page="admin-dashboard" selectedNav="dashboard">
      <AdminHeader title={FDN.I18n.t("adminDashboard.header.title")} />
      <div className="dashboard-welcome">Welcome, {user.displayname}!</div>
    </AdminLayout>
  );
};

export default AdminDashboard;

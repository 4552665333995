import React from "react";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { IFile, TActions } from "src/types";
import { IFileManagerParent } from "../types";
//import * as FDN from "src/core";
//import useServiceCore from "./CoreService";
//import StatusCode from "../config/statuscodes";

interface IServiceFileManagerProps {
  files: IFile[];
  parentActions: TActions;
  parent: IFileManagerParent;
}

const useServiceFileManager = (props: IServiceFileManagerProps) => {
  //const { APP, NOTIFICATIONS, api } = useServiceCore();

  const { parentActions, parent } = props;

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    //
  };

  const onUploadsFinished = (files: IFile[]) => {
    if (parent === "adventure" && actions.onPictureUploadsCompleted)
      actions.onPictureUploadsCompleted(files);
    else if (parent === "hotel" && actions.onHotelPictureUploadsCompleted)
      actions.onHotelPictureUploadsCompleted(files);
    else if (parent === "upgrade" && actions.onUpgradePictureUploadsCompleted)
      actions.onUpgradePictureUploadsCompleted(files);
    else if (parent === "order" && actions.onOrderFileUploadsCompleted)
      actions.onOrderFileUploadsCompleted(files);
  };

  const formatFileSize = (size: number): string => {
    let fSize = "";
    let unit = "";

    const locale = Config.get("currencyLocale") as string;

    if (size >= 10000000000) {
      fSize = FDN.formatNumber(size / 1000000000, 0, 1, locale);
      unit = "GB";
    } else if (size >= 1000000) {
      fSize = FDN.formatNumber(size / 1000000, 0, 2, locale);
      unit = "MB";
    } else if (size >= 1000) {
      fSize = FDN.formatNumber(size / 1000, 0, 0, locale);
      unit = "KB";
    } else {
      fSize = FDN.formatNumber(size / 1, 0, 0, locale);
      unit = "B";
    }

    return `${fSize} ${unit}`;
  };

  const actions: TActions = {
    onUploadsFinished,
    formatFileSize,
    ...parentActions,
  };

  return { actions };
};

export default useServiceFileManager;

import * as React from "react";
import * as FDN from "src/core";
import { IAdventure, IUpgrade, IUpgrades, TActions } from "src/types";
import AdventureUpgradesList from "../parts/upgrades/AdventureUpgradesList";
import UpgradeForm from "../parts/upgrades/UpgradeForm";
import AddUpgradePopup from "../parts/upgrades/AddUpgradePopup";

interface IAdventureUpgradesProps {
  adventure: IAdventure;
  upgradeAddPopupVisible: boolean;
  upgradeEditPopupVisible: boolean;
  upgrade?: IUpgrade;
  upgrades?: IUpgrades;
  editMode: boolean;
  actions: TActions;
}

const AdventureUpgrades: React.FunctionComponent<IAdventureUpgradesProps> = ({
  adventure,
  upgradeAddPopupVisible,
  upgradeEditPopupVisible,
  upgrade,
  upgrades,
  editMode,
  actions,
}) => {
  return (
    <div className="admin-adventure-tab-upgrades">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={18}>
            <AdventureUpgradesList adventure={adventure} editMode={editMode} actions={actions} />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
      {upgradeAddPopupVisible === true && (
        <AddUpgradePopup adventure={adventure} upgrades={upgrades} actions={actions} />
      )}
      {upgradeEditPopupVisible === true && upgrade ? (
        <UpgradeForm adventure={adventure} upgrade={upgrade} actions={actions} />
      ) : null}
    </div>
  );
};

export default AdventureUpgrades;

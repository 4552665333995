import * as React from "react";
import * as FDN from "src/core";
import useServiceCore from "src/services/CoreService";

const SupportButton: React.FunctionComponent = () => {
  const { APP } = useServiceCore();
  const supportEmail = APP.getSetting("supportEmail") as string;

  const url = `mailto:${supportEmail}?subject=${encodeURI("I have a question")}`;

  return (
    <div className="__supportbutton flex items-center h-full">
      <FDN.ToolTip tooltip={FDN.I18n.t("topbar.supportbutton.tooltip")}>
        <a href={url} target="_blank" rel="noopener noreferrer" className="small primary button">
          <FDN.Icon icon="question-circle" />{" "}
          <span className="hide-for-small-only">{FDN.I18n.t("topbar.supportbutton.label")}</span>
        </a>
      </FDN.ToolTip>
    </div>
  );
};

export default SupportButton;

import * as React from "react";
import * as FDN from "src/core";
import { TActionsAdminOrders } from "src/services/admin/OrdersService";
import { SearchOrdersFilter } from "src/types";

interface IOrdersSearchProps {
  search: SearchOrdersFilter;
  actions: TActionsAdminOrders;
}

const OrdersSearch: React.FunctionComponent<IOrdersSearchProps> = ({ search, actions }) => {
  return (
    <div className="admin-orders-search">
      <FDN.Box>
        <div className="admin-orders-search-title">
          <h3>{FDN.I18n.t("adminOrders.search.title")}</h3>
          <div className="admin-orders-search-title-buttons">
            <button className="small primary hollow button" onClick={actions.resetSearch}>
              {FDN.I18n.t("adminOrders.search.buttons.reset.label")}
            </button>
          </div>
        </div>

        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={12} lg={6}>
              <FDN.Input
                type="date"
                value={search.createdFrom}
                label={FDN.I18n.t("adminOrders.search.form.createdFrom.label")}
                onUpdate={(value) => actions.updateSearch("createdFrom", value)}
                editMode={true}
              />
            </FDN.Cell>
            <FDN.Cell sm={24} md={12} lg={6}>
              <FDN.Input
                type="date"
                value={search.createdUntil}
                label={FDN.I18n.t("adminOrders.search.form.createdUntil.label")}
                onUpdate={(value) => actions.updateSearch("createdUntil", value)}
                editMode={true}
              />
            </FDN.Cell>
            <FDN.Cell sm={24} md={12} lg={6}>
              <FDN.Input
                type="date"
                value={search.startDateFrom}
                label={FDN.I18n.t("adminOrders.search.form.startDateFrom.label")}
                onUpdate={(value) => actions.updateSearch("startDateFrom", value)}
                editMode={true}
              />
            </FDN.Cell>
            <FDN.Cell sm={24} md={12} lg={6}>
              <FDN.Input
                type="date"
                value={search.startDateUntil}
                label={FDN.I18n.t("adminOrders.search.form.startDateUntil.label")}
                onUpdate={(value) => actions.updateSearch("startDateUntil", value)}
                editMode={true}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.Box>
    </div>
  );
};

export default OrdersSearch;

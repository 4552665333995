import * as React from "react";
import * as FDN from "src/core";
import { BookingContext } from "src/components/context/BookingContext/BookingContext";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import en from "date-fns/locale/en-US";
import { add } from "date-fns";
import Config from "src/core/Config";
import { IAdventure } from "src/types";
import BookingPageTitle from "../parts/BookingPageTitle";

registerLocale("en-US", en);

interface IBookingDateProps {
  adventure: IAdventure;
}

const BookingDate: React.FunctionComponent<IBookingDateProps> = ({ adventure }) => {
  const BOOKING = React.useContext(BookingContext);
  const { order } = BOOKING;

  const today = new Date();
  const startDate = adventure.leadTimeInDays ? add(today, { days: adventure.leadTimeInDays }) : today;
  const endDate = add(today, { years: 2 });

  if (!order) return <FDN.Loading box />;

  return (
    <div className="__booking-form-page-date" id="booking_date">
      <BookingPageTitle page="date" order={order} />
      <div className="__booking-form-page-date-inline-datepicker">
        <DatePicker
          inline
          locale="en"
          selected={order.startDate ? new Date(order.startDate) : undefined}
          dateFormat={Config.get("datepickerFormat") as string}
          includeDateIntervals={[{ start: startDate, end: endDate }]}
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          showWeekNumbers
          isClearable
          allowSameDay={false}
          todayButton={"Today"}
          onChange={(date) => {
            BOOKING.onEdit("startDate", date);
          }}
        />
      </div>

      {/* <BookingLoginButton /> */}

      {/* <FDN.Box>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={12} mdo={6}>
              <div className="__ptfdn-input">
                <div className={`__ptfdn-input-edit`}>
                  <DatePicker
                    locale="en"
                    selected={order.startDate ? new Date(order.startDate) : undefined}
                    dateFormat={Config.get("datepickerFormat") as string}
                    includeDateIntervals={[{ start: startDate, end: endDate }]}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    showWeekNumbers
                    isClearable
                    allowSameDay={false}
                    todayButton={"Today"}
                    onChange={(date) => {
                      BOOKING.onEdit("startDate", date);
                    }}
                  />
                  <div className="__ptfdn-input-label">
                    {FDN.I18n.t("booking.form.group.date.startDate.label")}
                  </div>
                </div>
              </div>
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.Box> */}
    </div>
  );
};

export default BookingDate;

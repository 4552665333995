import { useState } from "react";
import { userHasPermission } from "src/core/AdminService/helpers";
import useServiceCore from "src/services/CoreService";
import { IPageContent } from "src/types";
import * as FDN from "src/core";
import StatusCode from "src/config/statuscodes";

export interface PageContentActions {
  init: (identifiers: string[]) => void;
  isEditModeAvailable: () => boolean;
  updateEditMode: (editMode: boolean) => void;
  onEdit: (identifier: string, content: string) => void;
  onCancel: () => void;
  onSave: () => void;
}

const usePageContent = () => {
  const [pageContents, setPageContents] = useState<Record<string, IPageContent>>({});
  const [editMode, setEditMode] = useState<boolean>(false);
  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const { APP, NOTIFICATIONS, api } = useServiceCore();
  const user = APP.getUser();

  const init = (identifiers: string[]) => {
    for (const identifier of identifiers) {
      fetchPageContent(identifier);
    }
  };

  const fetchPageContent = async (identifier: string) => {
    api.get("public.pageContent", { identifier }).then((response) => {
      const pageContent = response?.body?.pageContent as IPageContent;
      setPageContents((prev) => ({ ...prev, [identifier]: pageContent }));
    });
  };

  const isEditModeAvailable = () => {
    return userHasPermission(user, "admin");
  };

  const updateEditMode = (editMode: boolean) => {
    if (!userHasPermission(user, "admin")) return;
    setEditMode(editMode);
  };

  const onEdit = (identifier: string, content: string) => {
    setPageContents((prev) => ({ ...prev, [identifier]: { ...prev[identifier], content } }));
    setUnsavedChanges(true);
  };

  const onCancel = () => {
    const doCancel = () => window.location.reload();

    if (unsavedChanges)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  const onSave = () => {
    console.log("pageContents", pageContents);

    api.post("public.updatePageContent", {}, { pageContents }).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        window.location.reload();
      }
    });
  };

  const actions: PageContentActions = {
    init,
    isEditModeAvailable,
    updateEditMode,
    onEdit,
    onCancel,
    onSave,
  };

  return { pageContents, editMode, actions };
};

export default usePageContent;

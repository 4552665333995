import * as React from "react";
import * as FDN from "src/core";
import useServiceAdminOrder, { AddFilesObject, TActionsAdminOrder } from "src/services/admin/OrderService";
import AdminLayout from "../layouts/AdminLayout";
import AdminHeader from "../layouts/AdminHeader";
import {
  IAdventure,
  IDrivers,
  IFile,
  IOrder,
  IOrderParticipant,
  IReqInfoByParticipant,
  IReqInfoFiles,
  IStripePayments,
} from "src/types";
import UrlService from "src/core/UrlService";
import { formatFileSize, nl2br } from "src/core/helpers/helpers";
import { syncReqInfos } from "src/services/ReqInfoService";
import Config from "src/core/Config";
import { isArray } from "lodash";
import useServiceCore from "src/services/CoreService";
import BookingPaymentPlanSummary from "../../public/adventures/booking/parts/BookingPaymentPlanSummary";
import { PreviewUpgrades } from "../../public/adventures/booking/parts/ConfirmationReview";
import OrderFiles from "./parts/OrderFiles";
import WhatsappButton from "src/components/main/WhatsappButton";
import OrderDriver from "./parts/OrderDriver";
import { userHasPermission } from "src/core/AdminService/helpers";
import { Link, useNavigate } from "react-router-dom";
import OrderHotels from "./parts/OrderHotels";

const AdminOrder: React.FunctionComponent = () => {
  const {
    APP,
    order,
    orderTitle,
    adventure,
    reqInfo,
    reqInfoFiles,
    payments,
    tabs,
    selectedTab,
    setSelectedTab,
    popupAddFilesVisible,
    addFilesObject,
    drivers,
    actions,
  } = useServiceAdminOrder();

  const user = APP.getUser();
  const navigate = useNavigate();

  if (!order || !adventure || reqInfo === undefined) return <FDN.Loading />;

  let updatedOrderTitle = <>{orderTitle}</>;

  if (order.cancelled === true) {
    updatedOrderTitle = (
      <>
        {orderTitle}
        <span className={`admin-order-cancelled`}>
          <FDN.Icon icon="calendar-times-o" left />
          {FDN.I18n.t(`adminOrders.cancelled.label`)}
        </span>
      </>
    );
  }

  return (
    <div className="__admin-order">
      <AdminLayout page="admin-order" selectedNav="orders">
        <AdminHeader
          title={updatedOrderTitle}
          backButton={{ url: UrlService.url("admin.orders.index") }}
          buttons={[
            <>
              {userHasPermission(user, "adminOrdersEdit") ? (
                <>
                  {order.cancelled === true ? (
                    <button className="success hollow button" onClick={actions.onUncancelOrder}>
                      <FDN.Icon icon="calendar-check-o" left /> {FDN.I18n.t("adminOrders.buttons.uncancel.label")}
                    </button>
                  ) : (
                    <button className="alert hollow button" onClick={actions.onCancelOrder}>
                      <FDN.Icon icon="calendar-times-o" left /> {FDN.I18n.t("adminOrders.buttons.cancel.label")}
                    </button>
                  )}
                </>
              ) : null}
            </>,
          ]}
        />
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell md={6}>
              <OrderBoxInfo order={order} />
              <OrderBoxContact order={order} />
              <OrderBoxAdventure order={order} />
            </FDN.Cell>
            <FDN.Cell md={18}>
              <FDN.Tabs
                tabs={tabs}
                selected={selectedTab}
                updateHistory
                onUpdateUrl={(params) => navigate({ search: params })}
                onSelect={(tabKey) => setSelectedTab(tabKey)}
              >
                {selectedTab === "participants" && (
                  <OrderContentParticipants
                    order={order}
                    reqInfo={reqInfo}
                    reqInfoFiles={reqInfoFiles}
                    actions={actions}
                  />
                )}
                {selectedTab === "payments" && (
                  <OrderContentPayments order={order} payments={payments} adventure={adventure} />
                )}
                {selectedTab === "hotels" && <OrderContentHotels order={order} adventure={adventure} />}
                {selectedTab === "upgrades" && <OrderContentUpgrades order={order} adventure={adventure} />}
                {selectedTab === "files" && (
                  <OrderContentFiles
                    order={order}
                    popupAddFilesVisible={popupAddFilesVisible}
                    addFilesObject={addFilesObject}
                    actions={actions}
                  />
                )}
                {selectedTab === "driver" && <OrderContentDriver order={order} drivers={drivers} actions={actions} />}
              </FDN.Tabs>
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </AdminLayout>
    </div>
  );
};

interface IOrderBoxProps {
  order: IOrder;
  actions?: TActionsAdminOrder;
  index?: number;
}

const OrderBoxInfo: React.FunctionComponent<IOrderBoxProps> = ({ order }) => {
  return (
    <div className="__admin-order-box-info">
      <FDN.Box noPadding>
        <div className="admin-order-info-content">
          <div className="admin-order-info-content-value"># {order.orderNr}</div>
          <div className="admin-order-info-content-label">{FDN.I18n.t("adminOrders.info.orderNr")}</div>
        </div>
        <div className="admin-order-info-content">
          <div className="admin-order-info-content-value">{FDN.formatDateTime(order.createdAt, { seconds: true })}</div>
          <div className="admin-order-info-content-label">{FDN.I18n.t("adminOrders.info.orderDate")}</div>
        </div>
      </FDN.Box>
      <FDN.Box noPadding>
        <div className="admin-order-info-content text-center">
          <div className="admin-order-info-content-value">{FDN.formatDate(order.startDate)}</div>
          <div className="admin-order-info-content-label">{FDN.I18n.t("adminOrders.info.startDate")}</div>
        </div>
        {order.nights && order.endDate ? (
          <div className="admin-order-info-content text-center">
            <FDN.Grid full>
              <FDN.Row margin="xy">
                <FDN.Cell md={12}>
                  <div className="admin-order-info-content-value">{order.nights}</div>
                  <div className="admin-order-info-content-label">{FDN.I18n.t("adminOrders.info.nights")}</div>
                </FDN.Cell>
                <FDN.Cell md={12}>
                  <div className="admin-order-info-content-value">{FDN.formatDate(order.endDate)}</div>
                  <div className="admin-order-info-content-label">{FDN.I18n.t("adminOrders.info.endDate")}</div>
                </FDN.Cell>
              </FDN.Row>
            </FDN.Grid>
          </div>
        ) : null}
      </FDN.Box>
    </div>
  );
};

const OrderBoxContact: React.FunctionComponent<IOrderBoxProps> = ({ order }) => {
  return (
    <div className="__admin-order-box-contact">
      <FDN.Box>
        <div className="admin-order-content-contact">
          <h2>{FDN.I18n.t("adminOrders.contact.title")}</h2>
          {order.firstname ||
          order.lastname ||
          order.street ||
          order.zip ||
          order.city ||
          order.country ||
          order.phone ||
          order.email ? (
            <div>
              {order.firstname || order.lastname ? (
                <div>
                  {order.firstname} {order.lastname}
                </div>
              ) : null}
              {order.street ? <div>{order.street}</div> : null}
              {order.streetAdditional ? <div>{order.streetAdditional}</div> : null}
              {order.zip || order.city ? (
                <div>
                  {order.zip} {order.city}
                </div>
              ) : null}
              {order.country ? <div>{order.country}</div> : null}
              <div>&nbsp;</div>
              {order.phone ? (
                <div>
                  {FDN.I18n.t("adminOrders.contact.phone")}: {order.phone}
                </div>
              ) : null}
              {order.email ? (
                <div>
                  {FDN.I18n.t("adminOrders.contact.email")}: {order.email}
                </div>
              ) : null}
              {order.phone ? (
                <div className="admin-order-contact-whatsapp">
                  <WhatsappButton number={order.phone} />
                </div>
              ) : null}
            </div>
          ) : (
            <FDN.Icon icon="question" />
          )}
        </div>
      </FDN.Box>
    </div>
  );
};

const OrderBoxAdventure: React.FunctionComponent<IOrderBoxProps> = ({ order }) => {
  const pictureStyles = {
    backgroundImage: order.adventureMainPicture ? `url("${order.adventureMainPicture}")` : undefined,
  };

  return (
    <div className="__admin-order-box-adventure">
      <FDN.Box>
        <div className="admin-order-adventure-picture" style={pictureStyles}></div>
        <div className="admin-order-adventure-content">
          <h3>{order.adventureTitle}</h3>
          <div className="text-center pb-3">
            <Link
              to={UrlService.url("admin.adventures.show", { identifier: order.adventureIdentifier })}
              target="_blank"
              rel="noopener noreferrer"
            >
              {FDN.I18n.t("adminOrders.adventure.link.label")} <FDN.Icon icon="external-link" />
            </Link>
          </div>
          <div>{order.adventureDescription}</div>
        </div>
      </FDN.Box>
    </div>
  );
};

interface IOrderContentParticipantProps {
  order: IOrder;
  reqInfo: IReqInfoByParticipant;
  reqInfoFiles?: IReqInfoFiles;
  actions: TActionsAdminOrder;
}

const OrderContentParticipants: React.FunctionComponent<IOrderContentParticipantProps> = ({
  order,
  reqInfo,
  reqInfoFiles,
}) => {
  const linkToQuestionnaire = `${window.location.protocol}//${window.location.host}${UrlService.url("booking.reqinfo", {
    orderNr: order.orderNr as string,
    orderToken: order.orderToken as string,
  })}`;

  return (
    <>
      <FDN.Box>
        <div className="text-center">
          {order.reqInfoCompleted !== undefined ? (
            <div>
              <div>{FDN.I18n.t("adminOrders.participants.linkToQuestionnaire.label")}:</div>
              <div>
                <a href={linkToQuestionnaire} target="_blank" rel="noopener noreferrer">
                  {linkToQuestionnaire}
                </a>
              </div>
            </div>
          ) : (
            <div className="italic text-center">
              {FDN.I18n.t("adminOrders.participants.noQuestionnaireRequired.label")}
            </div>
          )}
        </div>
      </FDN.Box>

      <div className="admin-order-content-message">
        <h2>{FDN.I18n.t("adminOrders.message.title")}</h2>
        {order.message ? <div dangerouslySetInnerHTML={{ __html: nl2br(order.message) }} /> : <FDN.Icon icon="minus" />}
      </div>
      <div>&nbsp;</div>
      <div className="admin-order-content-participants">
        <h2>{FDN.I18n.t("adminOrders.tabs.participants.label")}</h2>
        {order.participants.map((participant, index) => {
          return (
            <OrderBoxParticipant
              order={order}
              reqInfo={reqInfo}
              reqInfoFiles={reqInfoFiles}
              participant={participant}
              key={index}
              index={index}
            />
          );
        })}
      </div>
    </>
  );
};

interface IOrderBoxParticipantProps {
  order: IOrder;
  participant: IOrderParticipant;
  reqInfo: IReqInfoByParticipant;
  reqInfoFiles?: IReqInfoFiles;
  index: number;
}

const OrderBoxParticipant: React.FunctionComponent<IOrderBoxParticipantProps> = ({
  index,
  participant,
  reqInfo,
  reqInfoFiles,
}) => {
  const { api } = useServiceCore();

  const requiredInformationTypes = Config.get("adventures.requiredInformation") as {
    [key: string]: any;
  };

  const participantReqInfos = reqInfo[participant.identifier as keyof typeof reqInfo];

  return (
    <div className="admin-order-box-participant">
      <h3 className="text-center">
        # {index + 1} {participant.salutation || ""} {participant.firstname} {participant.lastname}
      </h3>
      <FDN.Box noPadding>
        <div className="admin-order-participant-firstrow">
          <div className="admin-order-info-content">
            <div className="admin-order-info-content-value">{participant.salutation || "---"}</div>
            <div className="admin-order-info-content-label">
              {FDN.I18n.t("adminOrders.participants.salutation.label")}
            </div>
          </div>
          <div className="admin-order-info-content">
            <div className="admin-order-info-content-value">{participant.firstname || "---"}</div>
            <div className="admin-order-info-content-label">
              {FDN.I18n.t("adminOrders.participants.firstname.label")}
            </div>
          </div>
          <div className="admin-order-info-content">
            <div className="admin-order-info-content-value">{participant.lastname || "---"}</div>
            <div className="admin-order-info-content-label">
              {FDN.I18n.t("adminOrders.participants.lastname.label")}
            </div>
          </div>
          <div className="admin-order-info-content">
            <div className="admin-order-info-content-value">
              {participant.birthday ? FDN.formatDate(participant.birthday) : "---"}
            </div>
            <div className="admin-order-info-content-label">
              {FDN.I18n.t("adminOrders.participants.birthday.label")}
            </div>
          </div>
        </div>
        {Object.keys(requiredInformationTypes).map((reqInfoType) => {
          if (!participantReqInfos) return null;

          const participantReqInfo = participantReqInfos.find((r) => r.type === reqInfoType);
          if (!participantReqInfo) return null;

          const uploadedFiles: IFile[] = [];

          if (
            reqInfoFiles &&
            participantReqInfo.type === "passports" &&
            participantReqInfo.value &&
            isArray(participantReqInfo.value)
          ) {
            for (const fileIdentifier of participantReqInfo.value) {
              if (fileIdentifier in reqInfoFiles && !uploadedFiles.includes(reqInfoFiles[fileIdentifier]))
                uploadedFiles.push(reqInfoFiles[fileIdentifier]);
            }
          }

          if (syncReqInfos.includes(participantReqInfo.type) && index !== 0) return null;

          return (
            <div className="__booking-reqinfo-box-content" key={participantReqInfo.identifier}>
              <h4>{FDN.I18n.t(`booking.reqinfo.types.${participantReqInfo.type}.title`)}</h4>
              {participantReqInfo.type === "pickuplocation" ? (
                <div>
                  {participantReqInfo.value ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: nl2br((participantReqInfo.value as string) || ""),
                      }}
                    />
                  ) : (
                    <FDN.Icon icon="question" />
                  )}
                </div>
              ) : null}
              {participantReqInfo.type === "foodpreferences" ? (
                <div>
                  {participantReqInfo.value ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: nl2br((participantReqInfo.value as string) || ""),
                      }}
                    />
                  ) : (
                    <FDN.Icon icon="question" />
                  )}
                </div>
              ) : null}
              {participantReqInfo.type === "passports" ? (
                <>
                  {!uploadedFiles || uploadedFiles.length === 0 ? (
                    <div>
                      <em>{FDN.I18n.t("adminOrders.participants.nofiles")}</em>
                    </div>
                  ) : (
                    <div>
                      <div style={{ fontWeight: 500 }}>{FDN.I18n.t("adminOrders.participants.uploaded.title")}</div>
                      <ol>
                        {uploadedFiles.map((uploadedFile, index) => {
                          return (
                            <li key={index}>
                              <a
                                className="admin-order-filelist-item"
                                href={
                                  api.getEndpoint("files.download", {
                                    identifier: uploadedFile.identifier,
                                  }) as string
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FDN.Icon icon="download" left />
                                {uploadedFile.filename}
                              </a>{" "}
                              {uploadedFile.filesize && <>({formatFileSize(uploadedFile.filesize)})</>}
                            </li>
                          );
                        })}
                      </ol>
                    </div>
                  )}
                </>
              ) : null}
            </div>
          );
        })}
      </FDN.Box>
    </div>
  );
};

interface IOrderContentPaymentsProps {
  order: IOrder;
  adventure: IAdventure;
  payments?: IStripePayments;
}

const OrderContentPayments: React.FunctionComponent<IOrderContentPaymentsProps> = ({ order, payments, adventure }) => {
  const currency = Config.get("currency") as string;

  const linkToNextPayment = `${window.location.protocol}//${window.location.host}${UrlService.url(
    "payment.orderPayment",
    {
      orderNr: order.orderNr as string,
      orderToken: order.orderToken as string,
    }
  )}`;

  return (
    <div className="admin-order-content-payments">
      <h2>{FDN.I18n.t("adminOrders.tabs.payments.label")}</h2>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={24}>
            <h3>{FDN.I18n.t("adminOrders.payments.paymentPlan.title")}</h3>
            <FDN.Box>
              <BookingPaymentPlanSummary order={order} calculation={order.calculation} adventure={adventure} />
            </FDN.Box>
            <FDN.Box>
              <div className="text-center">
                {order.paymentStatus === "NOW_REQUIRED" || order.paymentStatus === "LATER_REQUIRED" ? (
                  <div>
                    <div>{FDN.I18n.t("adminOrders.payments.linkToNextPayment.label")}:</div>
                    <div>
                      <a href={linkToNextPayment} target="_blank" rel="noopener noreferrer">
                        {linkToNextPayment}
                      </a>
                    </div>
                  </div>
                ) : (
                  <div className="italic text-center">{FDN.I18n.t("adminOrders.payments.noPaymentRequired.label")}</div>
                )}
              </div>
            </FDN.Box>
            <h3>{FDN.I18n.t("adminOrders.payments.payments.title")}</h3>
            {payments?.map((payment) => {
              return (
                <FDN.Box key={payment.identifier}>
                  <div className="admin-order-payment-content">
                    <div className="admin-order-payment-content-status">
                      {payment.paymentStatus === "SUCCESS" && (
                        <span className="success-text">
                          <FDN.Icon icon="check" />
                        </span>
                      )}
                      {payment.paymentStatus === "PENDING" && (
                        <span className="warning-text">
                          <FDN.Icon icon="clock-o" />
                        </span>
                      )}
                      {payment.paymentStatus === "ERROR" && (
                        <span className="error-text">
                          <FDN.Icon icon="times-check" />
                        </span>
                      )}
                    </div>
                    <div className="admin-order-payment-content-content">
                      <div className="admin-order-payments-firstrow">
                        <div className="admin-order-info-content">
                          <div className="admin-order-info-content-value">{payment.paymentStatus}</div>
                          <div className="admin-order-info-content-label">
                            {FDN.I18n.t("adminOrders.payments.paymentStatus.label")}
                          </div>
                        </div>
                        <div className="admin-order-info-content">
                          <div className="admin-order-info-content-value">
                            {FDN.formatDateTime(payment.paymentDate)}
                          </div>
                          <div className="admin-order-info-content-label">
                            {FDN.I18n.t("adminOrders.payments.paymentDate.label")}
                          </div>
                        </div>
                        <div className="admin-order-info-content">
                          <div className="admin-order-info-content-value">
                            {payment.amount ? `${currency} ${FDN.formatNumber(payment.amount, 2, 2)}` : "-"}
                          </div>
                          <div className="admin-order-info-content-label">
                            {FDN.I18n.t("adminOrders.payments.amount.label")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </FDN.Box>
              );
            })}
          </FDN.Cell>
          <FDN.Cell md={24}></FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};
interface IOrderContentUpgradesProps {
  order: IOrder;
  adventure: IAdventure;
}

const OrderContentHotels: React.FunctionComponent<IOrderContentUpgradesProps> = ({ order }) => {
  return (
    <div className="admin-order-content-upgrades">
      <h2>{FDN.I18n.t("adminOrders.tabs.hotels.label")}</h2>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={24}>
            <OrderHotels order={order} />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

const OrderContentUpgrades: React.FunctionComponent<IOrderContentUpgradesProps> = ({ order, adventure }) => {
  //const currency = Config.get("currency") as string;

  return (
    <div className="admin-order-content-upgrades">
      <h2>{FDN.I18n.t("adminOrders.tabs.upgrades.label")}</h2>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={24}>
            <PreviewUpgrades order={order} adventure={adventure} />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

interface IOrderContentFilesProps {
  order: IOrder;
  popupAddFilesVisible: boolean;
  addFilesObject: AddFilesObject;
  actions: TActionsAdminOrder;
}

const OrderContentFiles: React.FunctionComponent<IOrderContentFilesProps> = ({
  order,
  popupAddFilesVisible,
  addFilesObject,
  actions,
}) => {
  return (
    <div className="admin-order-content-files">
      <h2>{FDN.I18n.t("adminOrders.tabs.files.label")}</h2>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={24}>
            <OrderFiles
              order={order}
              popupAddFilesVisible={popupAddFilesVisible}
              addFilesObject={addFilesObject}
              actions={actions}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

interface IOrderContentDriverProps {
  order: IOrder;
  drivers?: IDrivers;
  actions: TActionsAdminOrder;
}

const OrderContentDriver: React.FunctionComponent<IOrderContentDriverProps> = ({ order, drivers, actions }) => {
  return (
    <div className="admin-order-content-driver">
      <h2>{FDN.I18n.t("adminOrders.driver.title")}</h2>
      <p>{FDN.I18n.t("adminOrders.driver.intro")}</p>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={24}>
            {!drivers || drivers.length === 0 ? (
              <p>{FDN.I18n.t("adminOrders.driver.noDrivers")}</p>
            ) : (
              <OrderDriver order={order} drivers={drivers} actions={actions} />
            )}
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

export default AdminOrder;

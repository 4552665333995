import React, { useEffect } from "react";
import * as FDN from "src/core";
import {
  AdventuresListCount,
  AdventuresListType,
  IAdventures,
  LocationCountries,
  SearchAdventuresFilter,
  TActions,
} from "../../types";
import useServiceCore from "../CoreService";
import AdminApi from "../../api/AdminApi";
import StatusCode from "../../config/statuscodes";
import { cloneDeep } from "lodash";

const initTabs: FDN.ITabs = {
  published: {
    label: FDN.I18n.t("adminAdventures.tabs.published.label"),
    icon: "unlock",
  },
  private: {
    label: FDN.I18n.t("adminAdventures.tabs.private.label"),
    icon: "eye-slash",
  },
  unpublished: {
    label: FDN.I18n.t("adminAdventures.tabs.unpublished.label"),
    icon: "lock",
  },
};

const defaultTab = "published";

const initSearch: SearchAdventuresFilter = {};

export type FilterTypes = "published" | "private" | "unpublished";

const useServiceAdminAdventures = () => {
  const { APP, api } = useServiceCore();

  const user = APP.getUser();

  const [adventures, setAdventures] = React.useState<IAdventures | null>(null);
  const [locationCountries, setLocationCountries] = React.useState<LocationCountries>();

  const [tabs, setTabs] = React.useState<FDN.ITabs>();
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  const [filteredAdventures, setFilteredAdventures] = React.useState<IAdventures>([]);
  const [search, setSearch] = React.useState<SearchAdventuresFilter>(cloneDeep(initSearch));

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    fetchAdventures(selectedTab as AdventuresListType);
  }, [selectedTab]);

  useEffect(() => {
    updateFilteredAdventures();
  }, [search]);

  // useEffect(() => {
  //   if (!adventures) return;

  //   const updatedTabs = cloneDeep(initTabs);

  //   updatedTabs.published.badge = filterAdventures(adventures, "published").length;
  //   updatedTabs.private.badge = filterAdventures(adventures, "private").length;
  //   updatedTabs.unpublished.badge = filterAdventures(adventures, "unpublished").length;

  //   setTabs(updatedTabs);
  // }, [filteredAdventures]);

  useEffect(() => {
    if (!adventures) return;

    for (const adventure of adventures) {
      if (!adventure.mainPicture && adventure.pictures && adventure.pictures[0] && adventure.pictures[0].L) {
        adventure.mainPicture = adventure.pictures[0];
      }
    }
  }, [adventures]);

  const fetchAdventures = (type: AdventuresListType) => {
    setIsLoading(true);

    AdminApi.adminAdventures(api, type).then((response) => {
      APP.setPageTitle(FDN.I18n.t("adminAdventures.pageTitle"));

      if (response?.statusCode === StatusCode.SUCCESS) {
        const adventures = response?.body?.adventures as IAdventures;
        setAdventures(adventures);
        setLocationCountries(response?.body?.countries as LocationCountries);

        const adventuresCount = response?.body?.adventuresCount as AdventuresListCount;

        const filterType = selectedTab as FilterTypes;
        const filteredAdventures = filterAdventures(adventures, filterType);

        const updatedTabs = cloneDeep(initTabs);

        updatedTabs.published.badge = adventuresCount.published;
        updatedTabs.private.badge = adventuresCount.private;
        updatedTabs.unpublished.badge = adventuresCount.unpublished;

        setTabs(updatedTabs);

        setFilteredAdventures(filteredAdventures);

        setIsLoading(false);
      }
    });
  };

  const updateFilteredAdventures = () => {
    if (!adventures) return;

    const filterType = selectedTab as FilterTypes;
    const filteredAdventures = filterAdventures(adventures, filterType);

    setFilteredAdventures(filteredAdventures);
  };

  const filterAdventures = (adventures: IAdventures, type: FilterTypes): IAdventures => {
    let filteredAdventures: IAdventures = [];

    if (adventures && adventures.length > 0) {
      for (const adventure of adventures)
        switch (type) {
          case "published":
            if (adventure.published === true && adventure.isPrivate !== true) filteredAdventures.push(adventure);
            break;

          case "private":
            if (adventure.published === true && adventure.isPrivate === true) filteredAdventures.push(adventure);
            break;

          case "unpublished":
            if (adventure.published !== true) filteredAdventures.push(adventure);
            break;
        }
    }

    if (search) filteredAdventures = searchInAdventures(filteredAdventures, search);

    return filteredAdventures;
  };

  const searchInAdventures = (adventures: IAdventures, search: SearchAdventuresFilter): IAdventures => {
    const filteredAdventures: IAdventures = [];

    for (const adventure of adventures) {
      let addToList = true;

      if (search.text) {
        if (adventure.title?.toLowerCase().indexOf(search.text?.toLowerCase()) === -1) addToList = false;
      }

      if (search.country) {
        if (adventure.country !== search.country) addToList = false;
      }

      if (search.region) {
        if (adventure.region !== search.region) addToList = false;
      }

      if (search.nights !== undefined && adventure.nights !== undefined) {
        if (adventure.nights.toString() !== search.nights.toString()) addToList = false;
      }

      if (addToList === true) filteredAdventures.push(adventure);
    }

    return filteredAdventures;
  };

  function updateSearch<P extends keyof SearchAdventuresFilter, V extends SearchAdventuresFilter[P]>(
    property: P,
    value: V
  ) {
    const updatedSearch = search ? cloneDeep(search) : cloneDeep(initSearch);

    updatedSearch[property] = value;

    setSearch(updatedSearch);
  }

  const resetSearch = () => {
    setSearch(cloneDeep(initSearch));
  };

  const actions: TActions = {
    updateSearch,
    resetSearch,
  };

  return {
    actions,
    adventures,
    filteredAdventures,
    locationCountries,
    user,
    tabs,
    selectedTab,
    setSelectedTab,
    isLoading,
    search,
  };
};

export default useServiceAdminAdventures;

import * as React from "react";
import * as FDN from "src/core";
import { FOnCancel, FOnDelete, FOnSave } from "src/types";

interface IFormButtonsProps {
  saveLabel?: string;
  saveIcon?: string;
  saveIconPosition?: string;
  saveDisabled?: boolean;

  cancelLabel?: string;
  cancelIcon?: string;
  cancelIconPosition?: string;
  cancelDisabled?: boolean;

  deleteLabel?: string;
  deleteIcon?: string;
  deleteDisabled?: boolean;

  onSave?: FOnSave;
  onCancel?: FOnCancel;
  onDelete?: FOnDelete;

  noMargin?: boolean;
  marginBottom?: string;
  smallButtons?: boolean;

  missing?: string[];
}

const FormButtons: React.FunctionComponent<IFormButtonsProps> = (props) => {
  const { saveLabel, saveIcon, saveIconPosition, saveDisabled } = props;
  const { cancelLabel, cancelIcon, cancelIconPosition, cancelDisabled } = props;
  const { deleteLabel, deleteIcon, deleteDisabled } = props;
  const { onSave, onCancel, onDelete } = props;
  const { noMargin, marginBottom } = props;
  const { smallButtons } = props;
  const { missing } = props;

  return (
    <div
      className={`__ptPlatform-form-buttons ${noMargin ? `no-margin` : ``}`}
      style={{ marginBottom: marginBottom || undefined }}
    >
      {missing && missing.length > 0 ? (
        <FDN.CalloutBox type="alert" style={{ fontSize: "0.9rem" }}>
          <div>
            <strong>{FDN.I18n.t("errors.form.missing.title")}</strong>
          </div>
          <div>{FDN.I18n.t("errors.form.missing.text")}</div>
        </FDN.CalloutBox>
      ) : null}
      <FDN.Grid full>
        <FDN.Row margin="x">
          {onDelete ? (
            <FDN.Cell sm={24} md={8} className="text-center medium-text-left">
              {onDelete && (
                <FDN.Button
                  small={smallButtons || false}
                  hollow
                  primary
                  disabled={deleteDisabled}
                  icon={deleteIcon ? deleteIcon : "trash"}
                  onClick={onDelete}
                >
                  {deleteLabel ? deleteLabel : FDN.I18n.t("buttons.delete")}
                </FDN.Button>
              )}
            </FDN.Cell>
          ) : null}
          <FDN.Cell sm={24} md={onDelete ? 16 : 24} className="text-center medium-text-right">
            {onCancel && (
              <FDN.Button
                small={smallButtons || false}
                hollow
                primary
                disabled={cancelDisabled}
                icon={cancelIcon ? cancelIcon : "times"}
                iconLeft={cancelIconPosition === "right" ? false : true}
                iconRight={cancelIconPosition === "right" ? true : false}
                onClick={onCancel}
              >
                {cancelLabel ? cancelLabel : FDN.I18n.t("buttons.cancel")}
              </FDN.Button>
            )}
            {onSave && (
              <FDN.Button
                small={smallButtons || false}
                primary
                disabled={saveDisabled}
                icon={saveIcon ? saveIcon : "check"}
                iconLeft={saveIconPosition === "right" ? false : true}
                iconRight={saveIconPosition === "right" ? true : false}
                onClick={onSave}
              >
                {saveLabel ? saveLabel : FDN.I18n.t("buttons.save")}
              </FDN.Button>
            )}
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

export default FormButtons;

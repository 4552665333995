import * as React from "react";
import * as FDN from "src/core";
import { Link } from "react-router-dom";
import UrlService from "src/core/UrlService";
import useServiceLogin from "src/services/auth/LoginService";
import { ILoginInputValues, TActions } from "src/types";

const LoginPage: React.FunctionComponent = () => {
  const {
    actions,
    logoLoginUrl,
    signupEnabled,
    inputValues,
    errorEmpty,
    errorInvalidEmail,
    errorInvalidLoginData,
  } = useServiceLogin();

  const params = new URLSearchParams(window.location.search);

  const formErrors = {
    "login.emptyInput": errorEmpty,
    "login.invalidEmail": errorInvalidEmail,
    "login.invalidLoginData": errorInvalidLoginData,
  };

  return (
    <FDN.LayoutWithoutTopbar page="login">
      <main>
        <FDN.Grid>
          <FDN.Row margin="xy">
            <FDN.Cell md={12} mdo={6}>
              {logoLoginUrl && (
                <div className="__login-logo">
                  <Link to={"/"}>
                    <img src={logoLoginUrl} alt="" />
                  </Link>
                </div>
              )}
              {params.get("msg") === "emailexists" && (
                <div className="warning callout">{FDN.I18n.t("login.emailExists")}</div>
              )}
              <h1>{FDN.I18n.t("login.title")}</h1>
              <div className="__login-intro lead">{FDN.I18n.t("login.intro")}</div>
              {signupEnabled === true && (
                <div className="__login-with-signup">
                  <div className="callout">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: FDN.I18n.t("login.introWithSignup", {
                          link: `<a href="${UrlService.url("auth.signup")}">${FDN.I18n.t(
                            "login.introWithSignupLinkText"
                          )}</a>`,
                        }),
                      }}
                    />
                  </div>
                </div>
              )}
              <FDN.FormErrors errors={formErrors} />
              <LoginBox inputValues={inputValues} actions={actions} />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </main>
    </FDN.LayoutWithoutTopbar>
  );
};

interface ILoginBoxProps {
  inputValues: ILoginInputValues;
  actions: TActions;
}

const LoginBox = ({ inputValues, actions }: ILoginBoxProps) => {
  return (
    <div className="__login-box">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={24}>
            <FDN.Input
              type="email"
              editMode={true}
              required={true}
              value={inputValues.email}
              label={FDN.I18n.t("login.form.email.label")}
              placeholder={FDN.I18n.t("login.form.email.placeholder")}
              onUpdate={(value) => actions.onInput("email", value)}
              onKeyUp={actions.attemptLogin}
            />
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell md={24}>
            <FDN.Input
              type="password"
              editMode={true}
              required={true}
              value={inputValues.password}
              label={FDN.I18n.t("login.form.password.label")}
              placeholder={FDN.I18n.t("login.form.password.placeholder")}
              onUpdate={(value) => actions.onInput("password", value)}
              onKeyUp={actions.attemptLogin}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
      <div className="__login-password-forgotten-link">
        <Link to={UrlService.url("auth.passwordforgotten") as string}>
          {FDN.I18n.t("login.passwordForgotten.link")}
        </Link>
      </div>
      <FDN.FormButtons
        saveLabel={FDN.I18n.t("login.form.loginButton.label")}
        onSave={actions.attemptLogin}
      />
    </div>
  );
};

export default LoginPage;

import * as React from "react";
import * as FDN from "src/core";

interface IFormGroupProps {
  title?: string;
  info?: string;
  children?: React.ReactNode;
}

const FormGroup: React.FunctionComponent<IFormGroupProps> = (props) => {
  const { title, info, children } = props;

  return (
    <div className="__form-group">
      <FDN.Box>
        {info && (
          <div className="__form-group-info">
            <FDN.ToolTip tooltip={info}>
              <FDN.Icon icon="info-circle" />
            </FDN.ToolTip>
          </div>
        )}
        {title && <h3>{title}</h3>}
        {children}
      </FDN.Box>
    </div>
  );
};

export default FormGroup;

import * as React from "react";
import { BookingContext } from "src/components/context/BookingContext/BookingContext";
import * as FDN from "src/core";
import BookingPageTitle from "../parts/BookingPageTitle";

const BookingPersonal: React.FunctionComponent = () => {
  const BOOKING = React.useContext(BookingContext);
  const { order, missingFields } = BOOKING;

  if (!order) return <FDN.Loading box />;

  return (
    <div className="__booking-form-page-personal" id="booking_personal">
      <BookingPageTitle page="personal" order={order} />
      <FDN.Box>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <div
                dangerouslySetInnerHTML={{
                  __html: FDN.I18n.t("booking.form.group.contact.intro"),
                }}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={12}>
              <FDN.Input
                type="email"
                value={order.email}
                label={FDN.I18n.t("booking.form.group.contact.email.label")}
                editMode={true}
                isMissing={missingFields?.includes("email")}
                onUpdate={(value) => BOOKING.onEdit("email", value)}
              />
            </FDN.Cell>
            <FDN.Cell sm={24} md={12}>
              <FDN.Input
                type="text"
                value={order.phone}
                label={FDN.I18n.t("booking.form.group.contact.phone.label")}
                editMode={true}
                isMissing={missingFields?.includes("phone")}
                onUpdate={(value) => BOOKING.onEdit("phone", value)}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.Box>
      {/*<FDN.Box>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={12}>
              <FDN.Input
                type="text"
                value={order.firstname}
                label={FDN.I18n.t("booking.form.group.personal.firstname.label")}
                editMode={true}
                isMissing={missingFields?.includes("firstname")}
                onUpdate={(value) => BOOKING.onEdit("firstname", value)}
              />
            </FDN.Cell>
            <FDN.Cell sm={24} md={12}>
              <FDN.Input
                type="text"
                value={order.lastname}
                label={FDN.I18n.t("booking.form.group.personal.lastname.label")}
                editMode={true}
                isMissing={missingFields?.includes("lastname")}
                onUpdate={(value) => BOOKING.onEdit("lastname", value)}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
  </FDN.Box>*/}
      <FDN.Box>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={24}>
              <FDN.Input
                type="text"
                value={order.street}
                label={FDN.I18n.t("booking.form.group.personal.street.label")}
                editMode={true}
                isMissing={missingFields?.includes("street")}
                onUpdate={(value) => BOOKING.onEdit("street", value)}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={24}>
              <FDN.Input
                type="text"
                value={order.streetAdditional}
                label={FDN.I18n.t("booking.form.group.personal.streetAdditional.label")}
                editMode={true}
                isMissing={missingFields?.includes("streetAdditional")}
                onUpdate={(value) => BOOKING.onEdit("streetAdditional", value)}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={6}>
              <FDN.Input
                type="text"
                value={order.zip}
                label={FDN.I18n.t("booking.form.group.personal.zip.label")}
                editMode={true}
                isMissing={missingFields?.includes("zip")}
                onUpdate={(value) => BOOKING.onEdit("zip", value)}
              />
            </FDN.Cell>
            <FDN.Cell sm={24} md={18}>
              <FDN.Input
                type="text"
                value={order.city}
                label={FDN.I18n.t("booking.form.group.personal.city.label")}
                editMode={true}
                isMissing={missingFields?.includes("city")}
                onUpdate={(value) => BOOKING.onEdit("city", value)}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={24}>
              <FDN.Input
                type="country"
                value={order.country}
                label={FDN.I18n.t("booking.form.group.personal.country.label")}
                editMode={true}
                isMissing={missingFields?.includes("country")}
                options={{ locale: "en-US" }}
                onUpdate={(value) => BOOKING.onEdit("country", value)}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.Box>
      <BookingPageTitle page="message" order={order} />
      <FDN.Box>
        <FDN.Input
          type="textarea"
          rows={6}
          editMode={true}
          value={order.message}
          onUpdate={(value) => BOOKING.onEdit("message", value)}
        />
      </FDN.Box>
    </div>
  );
};

export default BookingPersonal;

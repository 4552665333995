import { IOrder, IReqInfoByParticipant } from "src/types";
import Api from "./Api";

export default class BookingApi {
  public static createOrder(api: Api, order: IOrder) {
    return api.post("booking.orders.create", {}, { order });
  }

  public static getOrderForPayment(api: Api, orderNr: string, orderToken: string) {
    return api.get("booking.orders.payment", { orderNr, orderToken });
  }

  public static getOrderForConfirmation(api: Api, orderNr: string, orderToken: string) {
    return api.get("booking.orders.confirmation", { orderNr, orderToken });
  }

  public static getReqInfo(api: Api, orderNr: string, orderToken: string) {
    return api.get("booking.orders.reqinfo", { orderNr, orderToken });
  }

  public static saveReqInfo(
    api: Api,
    orderNr: string,
    orderToken: string,
    reqInfo: IReqInfoByParticipant
  ) {
    return api.post("booking.orders.reqinfo", { orderNr, orderToken }, { reqInfo });
  }

  public static getMyOrders(api: Api) {
    return api.get("booking.myOrders.index");
  }

  public static getMyOrder(api: Api, orderNr: string) {
    return api.get("booking.myOrders.show", { orderNr });
  }
}

import * as React from "react";
import * as FDN from "src/core";
import { FOnUpdateAdventureSearch, IAdventureSearch, LocationCountries, LocationRegion } from "src/types";

const sortByOptions = [
  "newest_asc",
  "newest_desc",
  "price_asc",
  "price_desc",
  "title_asc",
  "title_desc",
  "country_asc",
  "country_desc",
];

interface IAdventureSearchProps {
  search: IAdventureSearch;
  locationCountries?: LocationCountries;
  onUpdateSearch: FOnUpdateAdventureSearch;
}

const AdventureSearch: React.FunctionComponent<IAdventureSearchProps> = ({
  search,
  locationCountries,
  onUpdateSearch,
}) => {
  if (!locationCountries) return <FDN.Loading box />;

  const searchCountry = locationCountries.find((c) => c.identifier === search.country);
  const searchCountryRegions: LocationRegion[] = [];
  if (searchCountry?.regions) {
    for (const region of searchCountry.regions) {
      searchCountryRegions.push(region);
    }
  }

  return (
    <div className="__public-adventure-search">
      <FDN.Box>
        <div className="__public-adventure-search-box-content">
          <div className="__public-adventure-search-type">
            <div className="__public-adventure-search-label">{FDN.I18n.t("adventures.search.labels.type")}</div>
            <select
              value={search.type ?? undefined}
              onChange={(e) => onUpdateSearch("type", e.target.value ?? undefined)}
            >
              <option value="">- {FDN.I18n.t("adventures.search.all")} -</option>
              <option value="adventure">{FDN.I18n.t("adventures.type.adventure")}</option>
              <option value="vacation">{FDN.I18n.t("adventures.type.vacation")}</option>
            </select>
          </div>
          <div className="__public-adventure-search-country">
            <div className="__public-adventure-search-label">{FDN.I18n.t("adventures.search.labels.country")}</div>
            <select value={search.country || ""} onChange={(e) => onUpdateSearch("country", e.target.value)}>
              <option value="">- {FDN.I18n.t("adventures.search.all")} -</option>
              {locationCountries.map((country) => {
                return (
                  <option key={country.identifier} value={country.identifier}>
                    {country.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="__public-adventure-search-region">
            <div className="__public-adventure-search-label">{FDN.I18n.t("adventures.search.labels.region")}</div>
            <select
              disabled={!search.country}
              value={search.region || ""}
              onChange={(e) => onUpdateSearch("region", e.target.value)}
            >
              <option value="">- {FDN.I18n.t("adventures.search.all")} -</option>
              {searchCountryRegions.map((region) => {
                return (
                  <option key={region.identifier} value={region.identifier}>
                    {region.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </FDN.Box>
      <div className="__public-adventure-search-box-content mb-6 pl-4 pr-5">
        <div className="__public-adventure-search-country">&nbsp;</div>
        <div className="__public-adventure-search-region">&nbsp;</div>
        <div className="__public-adventure-search-sortby">
          {/* <div className="__public-adventure-search-label">{FDN.I18n.t("adventures.search.labels.sortBy")}</div> */}
          <select value={search.sortBy || "newest-desc"} onChange={(e) => onUpdateSearch("sortBy", e.target.value)}>
            <option value="" disabled>
              {FDN.I18n.t("adventures.search.labels.sortBy")}:
            </option>
            {sortByOptions.map((sortByOption) => {
              return (
                <option key={sortByOption} value={sortByOption}>
                  {FDN.I18n.t(`adventures.search.sortBy.${sortByOption}.label`)}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
};

export default AdventureSearch;

import * as React from "react";
import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { IOrder, IStripePayment } from "src/types";
import { Elements } from "@stripe/react-stripe-js";
import { TActionsPayment } from "src/services/PaymentService";
import CheckoutForm from "./parts/CheckoutForm";

interface IStripePaymentProps {
  order: IOrder;
  stripePayment: IStripePayment;
  publicKey?: string;
  actions: TActionsPayment;
}

const StripePayment: React.FunctionComponent<IStripePaymentProps> = ({
  order,
  stripePayment,
  publicKey,
  actions,
}) => {
  const [stripePromise, setStripePromise] = React.useState<Stripe | null>(null);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    const initStripe = async () => {
      if (publicKey) {
        setStripePromise(await loadStripe(publicKey));
        setLoaded(true);
      }
    };

    initStripe();
  }, [publicKey]);

  if (!loaded || !stripePromise || !stripePayment) return null;

  return (
    <div className="__stripe-payment">
      <Elements
        stripe={stripePromise}
        options={{ clientSecret: stripePayment._stripePaymentClientSecret, locale: "en" }}
      >
        <CheckoutForm order={order} stripePayment={stripePayment} actions={actions} />
      </Elements>
    </div>
  );
};

export default StripePayment;

import * as React from "react";
import * as FDN from "src/core";
import useServiceCore from "src/services/CoreService";
import dataprivacytext from "./dataprivacytext";

const DataPrivacyPage: React.FunctionComponent = () => {
  const { api } = useServiceCore();

  React.useEffect(() => {
    api.get("hello");
  }, []);

  return (
    <FDN.LayoutWithTopbar page="legal">
      <FDN.PageContent>
        <FDN.SingleRowCell>
          <h1>Privacy policy</h1>
          <div dangerouslySetInnerHTML={{ __html: dataprivacytext }} />
        </FDN.SingleRowCell>
      </FDN.PageContent>
    </FDN.LayoutWithTopbar>
  );
};

export default DataPrivacyPage;

import * as React from "react";
import * as FDN from "src/core";
import { IAdventure, IUpgrade, IUpgrades, TActions } from "src/types";
import { getAdventurePictureUrl } from "src/core/helpers/helpers";
import Config from "src/core/Config";

interface IAddUpgradePopupListProps {
  adventure: IAdventure;
  upgrades?: IUpgrades;
  actions: TActions;
}

const AddUpgradePopupList: React.FunctionComponent<IAddUpgradePopupListProps> = ({
  adventure,
  upgrades,
  actions,
}) => {
  if (!upgrades) return <FDN.Loading box />;

  return (
    <div className="admin-adventure-upgrades-add-popup-list">
      {upgrades.length === 0 ? (
        <div className="text-center" style={{ padding: "40px 0" }}>
          {FDN.I18n.t("adminAdventures.upgrades.noUpgrades")}
        </div>
      ) : (
        <List adventure={adventure} upgrades={upgrades} actions={actions} />
      )}
    </div>
  );
};

const List: React.FunctionComponent<IAddUpgradePopupListProps> = ({
  adventure,
  upgrades,
  actions,
}) => {
  return (
    <ul>
      {upgrades?.map((upgrade) => {
        return (
          <li key={upgrade.identifier}>
            <ListItem
              upgrade={upgrade}
              isSelected={
                adventure.upgrades?.find((h) => h.identifier === upgrade.identifier) ? true : false
              }
              actions={actions}
            />
          </li>
        );
      })}
    </ul>
  );
};

interface IListItemProps {
  upgrade: IUpgrade;
  isSelected: boolean;
  actions: TActions;
}

const ListItem: React.FunctionComponent<IListItemProps> = ({ upgrade, isSelected, actions }) => {
  const styles: React.CSSProperties = {
    backgroundImage: upgrade.mainPicture
      ? `url("${getAdventurePictureUrl(upgrade.mainPicture)}")`
      : undefined,
  };

  return (
    <div
      className={`admin-upgrades-addpopup-list-item ${
        isSelected ? `success-shallow-background` : ``
      }`}
    >
      <div className="admin-upgrades-addpopup-list-item-icon">
        {isSelected ? (
          <span className="success-text">
            <FDN.Icon icon="check" />
          </span>
        ) : (
          <span className="gray-text">
            <FDN.Icon icon="minus" />
          </span>
        )}
      </div>
      <div className="admin-upgrades-addpopup-list-item-picture" style={styles}></div>
      <div className="admin-upgrades-addpopup-list-item-content">
        <div className="admin-upgrades-addpopup-list-item-name">{upgrade.name}</div>

        {upgrade.street || upgrade.zip || upgrade.city ? (
          <div className="admin-upgrades-addpopup-list-item-address">
            {upgrade.street}, {upgrade.zip} {upgrade.city}, {upgrade.country}
          </div>
        ) : null}
        <div className="admin-upgrades-addpopup-list-item-priceFull">
          {FDN.I18n.t("adminAdventures.upgrades.form.price.priceFull.label")}:{" "}
          {Config.get("currency") as string}
          {FDN.formatNumber(upgrade.priceFull || 0.0, 2, 2)}
          {upgrade.priceSingleFull ? (
            <>
              &nbsp; ({Config.get("currency") as string}{" "}
              {FDN.formatNumber(upgrade.priceSingleFull || 0.0, 2, 2)})
            </>
          ) : null}
        </div>
      </div>
      <div className="admin-upgrades-addpopup-list-item-buttons">
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              {isSelected ? (
                <button
                  className="tiny alert button"
                  onClick={() => actions.removeUpgradeFromAdventure(upgrade.identifier)}
                >
                  <FDN.Icon icon="minus" left />{" "}
                  {FDN.I18n.t("adminAdventures.upgrades.list.buttons.unselect.label")}{" "}
                </button>
              ) : (
                <button
                  className="tiny success button"
                  onClick={() => actions.addUpgradeToAdventure(upgrade)}
                >
                  <FDN.Icon icon="plus" left />{" "}
                  {FDN.I18n.t("adminAdventures.upgrades.list.buttons.select.label")}{" "}
                </button>
              )}
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row>
            <FDN.Cell sm={24} md={12}>
              <button
                className="tiny primary hollow button"
                onClick={() => actions.duplicateUpgrade(upgrade)}
              >
                <FDN.Icon icon="clone" left />{" "}
                {FDN.I18n.t("adminAdventures.upgrades.list.buttons.duplicate.label")}{" "}
              </button>
            </FDN.Cell>
            <FDN.Cell sm={24} md={12}>
              <button
                className="tiny primary hollow button"
                onClick={() => actions.showUpgradeEditPopup(upgrade)}
              >
                <FDN.Icon icon="edit" left />{" "}
                {FDN.I18n.t("adminAdventures.upgrades.list.buttons.edit.label")}{" "}
              </button>
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </div>
    </div>
  );
};

export default AddUpgradePopupList;

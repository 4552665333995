import React from "react";

const MaintenanceMode = () => {
  return (
    <div>
      <div
        className="flex justify-center items-center h-screen max-w-[400px] mx-auto text-center"
        style={{ zIndex: 999999 }}
      >
        <div>
          <h1>Click2Adventure</h1>
          <h2>Scheduled Maintenaince</h2>
          <p>Dear customers and visitors,</p>

          <p>
            We are currently carrying out maintenance and updating work. The planned downtime is estimated at approx.
            2-3 hours. Please visit us again later.
          </p>

          <p>We apologize for the inconvenience.</p>

          <p>
            Thank you very much
            <br />
            Your Click2Adventure Team
          </p>

          <div className="text-xs text-gray-500">Last update: Mon, 05/27/2024 08:23am</div>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceMode;

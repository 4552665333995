import * as React from "react";
import { IAdventure } from "src/types";
import AdventurePreTitle from "./AdventurePreTitle";

interface IAdventureHeaderProps {
  adventure: IAdventure;
}

const AdventureHeader: React.FunctionComponent<IAdventureHeaderProps> = ({ adventure }) => {
  return (
    <div className="__public-adventure-header">
      <AdventurePreTitle adventure={adventure} block />
      <h1>{adventure.title}</h1>
      <div className="__public-adventure-header-description">{adventure.description}</div>
    </div>
  );
};

export default AdventureHeader;

import * as React from "react";
import * as FDN from "src/core";
import useServiceCore from "src/services/CoreService";
import { IFile, IOrder } from "src/types";

interface IOrderFilesProps {
  order: IOrder;
}

const OrderFiles: React.FunctionComponent<IOrderFilesProps> = ({ order }) => {
  const { api } = useServiceCore();

  return (
    <div className="__order-files">
      {!order.files || order.files.length === 0 ? (
        <p>
          <em>{FDN.I18n.t("adminOrders.files.noFiles")}</em>
        </p>
      ) : (
        <>
          {order.files.map((file) => {
            const downloadUrl = api.getEndpoint("files.download", {
              identifier: file.identifier,
            }) as string;

            return (
              <div className="order-files-file" key={file.identifier}>
                <FDN.Box>
                  <div className="order-files-file-wrapper">
                    <div className="order-files-file-content">
                      <h3>
                        <FileIcon file={file} />
                        &nbsp; {file.title || file.filename}
                      </h3>
                      {file.description ? (
                        <div className="order-files-file-description">{file.description}</div>
                      ) : null}
                    </div>
                    <div className="order-files-file-buttons">
                      <div>
                        <a
                          href={downloadUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="small primary button"
                        >
                          <FDN.Icon icon="download" left />{" "}
                          {FDN.I18n.t("adminOrders.files.buttons.download.label")}
                        </a>
                      </div>
                    </div>
                  </div>
                </FDN.Box>
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

interface IFileIconProps {
  file: IFile;
}

const FileIcon: React.FunctionComponent<IFileIconProps> = ({ file }) => {
  if (file.filetype?.includes("pdf")) return <FDN.Icon icon="file-pdf-o" />;
  else return <FDN.Icon icon="file-o" />;
};

export default OrderFiles;

import * as React from "react";
import * as FDN from "src/core";
import { IAdventure, TActions } from "src/types";
import Config from "src/core/Config";

const maxDaysBefore = 90;

interface IAdventurePricesProps {
  adventure: IAdventure;
  editMode: boolean;
  actions: TActions;
}

const AdventurePrices: React.FunctionComponent<IAdventurePricesProps> = ({
  adventure,
  editMode,
  actions,
}) => {
  return (
    <div className="admin-adventure-tab-prices">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={24} lg={8}>
            <h2>{FDN.I18n.t("adminAdventures.prices.adults.title")}</h2>
            <FormGroupPriceFull adventure={adventure} editMode={editMode} actions={actions} />
            <FormGroupPriceBefore adventure={adventure} editMode={editMode} actions={actions} />
            <FormGroupPriceDownPayment
              adventure={adventure}
              editMode={editMode}
              actions={actions}
            />
          </FDN.Cell>
          <FDN.Cell md={24} lg={8}>
            <h2>{FDN.I18n.t("adminAdventures.prices.single.title")}</h2>
            <FormGroupSinglePriceFull adventure={adventure} editMode={editMode} actions={actions} />
            <FormGroupSinglePriceDownPayment
              adventure={adventure}
              editMode={editMode}
              actions={actions}
            />
          </FDN.Cell>
          <FDN.Cell md={24} lg={8}>
            <h2>{FDN.I18n.t("adminAdventures.prices.children.title")}</h2>
            <FormGroupChildrenPriceFull
              adventure={adventure}
              editMode={editMode}
              actions={actions}
            />
            <FormGroupChildrenPriceDownPayment
              adventure={adventure}
              editMode={editMode}
              actions={actions}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

interface IAdventurePricesFormGroupProps {
  adventure: IAdventure;
  editMode: boolean;
  actions: TActions;
}

const FormGroupPriceFull: React.FunctionComponent<IAdventurePricesFormGroupProps> = ({
  adventure,
  editMode,
  actions,
}) => {
  return (
    <>
      <FDN.FormGroup title={FDN.I18n.t("adminAdventures.prices.formgroup.priceFull.title")}>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <FDN.Input
                type="currency"
                options={{
                  currency: Config.get("currency") as string,
                  locale: Config.get("currencyLocale") as string,
                }}
                editMode={editMode}
                value={adventure.priceFull}
                label={FDN.I18n.t("adminAdventures.prices.form.priceFull.label")}
                onUpdate={(value) => actions.onEdit("priceFull", value)}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.FormGroup>
    </>
  );
};

const FormGroupPriceBefore: React.FunctionComponent<IAdventurePricesFormGroupProps> = ({
  adventure,
  editMode,
  actions,
}) => {
  return (
    <>
      <FDN.FormGroup>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} className="text-center">
              <FDN.Input
                type="checkbox"
                editMode={editMode}
                value={adventure.showPriceBefore}
                label={FDN.I18n.t("adminAdventures.prices.form.showPriceBefore.label")}
                onUpdate={(value) => actions.onEdit("showPriceBefore", value)}
              />
            </FDN.Cell>
          </FDN.Row>
          {adventure.showPriceBefore === true && (
            <FDN.Row margin="xy">
              <FDN.Cell sm={24}>
                <FDN.Input
                  type="currency"
                  options={{
                    currency: Config.get("currency") as string,
                    locale: Config.get("currencyLocale") as string,
                  }}
                  editMode={editMode}
                  value={adventure.priceBefore}
                  label={FDN.I18n.t("adminAdventures.prices.form.priceBefore.label")}
                  onUpdate={(value) => actions.onEdit("priceBefore", value)}
                />
              </FDN.Cell>
            </FDN.Row>
          )}
        </FDN.Grid>
      </FDN.FormGroup>
    </>
  );
};

const FormGroupPriceDownPayment: React.FunctionComponent<IAdventurePricesFormGroupProps> = ({
  adventure,
  editMode,
  actions,
}) => {
  let totalPrice = 0.0;
  let totalPricePercent = 0.0;

  try {
    if (adventure.priceDownPayment) totalPrice += adventure.priceDownPayment;
    if (adventure.priceRemaining) totalPrice += adventure.priceRemaining;

    if (adventure.priceFull && adventure.priceFull > 0 && totalPrice > 0) {
      totalPricePercent = ((totalPrice - adventure.priceFull) / adventure.priceFull) * 100;
      totalPricePercent = Math.floor(totalPricePercent * 100) / 100;
    }
  } catch {
    //
  }

  const selectValuesDaysBefore: { [key: number]: number | string } = {
    0: "---",
  };
  for (let i = 1; i <= maxDaysBefore; i++) {
    selectValuesDaysBefore[i] = i;
  }

  return (
    <>
      <FDN.FormGroup title={FDN.I18n.t("adminAdventures.prices.formgroup.priceDownPayment.title")}>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <FDN.Input
                type="currency"
                options={{
                  currency: Config.get("currency") as string,
                  locale: Config.get("currencyLocale") as string,
                }}
                editMode={editMode}
                value={adventure.priceDownPayment}
                label={FDN.I18n.t("adminAdventures.prices.form.priceDownPayment.label")}
                onUpdate={(value) => actions.onEdit("priceDownPayment", value)}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <FDN.Input
                type="currency"
                options={{
                  currency: Config.get("currency") as string,
                  locale: Config.get("currencyLocale") as string,
                }}
                editMode={editMode}
                value={adventure.priceRemaining}
                label={FDN.I18n.t("adminAdventures.prices.form.priceRemaining.label")}
                onUpdate={(value) => actions.onEdit("priceRemaining", value)}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <div className="__ptfdn-input __ptfdn-input-editmode-inactive">
                <div className="__input-show-value">
                  {totalPrice || totalPricePercent ? (
                    <>
                      <strong>
                        &nbsp;&nbsp;
                        {Config.get("currency") as string}
                        {FDN.formatNumber(totalPrice, 2, 2, Config.get("currencyLocale") as string)}
                      </strong>
                      {totalPricePercent && (
                        <span
                          className={`admin-adventure-totalprice-percent ${
                            totalPricePercent > 0 ? `success-text` : `error-text`
                          }`}
                        >
                          ({totalPricePercent >= 0 ? "+" : ""}
                          {FDN.formatNumber(
                            totalPricePercent,
                            2,
                            2,
                            Config.get("currencyLocale") as string
                          )}
                          %)
                        </span>
                      )}
                    </>
                  ) : (
                    <>&nbsp;</>
                  )}
                </div>
                <div className="__ptfdn-input-label">
                  {FDN.I18n.t("adminAdventures.prices.form.priceDownPaymentTotal.label")}
                </div>
              </div>
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.FormGroup>

      <FDN.FormGroup>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <FDN.Input
                type="number"
                editMode={editMode}
                value={adventure.payRemainingDaysBefore || null}
                label={FDN.I18n.t("adminAdventures.prices.form.payRemainingDaysBefore.label")}
                onUpdate={(value) => actions.onEdit("payRemainingDaysBefore", value)}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.FormGroup>
    </>
  );
};
const FormGroupSinglePriceFull: React.FunctionComponent<IAdventurePricesFormGroupProps> = ({
  adventure,
  editMode,
  actions,
}) => {
  return (
    <>
      <FDN.FormGroup title={FDN.I18n.t("adminAdventures.prices.formgroup.priceFull.title")}>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <FDN.Input
                type="currency"
                options={{
                  currency: Config.get("currency") as string,
                  locale: Config.get("currencyLocale") as string,
                }}
                editMode={editMode}
                value={adventure.priceSingleFull}
                label={FDN.I18n.t("adminAdventures.prices.form.priceFull.label")}
                onUpdate={(value) => actions.onEdit("priceSingleFull", value)}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.FormGroup>
    </>
  );
};

const FormGroupSinglePriceDownPayment: React.FunctionComponent<IAdventurePricesFormGroupProps> = ({
  adventure,
  editMode,
  actions,
}) => {
  let totalPrice = 0.0;
  let totalPricePercent = 0.0;

  try {
    if (adventure.priceSingleDownPayment) totalPrice += adventure.priceSingleDownPayment;
    if (adventure.priceSingleRemaining) totalPrice += adventure.priceSingleRemaining;

    if (adventure.priceSingleFull && adventure.priceSingleFull > 0 && totalPrice > 0) {
      totalPricePercent =
        ((totalPrice - adventure.priceSingleFull) / adventure.priceSingleFull) * 100;
      totalPricePercent = Math.floor(totalPricePercent * 100) / 100;
    }
  } catch {
    //
  }

  return (
    <>
      <FDN.FormGroup title={FDN.I18n.t("adminAdventures.prices.formgroup.priceDownPayment.title")}>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <FDN.Input
                type="currency"
                options={{
                  currency: Config.get("currency") as string,
                  locale: Config.get("currencyLocale") as string,
                }}
                editMode={editMode}
                value={adventure.priceSingleDownPayment}
                label={FDN.I18n.t("adminAdventures.prices.form.priceDownPayment.label")}
                onUpdate={(value) => actions.onEdit("priceSingleDownPayment", value)}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <FDN.Input
                type="currency"
                options={{
                  currency: Config.get("currency") as string,
                  locale: Config.get("currencyLocale") as string,
                }}
                editMode={editMode}
                value={adventure.priceSingleRemaining}
                label={FDN.I18n.t("adminAdventures.prices.form.priceRemaining.label")}
                onUpdate={(value) => actions.onEdit("priceSingleRemaining", value)}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <div className="__ptfdn-input __ptfdn-input-editmode-inactive">
                <div className="__input-show-value">
                  {totalPrice || totalPricePercent ? (
                    <>
                      <strong>
                        &nbsp;&nbsp;
                        {Config.get("currency") as string}
                        {FDN.formatNumber(totalPrice, 2, 2, Config.get("currencyLocale") as string)}
                      </strong>
                      {totalPricePercent && (
                        <span
                          className={`admin-adventure-totalprice-percent ${
                            totalPricePercent > 0 ? `success-text` : `error-text`
                          }`}
                        >
                          ({totalPricePercent >= 0 ? "+" : ""}
                          {FDN.formatNumber(
                            totalPricePercent,
                            2,
                            2,
                            Config.get("currencyLocale") as string
                          )}
                          %)
                        </span>
                      )}
                    </>
                  ) : (
                    <>&nbsp;</>
                  )}
                </div>
                <div className="__ptfdn-input-label">
                  {FDN.I18n.t("adminAdventures.prices.form.priceDownPaymentTotal.label")}
                </div>
              </div>
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.FormGroup>
    </>
  );
};

const FormGroupChildrenPriceFull: React.FunctionComponent<IAdventurePricesFormGroupProps> = ({
  adventure,
  editMode,
  actions,
}) => {
  const selectChildrenMaxAge: { [key: number]: number | string } = {
    0: "---",
  };
  for (let i = 1; i <= 17; i++) {
    selectChildrenMaxAge[i] = i;
  }

  return (
    <>
      <FDN.FormGroup
        title={FDN.I18n.t("adminAdventures.prices.formgroup.priceChildrenMaxAge.title")}
      >
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <FDN.Input
                type="select"
                selectValues={selectChildrenMaxAge}
                editMode={editMode}
                value={adventure.childrenMaxAge}
                label={FDN.I18n.t("adminAdventures.prices.form.priceChildrenMaxAge.label")}
                onUpdate={(value) => actions.onEdit("childrenMaxAge", value)}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.FormGroup>
      <FDN.FormGroup title={FDN.I18n.t("adminAdventures.prices.formgroup.priceChildrenFull.title")}>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <FDN.Input
                type="currency"
                options={{
                  currency: Config.get("currency") as string,
                  locale: Config.get("currencyLocale") as string,
                }}
                editMode={editMode}
                value={adventure.priceChildren}
                label={FDN.I18n.t("adminAdventures.prices.form.priceChildren.label", {
                  age: adventure.childrenMaxAge || "?",
                })}
                onUpdate={(value) => actions.onEdit("priceChildren", value)}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.FormGroup>
    </>
  );
};

const FormGroupChildrenPriceDownPayment: React.FunctionComponent<
  IAdventurePricesFormGroupProps
> = ({ adventure, editMode, actions }) => {
  let totalChildrenPrice = 0.0;
  let totalChildrenPricePercent = 0.0;

  try {
    if (adventure.priceChildrenDownPayment)
      totalChildrenPrice += adventure.priceChildrenDownPayment;
    if (adventure.priceChildrenRemaining) totalChildrenPrice += adventure.priceChildrenRemaining;

    if (adventure.priceChildren && adventure.priceChildren > 0 && totalChildrenPrice > 0) {
      totalChildrenPricePercent =
        ((totalChildrenPrice - adventure.priceChildren) / adventure.priceChildren) * 100;
      totalChildrenPricePercent = Math.floor(totalChildrenPricePercent * 100) / 100;
    }
  } catch {
    //
  }

  return (
    <>
      <FDN.FormGroup
        title={FDN.I18n.t("adminAdventures.prices.formgroup.priceChildrenDownPayment.title", {
          age: adventure.childrenMaxAge || "?",
        })}
      >
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <FDN.Input
                type="currency"
                options={{
                  currency: Config.get("currency") as string,
                  locale: Config.get("currencyLocale") as string,
                }}
                editMode={editMode}
                value={adventure.priceChildrenDownPayment}
                label={FDN.I18n.t("adminAdventures.prices.form.priceChildrenDownPayment.label", {
                  age: adventure.childrenMaxAge || "?",
                })}
                onUpdate={(value) => actions.onEdit("priceChildrenDownPayment", value)}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <FDN.Input
                type="currency"
                options={{
                  currency: Config.get("currency") as string,
                  locale: Config.get("currencyLocale") as string,
                }}
                editMode={editMode}
                value={adventure.priceChildrenRemaining}
                label={FDN.I18n.t("adminAdventures.prices.form.priceChildrenRemaining.label", {
                  age: adventure.childrenMaxAge || "?",
                })}
                onUpdate={(value) => actions.onEdit("priceChildrenRemaining", value)}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <div className="__ptfdn-input __ptfdn-input-editmode-inactive">
                <div className="__input-show-value">
                  {totalChildrenPrice || totalChildrenPricePercent ? (
                    <>
                      <strong>
                        &nbsp;&nbsp;
                        {Config.get("currency") as string}
                        {FDN.formatNumber(
                          totalChildrenPrice,
                          2,
                          2,
                          Config.get("currencyLocale") as string
                        )}
                      </strong>
                      {totalChildrenPricePercent && (
                        <span
                          className={`admin-adventure-totalprice-percent ${
                            totalChildrenPricePercent > 0 ? `success-text` : `error-text`
                          }`}
                        >
                          ({totalChildrenPricePercent >= 0 ? "+" : ""}
                          {FDN.formatNumber(
                            totalChildrenPricePercent,
                            2,
                            2,
                            Config.get("currencyLocale") as string
                          )}
                          %)
                        </span>
                      )}
                    </>
                  ) : (
                    <>&nbsp;</>
                  )}
                </div>
                <div className="__ptfdn-input-label">
                  {FDN.I18n.t("adminAdventures.prices.form.priceChildrenDownPaymentTotal.label", {
                    age: adventure.childrenMaxAge || "?",
                  })}
                </div>
              </div>
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.FormGroup>
    </>
  );
};

export default AdventurePrices;

import React, { useState } from "react";
import { useParams } from "react-router-dom";
import BookingApi from "src/api/BookingApi";
import { IOrder, IStripePayment, TFunction } from "src/types";
import useServiceCore from "./CoreService";
import StatusCode from "src/config/statuscodes";
import StripeApi from "src/components/thirdparty/stripe/api/StripeApi";
//import * as FDN from "src/core";
//import useServiceCore from "./CoreService";
//import StatusCode from "../config/statuscodes";

export interface TActionsPayment {
  deleteMe: TFunction;
}

const useServicePayment = () => {
  const [order, setOrder] = React.useState<IOrder>();
  const [stripePayment, setStripePaymnt] = React.useState<IStripePayment>();
  const [paymentProviderError, setPaymentProviderError] = useState(false);
  const [orderNotFound, setOrderNotFound] = useState(false);
  const [publicKey, setPublicKey] = useState<string>();

  const params = useParams();
  const { orderNr, orderToken } = params;

  const { APP, api } = useServiceCore();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    if (!orderNr || !orderToken) return;

    BookingApi.getOrderForPayment(api, orderNr, orderToken).then((response) => {
      if (response?.statusCode === StatusCode.NOT_FOUND) {
        setOrderNotFound(true);
      } else if (response?.statusCode === StatusCode.SUCCESS) {
        APP.setPageTitle("Payment");

        const order = response?.body?.order as IOrder;
        setOrder(response?.body?.order);

        if (["NOW_REQUIRED", "LATER_REQUIRED", "NOW_PAID"].includes(order.paymentStatus)) {
          getStripePayment(order.identifier);
        }

        // window.location.href = UrlService.url("booking.confirmation", {
        //   orderNr: order.orderNr,
        //   orderToken: order.orderToken as string,
        // });
      }
    });
  };

  const getStripePayment = (orderIdentifier: string) => {
    StripeApi.getPayment(api, orderIdentifier).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const stripePayment = response?.body?.stripePayment as IStripePayment;
        setStripePaymnt(stripePayment);

        const publicKey = response?.body?.publicKey as string;
        setPublicKey(publicKey);
      } else {
        setPaymentProviderError(true);
      }
    });
  };

  const deleteMe = () => {
    //
  };

  const actions: TActionsPayment = {
    deleteMe,
  };

  return {
    api,
    APP,
    order,
    stripePayment,
    publicKey,
    paymentProviderError,
    orderNotFound,
    actions,
  };
};

export default useServicePayment;

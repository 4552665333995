import React from "react";
import { IPasswordForgottenInputValues, TActions } from "src/types";
import useServiceCore from "src/services/CoreService";
import StatusCode from "src/config/statuscodes";
import EmailValidator from "email-validator";
import LocalStorageService from "src/core/LocalStorageService";
import AuthApi from "src/api/AuthApi";

export enum PageStatus {
  FORM = "FORM",
  SUCCESS = "SUCCESS",
}

const useServicePasswordForgotten = () => {
  const [pageStatus, setPageStatus] = React.useState(PageStatus.FORM);
  const [errorEmpty, setErrorEmpty] = React.useState(false);
  const [errorInvalidEmail, setErrorInvalidEmail] = React.useState(false);
  const [inputValues, setInputValues] = React.useState<IPasswordForgottenInputValues>({
    email: "",
  });

  const { APP, api } = useServiceCore();

  const logoLoginUrl = APP.getSetting("logoLoginUrl");

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    api.get("hello");

    if (LocalStorageService.get("LOGIN_EMAIL"))
      onInput("email", LocalStorageService.get("LOGIN_EMAIL"));
  };

  const onInput = (property: string, value: any): void => {
    setInputValues({ ...inputValues, [property]: value });

    if (property === "email") LocalStorageService.set("LOGIN_EMAIL", value);
  };

  const checkInput = () => {
    if (!inputValues.email) {
      setErrorEmpty(true);
      return false;
    } else setErrorEmpty(false);

    if (!EmailValidator.validate(inputValues.email)) {
      setErrorInvalidEmail(true);
      return false;
    } else setErrorInvalidEmail(false);

    return true;
  };

  const attemptReset = () => {
    if (!checkInput()) return false;

    AuthApi.attemptReset(api, inputValues).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setPageStatus(PageStatus.SUCCESS);
      }
    });
  };

  const actions: TActions = {
    onInput,
    checkInput,
    attemptReset,
  };

  return {
    actions,
    logoLoginUrl,
    pageStatus,
    errorEmpty,
    errorInvalidEmail,
    inputValues,
  };
};

export default useServicePasswordForgotten;

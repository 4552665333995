import { useEffect, useState } from "react";
import { IAdventure, IFile, IHotel, IHotelRoom, LocationCountries, SearchHotelsFilter, TActions } from "src/types";
import useServiceCore from "../CoreService";
import StatusCode from "src/config/statuscodes";
import { cloneDeep } from "lodash";
import AdminApi from "src/api/AdminApi";
import * as FDN from "src/core";
import { v4 } from "uuid";

const initSearch: SearchHotelsFilter = {};

const editLockType = "hotel";

type FnOnEdit = <P extends keyof IHotel, V extends IHotel[P]>(propert: P, value: V) => void;
export type FOnPictureUploadsCompleted = (files: IFile[]) => void;
type FnOnEditRoom = <P extends keyof IHotelRoom, V extends IHotelRoom[P]>(
  identifier: string,
  propert: P,
  value: V
) => void;

export interface TActionsAdminHotels extends TActions {
  init: () => void;
  updateSearch: <P extends keyof SearchHotelsFilter, V extends SearchHotelsFilter[P]>(property: P, value: V) => void;
  selectHotel: (identifier: string) => void;
  onEdit: FnOnEdit;
  onHotelPictureUploadsCompleted: FOnPictureUploadsCompleted;
  onCancelEdit: () => void;
  onSaveEdit: () => void;
  onAddRoom: () => void;
  onEditRoom: FnOnEditRoom;
  onRemoveRoom: (roomIdentifier: string) => void;
  onDelete: () => void;
}

const useHotels = () => {
  const [hotels, setHotels] = useState<IHotel[]>();

  const [search, setSearch] = useState<SearchHotelsFilter>(cloneDeep(initSearch));
  const [cities, setCities] = useState<string[]>();
  const [countries, setCountries] = useState<string[]>();
  const [locationCountries, setLocationCountries] = useState<LocationCountries>();

  const [filteredHotels, setFilteredHotels] = useState<IHotel[]>();
  const [selectedHotel, setSelectedHotel] = useState<IHotel>();
  const [adventuresOfHotel, setAdventuresOfHotel] = useState<IAdventure[]>();

  const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);

  const { APP, NOTIFICATIONS, api } = useServiceCore();

  useEffect(() => {
    updateFilteredHotels();
  }, [search, hotels]);

  useEffect(() => {
    getCountriesAndCitieas();
  }, [hotels, search.country]);

  function updateSearch<P extends keyof SearchHotelsFilter, V extends SearchHotelsFilter[P]>(property: P, value: V) {
    const updatedSearch = search ? cloneDeep(search) : cloneDeep(initSearch);

    updatedSearch[property] = value;

    setSearch(updatedSearch);
  }

  const fetchHotels = async () => {
    api.get("admin.hotels.all").then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const hotels = response.body?.hotels as IHotel[];
        setHotels(hotels);

        api.get("admin.hotels.adventuresamount").then((response) => {
          if (response?.statusCode === StatusCode.SUCCESS) {
            const adventuresAmount = response.body?.adventuresAmount as Record<string, number>;
            for (const hotel of hotels) {
              hotel.adventuresAmount = adventuresAmount[hotel.identifier] ?? 0;
            }

            setHotels(cloneDeep(hotels));
          }
        });
      }
    });
  };

  const updateFilteredHotels = () => {
    if (!hotels) return;
    const filteredHotels = hotels.filter((hotel) => {
      let addToList = true;

      if (search.text) {
        if (
          !hotel.name.toLowerCase().includes(search.text.toLowerCase()) &&
          !hotel.street?.toLowerCase().includes(search.text.toLowerCase()) &&
          !hotel.city?.toLowerCase().includes(search.text.toLowerCase()) &&
          !hotel.email?.toLowerCase().includes(search.text.toLowerCase())
        )
          addToList = false;
      }

      if (search.country) {
        if (hotel.country !== search.country) addToList = false;
      }

      if (addToList === true) return hotel;
    });

    setFilteredHotels(filteredHotels);
  };

  const getCountriesAndCitieas = () => {
    const tmpCountries = [];
    tmpCountries.push("");

    const tmpCities = [];
    tmpCities.push("");

    for (const hotel of hotels ?? []) {
      if (hotel.country && !tmpCountries.includes(hotel.country)) tmpCountries.push(hotel.country);

      if (search.country && hotel.country !== search.country) continue;
      if (hotel.city && !tmpCities.includes(hotel.city)) tmpCities.push(hotel.city);
    }

    setCities(tmpCities);
    setCountries(tmpCountries);
  };

  const selectHotel = (identifier: string) => {
    const hotel: IHotel | undefined =
      identifier === "new"
        ? {
            identifier: `new_${v4()}`,
            name: "",
            published: true,
            pictures: [],
          }
        : hotels?.find((hotel) => hotel.identifier === identifier);
    setSelectedHotel(hotel);

    if (!hotel) return;

    api.get("admin.hotels.adventures", { identifier: hotel.identifier }).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const adventures = response?.body?.adventures as IAdventure[];
        setAdventuresOfHotel(adventures);
        const locationCountries = response?.body?.countries as LocationCountries;
        setLocationCountries(locationCountries);
      }
    });
  };

  const onEdit: FnOnEdit = (property, value) => {
    if (!selectedHotel) return;

    const updatedHotel = cloneDeep(selectedHotel);
    updatedHotel[property] = value;

    setSelectedHotel(updatedHotel);
    setUnsavedChanges(true);
  };

  const onHotelPictureUploadsCompleted: FOnPictureUploadsCompleted = (files) => {
    if (!selectedHotel) return;

    const updatedPictures: IFile[] = selectedHotel.pictures || [];

    for (const file of files) {
      const alreadyUploaded = updatedPictures.find((p) => p.identifier === file.identifier);
      if (!alreadyUploaded) updatedPictures.push(file);
    }

    //const updatedPictures = selectedHotel.pictures ? [...selectedHotel.pictures, ...files] : files;
    const updatedPictureIdentifiers = updatedPictures.map((picture) => picture.identifier);

    console.log("updPic", updatedPictures);
    console.log("updPicIden", updatedPictureIdentifiers);

    selectedHotel.pictures = updatedPictures;
    selectedHotel.pictureIdentifiers = updatedPictureIdentifiers;
    setSelectedHotel(cloneDeep(selectedHotel));
  };

  const onCancelEdit = () => {
    const doCancel = () => {
      if (selectedHotel)
        AdminApi.deleteEditLock(api, editLockType, selectedHotel.identifier).then(() => {
          setSelectedHotel(undefined);
          setUnsavedChanges(false);
          NOTIFICATIONS.hideDialog();
        });
    };

    if (unsavedChanges)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  const onSaveEdit = () => {
    if (!selectedHotel) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    AdminApi.adminSaveHotel(api, selectedHotel.identifier, selectedHotel).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const hotels = response?.body?.hotels as IHotel[];
        setHotels(cloneDeep(hotels));

        setSelectedHotel(undefined);
        setUnsavedChanges(false);
        NOTIFICATIONS.hideSaving();

        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("adminHotels.form.onSave.success.title"),
          FDN.I18n.t("adminHotels.form.onSave.success.text")
        );

        api.get("admin.hotels.adventuresamount").then((response) => {
          if (response?.statusCode === StatusCode.SUCCESS) {
            const adventuresAmount = response.body?.adventuresAmount as Record<string, number>;
            for (const hotel of hotels) {
              hotel.adventuresAmount = adventuresAmount[hotel.identifier] ?? 0;
            }

            setHotels(cloneDeep(hotels));
          }
        });
      }
    });
  };

  const onAddRoom = () => {
    if (!selectedHotel) return;

    const room = {
      identifier: `new_${v4()}`,
      hotelIdentifier: selectedHotel.identifier,
      title: "",
      description: "",
      priceFull: 0,
      priceSingleFull: 0,
    };

    if (!selectedHotel.rooms) selectedHotel.rooms = [];
    selectedHotel.rooms.push(room);

    setSelectedHotel(cloneDeep(selectedHotel));

    // api
    //   .post("admin.hotels.room", { identifier: selectedHotel.identifier, roomIdentifier: room.identifier }, { room })
    //   .then((response) => {
    //     if (response?.statusCode === StatusCode.SUCCESS) {
    //       const rooms = response?.body?.rooms as IHotelRoom[];
    //       const updatedHotel = cloneDeep(selectedHotel);
    //       updatedHotel.rooms = rooms;
    //       setSelectedHotel(updatedHotel);
    //       setUnsavedChanges(true);
    //     }
    //   });
  };

  const onEditRoom: FnOnEditRoom = (identifier, property, value) => {
    if (!selectedHotel) return;

    const updatedHotel = cloneDeep(selectedHotel);
    const updatedRoom = updatedHotel.rooms?.find((room) => room.identifier === identifier);

    if (!updatedRoom) return;

    updatedRoom[property] = value;

    setSelectedHotel(updatedHotel);
    setUnsavedChanges(true);
  };

  const onRemoveRoom = (roomIdentifier: string) => {
    const doRemove = () => {
      if (!selectedHotel) return;

      const updatedHotel = cloneDeep(selectedHotel);
      updatedHotel.rooms = updatedHotel.rooms?.filter((room) => room.identifier !== roomIdentifier);

      setSelectedHotel(updatedHotel);
      setUnsavedChanges(true);
      NOTIFICATIONS.hideDialog();
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("adminHotels.rooms.form.onRemove.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => doRemove(),
        },
      ],
    });
  };

  const onDelete = () => {
    if (!selectedHotel) return;

    const doDelete = () => {
      if (!selectedHotel) return;

      api.delete("admin.hotels.hotel", { identifier: selectedHotel.identifier }).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          fetchHotels();

          setSelectedHotel(undefined);
          setAdventuresOfHotel(undefined);
          NOTIFICATIONS.hideDialog();
          NOTIFICATIONS.showNotification(
            "success",
            FDN.I18n.t("adminHotels.form.onDelete.success.title"),
            FDN.I18n.t("adminHotels.form.onDelete.success.text")
          );
        }
      });
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("adminHotels.form.onDelete.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => doDelete(),
        },
      ],
    });
  };

  const actions: TActionsAdminHotels = {
    init: fetchHotels,
    updateSearch,
    selectHotel,
    onEdit,
    onHotelPictureUploadsCompleted,
    onCancelEdit,
    onSaveEdit,
    onAddRoom,
    onEditRoom,
    onRemoveRoom,
    onDelete,
  };

  return {
    APP,
    filteredHotels,
    selectedHotel,
    adventuresOfHotel,
    search,
    cities,
    countries,
    locationCountries,
    actions,
  };
};

export default useHotels;

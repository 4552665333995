import * as React from "react";
import { BookingContext } from "src/components/context/BookingContext/BookingContext";
import * as FDN from "src/core";
import BookingPageTitle from "../parts/BookingPageTitle";
import { IAdventure, IUpgrade, IUpgradePrice, TFunction } from "src/types";
import Config from "src/core/Config";
import UpgradePictureSlider from "../../parts/UpgradePictureSlider";
import { getAdventurePictureUrl, nl2br } from "src/core/helpers/helpers";

interface IBookingUpgradeProps {
  adventure: IAdventure;
}

const BookingUpgrade: React.FunctionComponent<IBookingUpgradeProps> = ({ adventure }) => {
  const BOOKING = React.useContext(BookingContext);
  const { order } = BOOKING;

  if (!order || !adventure || !adventure.upgrades || !adventure.upgradePrices)
    return <FDN.Loading box />;

  return (
    <div className="__booking-form-page-upgrades" id="booking_upgrades">
      <BookingPageTitle page="upgrades" order={order} />

      {adventure.upgradePrices.map((upgradePrice) => {
        if (!adventure.upgrades) return null;

        const upgrade = adventure.upgrades.find(
          (h) => h.identifier === upgradePrice.upgradeIdentifier
        );

        if (!upgrade) return null;

        return (
          <UpgradeEntry
            key={upgradePrice.identifier}
            upgrade={upgrade}
            upgradePrice={upgradePrice}
          />
        );
      })}
    </div>
  );
};

interface IUpgradeEntryProps {
  upgradePrice: IUpgradePrice;
  upgrade: IUpgrade;
}

const UpgradeEntry: React.FunctionComponent<IUpgradeEntryProps> = ({ upgradePrice, upgrade }) => {
  const [showMore, setShowMore] = React.useState(false);

  const BOOKING = React.useContext(BookingContext);
  const { order } = BOOKING;

  if (!order) return null;

  const descriptionPreview =
    upgrade.description && upgrade.description.length > 200
      ? `${upgrade.description.slice(0, 200)}...`
      : undefined;

  const currency = Config.get("currency") as string;

  const styles: React.CSSProperties = {
    backgroundImage: upgrade.mainPicture
      ? `url("${getAdventurePictureUrl(upgrade.mainPicture, "L")}")`
      : undefined,
  };

  return (
    <>
      <div
        className={`booking-upgrade-entry ${
          order.selectedUpgradePrices?.includes(upgradePrice.identifier)
            ? `selected`
            : `not-selected`
        }`}
      >
        <FDN.Box>
          <div className="booking-upgrade-entry-box">
            {/* <div className="booking-upgrade-entry-icon">
            {order.selectedUpgradePrices === upgradePrice.identifier ? (
              <span className="success-text">
                <FDN.Icon icon="check" />
              </span>
            ) : (
              <span className="booking-upgrade-entry-icon-notselected">
                <FDN.Icon icon="check" />
              </span>
            )}
          </div> */}
            <div className="booking-upgrade-entry-content-wrapper">
              <button
                className="booking-upgrade-entry-pictures"
                style={styles}
                onClick={() => setShowMore(true)}
              ></button>
              <div className="booking-upgrade-entry-content">
                <div className="booking-upgrade-entry-name">
                  <span>{upgrade.name}</span>
                </div>
                <div className="booking-upgrade-entry-description">
                  {descriptionPreview ? (
                    <>
                      <div dangerouslySetInnerHTML={{ __html: nl2br(descriptionPreview, " | ") }} />
                      <span
                        className="booking-upgrade-entry-showmore"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setShowMore(true);
                        }}
                      >
                        {FDN.I18n.t("booking.form.group.upgrades.showMore")}
                      </span>
                    </>
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{ __html: nl2br(upgrade.description || "", " | ") }}
                    />
                  )}
                </div>
                <div className="booking-upgrade-entry-price">
                  {FDN.I18n.t("booking.form.group.upgrades.price")}:{" "}
                  <span>
                    {currency}{" "}
                    {FDN.formatNumber(
                      order.participants.length === 1 && upgradePrice.priceSingleFull
                        ? upgradePrice.priceSingleFull
                        : upgradePrice.priceFull || 0.0
                    )}{" "}
                  </span>
                </div>
                <div className="booking-upgrade-entry-buttons">
                  <button
                    className={`small hollow primary button`}
                    onClick={() => setShowMore(true)}
                  >
                    <FDN.Icon icon="search" />{" "}
                    {FDN.I18n.t("booking.form.group.upgrades.buttons.details.label")}
                  </button>{" "}
                  <UpgradeEntrySelectButtons upgradePrice={upgradePrice} />
                </div>
              </div>
            </div>
          </div>
        </FDN.Box>
      </div>
      {showMore === true ? (
        <UpgradeEntryPopup
          upgrade={upgrade}
          upgradePrice={upgradePrice}
          setShowMore={setShowMore}
        />
      ) : null}
    </>
  );
};

interface IUpgradeEntryPopupProps {
  upgradePrice: IUpgradePrice;
  upgrade: IUpgrade;
  setShowMore: TFunction;
}

const UpgradeEntryPopup: React.FunctionComponent<IUpgradeEntryPopupProps> = ({
  upgrade,
  upgradePrice,
  setShowMore,
}) => {
  const BOOKING = React.useContext(BookingContext);
  const { order } = BOOKING;

  if (!order) return null;

  const currency = Config.get("currency") as string;

  return (
    <FDN.Popup size="large" onClose={() => setShowMore(false)} title={<>{upgrade.name}</>}>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={10}>
            <UpgradePictureSlider upgrade={upgrade} />
            <div className="booking-upgrade-entry-popup-price">
              {FDN.I18n.t("booking.form.group.upgrades.price")}:{" "}
              <span>
                {currency}{" "}
                {FDN.formatNumber(
                  order.participants.length === 1 && upgradePrice.priceSingleFull
                    ? upgradePrice.priceSingleFull
                    : upgradePrice.priceFull || 0.0
                )}{" "}
              </span>
            </div>
            <div className="booking-upgrade-entry-popup-buttons">
              <UpgradeEntrySelectButtons upgradePrice={upgradePrice} />
            </div>
          </FDN.Cell>
          <FDN.Cell sm={24} md={14}>
            <div dangerouslySetInnerHTML={{ __html: nl2br(upgrade.description || "") }} />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </FDN.Popup>
  );
};

interface IUpgradeEntrySelectButtonsProps {
  upgradePrice: IUpgradePrice;
}

const UpgradeEntrySelectButtons: React.FunctionComponent<IUpgradeEntrySelectButtonsProps> = ({
  upgradePrice,
}) => {
  const BOOKING = React.useContext(BookingContext);
  const { order } = BOOKING;

  if (!order) return null;

  return (
    <>
      {order.selectedUpgradePrices?.includes(upgradePrice.identifier) ? (
        <button
          className={`small hollow primary button`}
          onClick={() => BOOKING.onEdit("selectedUpgradePrices", upgradePrice.identifier)}
        >
          <FDN.Icon icon="minus" /> {FDN.I18n.t("booking.form.group.upgrades.buttons.remove.label")}
        </button>
      ) : (
        <button
          className={`small primary button`}
          onClick={() => BOOKING.onEdit("selectedUpgradePrices", upgradePrice.identifier)}
        >
          <FDN.Icon icon="plus" /> {FDN.I18n.t("booking.form.group.upgrades.buttons.select.label")}
        </button>
      )}
    </>
  );
};

export default BookingUpgrade;

import * as React from "react";
import * as FDN from "src/core";
import AdminLayout from "../layouts/AdminLayout";
import AdminHeader from "../layouts/AdminHeader";
import useServiceAdminDrivers from "src/services/admin/DriversService";
import DriversList from "./parts/DriversList";
import { userHasPermission } from "src/core/AdminService/helpers";
import EditDriverPopup from "./parts/EditDriverPopup";

const AdminDrivers: React.FunctionComponent = () => {
  const { APP, drivers, driver, editMode, actions } = useServiceAdminDrivers();
  const user = APP.getUser();

  if (!drivers) return <FDN.Loading />;

  return (
    <div className="__admin-drivers">
      <AdminLayout page="admin-drivers" selectedNav="drivers">
        <AdminHeader
          title={FDN.I18n.t("adminDrivers.header.title")}
          buttons={[
            <>
              {userHasPermission(user, "adminDriversAdd") ? (
                <button className="primary button" onClick={() => actions.selectDriver("new")}>
                  <FDN.Icon icon="plus" left /> {FDN.I18n.t("adminDrivers.buttons.add.label")}
                </button>
              ) : null}
            </>,
          ]}
        />
        <div className="__admin-drivers-list">
          <DriversList drivers={drivers} actions={actions} />
        </div>
        {driver ? <EditDriverPopup driver={driver} editMode={editMode} actions={actions} /> : null}
      </AdminLayout>
    </div>
  );
};

export default AdminDrivers;

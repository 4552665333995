import * as React from "react";
import { FileUploadFile } from "../../ImageUpload/types";
import { TActions } from "src/types";
import * as FDN from "src/core";
import UploadButtons from "./UploadButtons";
import ProgressBar from "@ramonak/react-progress-bar";

interface IFilesToBeUploadedProps {
  files: FileUploadFile[];
  uploadProgresses: { [key: string]: number };
  actions: TActions;
}

const UploadFilesList: React.FunctionComponent<IFilesToBeUploadedProps> = ({
  files,
  uploadProgresses,
  actions,
}) => {
  if (!files || files.length === 0) return null;

  return (
    <>
      <div className="__filemultiupload-filelist">
        <div className="__filemultiupload-filelist-total">
          {FDN.I18n.t("filemultiupload.filelist.text", {
            total: files.length,
            textFiles: FDN.I18n.t(
              `filemultiupload.filelist.text.${files.length === 1 ? `file` : `files`}`
            ),
          })}
        </div>
        <div className="__filemultiupload-filelist-list">
          <ul>
            {files.map((file, index) => {
              return (
                <li key={index}>
                  <UploadFilesListFile
                    file={file}
                    index={index}
                    uploadProgress={uploadProgresses[file.uuid] || 0}
                    actions={actions}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
      <UploadButtons files={files} actions={actions} />
    </>
  );
};

interface IUploadFilesListFile {
  index: number;
  file: FileUploadFile;
  uploadProgress: number;
  actions: TActions;
}

const UploadFilesListFile: React.FunctionComponent<IUploadFilesListFile> = ({
  file,
  index,
  uploadProgress,
  actions,
}) => {
  return (
    <div className="__filemultiupload-filelist-list-item">
      <FileIcon file={file} />
      <div className="__filemultiupload-filelist-list-item-content">
        <div className="__filemultiupload-filelist-list-item-content-name">{file.name}</div>
        <div className="__filemultiupload-filelist-list-item-content-size">
          {actions.formatFileSize(file.size)}
        </div>
        {uploadProgress ? (
          <div className="__filemultiupload-filelist-list-item-content-progress">
            <ProgressBar
              completed={uploadProgress}
              isLabelVisible={false}
              bgColor={uploadProgress === 100 ? "rgb(96, 189, 34)" : "#8147cf"}
              height="4px"
            />
          </div>
        ) : null}
      </div>
      <div className="__filemultiupload-filelist-list-item-buttons">
        <button className="clickable-text" onClick={() => actions.removeFileFromList(index)}>
          <FDN.ToolTip tooltip={FDN.I18n.t("filemultiupload.filelist.file.buttons.remove.label")}>
            <FDN.Icon icon="times" />
          </FDN.ToolTip>
        </button>
      </div>
    </div>
  );
};

interface IFileIcon {
  file: FileUploadFile;
}

const FileIcon: React.FunctionComponent<IFileIcon> = ({ file }) => {
  if (file?.original)
    return (
      <div
        className="__filemultiupload-filelist-list-item-icon"
        style={{
          backgroundImage: `url("${URL.createObjectURL(file.original)}")`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        &nbsp;
      </div>
    );

  return <div className="__filemultiupload-filelist-list-item-icon">&nbsp;</div>;
};

export default UploadFilesList;

import * as React from "react";
import * as FDN from "src/core";
import useServicePayment from "src/services/PaymentService";
import { PaymentStatusTypes } from "src/types";
import { Elements } from "@stripe/react-stripe-js";
import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import PaymentStatus from "src/components/thirdparty/stripe/components/parts/PaymentStatus";
import UrlService from "src/core/UrlService";
import { useParams } from "react-router-dom";
import StripeApi from "src/components/thirdparty/stripe/api/StripeApi";
import StatusCode from "src/config/statuscodes";

const PaymentPageCompletion: React.FunctionComponent = () => {
  const { api, order, publicKey, orderNotFound, stripePayment } = useServicePayment();

  const [stripePromise, setStripePromise] = React.useState<Stripe | null>(null);
  const [loaded, setLoaded] = React.useState(false);

  const params = useParams();
  const orderNr = params.orderNr as string;
  const orderToken = params.orderToken as string;

  React.useEffect(() => {
    const initStripe = async () => {
      if (publicKey) {
        console.log("pub", publicKey);

        setStripePromise(await loadStripe(publicKey));
        setLoaded(true);
      }
    };

    initStripe();
  }, [publicKey]);

  const onStatusUpdate = (status: PaymentStatusTypes) => {
    if (!stripePayment) return;

    StripeApi.updatePaymentStatus(api, stripePayment?.identifier, status).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        if (status === "SUCCESS") {
          window.location.href = UrlService.url("booking.confirmation", { orderNr, orderToken });
        }
      }
    });
  };

  if (orderNotFound === true)
    return (
      <div className="__payment">
        <FDN.LayoutWithTopbar page="payment">
          <FDN.PageContent>
            <FDN.Grid>
              <FDN.Row margin="xy">
                <FDN.Cell md={12} mdo={6}>
                  <FDN.CalloutBox type="alert">
                    <p>
                      <strong>{FDN.I18n.t("payment.orderNotFound.title")}</strong>
                    </p>
                    <div>{FDN.I18n.t("payment.orderNotFound.text")}</div>
                  </FDN.CalloutBox>
                </FDN.Cell>
              </FDN.Row>
            </FDN.Grid>
          </FDN.PageContent>
        </FDN.LayoutWithTopbar>
      </div>
    );

  if (!order || !stripePayment || !loaded)
    return <FDN.Loading text={FDN.I18n.t("payment.checkingCompletion")} />;

  return (
    <div className="__payment">
      <FDN.LayoutWithTopbar page="payment">
        <FDN.PageContent>
          <FDN.Grid>
            <FDN.Row margin="xy">
              <FDN.Cell md={12} mdo={6}>
                <Elements stripe={stripePromise} options={{ locale: "en" }}>
                  <PaymentStatus onStatusUpdate={onStatusUpdate} />
                </Elements>
              </FDN.Cell>
            </FDN.Row>
          </FDN.Grid>
        </FDN.PageContent>
      </FDN.LayoutWithTopbar>
    </div>
  );
};

export default PaymentPageCompletion;

import * as React from "react";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { nl2br } from "src/core/helpers/helpers";
import { IAdventure, IIconsIncluded } from "src/types";
import AdventureSidebar from "./AdventureSidebar";
import AdventureBodyHotelPreview from "./AdventureBodyHotelPreview";

interface IAdventureBodyProps {
  adventure: IAdventure;
}

const AdventureBody: React.FunctionComponent<IAdventureBodyProps> = ({ adventure }) => {
  return (
    <div className="__public-adventure-body">
      <div className="__public-adventure-body-sidebar">
        <AdventureSidebar adventure={adventure} />
      </div>
      <div className="__public-adventure-body-content">
        <AdventureDescription adventure={adventure} />
        <AdventureIncluded adventure={adventure} />
        <AdventureBodyHotelPreview adventure={adventure} />
        <AdventurePaymentPolicy adventure={adventure} />
      </div>
    </div>
  );
};

const AdventureDescription: React.FunctionComponent<IAdventureBodyProps> = ({ adventure }) => {
  return (
    <FDN.Box>
      <h3>{adventure.title}</h3>
      <div dangerouslySetInnerHTML={{ __html: adventure.content || "" }} />
    </FDN.Box>
  );
};

const AdventureIncluded: React.FunctionComponent<IAdventureBodyProps> = ({ adventure }) => {
  const icons = Config.get("adventures.iconsIncluded") as { [key: string]: { [key: string]: any } };

  return (
    <FDN.Box>
      <h3>{FDN.I18n.t("adventures.iconsIncluded.title")}</h3>
      <div>{FDN.I18n.t("adventures.iconsIncluded.text")}</div>

      <ul className="__public-adventure-included-list">
        {Object.keys(icons).map((iconKey) => {
          if (!adventure.iconsIncluded.includes(iconKey as IIconsIncluded)) return null;

          return (
            <li key={iconKey}>
              <span className="success-text">
                <FDN.Icon icon="check" left />
              </span>
              {/*<FDN.Icon icon={icons[iconKey].icon} left />{" "}*/}
              {FDN.I18n.t(`adventures.iconsIncluded.${iconKey}.label`)}
            </li>
          );
        })}
      </ul>
      <ul className="__public-adventure-included-list">
        {adventure.included.map((item) => {
          return (
            <li key={item}>
              <span className="success-text">
                <FDN.Icon icon="check" left />
              </span>
              {item}
            </li>
          );
        })}
      </ul>
    </FDN.Box>
  );
};

const AdventurePaymentPolicy: React.FunctionComponent<IAdventureBodyProps> = ({ adventure }) => {
  if (!adventure.paymentPolicy) return null;

  return (
    <div className="__public-adventure-paymentpolicy">
      <h3>{FDN.I18n.t("adventures.paymentPolicy.title")}</h3>
      <div
        className="__public-adventure-paymentpolicy-text"
        dangerouslySetInnerHTML={{ __html: nl2br(adventure.paymentPolicy || "") }}
      />
    </div>
  );
};

export default AdventureBody;

import * as React from "react";
import * as FDN from "src/core";
import { getAdventurePictureUrl, nl2br } from "src/core/helpers/helpers";
import { IAdventure, IOrder } from "src/types";
import BookingPaymentPlanSummary from "../parts/BookingPaymentPlanSummary";
import HotelStars from "src/components/pages/admin/adventures/parts/hotels/HotelStars";
import Config from "src/core/Config";
import { classNames } from "src/core/helpers/design";

interface IConfirmationReviewProps {
  order: IOrder;
  adventure: IAdventure;
  location?: "preview" | "pdf" | "admin";
}

const ConfirmationReview: React.FunctionComponent<IConfirmationReviewProps> = ({ order, adventure, location }) => {
  if (!order) return <FDN.Loading box />;

  return (
    <div className="__booking-form-page-preview" id="booking_preview">
      <PreviewBookingInfo order={order} adventure={adventure} location={location} />
      <PreviewAdventure order={order} adventure={adventure} location={location} />
      <PreviewDate order={order} adventure={adventure} location={location} />
      <div className="page-break" />
      <PreviewParticipants order={order} adventure={adventure} location={location} />
      <PreviewHotelByNight order={order} adventure={adventure} location={location} />
      <PreviewUpgrades order={order} adventure={adventure} location={location} />
      <PreviewMessage order={order} adventure={adventure} location={location} />
      <PreviewPaymentPlan order={order} adventure={adventure} location={location} />
      <PreviewTerms order={order} adventure={adventure} location={location} />
    </div>
  );
};

interface IPreviewBox {
  order: IOrder;
  adventure: IAdventure;
  location?: "preview" | "pdf" | "admin";
}

const PreviewBookingInfo: React.FunctionComponent<IPreviewBox> = ({ order }) => {
  return (
    <>
      <div className="__booking-confirmation-bookinginfo">
        <div>
          {FDN.I18n.t("booking.confirmation.info.orderNr")}: <strong>{order.orderNr}</strong>
        </div>
        <div>
          {FDN.I18n.t("booking.confirmation.info.orderDate")}:{" "}
          <strong>{order.createdAt ? FDN.formatDateTime(order.createdAt, { seconds: true }) : "?"}</strong>
        </div>
      </div>
    </>
  );
};

const PreviewAdventure: React.FunctionComponent<IPreviewBox> = ({ order }) => {
  return (
    <>
      <h3>{FDN.I18n.t("booking.form.group.preview.box.adventure.title")}</h3>
      <FDN.Box>
        <div className="__booking-confirmation-adventure">
          {order.adventureMainPicture && (
            <div
              className="__booking-confirmation-adventure-picture"
              style={{
                backgroundImage: `url("${order.adventureMainPicture}")`,
              }}
            ></div>
          )}
          <div className="__booking-confirmation-adventure-text">
            <h4>{order.adventureTitle}</h4>
            <div>{order.adventureDescription}</div>
            <div></div>
          </div>
        </div>
      </FDN.Box>
    </>
  );
};

const PreviewDate: React.FunctionComponent<IPreviewBox> = ({ order }) => {
  return (
    <>
      <h3>{FDN.I18n.t("booking.form.group.preview.box.date.title")}</h3>
      <FDN.Box>
        <div className="__booking-form-preview-box-date">
          <div>{FDN.formatDate(order.startDate)}</div>
          {order.nights && order.endDate ? (
            <>
              <div>
                <FDN.Icon icon="long-arrow-right" />
              </div>
              <div>
                <FDN.Icon icon="moon-o" left /> {order.nights} {FDN.I18n.t("booking.sidebar.nights.label")}
              </div>
              <div>
                <FDN.Icon icon="long-arrow-right" />
              </div>
              <div>{FDN.formatDate(order.endDate)}</div>
            </>
          ) : null}
        </div>
      </FDN.Box>
    </>
  );
};

const PreviewParticipants: React.FunctionComponent<IPreviewBox> = ({ order }) => {
  if (!order.participants) return null;

  return (
    <>
      <h3>{FDN.I18n.t("booking.form.group.preview.box.participants.title")}</h3>
      {order.participants.map((participant, index) => {
        return (
          <FDN.Box noMargin key={index} style={{ marginBottom: "5px" }}>
            <FDN.Grid full>
              <FDN.Row>
                <FDN.Cell sm={24} md={24}>
                  {`${participant.firstname || ""} ${participant.lastname || ""}`}{" "}
                  {participant.birthday
                    ? `(${FDN.I18n.t("booking.form.group.participants.birthday.label")}: ${FDN.formatDate(
                        participant.birthday
                      )})`
                    : null}
                </FDN.Cell>
              </FDN.Row>
            </FDN.Grid>
          </FDN.Box>
        );
      })}
    </>
  );
};

export const PreviewHotelByNight: React.FunctionComponent<IPreviewBox> = ({ order, location }) => {
  if (!order.hotelsByNight) return null;

  const currency = Config.get("currency") as string;

  return (
    <>
      <h3>{FDN.I18n.t("booking.form.group.preview.box.hotel.title")}</h3>
      {Object.keys(order.hotelsByNight).map((night) => {
        if (!order.hotelsByNight) return null;

        const hotel = order.hotelsByNight[parseInt(night)];
        if (!hotel || !hotel.info) return null;

        const descriptionPreview =
          hotel.info.description && hotel.info.description.length > 120
            ? `${hotel.info.description.slice(0, 117)}...`
            : undefined;

        let extraCost = <>{FDN.I18n.t("booking.form.group.hotel.roomIncluded.label")}</>;
        if (order.participants.length === 1 && hotel.room?.pricePerNightSingleFull)
          extraCost = (
            <>
              {currency}
              {FDN.formatNumber(hotel.room.pricePerNightSingleFull, 2, 2)}
            </>
          );
        if (order.participants.length > 1 && hotel.room?.pricePerNightFull)
          extraCost = (
            <>
              {currency}
              {FDN.formatNumber(hotel.room.pricePerNightFull, 2, 2)}
            </>
          );

        const styles: React.CSSProperties = {
          backgroundImage: hotel.info.mainPicture
            ? `url("${getAdventurePictureUrl(hotel.info.mainPicture, "L")}")`
            : undefined,
        };

        return (
          <div key={night} className={`booking-hotel-entry mb-1`}>
            <FDN.Box noMargin>
              <div className="booking-hotel-entry-box">
                <div className="booking-hotel-entry-content-wrapper">
                  {location !== "pdf" && <div className="booking-hotel-entry-pictures" style={styles}></div>}
                  <div className={classNames(location !== "pdf" && "booking-hotel-entry-content")}>
                    <div className="text-xs font-medium uppercase">
                      {FDN.I18n.t("adminHotels.night")} {night}
                    </div>
                    <div className="booking-hotel-entry-name">
                      <span>{hotel.info.name}</span>
                      {hotel.info.stars ? <HotelStars stars={hotel.info.stars} /> : null}
                    </div>
                    <div className="booking-hotel-entry-description">
                      {descriptionPreview ? (
                        <div dangerouslySetInnerHTML={{ __html: nl2br(descriptionPreview, " | ") }} />
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: nl2br(hotel.info.description || "", " | ") }} />
                      )}
                    </div>
                    {hotel.room && (
                      <div className="pt-2">
                        <div className="rounded-md border px-2 py-1 flex">
                          <div className="flex-grow font-medium text-xs">
                            <FDN.Icon icon="bed" /> {FDN.I18n.t("booking.form.group.hotel.selectedRoom.title")}:{" "}
                            {hotel.room.title}
                          </div>
                          <div className="text-right font-medium text-xs">{extraCost}</div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </FDN.Box>
          </div>
        );
      })}
    </>
  );
};

// export const PreviewHotel: React.FunctionComponent<IPreviewBox> = ({ order, adventure }) => {
//   if (!adventure.hotels || !adventure.hotelPrices) return null;

//   const hotelPrice = adventure.hotelPrices.find((hp) => hp.identifier === order.selectedHotelPrice);

//   if (!hotelPrice) return null;

//   const hotel = adventure.hotels.find((h) => h.identifier === hotelPrice.hotelIdentifier);

//   if (!hotel) return null;

//   const descriptionPreview =
//     hotel.description && hotel.description.length > 200
//       ? `${hotel.description.slice(0, 200)}...`
//       : undefined;

//   const styles: React.CSSProperties = {
//     backgroundImage: hotel.mainPicture
//       ? `url("${getAdventurePictureUrl(hotel.mainPicture, "L")}")`
//       : undefined,
//   };
//   return (
//     <>
//       <h3>{FDN.I18n.t("booking.form.group.preview.box.hotel.title")}</h3>
//       <div className={`booking-hotel-entry`}>
//         <FDN.Box>
//           <div className="booking-hotel-entry-box">
//             <div className="booking-hotel-entry-content-wrapper">
//               <div className="booking-hotel-entry-pictures hide-for-pdf" style={styles}></div>
//               <div className="booking-hotel-entry-content">
//                 <div className="booking-hotel-entry-name">
//                   <span>{hotel.name}</span>
//                   {hotel.stars ? <HotelStars stars={hotel.stars} /> : null}
//                 </div>
//                 <div className="booking-hotel-entry-description">
//                   {descriptionPreview ? (
//                     <div dangerouslySetInnerHTML={{ __html: nl2br(descriptionPreview, " | ") }} />
//                   ) : (
//                     <div
//                       dangerouslySetInnerHTML={{ __html: nl2br(hotel.description || "", " | ") }}
//                     />
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </FDN.Box>
//       </div>
//     </>
//   );
// };

export const PreviewUpgrades: React.FunctionComponent<IPreviewBox> = ({ order, adventure }) => {
  if (!adventure.upgrades || !adventure.upgradePrices) return null;

  return (
    <>
      <h3>{FDN.I18n.t("booking.form.group.preview.box.upgrades.title")}</h3>
      {order.selectedUpgradePrices?.map((upgradePriceIdentifier) => {
        if (!adventure.upgradePrices || !adventure.upgrades) return null;

        const upgradePrice = adventure.upgradePrices.find((up) => up.identifier === upgradePriceIdentifier);
        if (!upgradePrice) return null;

        const upgrade = adventure.upgrades.find((u) => u.identifier === upgradePrice.upgradeIdentifier);
        if (!upgrade) return null;

        const descriptionPreview =
          upgrade.description && upgrade.description.length > 200
            ? `${upgrade.description.slice(0, 200)}...`
            : undefined;

        const styles: React.CSSProperties = {
          backgroundImage: upgrade.mainPicture
            ? `url("${getAdventurePictureUrl(upgrade.mainPicture, "L")}")`
            : undefined,
        };

        return (
          <div className={`booking-upgrade-entry`} key={upgradePriceIdentifier}>
            <FDN.Box>
              <div className="booking-upgrade-entry-box">
                <div className="booking-upgrade-entry-content-wrapper">
                  <div className="booking-upgrade-entry-pictures hide-for-pdf" style={styles}></div>
                  <div className="booking-upgrade-entry-content">
                    <div className="booking-upgrade-entry-name">
                      <span>{upgrade.name}</span>
                    </div>
                    <div className="booking-upgrade-entry-description">
                      {descriptionPreview ? (
                        <div dangerouslySetInnerHTML={{ __html: nl2br(descriptionPreview, " | ") }} />
                      ) : (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: nl2br(upgrade.description || "", " | "),
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </FDN.Box>
          </div>
        );
      })}
    </>
  );
};

export const PreviewMessage: React.FunctionComponent<IPreviewBox> = ({ order }) => {
  if (!order.message) return null;

  return (
    <>
      <h3>{FDN.I18n.t("booking.form.group.preview.box.message.title")}</h3>
      <div className={`booking-hotel-entry`}>
        <FDN.Box>
          <div dangerouslySetInnerHTML={{ __html: nl2br(order.message) }} />
        </FDN.Box>
      </div>
    </>
  );
};

const PreviewPaymentPlan: React.FunctionComponent<IPreviewBox> = ({ order, adventure }) => {
  if (!order.participants) return null;

  return (
    <>
      <h3>{FDN.I18n.t("booking.form.group.preview.box.paymentplan.title")}</h3>
      <FDN.Box>
        <div className="__booking-form-preview-box-paymentplan">
          <BookingPaymentPlanSummary order={order} calculation={order.calculation} adventure={adventure} />
        </div>
      </FDN.Box>
    </>
  );
};

const PreviewTerms: React.FunctionComponent<IPreviewBox> = ({ order }) => {
  if (!order.participants) return null;

  return (
    <>
      <FDN.Box>
        <div className="__booking-form-preview-box-terms text-center">
          <FDN.Icon icon="check-square-o" left /> <span>{FDN.I18n.t("booking.terms.textFull")}</span>
        </div>
      </FDN.Box>
    </>
  );
};

export default ConfirmationReview;

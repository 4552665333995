import * as React from "react";
import { Link } from "react-router-dom";
import { getWhatsAppUrl } from "src/components/main/WhatsappButton";
import * as FDN from "src/core";
import UrlService from "src/core/UrlService";
import useServiceMyOrders, { TActionsMyOrders } from "src/services/MyOrdersService";
import { IOrder, OrderPaymentStatus } from "src/types";

const MyOrders: React.FunctionComponent = () => {
  const { orders, actions } = useServiceMyOrders();

  if (!orders) return <FDN.Loading />;

  return (
    <div className="__myorders">
      <FDN.LayoutWithTopbar page="myorders">
        <FDN.PageContent>
          <h1>{FDN.I18n.t("myorders.header.title")}</h1>
          {orders.length === 0 ? (
            <p>
              <em>{FDN.I18n.t("myorders.noOrders")}</em>
            </p>
          ) : (
            <ul>
              {orders.map((order) => {
                return (
                  <li key={order.identifier}>
                    <OrderItem order={order} actions={actions} />
                  </li>
                );
              })}
            </ul>
          )}
          <div className="text-center">
            <Link to={UrlService.url("index")} className="primary button">
              <FDN.Icon icon="binoculars" left />{" "}
              {FDN.I18n.t(
                orders.length === 0
                  ? "myorders.buttons.orderNow.label"
                  : "myorders.buttons.orderAgain.label"
              )}
            </Link>
          </div>
        </FDN.PageContent>
      </FDN.LayoutWithTopbar>
    </div>
  );
};

interface IOrderItemProps {
  order: IOrder;
  actions: TActionsMyOrders;
}

const OrderItem: React.FunctionComponent<IOrderItemProps> = ({ order }) => {
  return (
    <FDN.Box>
      <div className="__myorders-list-order-content">
        <div
          className="order-adventure-picture"
          style={{ backgroundImage: `url("${order.adventureMainPicture}")` }}
        />
        <div className="order-content">
          <div className="order-info">
            <div className="order-info-infobox">
              <div className="order-info-ordernr">
                Order # <span>{order.orderNr}</span>
              </div>
              <div className="order-info-startdate">
                <FDN.Icon icon="calendar-check-o" left /> {FDN.formatDate(order.startDate)}
              </div>
              <div className="order-info-nights">
                <FDN.Icon icon="moon-o" left /> {order.nights}
              </div>
              <div className="order-info-participantcount">
                <FDN.Icon icon="users" left /> {order.participants.length}
              </div>
              <div className="order-info-totalcost">
                <FDN.Icon icon="dollar" left /> {FDN.formatNumber(order.calculation.total, 2, 2)}
              </div>
            </div>

            <div className="order-info-adventure-title"> {order.adventureTitle}</div>
            <div className="order-info-adventure-description"> {order.adventureDescription}</div>
            <div className="order-info-labels">
              {order.driver && order.driver.whatsapp ? (
                <span className="order-info-driver-whatsapp">
                  <a
                    href={getWhatsAppUrl(order.driver.whatsapp)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={`myorders-order-label label-type-whatsapp`}
                  >
                    <FDN.Icon icon="whatsapp" left />
                    {FDN.I18n.t(`myorders.labels.whatsapp.label`)}
                  </a>
                </span>
              ) : null}
              {order.reqInfoCompleted !== undefined && order.reqInfoCompleted < 100 ? (
                <Label type="REQINFO_MISSING" />
              ) : null}
              {order.paymentStatus === "NOW_REQUIRED" ||
              order.paymentStatus === "LATER_REQUIRED" ? (
                <Label type={order.paymentStatus} />
              ) : null}
              {order.paymentStatus === "NOW_PENDING" || order.paymentStatus === "LATER_PENDING" ? (
                <Label type={order.paymentStatus} />
              ) : null}
              {order.paymentStatus === "NOW_PAID" && <Label type={order.paymentStatus} />}
              {order.paymentStatus === "PAID" && <Label type={order.paymentStatus} />}
            </div>
          </div>
          <div className="order-buttons">
            <Link
              className="small primary hollow button"
              to={UrlService.url("user.myorders.show", { orderNr: order.orderNr as string })}
            >
              <FDN.Icon icon="search-plus" left /> {FDN.I18n.t("myorders.buttons.view.label")}
            </Link>
            {order.reqInfoCompleted !== undefined && order.reqInfoCompleted < 100 ? (
              <Link
                className="small altered primary button"
                to={UrlService.url("booking.reqinfo", {
                  orderNr: order.orderNr as string,
                  orderToken: order.orderToken as string,
                })}
              >
                <FDN.Icon icon="question-circle" left />{" "}
                {FDN.I18n.t("myorders.buttons.reqinfo.label")}
              </Link>
            ) : null}
            {order.paymentStatus === "NOW_REQUIRED" || order.paymentStatus === "LATER_REQUIRED" ? (
              <Link
                className="small altered primary button"
                to={UrlService.url("payment.orderPayment", {
                  orderNr: order.orderNr as string,
                  orderToken: order.orderToken as string,
                })}
              >
                <FDN.Icon icon="dollar" left /> {FDN.I18n.t("myorders.buttons.paynow.label")}
              </Link>
            ) : null}
          </div>
        </div>
        <div className="clearfix" />
      </div>
    </FDN.Box>
  );
};

type LabelTypes = OrderPaymentStatus | "REQINFO_MISSING";

interface ILabelProps {
  type: LabelTypes;
}

const Label: React.FunctionComponent<ILabelProps> = ({ type }) => {
  if (type === "NOW_PENDING" || type === "LATER_PENDING")
    return (
      <span className={`myorders-order-label label-type-pending`}>
        <FDN.Icon icon="spinner" left />
        {FDN.I18n.t(`myorders.labels.pending.label`)}
      </span>
    );

  if (type === "REQINFO_MISSING")
    return (
      <span className={`myorders-order-label label-type-required`}>
        <FDN.Icon icon="exclamation-circle" left />
        {FDN.I18n.t(`myorders.labels.reqinfo_missing.label`)}
      </span>
    );

  if (type === "NOW_PAID")
    return (
      <span className={`myorders-order-label label-type-paid`}>
        <FDN.Icon icon="check" left />
        {FDN.I18n.t(`myorders.labels.downpayment_paid.label`)}
      </span>
    );

  if (type === "PAID")
    return (
      <span className={`myorders-order-label label-type-paid`}>
        <FDN.Icon icon="check" left />
        {FDN.I18n.t(`myorders.labels.paidinfull.label`)}
      </span>
    );

  if (type === "NOW_REQUIRED" || type === "LATER_REQUIRED")
    return (
      <span className={`myorders-order-label label-type-required`}>
        <FDN.Icon icon="exclamation-circle" left />
        {FDN.I18n.t(`myorders.labels.payment_required.label`)}
      </span>
    );

  return null;
};

export default MyOrders;

import React, { useState } from "react";
import * as FDN from "src/core";
import { EmailTemplateState, IEmailTemplatePlaceholder, TActions } from "src/types";
import useServiceCore from "../CoreService";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import { useParams } from "react-router-dom";
import { userHasPermission } from "src/core/AdminService/helpers";
import UrlService from "src/core/UrlService";
import { cloneDeep } from "lodash";

const useServiceAdminEmailTemplate = () => {
  const { APP, NOTIFICATIONS, api } = useServiceCore();

  const params = useParams();
  const emailTemplateIdentifier = params.identifier as string;

  const [emailTemplate, setEmailTemplate] = React.useState<EmailTemplateState | null>(null);
  const [placeholders, setPlaceholders] = useState<IEmailTemplatePlaceholder>();
  const [editMode, setEditMode] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    AdminApi.adminEmailTemplate(api, emailTemplateIdentifier).then((response) => {
      APP.setPageTitle(FDN.I18n.t("adminEmailTemplates.pageTitle"));
      if (response?.statusCode === StatusCode.SUCCESS) {
        const user = response?.user;
        setEmailTemplate(response?.body?.emailTemplate as EmailTemplateState);
        setPlaceholders(response?.body?.placeholders as IEmailTemplatePlaceholder);
        if (userHasPermission(user, "adminEmailTemplatesEdit")) setEditMode(true);
      }
    });
  };

  const onCancel = () => {
    const doCancel = () => (window.location.href = UrlService.url("admin.emailtemplates.index"));

    if (unsavedChanges)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  const onEdit = (property: string, language: string, value: any) => {
    if (!emailTemplate) return;

    try {
      emailTemplate[language][property] = value;
      setEmailTemplate(cloneDeep(emailTemplate));
      setUnsavedChanges(true);
    } catch {
      //
    }
  };

  const onSave = () => {
    if (!emailTemplate) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    AdminApi.updateAdminEmailTemplate(api, emailTemplateIdentifier, emailTemplate).then(
      (response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          NOTIFICATIONS.showNotification(
            "success",
            FDN.I18n.t("adminEmailTemplates.onSave.success.title"),
            FDN.I18n.t("adminEmailTemplates.onSave.success.text")
          );

          NOTIFICATIONS.hideSaving();
        }
      }
    );
  };

  const actions: TActions = {
    onEdit,
    onCancel,
    onSave,
  };

  return { actions, emailTemplate, placeholders, editMode };
};

export default useServiceAdminEmailTemplate;

import * as React from "react";
import * as FDN from "src/core";

interface IPageContentProps {
  full?: boolean;
  noPadding?: boolean;
  children: React.ReactNode;
}

const PageContent: React.FunctionComponent<IPageContentProps> = (props) => {
  return (
    <main className={`${props.noPadding === true ? `no-padding` : `with-padding`}`}>
      <FDN.Grid full={props.full}>
        <FDN.Row>
          <FDN.Cell sm={24}>{props.children}</FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </main>
  );
};

export default PageContent;

import routes from "src/config/routes.json";

export default class UrlService {
  public static url(routeKey: string, params?: { [key: string]: string }): string {
    let url = this.findEntry(routes, routeKey, 1);
    if (!url) url = "";

    if (params && url) {
      Object.keys(params).map((paramKey) => {
        if (url !== null) url = url.replace(`:${paramKey}`, params[paramKey]);
        return "";
      });
    }

    return url;
  }

  private static findEntry(payload: any, key: string, i: number): string | null {
    const keyParts = key.split(".");
    //console.log("Run " + i, payload, key, keyParts[i - 1]);

    if (i <= keyParts.length) {
      if (payload[keyParts[i - 1]]) return this.findEntry(payload[keyParts[i - 1]], key, i + 1);
    } else return payload;

    return null;
  }
}

import * as React from "react";
import { IAdventure, IPicture } from "src/types";
import { getAdventurePictureUrl } from "src/core/helpers/helpers";
import { Slide } from "react-slideshow-image";

interface IAdventurePictureSliderProps {
  adventure: IAdventure;
}

const AdventurePictureSlider: React.FunctionComponent<IAdventurePictureSliderProps> = ({
  adventure,
}) => {
  const pictures = adventure.pictures as IPicture[];

  return (
    <div className="__public-adventure-picture-slider">
      <Slide duration={0} transitionDuration={500} indicators={true} autoplay={false}>
        {pictures.map((picture, index) => {
          return <SlideWithContent key={index} picture={picture} />;
        })}
      </Slide>
    </div>
  );
};

interface IHeroSlideProps {
  picture: IPicture;
}

const SlideWithContent: React.FunctionComponent<IHeroSlideProps> = ({ picture }) => {
  const styles: React.CSSProperties = {
    backgroundImage: `url("${getAdventurePictureUrl(picture, "L")}")`,
  };

  return (
    <div className="each-slide-effect">
      <div style={styles} className="__public-adventure-picture-slider-slide">
        <div className="__public-adventure-picture-slider-slide-content">
          <div className="__public-adventure-picture-slider-slide-text">&nbsp;</div>
        </div>
      </div>
    </div>
  );
};

export default AdventurePictureSlider;

import * as React from "react";
import { BookingContext } from "src/components/context/BookingContext/BookingContext";
import * as FDN from "src/core";
import BookingPageTitle from "../parts/BookingPageTitle";
import { IOrder, IOrderParticipant, TFunction } from "src/types";
import Config from "src/core/Config";

import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import en from "date-fns/locale/en-US";

registerLocale("en-US", en);

const BookingParticipants: React.FunctionComponent = () => {
  const BOOKING = React.useContext(BookingContext);
  const { order, onEditParticipant, onRemoveParticipant, onAddParticipant } = BOOKING;

  if (!order) return <FDN.Loading box />;

  return (
    <div className="__booking-form-page-participants" id="booking_participants">
      <BookingPageTitle page="participants" order={order} />
      {order.participants.map((participant, index) => {
        return (
          <FDN.Box key={index}>
            <Participant
              index={index}
              participant={participant}
              order={order}
              onEditParticipant={onEditParticipant}
              onRemoveParticipant={onRemoveParticipant}
            />
          </FDN.Box>
        );
      })}
      <div className="__booking-form-participants-add-participant-button">
        <button onClick={onAddParticipant}>
          <FDN.Icon icon="plus-circle" />
        </button>
      </div>
    </div>
  );
};

interface IParticipantProps {
  index: number;
  participant: IOrderParticipant;
  order: IOrder;
  onEditParticipant: TFunction;
  onRemoveParticipant: TFunction;
}

const Participant: React.FunctionComponent<IParticipantProps> = ({
  index,
  participant,
  order,
  onEditParticipant,
  onRemoveParticipant,
}) => {
  const salutations = Config.get("salutations") as string[];

  const BOOKING = React.useContext(BookingContext);
  const { missingFields } = BOOKING;

  return (
    <FDN.Grid full>
      <FDN.Row margin="xy">
        <FDN.Cell sm={24} md={12}>
          <FDN.Input
            type="select"
            editMode={true}
            value={participant.salutation}
            selectValues={salutations}
            isMissing={missingFields?.includes("salutation") && !participant.salutation}
            label={FDN.I18n.t("booking.form.group.participants.salutation.label")}
            onUpdate={(value) => {
              onEditParticipant(index, "salutation", value);
            }}
          />
        </FDN.Cell>
        <FDN.Cell sm={24} md={12}>
          <div className="__ptfdn-input">
            <div
              className={`${
                missingFields?.includes("birthday") && !participant.birthday ? `missing` : ``
              } __ptfdn-input-edit`}
            >
              <DatePicker
                locale="en"
                dateFormat={"dd.MM.yyyy"}
                selected={participant.birthday ? new Date(participant.birthday) : undefined}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown
                dropdownMode="select"
                showWeekNumbers
                isClearable
                todayButton={"Today"}
                minDate={new Date("1900-01-01")}
                maxDate={order.startDate ? new Date(order.startDate) : new Date()}
                onChange={(date) => {
                  onEditParticipant(index, "birthday", date);
                }}
              />
              <div className="__ptfdn-input-label">
                {FDN.I18n.t("booking.form.group.participants.birthday.label")}
              </div>
            </div>
          </div>
        </FDN.Cell>
      </FDN.Row>
      <FDN.Row margin="xy">
        <FDN.Cell sm={24} md={12}>
          <FDN.Input
            type="text"
            editMode={true}
            value={participant.firstname}
            isMissing={missingFields?.includes("firstname") && !participant.firstname}
            label={FDN.I18n.t("booking.form.group.participants.firstname.label")}
            onUpdate={(value) => {
              onEditParticipant(index, "firstname", value);
            }}
          />
        </FDN.Cell>
        <FDN.Cell sm={24} md={12}>
          <FDN.Input
            type="text"
            editMode={true}
            value={participant.lastname}
            isMissing={missingFields?.includes("lastname") && !participant.lastname}
            label={FDN.I18n.t("booking.form.group.participants.lastname.label")}
            onUpdate={(value) => {
              onEditParticipant(index, "lastname", value);
            }}
          />
        </FDN.Cell>
      </FDN.Row>
      {index !== 0 && (
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} className="text-center">
            <button
              className="__booking-form-participants-remove-participant"
              onClick={() => onRemoveParticipant(index)}
            >
              <FDN.Icon icon="times" left />
              {FDN.I18n.t("booking.form.group.participants.buttons.remove.label")}
            </button>
          </FDN.Cell>
        </FDN.Row>
      )}
    </FDN.Grid>
  );
};

export default BookingParticipants;

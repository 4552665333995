import * as React from "react";
import * as FDN from "src/core";

interface IPublicSectionProps {
  noPadding?: boolean;
  children: React.ReactNode;
}

const PublicSection: React.FunctionComponent<IPublicSectionProps> = ({ noPadding, children }) => {
  return (
    <section className={`__public-section ${noPadding === true ? `no-padding` : ``}`}>
      <FDN.SingleRowCell>{children}</FDN.SingleRowCell>
    </section>
  );
};

export default PublicSection;

import React from "react";
import * as FDN from "src/core";
import { EmailTemplatesState, TActions } from "src/types";
import useServiceCore from "../CoreService";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";

const useServiceAdminEmailTemplates = () => {
  const { APP, api } = useServiceCore();

  const [emailTemplates, setEmailTemplates] = React.useState<EmailTemplatesState | null>(null);

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    AdminApi.adminEmailTemplates(api).then((response) => {
      APP.setPageTitle(FDN.I18n.t("adminEmailTemplates.pageTitle"));
      if (response?.statusCode === StatusCode.SUCCESS) {
        setEmailTemplates(response?.body?.emailTemplates as EmailTemplatesState);
      }
    });
  };

  const actions: TActions = {};

  return { actions, emailTemplates };
};

export default useServiceAdminEmailTemplates;

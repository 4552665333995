import * as React from "react";
import { AppContext } from "src/components/context/AppContext/AppContext";
import WhatsappButton from "src/components/main/WhatsappButton";
import * as FDN from "src/core";
import { userHasPermission } from "src/core/AdminService/helpers";
import { TActionsAdminOrder } from "src/services/admin/OrderService";
import { IDrivers, IOrder } from "src/types";

interface IOrderDriverProps {
  order: IOrder;
  drivers: IDrivers;
  actions: TActionsAdminOrder;
}

const OrderDriver: React.FunctionComponent<IOrderDriverProps> = ({ order, drivers, actions }) => {
  const APP = React.useContext(AppContext);
  const user = APP.getUser();

  return (
    <div className="admin-order-driver-select">
      <SelectedDriverInfo order={order} drivers={drivers} actions={actions} />
      {userHasPermission(user, "adminOrdersDriver") ? (
        <SelectedDriverSelect order={order} drivers={drivers} actions={actions} />
      ) : null}
    </div>
  );
};

const SelectedDriverSelect: React.FunctionComponent<IOrderDriverProps> = ({
  order,
  drivers,
  actions,
}) => {
  const selectValues: { [key: string]: string } = {
    none: `-- ${FDN.I18n.t("adminOrders.driver.select.select.none")} --`,
  };

  for (const driver of drivers) {
    selectValues[
      driver.identifier
    ] = `${driver.displayname} | ${driver.whatsapp} | ${driver.country}`;
  }

  return (
    <div className="admin-order-driver-select-select">
      <FDN.Box>
        <h3 className="text-center">{FDN.I18n.t("adminOrders.driver.select.title")}</h3>
        {order.selectedDriverIdentifier === "_saving" ? (
          <div className="admin-order-driver-select-select-saving">
            <FDN.Icon icon="spin fa-circle-o-notch" />
          </div>
        ) : (
          <FDN.Input
            type="select"
            selectValues={selectValues}
            value={order.selectedDriverIdentifier || "none"}
            label={FDN.I18n.t("adminOrders.driver.select.select.label")}
            editMode={true}
            onUpdate={(value) => {
              if (value === "none") actions.onSelectDriver(undefined);
              else actions.onSelectDriver(value);
            }}
          />
        )}
      </FDN.Box>
    </div>
  );
};

const SelectedDriverInfo: React.FunctionComponent<IOrderDriverProps> = ({ order, drivers }) => {
  const driver = order.selectedDriverIdentifier
    ? drivers.find((d) => d.identifier === order.selectedDriverIdentifier)
    : undefined;

  return (
    <div className="admin-order-driver-select-info">
      <FDN.Box>
        <h3>{FDN.I18n.t("adminOrders.driver.info.title")}</h3>
        {!driver ? (
          <p>
            <em>{FDN.I18n.t("adminOrders.driver.noSelectedDriver")}</em>
          </p>
        ) : (
          <div>
            <div className="admin-order-driver-select-info-name">{driver.displayname}</div>
            {driver.whatsapp ? (
              <div>
                <div>{driver.whatsapp}</div>
                <div className="admin-order-driver-select-info-whatsapp">
                  <WhatsappButton number={driver.whatsapp} />
                </div>
              </div>
            ) : null}
          </div>
        )}
      </FDN.Box>
    </div>
  );
};

export default OrderDriver;

import * as React from "react";
import * as FDN from "src/core";
import { FileUploadFile } from "../../ImageUpload/types";
import { TActionsFileUpload } from "../FileUploadService";
import { IFile } from "src/types";

interface IFilesListProps {
  waitingFiles: FileUploadFile[];
  uploadedFiles: IFile[];
  uploadProgresses: { [key: string]: number };
  actions: TActionsFileUpload;
}

const FilesList: React.FunctionComponent<IFilesListProps> = ({
  waitingFiles,
  uploadedFiles,
  uploadProgresses,
  actions,
}) => {
  return (
    <div className="__fileupload-filesllist">
      <ul>
        {uploadedFiles.map((uploadedFile, index) => {
          return (
            <li key={index}>
              <UploadedFile file={uploadedFile} actions={actions} />
            </li>
          );
        })}
      </ul>
      <ul>
        {waitingFiles.map((waitingFile, index) => {
          if (uploadedFiles.find((u) => u.uploadUuid === waitingFile.uuid)) return null;
          return (
            <li key={index}>
              <WaitingFile
                file={waitingFile}
                index={index}
                uploadProgresses={uploadProgresses}
                actions={actions}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

interface IWaitingFileProps {
  file: FileUploadFile;
  index: number;
  uploadProgresses: { [key: string]: number };
  actions: TActionsFileUpload;
}

const WaitingFile: React.FunctionComponent<IWaitingFileProps> = ({
  file,
  index,
  uploadProgresses,
  actions,
}) => {
  const uploadProgress = uploadProgresses[index] || 0;

  return (
    <>
      <span className="__fileupload-filesllist-upload-item">
        <span
          className="__fileupload-filesllist-upload-progress"
          style={{ width: uploadProgress }}
        ></span>
        <span className="__fileupload-filesllist-upload-text">
          <FDN.Icon icon="file-o" left /> {file.name}
        </span>
      </span>
      {uploadProgress === 0 ? (
        <button
          className="__fileupload-filesllist-remove"
          onClick={() => actions.removeFile("waiting", file.uuid)}
        >
          <FDN.Icon icon="times" />
        </button>
      ) : null}
    </>
  );
};

interface IUploadedFileProps {
  file: IFile;
  actions: TActionsFileUpload;
}

const UploadedFile: React.FunctionComponent<IUploadedFileProps> = ({ file, actions }) => {
  return (
    <>
      <span className="__fileupload-filesllist-upload-item">
        <span className="__fileupload-filesllist-upload-text success-text">
          <FDN.Icon icon="check" left /> {file.filename}
        </span>
      </span>

      <button
        className="__fileupload-filesllist-remove"
        onClick={() => actions.removeFile("uploaded", file.identifier)}
      >
        <FDN.Icon icon="times" />
      </button>
    </>
  );
};

export default FilesList;

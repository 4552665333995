import * as React from "react";
import * as FDN from "src/core";

interface IHotelStarsProps {
  stars: number;
}

const HotelStars: React.FunctionComponent<IHotelStarsProps> = ({ stars }) => {
  if (!stars) return null;

  let starsCount = stars / 10;
  let hasHalfStar = false;
  if (Math.floor(starsCount) !== starsCount) {
    starsCount -= 0.5;
    hasHalfStar = true;
  }

  return (
    <span className="hotel-stars">
      {Array(starsCount)
        .fill(0)
        .map((v, index) => (
          <FDN.Icon key={index} icon="star" />
        ))}
      {hasHalfStar && <FDN.Icon icon="star-half" />}
    </span>
  );
};

export default HotelStars;

import * as React from "react";
import { Link } from "react-router-dom";
import * as FDN from "src/core";
import UrlService from "src/core/UrlService";
import useServiceSignup from "src/services/auth/SignupService";
import { ISignupInputValues, TActions } from "src/types";

const SignupPage: React.FunctionComponent = () => {
  const {
    actions,
    logoLoginUrl,
    settingSignupLastname,
    signupEnabled,
    errorEmpty,
    errorInvalidEmail,
    signupSuccess,
    inputValues,
  } = useServiceSignup();

  const formErrors = {
    "login.emptyInput": errorEmpty,
    "login.invalidEmail": errorInvalidEmail,
  };

  if (!signupEnabled) return <FDN.Loading />;

  return (
    <FDN.LayoutWithoutTopbar page="signup">
      <FDN.Grid>
        <FDN.Row margin="xy">
          <FDN.Cell md={12} mdo={6}>
            {logoLoginUrl && (
              <div className="__login-logo">
                <Link to="/">
                  <img src={logoLoginUrl} alt="" />
                </Link>
              </div>
            )}
            <h1>{FDN.I18n.t("signup.title")}</h1>
            <div className="__login-intro lead">{FDN.I18n.t("signup.intro")}</div>
            <div className="__login-with-signup">
              <div className="callout">
                <div
                  dangerouslySetInnerHTML={{
                    __html: FDN.I18n.t("signup.introWithLogin", {
                      link: `<a href="${UrlService.url("auth.login")}">${FDN.I18n.t(
                        "signup.introWithLoginLinkText"
                      )}</a>`,
                    }),
                  }}
                />
              </div>
            </div>
            {signupSuccess === true ? (
              <div className="success callout">
                <div>
                  <strong>{FDN.I18n.t("signup.form.signupSuccess.title")}</strong>
                </div>
                <div>{FDN.I18n.t("signup.form.signupSuccess.text")}</div>
              </div>
            ) : (
              <>
                <FDN.FormErrors errors={formErrors} />
                <SignupBox
                  inputValues={inputValues}
                  settingSignupLastname={settingSignupLastname}
                  actions={actions}
                />
              </>
            )}
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </FDN.LayoutWithoutTopbar>
  );
};

interface ISignupBoxProps {
  inputValues: ISignupInputValues;
  settingSignupLastname: string;
  actions: TActions;
}

const SignupBox = ({ inputValues, settingSignupLastname, actions }: ISignupBoxProps) => {
  return (
    <div className="__login-box">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={24}>
            <FDN.Input
              type="email"
              editMode={true}
              required={true}
              value={inputValues.email}
              label={FDN.I18n.t("signup.form.email.label")}
              placeholder={FDN.I18n.t("signup.form.email.placeholder")}
              onUpdate={(value) => actions.onInput("email", value)}
              onKeyUp={actions.attemptLogin}
            />
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell md={24}>
            <FDN.Input
              type="text"
              editMode={true}
              required={true}
              value={inputValues.firstname}
              label={FDN.I18n.t("signup.form.firstname.label")}
              placeholder={FDN.I18n.t("signup.form.firstname.placeholder")}
              onUpdate={(value) => actions.onInput("firstname", value)}
              onKeyUp={actions.attemptLogin}
            />
          </FDN.Cell>
        </FDN.Row>
        {["show", "required"].includes(settingSignupLastname) && (
          <FDN.Row margin="xy">
            <FDN.Cell md={24}>
              <FDN.Input
                type="text"
                editMode={true}
                required={settingSignupLastname === "required"}
                value={inputValues.lastname}
                label={FDN.I18n.t("signup.form.lastname.label")}
                placeholder={FDN.I18n.t("signup.form.lastname.placeholder")}
                onUpdate={(value) => actions.onInput("lastname", value)}
                onKeyUp={actions.attemptLogin}
              />
            </FDN.Cell>
          </FDN.Row>
        )}
      </FDN.Grid>
      <FDN.FormButtons
        saveLabel={FDN.I18n.t("signup.form.signupButton.label")}
        onSave={actions.attemptSignup}
      />
    </div>
  );
};

export default SignupPage;

import * as React from "react";
import { Link } from "react-router-dom";
import { AppContext } from "src/components/context/AppContext/AppContext";
import UrlService from "src/core/UrlService";
import UserButton from "./UserButton";
import LanguageSwitchButton from "./LanguageSwitchButton";
import NotificationsButton from "./NotificationsButton";
import * as FDN from "src/core";
import SupportButton from "./SupportButton";

const Topbar: React.FunctionComponent = () => {
  const APP = React.useContext(AppContext);

  return (
    <div className="__topbar">
      <div className="__topbar-content">
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={12} md={6} lg={6}>
              <div className="__topbar-logo">
                <Logo imageUrl={APP.getSetting("logoUrl") as string} />
              </div>
            </FDN.Cell>
            <FDN.Cell sm={12} md={18} lg={18}>
              <div className="__topbar-right-side h-full">
                <div className="hidden md:flex h-full items-center mr-2">
                  <Link
                    to={UrlService.url("aboutus")}
                    className="px-3 py-2 font-semibold hover:bg-brightPurple hover:text-white"
                  >
                    About us
                  </Link>
                </div>

                <SupportButton />
                <NotificationsButton />
                <LanguageSwitchButton />
                <UserButton />
              </div>
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </div>
    </div>
  );
};

interface ILogoProps {
  imageUrl?: string;
}

const Logo: React.FunctionComponent<ILogoProps> = ({ imageUrl }) => {
  if (!imageUrl) return <>[LOGO]</>;
  return (
    <Link to={UrlService.url("index")}>
      <img src={imageUrl} alt="" />
    </Link>
  );
};

export default Topbar;

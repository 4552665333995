import * as React from "react";
import * as FDN from "src/core";
import {
  IAdminListBodyRow,
  IAdminListColumn,
  IAdminListSchema,
} from "src/components/main/AdminList/types";
import { TActions, IUserrolesState } from "src/types";
import { cloneDeep } from "lodash";
import { userRolesRoleStatus } from "src/core/AdminService/helpers";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: {
      md: 12,
    },
    content: FDN.I18n.t("adminUserroles.list.head.name.title"),
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="unlock-alt" />,
    tooltip: FDN.I18n.t("adminUserroles.list.head.access.title"),
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="sliders" />,
    tooltip: FDN.I18n.t("adminUserroles.list.head.admin.title"),
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="th-large" />,
    tooltip: FDN.I18n.t("adminUserroles.list.head.dashboard.title"),
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="user" />,
    tooltip: FDN.I18n.t("adminUserroles.list.head.accounts.title"),
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="users" />,
    tooltip: FDN.I18n.t("adminUserroles.list.head.userroles.title"),
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="cogs" />,
    tooltip: FDN.I18n.t("adminUserroles.list.head.settings.title"),
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="envelope" />,
    tooltip: FDN.I18n.t("adminUserroles.list.head.emailtemplates.title"),
  },
];

const listSchema: IAdminListSchema = {
  rowMargin: undefined,
  head: { columns: listColumnsSchema },
  body: { rows: [] },
};

interface IBuildAdminList {
  userroles: IUserrolesState;
  actions: TActions;
  editable?: boolean;
  selectable?: boolean;
  selected?: string[];
  showMembersCount?: boolean;
}

const buildAdminList = ({
  userroles,
  actions,
  editable,
  selectable,
  selected,
  showMembersCount,
}: IBuildAdminList) => {
  if (!userroles) return;
  const language = FDN.I18n.getLanguage();

  const adminListSchema = cloneDeep(listSchema);

  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const userrole of userroles) {
    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    if (selectable === true && selected) {
      if (selected.includes(userrole.internalKey) || selected.includes(userrole.identifier)) {
        row.className = `${row.className} selected`;
      } else {
        row.className = `${row.className} unselected`;
      }
    }

    if (editable === true && !selectable)
      row.onClick = () => {
        actions.selectUserrole(userrole);
      };
    else if (editable === true && selectable === true)
      row.onClick = () => {
        actions.selectUserrole(userrole.internalKey || userrole.identifier);
      };

    row.columns[0].content = (
      <>
        {selectable === true && selected ? (
          <>
            <span className="selected-icon">
              <FDN.Icon icon="check" left />
            </span>
            <span className="unselected-icon">
              <FDN.Icon icon="minus" left />
            </span>
          </>
        ) : null}
        {userrole.name[language] || ""}
        {showMembersCount === true && userrole.members ? (
          <div className="__adminlist-subtitle">
            {FDN.I18n.t("adminUserroles.list.membersCount.text", {
              members: `${userrole.members.length} ${FDN.I18n.t(
                `adminUserroles.list.membersCount.${
                  userrole.members.length === 1 ? `member` : `members`
                }`
              )}`,
            })}
          </div>
        ) : null}
      </>
    );
    row.columns[1].content = (
      <div className="center-vertically-horizontally">
        <FDN.StatusIndicator type="trafficlight" status={userRolesRoleStatus(userrole, "access")} />
      </div>
    );
    row.columns[2].content = (
      <div className="center-vertically-horizontally">
        <FDN.StatusIndicator type="trafficlight" status={userRolesRoleStatus(userrole, "admin")} />
      </div>
    );
    row.columns[3].content = (
      <div className="center-vertically-horizontally">
        <FDN.StatusIndicator
          type="trafficlight"
          status={userRolesRoleStatus(userrole, "dashboard")}
        />
      </div>
    );
    row.columns[4].content = (
      <div className="center-vertically-horizontally">
        <FDN.StatusIndicator
          type="trafficlight"
          status={userRolesRoleStatus(userrole, "accounts")}
        />
      </div>
    );
    row.columns[5].content = (
      <div className="center-vertically-horizontally">
        <FDN.StatusIndicator
          type="trafficlight"
          status={userRolesRoleStatus(userrole, "userroles")}
        />
      </div>
    );
    row.columns[6].content = (
      <div className="center-vertically-horizontally">
        <FDN.StatusIndicator
          type="trafficlight"
          status={userRolesRoleStatus(userrole, "settings")}
        />
      </div>
    );
    row.columns[7].content = (
      <div className="center-vertically-horizontally">
        <FDN.StatusIndicator
          type="trafficlight"
          status={userRolesRoleStatus(userrole, "emailtemplates")}
        />
      </div>
    );

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface IUserRolesListProps {
  editable: boolean;
  selectable?: boolean;
  selected?: string[];
  userroles?: IUserrolesState;
  hidePagination?: boolean;
  showMembersCount?: boolean;
  actions?: TActions;
}

const UserRolesList: React.FunctionComponent<IUserRolesListProps> = ({
  editable,
  selectable,
  selected,
  userroles,
  hidePagination,
  showMembersCount,
  actions,
}) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  React.useEffect(() => {
    if (!userroles || !actions) return;

    setAdminListSchema(
      buildAdminList({ userroles, actions, editable, selectable, selected, showMembersCount })
    );
    setListLoading(false);
  }, [userroles, selected]);

  if (!userroles || !actions) return <FDN.Loading box />;

  return (
    <>
      {adminListSchema && (
        <FDN.AdminList
          schema={adminListSchema}
          loading={listLoading}
          hidePagination={hidePagination}
        />
      )}
    </>
  );
};

export default UserRolesList;

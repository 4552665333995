import * as React from "react";
import CookieBanner from "../dataprivacy/CookieBanner";
import Config from "src/core/Config";
import LocalStorageService from "src/core/LocalStorageService";
import GA4React from "ga-4-react";
import MaintenanceMode from "./MaintenanceMode";
import { AppContext } from "../context/AppContext/AppContext";

interface IMasterProps {
  page: string;
  children?: any;
}

const Master: React.FunctionComponent<IMasterProps> = (props) => {
  const { user } = React.useContext(AppContext);

  React.useEffect(() => {
    let dataprivacyStatsAllowed = false;

    try {
      const savedCookieSettingsStr = LocalStorageService.get("COOKIEBANNER_SETTINGS");
      if (savedCookieSettingsStr) {
        const savedCookieSettings = JSON.parse(savedCookieSettingsStr);

        if (savedCookieSettings && savedCookieSettings.stats && savedCookieSettings.stats === true) {
          dataprivacyStatsAllowed = true;
        }
      }
    } catch {
      //
    }

    if (!Config.isDebug() && dataprivacyStatsAllowed === true) {
      const ga4id = Config.get("analytics.ga4id") as string;

      //Logger.info("ANALYTICS | Trying to initialize Google Analytics...", ga4id);

      const ga4react = new GA4React(ga4id);
      ga4react.initialize().then(
        (ga4) => {
          ga4.pageview("path");
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }, []);

  return (
    <div className={`__page-${props.page}`}>
      {(Config.get("maintenanceEnabled") as boolean) === true &&
      user?.identifier !== "9dw7fzqh" &&
      user?.identifier !== "f22x7hwd" ? (
        <MaintenanceMode />
      ) : (
        <>{props.children}</>
      )}
      <CookieBanner />
    </div>
  );
};

export default Master;

import * as React from "react";
import { BookingContext } from "src/components/context/BookingContext/BookingContext";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { IAdventure, IOrder, IOrderCalculation } from "src/types";

interface IBookingPaymentPlanSummaryMobileProps {
  order: IOrder;
  calculation: IOrderCalculation;
  adventure: IAdventure;
}

const BookingPaymentPlanSummaryMobile: React.FunctionComponent<
  IBookingPaymentPlanSummaryMobileProps
> = ({ order, calculation, adventure }) => {
  const BOOKING = React.useContext(BookingContext);

  const childMaxAge = order.adventureChildrenMaxAge || 0;

  if (!order) return <>ERROR</>;

  return (
    <div className="__booking-form-paymentplan-summary summary-mobile">
      <FDN.Grid full>
        <FDN.Row>
          <FDN.Cell sm={24} md={24}>
            {order.participants.length === 1 &&
            (!order.participants[0].firstname ||
              !order.participants[0].lastname ||
              !order.participants[0].birthday) ? (
              <div className="text-center">
                {FDN.I18n.t("booking.form.group.paymentplan.summary.noParticipants")}
              </div>
            ) : (
              <>
                <SummaryLine
                  type="title"
                  valueLabel1={FDN.I18n.t(`booking.form.group.paymentplan.summary.paynow`)}
                  valueLabel2={
                    order.paymentPlan == "full"
                      ? undefined
                      : FDN.I18n.t(`booking.form.group.paymentplan.summary.paylater`)
                  }
                />

                {order.participants.map((participant, index) => {
                  const label = (
                    <>
                      {`${participant.salutation} ${participant.firstname || ""} ${
                        participant.lastname || ""
                      }`}{" "}
                      {BOOKING.isParticipantChild(participant) ? (
                        <span className="__booking-form-paymentplan-summary-line-is-child">
                          (&lt; {childMaxAge} y.)
                        </span>
                      ) : (
                        <>
                          {order.participants.length === 1 ? (
                            <span className="__booking-form-paymentplan-summary-line-is-child">
                              ({FDN.I18n.t("booking.form.group.paymentplan.summary.singleTraveler")}
                              )
                            </span>
                          ) : null}
                        </>
                      )}
                    </>
                  );
                  return (
                    <SummaryLine
                      type="participant"
                      key={index}
                      label={label}
                      value1={
                        order.paymentPlan == "full" ? participant.priceFull : participant.priceNow
                      }
                      value2={order.paymentPlan == "full" ? undefined : participant.priceLater}
                      valueLabel1={FDN.I18n.t(`booking.form.group.paymentplan.summary.paynow`)}
                      valueLabel2={FDN.I18n.t(`booking.form.group.paymentplan.summary.paylater`)}
                    />
                  );
                })}
                {calculation.hotelNow || calculation.hotelLater ? (
                  <div className="__booking-form-paymentplan-summary-line-hotel">
                    <SummaryLine
                      type="hotel"
                      label={`${order.participants.length} x ${FDN.I18n.t(
                        "booking.form.group.paymentplan.summary.hotel"
                      )}`}
                      value1={order.paymentPlan == "full" ? calculation.hotelNow : undefined}
                      value2={order.paymentPlan == "full" ? undefined : calculation.hotelLater}
                      valueLabel1={FDN.I18n.t(`booking.form.group.paymentplan.summary.paynow`)}
                      valueLabel2={FDN.I18n.t(`booking.form.group.paymentplan.summary.paylater`)}
                    />
                  </div>
                ) : null}
                {calculation.upgradesNow || calculation.upgradesLater ? (
                  <div className="__booking-form-paymentplan-summary-line-upgrades">
                    {order.selectedUpgradePrices?.map((upgradePriceIdentifier) => {
                      if (!adventure.upgradePrices || !adventure.upgrades) return null;

                      const upgradePrice = adventure.upgradePrices.find(
                        (up) => up.identifier === upgradePriceIdentifier
                      );
                      if (!upgradePrice) return null;

                      const upgrade = adventure.upgrades.find(
                        (u) => u.identifier === upgradePrice.upgradeIdentifier
                      );
                      if (!upgrade) return null;

                      return (
                        <SummaryLine
                          key={upgradePriceIdentifier}
                          type="upgrades"
                          label={`${order.participants.length} x ${upgrade.name}`}
                          value1={
                            order.paymentPlan == "full"
                              ? order.participants.length === 1 && upgradePrice.priceSingleFull
                                ? upgradePrice.priceSingleFull
                                : order.participants.length * (upgradePrice.priceFull || 0)
                              : undefined
                          }
                          value2={
                            order.paymentPlan == "full"
                              ? undefined
                              : order.participants.length === 1 && upgradePrice.priceSingleFull
                              ? upgradePrice.priceSingleFull
                              : order.participants.length * (upgradePrice.priceFull || 0)
                          }
                          valueLabel1={FDN.I18n.t(`booking.form.group.paymentplan.summary.paynow`)}
                          valueLabel2={FDN.I18n.t(
                            `booking.form.group.paymentplan.summary.paylater`
                          )}
                        />
                      );
                    })}
                  </div>
                ) : null}
                <div className="__booking-form-paymentplan-summary-line-total">
                  {order.paymentPlan === "full" && (
                    <>
                      <SummaryLine
                        type="total"
                        label={FDN.I18n.t("booking.form.group.paymentplan.summary.total")}
                        value1={calculation.total}
                      />
                    </>
                  )}
                  {order.paymentPlan === "downpayment" && (
                    <>
                      <SummaryLine
                        type="paynow"
                        label={FDN.I18n.t("booking.form.group.paymentplan.summary.paynow")}
                        value1={calculation.now}
                      />
                      <SummaryLine
                        type="paylater"
                        label={FDN.I18n.t("booking.form.group.paymentplan.summary.paylater")}
                        value1={calculation.later}
                      />
                      <SummaryLine
                        type="total"
                        label={FDN.I18n.t("booking.form.group.paymentplan.summary.total")}
                        value1={calculation.total}
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

interface ISummaryLineProps {
  type: "title" | "participant" | "hotel" | "upgrades" | "total" | "paynow" | "paylater";
  label?: string | React.ReactNode;
  value1?: number | null;
  value2?: number | null;
  valueLabel1?: string;
  valueLabel2?: string;
}

const SummaryLine: React.FunctionComponent<ISummaryLineProps> = ({
  type,
  label,
  value1,
  value2,
  valueLabel1,
  valueLabel2,
}) => {
  const currency = Config.get("currency") as string;

  // if (type === "title")
  //   return (
  //     <div className={`__booking-form-paymentplan-summary-line type-${type}`}>
  //       <div className="__booking-form-paymentplan-summary-line-label">{label}</div>
  //       <div className="__booking-form-paymentplan-summary-line-valuelabel1">{valueLabel1} </div>
  //       {valueLabel2 ? (
  //         <div className="__booking-form-paymentplan-summary-line-valuelabel2">{valueLabel2} </div>
  //       ) : null}
  //     </div>
  //   );

  return (
    <div className={`__booking-form-paymentplan-summary-line type-${type}`}>
      <div className="__booking-form-paymentplan-summary-line-label">{label}</div>
      <div className="__booking-form-paymentplan-summary-line-value1">
        <>
          {value1 !== undefined ? (
            <>
              {value1 ? (
                <>
                  <span className={`__booking-form-mobile-label`}>{valueLabel1}</span>
                  <span className={`__booking-form-currency`}>
                    {currency}
                    {FDN.formatNumber(value1, 2, 2)}
                  </span>
                </>
              ) : (
                <>&nbsp;</>
              )}
            </>
          ) : null}
        </>
      </div>
      {value2 !== undefined ? (
        <div className="__booking-form-paymentplan-summary-line-value2">
          {value2 ? (
            <>
              <span className={`__booking-form-mobile-label`}>{valueLabel2}</span>
              <span className={`__booking-form-currency`}>
                {currency}
                {FDN.formatNumber(value2, 2, 2)}
              </span>
            </>
          ) : (
            <>&nbsp;</>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default BookingPaymentPlanSummaryMobile;

import * as React from "react";
import * as FDN from "src/core";
import useServiceFileUpload from "./FileUploadService";
import { AllowedFileTypes, FOnUploadCompleted } from "./types";
import DropArea from "./parts/DropArea";
import FilesList from "./parts/FilesList";
import { IFile } from "src/types";

interface IFileUploadProps {
  uploadType?: string;
  maxFiles: number;
  allowedFileTypes: AllowedFileTypes[];
  alreadyUploadedFiles?: IFile[];
  onUploadCompleted: FOnUploadCompleted;
}

const FileUpload: React.FunctionComponent<IFileUploadProps> = ({
  uploadType,
  maxFiles,
  allowedFileTypes,
  alreadyUploadedFiles,
  onUploadCompleted,
}) => {
  const { waitingFiles, uploadedFiles, uploadProgresses, actions } = useServiceFileUpload(
    onUploadCompleted,
    maxFiles,
    allowedFileTypes,
    uploadType,
    alreadyUploadedFiles
  );

  const fileUploadType = maxFiles === 1 ? "single" : "multi";

  return (
    <div className="__fileupload">
      {(!waitingFiles || waitingFiles.length === 0) &&
      (!uploadedFiles || uploadedFiles.length === 0) ? (
        <>
          <p>
            <em>{FDN.I18n.t("fileupload.noFilesSelected")}</em>
          </p>
          <div className="__fileupload-firstAdd-button">
            <DropArea maxFiles={maxFiles} allowedFileTypes={allowedFileTypes} actions={actions}>
              <button className="small primary hollow button">
                <FDN.Icon icon="plus" left />{" "}
                {FDN.I18n.t(`fileupload.buttons.firstAdd.${fileUploadType}.label`)}
              </button>
            </DropArea>
          </div>
        </>
      ) : (
        <>
          <FilesList
            waitingFiles={waitingFiles}
            uploadedFiles={uploadedFiles}
            uploadProgresses={uploadProgresses}
            actions={actions}
          />
          <div className="__fileupload-add-button">
            <DropArea maxFiles={maxFiles} allowedFileTypes={allowedFileTypes} actions={actions}>
              <button className="small primary hollow button">
                <FDN.Icon icon="plus" left />{" "}
                {FDN.I18n.t(`fileupload.buttons.add.${fileUploadType}.label`)}
              </button>
            </DropArea>{" "}
            <button className="small primary button" onClick={actions.uploadFiles}>
              <FDN.Icon icon="upload" left /> {FDN.I18n.t("fileupload.buttons.upload.label")}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default FileUpload;

import localstorage from "src/config/localstorage.json";

interface ILocalStorageKeys {
  [key: string]: string;
}

export default class LocalStorageService {
  public static get(key: string): string | null {
    const localstorageKey = (localstorage as ILocalStorageKeys)[key];
    return window.localStorage.getItem(localstorageKey);
  }

  public static set(key: string, value: any): void {
    const localstorageKey = (localstorage as ILocalStorageKeys)[key];
    window.localStorage.setItem(localstorageKey, this.prepareForSaving(value));
  }

  public static remove(key: string): void {
    const localstorageKey = (localstorage as ILocalStorageKeys)[key];
    window.localStorage.removeItem(localstorageKey);
  }

  private static prepareForSaving(value: any): string {
    if (typeof value === "object") return JSON.stringify(value);
    if (typeof value === "number") return value.toString();
    return value as string;
  }
}

import * as React from "react";
import * as FDN from "src/core";
import Api from "src/api/Api";
import { AppContext } from "src/components/context/AppContext/AppContext";
import { NotificationsContext } from "src/components/context/NotificationsContext/NotificationsContext";
import FeaturedHero from "./parts/FeaturedHero";
import { IAdventures, IAdventureSearch } from "src/types";
import AdventuresList from "./parts/AdventuresList";
import PublicSection from "./parts/PublicSection";
import { urlForTailorMadeInquiry } from "../content/aboutus/AboutUsPage";

const defaultSearch: IAdventureSearch = {
  country: null,
  region: null,
  sortBy: "newest_desc",
};

const IndexPage: React.FunctionComponent = () => {
  const APP = React.useContext(AppContext);
  const NOTIFICATIONS = React.useContext(NotificationsContext);

  const [pageLoaded, setPageLoaded] = React.useState(false);
  const [adventures, setAdventures] = React.useState<IAdventures>();
  const [featured, setFeatured] = React.useState<IAdventures>();

  const [search, setSearch] = React.useState(defaultSearch);

  React.useEffect(() => {
    const api = new Api(APP, NOTIFICATIONS);
    api.get("public.index").then((response) => {
      setFeatured(response?.body?.featured as IAdventures);
      setAdventures(response?.body?.adventures as IAdventures);
      setPageLoaded(true);
    });
  }, []);

  if (!pageLoaded) return <FDN.Loading />;

  return (
    <FDN.LayoutWithTopbar page="index">
      <FDN.PageContent full noPadding>
        {featured && <FeaturedHero adventures={featured} />}

        {adventures && (
          <PublicSection noPadding>
            <div className="py-10">
              <div className="w-full md:w-4/5 mx-auto">
                <h2>{FDN.I18n.t("publicIndex.list.title")}</h2>
                <div className="text-lg" dangerouslySetInnerHTML={{ __html: FDN.I18n.t("publicIndex.list.intro") }} />
              </div>
              <div className="flex flex-col md:flex-row justify-between gap-6 pt-5 pb-6 px-4 border-t border-b border-gray-300">
                <div className="flex-1">
                  <h3>{FDN.I18n.t("publicIndex.list.adventures.title")}</h3>
                  <div className="text-left leading-6">{FDN.I18n.t("publicIndex.list.adventures.text")}</div>
                  <div className="text-center pt-4">
                    <button
                      className="small primary button"
                      onClick={() => {
                        setSearch({ ...search, type: "adventure" });
                        document
                          .getElementById("adventuresSearch")
                          ?.scrollIntoView({ behavior: "smooth", block: "start" });
                      }}
                    >
                      {FDN.I18n.t("publicIndex.list.adventures.button.label")}
                    </button>
                  </div>
                </div>
                <div className="flex-1">
                  <h3>{FDN.I18n.t("publicIndex.list.vacations.title")}</h3>
                  <div className="text-left leading-6">{FDN.I18n.t("publicIndex.list.vacations.text")}</div>
                  <div className="text-center pt-4">
                    <button
                      className="small primary button"
                      onClick={() => {
                        setSearch({ ...search, type: "vacation" });
                        document
                          .getElementById("adventuresSearch")
                          ?.scrollIntoView({ behavior: "smooth", block: "start" });
                      }}
                    >
                      {FDN.I18n.t("publicIndex.list.vacations.button.label")}
                    </button>
                  </div>
                </div>
                <div className="flex-1">
                  <h3>{FDN.I18n.t("publicIndex.list.tailormade.title")}</h3>
                  <div className="text-left leading-6">{FDN.I18n.t("publicIndex.list.tailormade.text")}</div>
                  <div className="text-center pt-4">
                    <a href={urlForTailorMadeInquiry} className="small primary button">
                      {FDN.I18n.t("publicIndex.list.tailormade.button.label")}
                    </a>
                  </div>
                </div>
              </div>
              <div id="adventuresSearch" />
              <AdventuresList adventures={adventures} defaultSearch={search} />
            </div>
          </PublicSection>
        )}
      </FDN.PageContent>
    </FDN.LayoutWithTopbar>
  );
};

export default IndexPage;

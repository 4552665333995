import * as React from "react";
import { useParams } from "react-router-dom";
import AdventuresApi from "src/api/AdventuresApi";
import StatusCode from "src/config/statuscodes";
import * as FDN from "src/core";
import useServiceCore from "src/services/CoreService";
import { IAdventure, IOrder, IOrderParticipant } from "src/types";
import AdventureHeader from "./parts/AdventureHeader";
import AdventuresLayout from "./AdventuresLayout";
import AdventureBody from "./parts/AdventureBody";
import AdventureBooking from "./booking/AdventureBooking";
import AdventurePictureSlider from "./parts/AdventurePictureSlider";
import UrlService from "src/core/UrlService";

// const showBannerForRegions = [
//   "ggrcnczq", // DEV
//   "pvh7ptkw", // PROD
// ];

const showBannerForRegions: string[] = [];

const Adventure: React.FunctionComponent = () => {
  const [adventure, setAdventure] = React.useState<IAdventure>();

  const { BOOKING, APP, api } = useServiceCore();
  const { order } = BOOKING;

  const params = useParams();
  const adventureSlug = params.slug as string;
  const adventureIdentifier = adventureSlug.split("-")[0];
  const adventureToken = params.token as string;

  React.useEffect(() => {
    AdventuresApi.getAdventure(api, adventureIdentifier, adventureToken).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const adventure = response.body?.adventure as IAdventure;
        if (adventure) {
          setAdventure(adventure);
          BOOKING.setAdventure(adventure);
          APP.setPageTitle(adventure.title);
        }
        if (response?.body?.virginOrder && response?.body?.virginOrderParticipant) {
          BOOKING.setVirginOrder(
            response.body.virginOrder as IOrder,
            response.body.virginOrderParticipant as IOrderParticipant
          );
          BOOKING.initOrderPages(adventure);
        }
      } else if (response?.statusCode === StatusCode.NOT_FOUND) {
        window.location.href = UrlService.url("public.adventures");
      }
    });
  }, []);

  if (!adventure || !order) return <FDN.Loading />;

  return (
    <div className="__public-adventure">
      <AdventuresLayout page="adventure">
        <AdventureHeader adventure={adventure} />

        <AdventurePictureSlider adventure={adventure} />

        {showBannerForRegions.includes(adventure.region as string) && (
          <div className="__public-adventure-banner">
            <FDN.Box noPadding>
              <img src="https://static.click2adventure.com/images/banner/app-special-2023.jpeg" alt="" />
            </FDN.Box>
          </div>
        )}

        <AdventureBody adventure={adventure} />
        <hr id="booking" style={{ margin: "30px 0" }} />
        <AdventureBooking adventure={adventure} />
      </AdventuresLayout>
    </div>
  );
};

export default Adventure;

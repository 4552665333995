import * as React from "react";
import { BookingContext } from "src/components/context/BookingContext/BookingContext";
import * as FDN from "src/core";

// const dotIcons = {
//   done: "circle",
//   active: "dot-circle-o",
//   future: "circle-o",
//   nothing: "question-circle",
// };

export const bookingNavigationIcons = {
  date: "calendar",
  participants: "users",
  personal: "id-card",
  paymentPlan: "shopping-cart",
  hotel: "bed",
  upgrades: "plus-circle",
  preview: "list-alt",
  payment: "credit-card",
  confirmation: "globe",
  message: "question-circle",
};

const BookingNavigation: React.FunctionComponent = () => {
  const BOOKING = React.useContext(BookingContext);
  const { orderPages, completedPages, missingFields } = BOOKING;

  if (!orderPages) return <FDN.Loading box />;

  return (
    <div className="__booking-navigation" id="bookingNav">
      {orderPages.map((orderPageIndex) => {
        let itemStatus = "uncompleted";
        if (completedPages) {
          if (completedPages.includes(orderPageIndex)) {
            itemStatus = "completed";
          }
        }

        return (
          <button
            key={orderPageIndex}
            className={`${itemStatus} __booking-navigation-item`}
            onClick={() => BOOKING.goToPage(orderPageIndex)}
          >
            <div className="__booking-navigation-item-line">
              <div className="__booking-navigation-item-line-left"></div>
              <div className="__booking-navigation-item-line-middle">
                {/*<FDN.Icon icon={dotIcons[itemStatus]} />*/}
              </div>
              <div className="__booking-navigation-item-line-right"></div>
            </div>
            <div
              className={`__booking-navigation-item-label ${
                missingFields && missingFields.length > 0 && itemStatus === "uncompleted"
                  ? `error-text`
                  : ``
              }`}
            >
              {/*<div className="__booking-navigation-item-label-number">{index + 1}</div>*/}
              <div className="__booking-navigation-item-label-icon">
                {itemStatus === "completed" ? (
                  <FDN.Icon icon="check" />
                ) : (
                  <FDN.Icon icon={bookingNavigationIcons[orderPageIndex]} />
                )}
              </div>
              <div className={`__booking-navigation-item-label-text`}>
                {missingFields && missingFields.length > 0 && itemStatus === "uncompleted" ? (
                  <span className="__booking-navigation-item-label-missingfields-icon">
                    <FDN.Icon icon="exclamation-circle" />
                  </span>
                ) : null}
                {FDN.I18n.t(`booking.nav.items.${orderPageIndex}.label`)}
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default BookingNavigation;

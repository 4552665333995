import * as React from "react";
import * as FDN from "src/core";
import { IFile, TActions } from "src/types";
import useServiceCore from "src/services/CoreService";

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  useSortable,
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { IFileManagerAllowedTypes, IFileManagerParent } from "../types";

interface IFilesListProps {
  parent: IFileManagerParent;
  files: IFile[];
  editMode: boolean;
  mainPictureIndex?: number;
  allow: IFileManagerAllowedTypes[];
  allowEdit?: boolean;
  allowRemove?: boolean;
  actions: TActions;
}

const FilesList: React.FunctionComponent<IFilesListProps> = ({
  parent,
  files,
  editMode,
  mainPictureIndex,
  allow,
  allowEdit,
  allowRemove,
  actions,
}) => {
  const [items, setItems] = React.useState<string[]>([]);

  React.useEffect(() => {
    //    console.log("files updated", files);
    const updatedItems = files.map((f) => f.identifier);
    //    console.log("items?", items);
    if (items.length !== updatedItems.length) setItems(updatedItems);
  }, [files]);

  React.useEffect(() => {
    if (items) {
      if (parent === "adventure" && actions.onUpdatePicturesOrder)
        actions.onUpdatePicturesOrder(items);
      else if (parent === "hotel" && actions.onUpdateHotelPicturesOrder)
        actions.onUpdateHotelPicturesOrder(items);
      else if (parent === "upgrade" && actions.onUpdateUpgradePicturesOrder)
        actions.onUpdateUpgradePicturesOrder(items);
    }
  }, [items]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event: DragEndEvent) {
    const { active, over } = event;

    if (!active?.id || !over?.id) return items;

    if (active.id !== over.id) {
      setItems((items) => {
        const oldIndex = items.indexOf(active.id as string);
        const newIndex = items.indexOf(over.id as string);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  }

  if (!files || files.length === 0) {
    if (allow.length === 1 && allow[0] === "images")
      return <div className="text-center">{FDN.I18n.t("filemanager.noPictures")}</div>;
    else return <div className="text-center">{FDN.I18n.t("filemanager.noFiles")}</div>;
  }

  return (
    <div className="__fm-files-list">
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <SortableContext items={items} strategy={verticalListSortingStrategy}>
          {items.map((id, index) => {
            const labels: string[] = [];

            if ((mainPictureIndex || mainPictureIndex === 0) && mainPictureIndex === index)
              labels.push("mainPicture");

            return (
              <SortableItem
                key={id}
                id={id}
                parent={parent}
                file={files.find((f) => f.identifier === id)}
                editMode={editMode}
                labels={labels}
                allowEdit={allowEdit}
                allowRemove={allowRemove}
                actions={actions}
              />
            );
          })}
        </SortableContext>
      </DndContext>
    </div>
  );
};

interface ISortableItemProps {
  id: string;
  parent: IFileManagerParent;
  file?: IFile;
  editMode: boolean;
  labels?: string[];
  allowEdit?: boolean;
  allowRemove?: boolean;
  actions: TActions;
}

const SortableItem: React.FunctionComponent<ISortableItemProps> = ({
  id,
  parent,
  file,
  editMode,
  labels,
  allowEdit,
  allowRemove,
  actions,
}) => {
  if (!file) return null;

  const { setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <FilesListItem
        parent={parent}
        file={file}
        editMode={editMode}
        labels={labels}
        allowEdit={allowEdit}
        allowRemove={allowRemove}
        actions={actions}
      />
    </div>
  );
};

interface IFilesListItem {
  parent: IFileManagerParent;
  file: IFile;
  editMode: boolean;
  labels?: string[];
  allowEdit?: boolean;
  allowRemove?: boolean;
  actions: TActions;
}

const FilesListItem: React.FunctionComponent<IFilesListItem> = ({
  parent,
  file,
  editMode,
  labels,
  allowEdit,
  allowRemove,
  actions,
}) => {
  const { listeners, attributes } = useSortable({ id: file.identifier });

  return (
    <div className="__fm-files-list-item">
      {editMode === true && (
        <div className="__fm-files-list-item-handle" {...listeners} {...attributes}>
          <FDN.Icon icon="bars" />
        </div>
      )}
      <div className="__fm-files-list-item-icon">
        <FileIcon file={file} />
      </div>
      <div className="__fm-files-list-item-info">
        {file.filename}

        {labels && labels.length > 0 ? (
          <div className="__fm-files-list-item-labels">
            {labels.map((label, index) => {
              return (
                <span key={index} className={`__fm-files-list-item-label label-${label}`}>
                  {FDN.I18n.t(`filemanager.labels.${label}`)}
                </span>
              );
            })}
          </div>
        ) : null}
      </div>
      <div className="__fm-files-list-item-actions">
        {editMode === true && allowEdit === true ? (
          <button
            className="__fm-files-list-item-actions-button"
            onClick={() => actions.editPicture(file.identifier)}
          >
            <FDN.Icon icon="edit" />
          </button>
        ) : null}
        {editMode === true && allowRemove === true ? (
          <button
            className="__fm-files-list-item-actions-button"
            onClick={() => {
              if (parent === "adventure" && actions.onRemovePictures)
                actions.onRemovePictures(file.identifier);
              else if (parent === "hotel" && actions.onRemoveHotelPictures)
                actions.onRemoveHotelPictures(file.identifier);
              else if (parent === "upgrade" && actions.onRemoveUpgradePictures)
                actions.onRemoveUpgradePictures(file.identifier);
            }}
          >
            <FDN.Icon icon="times" />
          </button>
        ) : null}
      </div>
    </div>
  );
};

interface IFileIcon {
  file: IFile;
}

const FileIcon: React.FunctionComponent<IFileIcon> = ({ file }) => {
  if (!file.identifier || !file.filetype?.includes("image/")) return null;

  const { api } = useServiceCore();
  const fileUrl = api.getEndpoint("files.image", { identifier: file.identifier, size: "S" });

  return (
    <div
      className="__fm-files-list-item-preview"
      style={{
        backgroundImage: `url("${fileUrl}")`,
      }}
    >
      &nbsp;
    </div>
  );
};

export default FilesList;

import * as React from "react";
import * as FDN from "src/core";
import { TActions } from "src/types";
import { FileUploadFile } from "../../ImageUpload/types";
import { useDropzone } from "react-dropzone";
import { AcceptedFileTypes } from "../types";
import { IFileManagerAllowedTypes } from "../../FileManager/types";
import { v4 } from "uuid";

interface IDropAreaProps {
  type: IFileManagerAllowedTypes;
  maxFiles?: number;
  accept: AcceptedFileTypes;
  actions: TActions;
}

const DropArea: React.FunctionComponent<IDropAreaProps> = ({ type, maxFiles, accept, actions }) => {
  const onDrop = (acceptedFiles: File[]) => {
    const selectedFiles: FileUploadFile[] = [];

    for (const acceptedFile of acceptedFiles) {
      const selectedFile: FileUploadFile = {
        uuid: v4(),
        name: acceptedFile.name,
        size: acceptedFile.size,
        mime: acceptedFile.type,
        original: acceptedFile,
        uploadProgress: 0,
      };
      selectedFiles.push(selectedFile);
    }

    actions.onSelectFiles(selectedFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles,
    accept,
  });

  return (
    <div className="__filemultiupload-droparea" {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="__filemultupload-droparea-icon">
        <FDN.Icon icon="upload" />
      </div>
      <div className="__filemultupload-droparea-text">
        <div>
          <strong>{FDN.I18n.t(`filemultiupload.droparea.${type}.title`)}</strong>
        </div>
        <div>{FDN.I18n.t(`filemultiupload.droparea.${type}.text`)}</div>
      </div>
    </div>
  );
};

export default DropArea;

import React from "react";
import { Popup } from "@tschirpke/ptfdn-frontend-components";
import * as FDN from "src/core";
import { IAdventure, IAdventureHotel, IHotels, SearchHotelsFilter } from "src/types";
import HotelsList from "./HotelsList";
import { AdventureHotelsActions } from "src/services/admin/useAdventureHotels";
import SelectedHotel from "./SelectedHotel";
import HotelsListSearch from "./HotelsListSearch";

interface AddHotelProps {
  search: SearchHotelsFilter;
  countries?: string[];
  cities?: string[];
  hotels: IHotels;
  selectedHotel?: IAdventureHotel;
  adventure: IAdventure;
  night: number;
  actions: AdventureHotelsActions;
}

const AddHotel = ({ hotels, selectedHotel, night, adventure, search, countries, cities, actions }: AddHotelProps) => {
  if (!hotels) return <FDN.Loading box />;

  const nightsWithHotels = actions.getNightsWithHotels();

  return (
    <Popup
      size="medium"
      title={FDN.I18n.t("adminHotels.adventure.addHotel.popup.title", { night })}
      onClose={actions.closeAddHotelPopup}
    >
      <div className="">
        {!selectedHotel && (
          <div className="h-full">
            <HotelsListSearch search={search} countries={countries} cities={cities} actions={actions} />
            <div className="h-[45vh] overflow-y-auto border">
              <HotelsList hotels={hotels} selectedHotel={selectedHotel} night={night} actions={actions} />
            </div>
          </div>
        )}
        {selectedHotel && (
          <div className="h-full overflow-y-auto">
            <div className="border-y mb-4">
              <button
                className="block w-full px-2 py-2 text-left font-medium cursor-pointer hover:bg-hover"
                onClick={() => actions.selectHotel(undefined)}
              >
                <FDN.Icon icon="chevron-left" /> {FDN.I18n.t("adminHotels.adventure.addHotel.buttons.backToList.label")}
              </button>
            </div>
            <div className="h-full">
              {selectedHotel && (
                <SelectedHotel adventure={adventure} selectedHotel={selectedHotel} hotels={hotels} actions={actions} />
              )}
            </div>
          </div>
        )}
      </div>
      {!selectedHotel && (
        <div className="border rounded-md px-4 py-4 bg-slate-50 my-4">
          <div className="font-semibold text-brightPurple pb-2">
            {FDN.I18n.t("adminHotels.adventure.addHotel.duplicateFromNight.title")}
          </div>
          <select onChange={(e) => actions.duplicateFromNight(parseInt(e.target.value))}>
            <option value=""></option>
            {Object.keys(nightsWithHotels).map((night) => {
              return (
                <option key={night} value={night}>
                  {FDN.I18n.t("adminHotels.night")} {night}: {nightsWithHotels[parseInt(night)].length} hotel(s)
                </option>
              );
            })}
          </select>
        </div>
      )}
      <div className="text-right pt-4">
        <FDN.Button onClick={() => actions.closeAddHotelPopup()}>{FDN.I18n.t("buttons.close")}</FDN.Button>
      </div>
    </Popup>
  );
};

export default AddHotel;

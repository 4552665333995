import React, { useEffect, useState } from "react";
import { TActions } from "src/types";
import useServiceCore from "../CoreService";
import { IAdminListSchema } from "src/components/main/AdminList/types";
import UserApi from "src/api/UserApi";

const defaultPerPage = 100;

interface AdminListServiceProps {
  schema?: IAdminListSchema;
  page?: number;
}

const useServiceAdminListService = (props: AdminListServiceProps) => {
  const [paginate, setPaginate] = useState<number | undefined>();
  const [page, setPage] = React.useState<number>(props.page || 1);
  const [pagesTotal, setPagesTotal] = React.useState<number>(1);

  const { APP, api } = useServiceCore();
  const user = APP.getUser();

  const { schema } = props;

  React.useEffect(() => {
    if (!paginate || !schema?.body?.rows.length) return;

    const pagesTotal = Math.ceil(schema?.body?.rows.length / paginate);
    setPagesTotal(pagesTotal);
    setPage(1);
  }, [schema?.body?.rows, paginate]);

  useEffect(() => {
    //
  }, [paginate]);

  useEffect(() => {
    if (user?.usersettings?.perPageAdminList) setPaginate(user?.usersettings?.perPageAdminList);
    else setPaginate(defaultPerPage);
  }, [user?.usersettings?.perPageAdminList]);

  const selectPage = (page: number) => {
    setPage(page);
  };

  const selectPerPage = (perPage: number) => {
    setPaginate(perPage);
    UserApi.updateUserSetting(api, "perPageAdminList", perPage);
  };

  const actions: TActions = {
    selectPage,
    selectPerPage,
  };

  return { actions, page, pagesTotal, paginate, defaultPerPage };
};

export default useServiceAdminListService;

import * as React from "react";
import { Link } from "react-router-dom";
import * as FDN from "src/core";
import Config from "src/core/Config";
import UrlService from "src/core/UrlService";
import useServiceCore from "src/services/CoreService";
import { IAdventure, IAdventures, IAdventureSearch, IIconsIncluded, LocationCountries } from "src/types";
import AdventureSearch from "./AdventureSearch";
import { cloneDeep } from "lodash";
import AdventuresApi from "src/api/AdventuresApi";
import StatusCode from "src/config/statuscodes";
import { getAdventurePictureUrl } from "src/core/helpers/helpers";

type Views = "list" | "grid";

interface IAdventuresListProps {
  adventures: IAdventures;
  defaultSearch: IAdventureSearch;
}

const AdventuresList: React.FunctionComponent<IAdventuresListProps> = ({ adventures, defaultSearch }) => {
  const [search, setSearch] = React.useState(defaultSearch);
  const [filteredAdventures, setFilteredAdventures] = React.useState<IAdventures>(adventures);
  const [view, setView] = React.useState<Views>("grid");
  const [locationCountries, setLocationCountries] = React.useState<LocationCountries | undefined>();

  const { api } = useServiceCore();

  React.useEffect(() => {
    if (!locationCountries) {
      AdventuresApi.getCountries(api).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          setLocationCountries(response?.body?.countries as LocationCountries);
        }
      });
    }
  }, []);

  React.useEffect(() => {
    setSearch(cloneDeep(defaultSearch));
  }, [defaultSearch]);

  React.useEffect(() => {
    let filtered: IAdventures = [];

    if (!search || !locationCountries) return;

    for (const adventure of adventures) {
      let addToList = false;

      if (search.country) {
        if (search.country === adventure.country) {
          if (search.region) {
            if (search.region === adventure.region) addToList = true;
          } else addToList = true;
        }
      } else addToList = true;

      if (search.type) {
        if (search.type === adventure.type) addToList = true;
        else addToList = false;
      }

      if (addToList === true) filtered.push(adventure);
    }

    if (search.sortBy) filtered = sortAdventures(filtered, search.sortBy);

    setFilteredAdventures(filtered);
  }, [search]);

  const changeView = (view: Views) => {
    setView(view);
  };

  const sortAdventures = (adventures: IAdventures, sortBy: string): IAdventures => {
    const [sortByKey, sortByDirection] = sortBy.split("_");

    let property = "";
    if (sortByKey === "newest") property = "createdAt";
    else if (sortByKey === "price") property = "priceFull";
    else if (sortByKey === "title") property = "title";
    else if (sortByKey === "country") property = "country";

    if (!property || (sortByDirection !== "asc" && sortByDirection !== "desc")) return adventures;

    const sorted = adventures.sort((a, b) => {
      if (property === "createdAt") {
        if (sortByDirection === "asc") return new Date(a[property]).getTime() - new Date(b[property]).getTime();
        else return new Date(b[property]).getTime() - new Date(a[property]).getTime();
      } else if (property === "title") {
        if (sortByDirection === "asc") return a[property].localeCompare(b[property]);
        else return b[property].localeCompare(a[property]);
      } else if (property === "country") {
        if (sortByDirection === "asc") return (a[property] as string).localeCompare(b[property] as string);
        else return (b[property] as string).localeCompare(a[property] as string);
      } else {
        // if (sortByDirection === "asc") return a[property] - b[property];
        // else return b[property] - a[property];
        return 1;
      }
    });

    return sorted;
  };

  const onUpdateSearch = (key: keyof IAdventureSearch, value: any) => {
    if (!search || !key) return;

    search[key] = value;

    if (key === "country") search.region = "";

    setSearch(cloneDeep(search));
  };

  return (
    <div className={`__public-adventures-list view-${view}`} id="adventuresList">
      <AdventureSearch search={search} locationCountries={locationCountries} onUpdateSearch={onUpdateSearch} />
      <List adventures={filteredAdventures} view={view} />
    </div>
  );
};

interface IListProps {
  adventures: IAdventures;
  view: Views;
}

const List: React.FunctionComponent<IListProps> = ({ adventures, view }) => {
  const sm = 24;
  const md = view === "grid" ? 12 : 24;
  const lg = view === "grid" ? 8 : 24;

  return (
    <FDN.Grid>
      <FDN.Row margin="xy">
        {adventures.map((adventure) => {
          return (
            <FDN.Cell key={adventure.identifier} sm={sm} md={md} lg={lg}>
              <ListItem adventure={adventure} />
            </FDN.Cell>
          );
        })}
      </FDN.Row>
    </FDN.Grid>
  );
};

interface IListItemProps {
  adventure: IAdventure;
}

const ListItem: React.FunctionComponent<IListItemProps> = ({ adventure }) => {
  let titlePreTitle = <></>;
  if (adventure.countryName) {
    titlePreTitle = <>{adventure.countryName}</>;

    if (adventure.regionName)
      titlePreTitle = (
        <>
          {titlePreTitle} <FDN.Icon icon="angle-right" /> {adventure.regionName}
        </>
      );
  }

  const styles: React.CSSProperties = {
    backgroundImage: adventure.mainPicture ? `url("${getAdventurePictureUrl(adventure.mainPicture, "S")}")` : undefined,
  };

  return (
    <Link to={UrlService.url("public.adventure", { slug: adventure.slug })} className="__public-adventure-list-item">
      <div className="__public-adventure-list-item-pictures" style={styles}></div>
      <div className="__public-adventure-list-item-content-wrapper">
        <div className="__public-adventure-list-item-content">
          <div className="__public-adventure-list-item-pretitle">{titlePreTitle}</div>
          <h3>{adventure.title}</h3>
        </div>
        <ListItemIcons adventure={adventure} />
        <div className="__public-adventure-list-item-content">
          {adventure.nights && adventure.nights > 0 ? (
            <div className="text-center text-xs font-medium pb-1">
              {adventure.nights} {FDN.I18n.t(`adminHotels.night${adventure.nights !== 1 ? "s" : ""}`)}
            </div>
          ) : null}
          <div className="__public-adventure-list-item-content-description">{adventure.description}</div>
          <ListItemPrice adventure={adventure} />
        </div>
      </div>
    </Link>
  );
};

const ListItemPrice: React.FunctionComponent<IListItemProps> = ({ adventure }) => {
  const currency = Config.get("currency") as string;

  return (
    <div className="__public-adventure-list-item-price">
      <div className="__public-adventure-list-item-price-priceBefore">
        {adventure.showPriceBefore === true && adventure.priceBefore ? (
          <span>
            {currency}
            {adventure.priceBefore}
          </span>
        ) : (
          <>&nbsp;</>
        )}
      </div>
      <div className="__public-adventure-list-item-price-priceFull">
        {adventure.priceFull ? (
          <>
            <span className="__public-adventure-list-item-price-from-label">{FDN.I18n.t("adventures.price.from")}</span>{" "}
            {currency}
            {adventure.priceFull}
          </>
        ) : (
          <>&nbsp;</>
        )}
      </div>
    </div>
  );
};

const ListItemIcons: React.FunctionComponent<IListItemProps> = ({ adventure }) => {
  const icons = Config.get("adventures.iconsIncluded") as { [key: string]: { [key: string]: any } };

  const iconsIncluded = adventure.iconsIncluded || [];

  return (
    <div className="__public-adventure-list-item-icons">
      {Object.keys(icons).map((iconKey) => {
        if (icons[iconKey].showInList === true && iconsIncluded.includes(iconKey as IIconsIncluded))
          return <ListItemIconsIcon key={iconKey} iconKey={iconKey} icon={icons[iconKey]} />;
        return null;
      })}
    </div>
  );
};

interface IListItemIconProps {
  iconKey: string;
  icon: { [key: string]: any };
}

const ListItemIconsIcon: React.FunctionComponent<IListItemIconProps> = ({ iconKey, icon }) => {
  return (
    <span className="__public-adventure-list-item-icons-icon">
      <FDN.ToolTip tooltip={FDN.I18n.t(`adventures.iconsIncluded.${iconKey}.label`)}>
        <FDN.Icon icon={icon.icon} />
      </FDN.ToolTip>
    </span>
  );
};

export default AdventuresList;

import React, { useEffect, useState } from "react";
import {
  IAdventures,
  IOrders,
  LocationCountries,
  OrdersListCount,
  OrdersListType,
  SearchOrdersFilter,
} from "../../types";
import useServiceCore from "../CoreService";
import StatusCode from "src/config/statuscodes";
import * as FDN from "src/core";
import AdminApi from "src/api/AdminApi";
import { cloneDeep } from "lodash";
import { setTimeToBeginningOfDay, setTimeToEndOfDay } from "src/core/helpers/helpers";

const initTabs: FDN.ITabs = {
  running: {
    label: FDN.I18n.t("adminOrders.list.tabs.running.label"),
    icon: "refresh",
  },
  future: {
    label: FDN.I18n.t("adminOrders.list.tabs.future.label"),
    icon: "calendar-check-o",
  },
  past: {
    label: FDN.I18n.t("adminOrders.list.tabs.past.label"),
    icon: "calendar-minus-o",
  },
  cancelled: {
    label: FDN.I18n.t("adminOrders.list.tabs.cancelled.label"),
    icon: "calendar-times-o",
  },
};

const defaultTab = "running";

const initSearch: SearchOrdersFilter = {};

export type FilterTypes = "running" | "future" | "past" | "cancelled";

export interface TActionsAdminOrders {
  updateSearch: <P extends keyof SearchOrdersFilter, V extends SearchOrdersFilter[P]>(property: P, value: V) => void;
  resetSearch: () => void;
}

const useServiceAdminOrders = () => {
  const [orders, setOrders] = useState<IOrders>();
  const [adventures, setAdventures] = useState<IAdventures>();
  const [locationCountries, setLocationCountries] = useState<LocationCountries>();

  const [tabs, setTabs] = useState<FDN.ITabs>();
  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const [filteredOrders, setFilteredOrders] = useState<IOrders>([]);
  const [search, setSearch] = useState<SearchOrdersFilter>(cloneDeep(initSearch));

  const [isLoading, setIsLoading] = useState(true);

  const { APP, api } = useServiceCore();

  React.useEffect(() => {
    fetchOrders(selectedTab as OrdersListType);
  }, [selectedTab]);

  useEffect(() => {
    updateFilteredOrders();
  }, [search]);

  const fetchOrders = (type: OrdersListType) => {
    setIsLoading(true);

    AdminApi.adminOrders(api, type).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const orders = response?.body?.orders as IOrders;
        setOrders(orders);

        const ordersCount = response?.body?.ordersCount as OrdersListCount;

        const adventures = response?.body?.adventures as IAdventures;
        setAdventures(adventures);

        const locationCountries = response?.body?.countries as LocationCountries;
        setLocationCountries(locationCountries);

        // const filterType = selectedTab as FilterTypes;
        const filteredOrders = filterOrders(orders);

        setFilteredOrders(filteredOrders);

        APP.setPageTitle(FDN.I18n.t("adminOrders.pageTitle"));

        const updatedTabs = cloneDeep(initTabs);

        updatedTabs.running.badge = ordersCount.running;
        updatedTabs.future.badge = ordersCount.future;
        updatedTabs.past.badge = ordersCount.past;
        updatedTabs.cancelled.badge = ordersCount.cancelled;

        setTabs(updatedTabs);

        setIsLoading(false);
      }
    });
  };

  const updateFilteredOrders = () => {
    if (!orders) return;

    // const filterType = selectedTab as FilterTypes;
    const filteredOrders = filterOrders(orders);

    setFilteredOrders(filteredOrders);
  };

  const searchInOrders = (orders: IOrders, search: SearchOrdersFilter): IOrders => {
    const filteredOrders: IOrders = [];

    for (const order of orders) {
      let addToList = true;

      if (search.createdFrom) {
        if (order.createdAt) {
          const createdAt = setTimeToBeginningOfDay(new Date(order.createdAt));
          const searchCreatedFrom = setTimeToBeginningOfDay(new Date(search.createdFrom));

          if (createdAt < searchCreatedFrom) addToList = false;
        }
      }

      if (search.createdUntil) {
        if (order.createdAt) {
          const createdAt = setTimeToEndOfDay(new Date(order.createdAt));
          const searchCreatedUntil = setTimeToEndOfDay(new Date(search.createdUntil));

          if (createdAt > searchCreatedUntil) addToList = false;
        }
      }

      if (search.startDateFrom) {
        if (order.startDate) {
          const startDateFrom = setTimeToBeginningOfDay(new Date(order.startDate));
          const searchCreatedFrom = setTimeToBeginningOfDay(new Date(search.startDateFrom));

          if (startDateFrom < searchCreatedFrom) addToList = false;
        }
      }

      if (search.startDateUntil) {
        if (order.startDate) {
          const startDateFrom = setTimeToEndOfDay(new Date(order.startDate));
          const searchCreatedUntil = setTimeToEndOfDay(new Date(search.startDateUntil));

          if (startDateFrom > searchCreatedUntil) addToList = false;
        }
      }

      if (addToList === true) filteredOrders.push(order);
    }

    return filteredOrders;
  };

  const filterOrders = (orders: IOrders): IOrders => {
    let filteredOrders: IOrders = orders;

    // const now = new Date();

    // if (orders && orders.length > 0) {
    //   for (const order of orders)
    //     switch (type) {
    //       case "running":
    //         if (
    //           !order.cancelled &&
    //           order.startDate &&
    //           new Date(order.startDate) < now &&
    //           order.endDate &&
    //           new Date(order.endDate) > now
    //         )
    //           filteredOrders.push(order);
    //         break;
    //       case "future":
    //         if (!order.cancelled && order.startDate && new Date(order.startDate) > now) filteredOrders.push(order);
    //         break;
    //       case "past":
    //         if (!order.cancelled && order.endDate && new Date(order.endDate) < now) filteredOrders.push(order);
    //         break;
    //       case "cancelled":
    //         if (order.cancelled) filteredOrders.push(order);
    //         break;
    //     }
    // }

    if (search) filteredOrders = searchInOrders(filteredOrders, search);

    return filteredOrders;
  };

  function updateSearch<P extends keyof SearchOrdersFilter, V extends SearchOrdersFilter[P]>(property: P, value: V) {
    const updatedSearch = search ? cloneDeep(search) : cloneDeep(initSearch);

    updatedSearch[property] = value;

    setSearch(updatedSearch);
  }

  const resetSearch = () => {
    setSearch(cloneDeep(initSearch));
  };

  const actions: TActionsAdminOrders = {
    updateSearch,
    resetSearch,
  };

  return {
    orders,
    adventures,
    locationCountries,
    tabs,
    selectedTab,
    setSelectedTab,
    filteredOrders,
    search,
    isLoading,
    actions,
  };
};

export default useServiceAdminOrders;

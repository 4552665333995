import * as React from "react";
import { IAdventure, IOrder, IOrderCalculation } from "src/types";
import BookingPaymentPlanSummaryMobile from "./BookingPaymentPlanSummaryMobile";
import BookingPaymentPlanSummaryDesktop from "./BookingPaymentPlanSummaryDesktop";

interface IBookingPaymentPlanSummaryProps {
  order: IOrder;
  calculation: IOrderCalculation;
  adventure: IAdventure;
}

const BookingPaymentPlanSummary: React.FunctionComponent<IBookingPaymentPlanSummaryProps> = ({
  order,
  calculation,
  adventure,
}) => {
  if (!order) return <>ERROR</>;

  return (
    <>
      <div className="show-for-small-only">
        <BookingPaymentPlanSummaryMobile
          order={order}
          calculation={calculation}
          adventure={adventure}
        />
      </div>
      <div className="hide-for-small-only">
        <BookingPaymentPlanSummaryDesktop
          order={order}
          calculation={calculation}
          adventure={adventure}
        />
      </div>
    </>
  );
};

export default BookingPaymentPlanSummary;

import React, { useEffect, useState } from "react";
import {
  IAdventure,
  IAdventureRequiredInformation,
  IOrder,
  IReqInfoByParticipant,
  IReqInfoFiles,
} from "../types";
import useServiceCore from "./CoreService";
import BookingApi from "src/api/BookingApi";
import StatusCode from "src/config/statuscodes";
import * as FDN from "src/core";
import { useParams } from "react-router-dom";
import { cloneDeep, isArray } from "lodash";

type TEdit = (
  participantIdentifier: string,
  type: IAdventureRequiredInformation,
  value: string[] | string | number | Date | null
) => void;

export interface TActionsReqInfo {
  onEdit: TEdit;
  onSave: () => void;
}

export const syncReqInfos = ["pickuplocation"];

const useServiceReqInfo = () => {
  const [adventure, setAdventure] = useState<IAdventure>();
  const [order, setOrder] = useState<IOrder>();
  const [reqInfo, setReqInfo] = useState<IReqInfoByParticipant>();
  const [reqInfoFiles, setReqInfoFiles] = useState<IReqInfoFiles>();
  const [orderNotFound, setOrderNotFound] = useState(false);
  const [completion, setCompletion] = useState(0);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const params = useParams();
  const orderNr = params.orderNr as string;
  const orderToken = params.orderToken as string;

  const { APP, NOTIFICATIONS, api } = useServiceCore();

  React.useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    calculateCompletion();
  }, [reqInfo]);

  const init = () => {
    BookingApi.getReqInfo(api, orderNr, orderToken).then((response) => {
      if (response?.statusCode === StatusCode.NOT_FOUND) {
        setOrderNotFound(true);
      } else if (response?.statusCode === StatusCode.SUCCESS) {
        const reqInfo = response?.body?.reqInfo as IReqInfoByParticipant;
        setReqInfo(reqInfo);

        const reqInfoFiles = response?.body?.reqInfoFiles as IReqInfoFiles;
        setReqInfoFiles(reqInfoFiles);

        const adventure = response?.body?.adventure as IAdventure;
        setAdventure(adventure);

        const order = response?.body?.order as IOrder;
        setOrder(order);

        APP.setPageTitle(FDN.I18n.t("booking.reqinfo.pageTitle"));
      }
    });
  };

  const calculateCompletion = () => {
    if (!reqInfo) return;

    let totalEntries = 0;
    let entriesCompleted = 0;

    for (const pi of Object.keys(reqInfo)) {
      const pri = reqInfo[pi];
      for (const prii of pri) {
        totalEntries++;
        if ((!isArray(prii.value) && prii.value) || (isArray(prii.value) && prii.value.length > 0))
          entriesCompleted++;
      }
    }

    if (totalEntries > 0) {
      const completion = Math.round((entriesCompleted / totalEntries) * 100);
      setCompletion(completion);
    }
  };

  const onEdit: TEdit = (participantIdentifier, type, value) => {
    if (!reqInfo) return;

    if (syncReqInfos.includes(type)) {
      for (const participantReqInfoKey of Object.keys(reqInfo)) {
        const participantReqInfo = reqInfo[participantReqInfoKey];

        if (participantReqInfo) {
          for (const entry of participantReqInfo) {
            if (entry && entry.type === type) {
              entry.value = value;
              setUnsavedChanges(true);
            }
          }
        }
      }
    } else {
      const participantReqInfo = reqInfo[participantIdentifier];
      if (participantReqInfo) {
        for (const entry of participantReqInfo) {
          if (entry && entry.type === type) {
            entry.value = value;
            setUnsavedChanges(true);
          }
        }
      }
    }

    setReqInfo(cloneDeep(reqInfo));
  };

  const onSave = () => {
    if (!reqInfo) return;

    NOTIFICATIONS.showSaving({ type: "save" });

    BookingApi.saveReqInfo(api, orderNr, orderToken, reqInfo).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setUnsavedChanges(false);
        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("booking.reqinfo.onSave.success.title"),
          FDN.I18n.t("booking.reqinfo.onSave.success.text")
        );
        NOTIFICATIONS.hideSaving();
      }
    });
  };

  const actions: TActionsReqInfo = {
    onEdit,
    onSave,
  };

  return {
    APP,
    adventure,
    order,
    reqInfo,
    reqInfoFiles,
    orderNotFound,
    completion,
    unsavedChanges,
    actions,
    syncReqInfos,
  };
};

export default useServiceReqInfo;

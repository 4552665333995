import { EditorState, ContentState, convertToRaw, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { cloneDeep } from "lodash";
import { useEffect, useState } from "react";
import { EditorStatus, FOnUpdateRichTextEditor, ReturnContentType } from "./types";

export interface RichTextEditorServiceProps {
  initialEditorState?: EditorState;
  initialHtml?: string;
  returnContentType: ReturnContentType;
  onUpdate: FOnUpdateRichTextEditor;
}

const useRichTextEditorService = (props: RichTextEditorServiceProps) => {
  const { initialEditorState, initialHtml, returnContentType, onUpdate } = props;

  const [editorStatus, setEditorStatus] = useState<EditorStatus>("INIT");
  const [editorState, setEditorState] = useState<EditorState | undefined>();

  useEffect(() => {
    if (initialEditorState) {
      setEditorState(initialEditorState);
      setEditorStatus("LOADED");
    } else if (initialHtml) {
      const blockFromHtml = convertFromHTML(initialHtml);
      const state = ContentState.createFromBlockArray(
        blockFromHtml.contentBlocks,
        blockFromHtml.entityMap
      );
      setEditorState(EditorState.createWithContent(state));
      setEditorStatus("LOADED");
    } else {
      const blockFromHtml = convertFromHTML("");
      const state = ContentState.createFromBlockArray(
        blockFromHtml.contentBlocks,
        blockFromHtml.entityMap
      );
      setEditorState(EditorState.createWithContent(state));
      setEditorStatus("LOADED");
    }
  }, []);

  const onChange = (edtState: EditorState) => {
    setEditorState(cloneDeep(edtState));

    if (returnContentType === "html") {
      const rawContentState = convertToRaw(edtState.getCurrentContent());

      const html = draftToHtml(rawContentState);

      onUpdate(html);
    } else {
      onUpdate(edtState);
    }
  };

  return { editorStatus, editorState, onChange };
};

export default useRichTextEditorService;

import * as React from "react";
import AdminApi from "src/api/AdminApi";
import StatusCode from "src/config/statuscodes";
import { LogsState } from "src/types";
import useServiceCore from "src/services/CoreService";
import LogsList from "./LogsList";
import * as FDN from "src/core";

interface ILogsProps {
  type: string;
  affectedIdentifier: string;
  affectedObject?: any;
}

const Logs: React.FunctionComponent<ILogsProps> = (props) => {
  const [logs, setLogs] = React.useState<LogsState | undefined>();

  const { type, affectedIdentifier, affectedObject } = props;

  const { api } = useServiceCore();

  React.useEffect(() => {
    loadLogs();
  }, [type, affectedIdentifier, affectedObject]);

  const loadLogs = () => {
    AdminApi.getLogs(api, type, affectedIdentifier).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const logs = response?.body?.logEntries as LogsState;
        if (logs) setLogs(logs);
      }
    });
  };

  if (!logs) return <FDN.Loading box />;

  return (
    <div>
      <FDN.SingleRowCell full margin="xy">
        <LogsList logs={logs} />
      </FDN.SingleRowCell>
    </div>
  );
};

export default Logs;

import * as React from "react";
import * as FDN from "src/core";
import { IAdventure, IUpgrades, TActions } from "src/types";
import AddUpgradePopupList from "./AddUpgradePopupList";
import { cloneDeep } from "lodash";
import AddUpgradePopupSearch from "./AddUpgradePopupSearch";

export interface IUpgradeSearchParameters {
  text: string;
}

export type FSearchUpgrade = (searchParams: IUpgradeSearchParameters) => void;

const initSearchParams: IUpgradeSearchParameters = {
  text: "",
};

interface IAddUpgradePopupProps {
  adventure: IAdventure;
  upgrades?: IUpgrades;
  actions: TActions;
}

const AddUpgradePopup: React.FunctionComponent<IAddUpgradePopupProps> = ({
  adventure,
  upgrades,
  actions,
}) => {
  const [searchParams, setSearchParams] = React.useState(initSearchParams);
  const [filteredUpgrades, setFilteredUpgrades] = React.useState<IUpgrades | undefined>();

  React.useEffect(() => {
    if (!upgrades) return;

    const filteredUpgrades: IUpgrades = [];

    for (const upgrade of upgrades) {
      let showInList = true;

      if (searchParams.text) {
        const searchText = searchParams.text.toLowerCase();

        if (
          upgrade.name.toLowerCase().includes(searchText) ||
          upgrade.city?.toLowerCase().includes(searchText) ||
          upgrade.country?.toLowerCase().includes(searchText)
        ) {
          //
        } else showInList = false;
      }

      if (showInList === true) filteredUpgrades.push(upgrade);
    }

    setFilteredUpgrades(cloneDeep(filteredUpgrades));
  }, [searchParams]);

  const searchUpgrade: FSearchUpgrade = (searchParams) => {
    setSearchParams(cloneDeep(searchParams));
  };

  if (!filteredUpgrades) return <FDN.Loading box />;

  return (
    <div className="admin-adventure-upgrades-add-popup">
      <FDN.Popup
        size="medium"
        title={FDN.I18n.t("adminAdventures.upgrades.addPopup.title")}
        onClose={actions.hideUpgradeAddPopup}
      >
        <p>{FDN.I18n.t("adminAdventures.upgrades.addPopup.intro")}</p>
        <AddUpgradePopupSearch searchParams={searchParams} searchUpgrade={searchUpgrade} />
        <AddUpgradePopupList adventure={adventure} upgrades={filteredUpgrades} actions={actions} />
        <div className="add-popup-createnew">
          <button
            className="primary button"
            style={{ width: "100%" }}
            onClick={() => actions.showUpgradeEditPopup("new")}
          >
            <FDN.Icon icon="plus" left />
            {FDN.I18n.t("adminAdventures.upgrades.addPopup.createNew")}
          </button>
        </div>
        <FDN.FormButtons
          onCancel={actions.hideUpgradeAddPopup}
          cancelLabel={FDN.I18n.t("adminAdventures.upgrades.addPopup.buttons.onCancel.label")}
        />
      </FDN.Popup>
    </div>
  );
};

export default AddUpgradePopup;

import React, { useContext } from "react";
import * as FDN from "src/core";
import { TActions } from "src/types";
import useServiceCore from "./CoreService";
import {
  IUserNotification,
  IUserNotifications,
} from "src/components/context/NotificationsContext/types";
import { SocketContext } from "src/components/context/SocketContext/SocketContext";
import UrlService from "src/core/UrlService";

const menuKey = "usernotifications";

const useServiceUserNotifications = () => {
  const { APP, NOTIFICATIONS } = useServiceCore();
  const SOCKETIO = useContext(SocketContext);

  const userNotifications: IUserNotifications | undefined = NOTIFICATIONS.getUserNotifications();

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const menuRef = React.useRef<HTMLDivElement>(null);

  const menuStatus = NOTIFICATIONS.getMenuStatus(menuKey);

  React.useEffect(() => {
    window.addEventListener("resize", updateDropdownMenuPosition);
  }, []);

  React.useEffect(() => {
    updateDropdownMenuPosition();
  }, [menuStatus]);

  const updateDropdownMenuPosition = () => {
    if (!buttonRef.current || !menuRef.current) return;

    if (window.innerWidth < 860) {
      menuRef.current.style.left = "0";

      return;
    }

    const x = buttonRef.current.offsetLeft;
    const menuWidth = menuRef.current.offsetWidth;
    const buttonWidth = buttonRef.current.offsetWidth;

    if (x && menuWidth && buttonWidth)
      menuRef.current.style.left = `${x - menuWidth + buttonWidth}px`;

    const y = buttonRef.current.offsetTop;
    const buttonHeight = buttonRef.current.offsetHeight;

    if ((y || y === 0) && buttonHeight) menuRef.current.style.top = `${y + buttonHeight - 10}px`;
  };

  const toggleMenu = () => {
    NOTIFICATIONS.toggleMenu(menuKey);
  };

  const filterUserNotifications = (type: string): IUserNotifications | undefined => {
    if (!userNotifications) return;

    userNotifications.filteredNotifications = [];

    for (const userNotification of userNotifications.notifications) {
      if (type === "unread") {
        if (!userNotification.read) userNotifications.filteredNotifications.push(userNotification);
      } else if (type === "all") {
        userNotifications.filteredNotifications.push(userNotification);
      }
    }

    return userNotifications;
  };

  const buildMessage = (userNotification: IUserNotification): string => {
    let message = "";

    const i18nKey = `usernotifications.messages.${userNotification.area}.${userNotification.type}.${userNotification.action}`;

    if (FDN.I18n.t(i18nKey) === i18nKey) message = "?";
    else message = FDN.I18n.t(i18nKey);

    return message;
  };

  const onClickItem = (userNotification: IUserNotification): void => {
    const key = `${userNotification.area}.${userNotification.type}.${userNotification.action}`;

    SOCKETIO.userNotificationsToggleRead(userNotification.identifier);

    switch (key) {
      case "myaccount.welcome.welcome":
        window.location.href = UrlService.url("user.myaccount");
        break;
    }
  };

  const toggleRead = (userNotification: IUserNotification): void => {
    SOCKETIO.userNotificationsToggleRead(userNotification.identifier);
  };

  const actions: TActions = {
    toggleMenu,
    filterUserNotifications,
    buildMessage,
    onClickItem,
    toggleRead,
  };

  return { APP, NOTIFICATIONS, userNotifications, buttonRef, menuRef, menuKey, actions };
};

export default useServiceUserNotifications;

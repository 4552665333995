import React, { useEffect, useState } from "react";
import {
  IAdventure,
  IDrivers,
  IFile,
  IOrder,
  IReqInfoByParticipant,
  IReqInfoFiles,
  IStripePayments,
} from "../../types";
import useServiceCore from "../CoreService";
import StatusCode from "src/config/statuscodes";
import * as FDN from "src/core";
import AdminApi from "src/api/AdminApi";
import { useParams } from "react-router-dom";
import { cloneDeep } from "lodash";

const tabs: FDN.ITabs = {
  participants: {
    label: FDN.I18n.t("adminOrders.tabs.participants.label"),
    icon: "users",
  },
  hotels: {
    label: FDN.I18n.t("adminOrders.tabs.hotels.label"),
    icon: "bed",
  },
  upgrades: {
    label: FDN.I18n.t("adminOrders.tabs.upgrades.label"),
    icon: "plus",
  },
  payments: {
    label: FDN.I18n.t("adminOrders.tabs.payments.label"),
    icon: "dollar",
  },
  files: {
    label: FDN.I18n.t("adminOrders.tabs.files.label"),
    icon: "files-o",
  },
  driver: {
    label: FDN.I18n.t("adminOrders.tabs.driver.label"),
    icon: "cab",
  },
};

const defaultTab = "participants";

export interface AddFilesObject {
  files: IFile[] | undefined;
  informClient: boolean;
}

type FOnEditAddedFile = (fileIdentifier: string, property: "title" | "description", value: string) => void;
type FOnEditAddedFileObject = (property: "files" | "informClient", value: boolean | IFile[] | undefined) => void;

export interface TActionsAdminOrder {
  showPopupAddFiles: () => void;
  hidePopupAddFiles: () => void;
  onOrderFileUploadsCompleted: (files: IFile[]) => void;
  onEditAddedFile: FOnEditAddedFile;
  onEditAddedFileObject: FOnEditAddedFileObject;
  onRemoveFile: (fileIdentifier: string) => void;
  onCancel: () => void;
  onSave: () => void;
  onResendMail: (fileIdentifier: string) => void;
  onSelectDriver: (identifier: string | undefined) => void;
  onCancelOrder: () => void;
  onUncancelOrder: () => void;
}

const defaultAddFilesObject: AddFilesObject = {
  files: undefined,
  informClient: true,
};

const useServiceAdminOrder = () => {
  const [order, setOrder] = useState<IOrder>();
  const [orderTitle, setOrderTitle] = useState("");
  const [adventure, setAdventure] = useState<IAdventure>();

  const [reqInfo, setReqInfo] = useState<IReqInfoByParticipant>();
  const [reqInfoFiles, setReqInfoFiles] = useState<IReqInfoFiles>();
  const [payments, setPayments] = useState<IStripePayments>();

  const [drivers, setDrivers] = useState<IDrivers>();

  const [selectedTab, setSelectedTab] = useState(defaultTab);

  const [popupAddFilesVisible, setPopupAddFilesVisible] = useState(false);
  const [addFilesObject, setAddFilesObject] = useState<AddFilesObject>(cloneDeep(defaultAddFilesObject));
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  const { APP, NOTIFICATIONS, api } = useServiceCore();

  const params = useParams();
  const orderIdentifier = params.identifier;

  React.useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (!order) return;
    const orderTitle = `# ${order.orderNr} | ${order.participants[0].salutation || null} ${order.firstname} ${
      order.lastname
    }`;
    setOrderTitle(orderTitle);
    APP.setPageTitle(orderTitle);
  }, [order]);

  const init = () => {
    AdminApi.adminOrder(api, orderIdentifier as string).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const order = response?.body?.order as IOrder;
        setOrder(order);

        const adventure = response?.body?.adventure as IAdventure;
        setAdventure(adventure);

        const reqInfo = response?.body?.reqInfo as IReqInfoByParticipant;
        setReqInfo(reqInfo);

        const reqInfoFiles = response?.body?.reqInfoFiles as IReqInfoFiles;
        setReqInfoFiles(reqInfoFiles);

        const payments = response?.body?.payments as IStripePayments;
        setPayments(payments);

        const drivers = response?.body?.drivers as IDrivers;
        setDrivers(drivers);
      }
    });
  };

  const showPopupAddFiles = () => {
    setPopupAddFilesVisible(true);
  };

  const hidePopupAddFiles = () => {
    setPopupAddFilesVisible(false);
  };

  const onOrderFileUploadsCompleted = (files: IFile[]) => {
    if (!order) return;

    onEditAddedFileObject("files", cloneDeep(files));
    setUnsavedChanges(true);
  };

  const onEditAddedFile: FOnEditAddedFile = (fileIdentifier, property, value) => {
    const file = addFilesObject?.files?.find((f) => f.identifier === fileIdentifier);
    if (!file) return;

    const updatedFiles = addFilesObject.files?.map((file) => {
      if (file.identifier === fileIdentifier) {
        if (property === "title") file[property] = value;
        if (property === "description") file[property] = value;
        setUnsavedChanges(true);
      }

      return file;
    });

    addFilesObject.files = updatedFiles;
    setAddFilesObject(cloneDeep(addFilesObject));
  };

  const onEditAddedFileObject: FOnEditAddedFileObject = (property, value) => {
    const obj = addFilesObject ? addFilesObject : cloneDeep(defaultAddFilesObject);

    if (property === "files") obj[property] = value as IFile[];
    if (property === "informClient") obj[property] = value as boolean;

    setAddFilesObject(cloneDeep(obj));
  };

  const onCancel = () => {
    const doCancel = () => {
      setUnsavedChanges(false);
      setAddFilesObject(cloneDeep(defaultAddFilesObject));
      setPopupAddFilesVisible(false);
      NOTIFICATIONS.hideDialog();
    };

    if (unsavedChanges)
      NOTIFICATIONS.showDialog({
        type: "yesno",
        message: FDN.I18n.t("main.form.buttons.onCancel.confirm"),
        buttons: [
          {
            type: "cancel",
            onClick: () => NOTIFICATIONS.hideDialog(),
          },
          {
            type: "ok",
            onClick: () => doCancel(),
          },
        ],
      });
    else doCancel();
  };

  const onSave = () => {
    if (!order || !addFilesObject) return;

    NOTIFICATIONS.showSaving({ type: "upload" });

    AdminApi.adminOrderAddFiles(api, order.identifier, addFilesObject).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const order = response?.body?.order as IOrder;
        setOrder(order);
        setUnsavedChanges(false);
        setAddFilesObject(cloneDeep(defaultAddFilesObject));
        setPopupAddFilesVisible(false);

        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("adminOrders.files.onSave.success.title"),
          FDN.I18n.t("adminOrders.files.onSave.success.text")
        );

        NOTIFICATIONS.hideSaving();
      }
    });
  };

  const onResendMail = (fileIdentifier: string) => {
    if (!order) return;

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("adminOrders.files.onResendMail.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => {
            NOTIFICATIONS.showSaving({ type: "save" });

            AdminApi.adminOrderFileSendMail(api, order.identifier, fileIdentifier).then((response) => {
              if (response?.statusCode === StatusCode.SUCCESS) {
                const order = response?.body?.order as IOrder;
                setOrder(order);
                setUnsavedChanges(false);
                setAddFilesObject(cloneDeep(defaultAddFilesObject));
                setPopupAddFilesVisible(false);

                NOTIFICATIONS.showNotification(
                  "success",
                  FDN.I18n.t("adminOrders.files.onResendMail.success.title"),
                  FDN.I18n.t("adminOrders.files.onResendMail.success.text")
                );

                NOTIFICATIONS.hideSaving();
              }
            });
          },
        },
      ],
    });
  };

  const onRemoveFile = (fileIdentifier: string) => {
    if (!order) return;

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("adminOrders.files.onRemove.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
          },
        },
        {
          type: "ok",
          onClick: () => {
            AdminApi.adminOrderRemoveFile(api, order.identifier, fileIdentifier).then((response) => {
              if (response?.statusCode === StatusCode.SUCCESS) {
                const order = response?.body?.order as IOrder;
                setOrder(order);
                setUnsavedChanges(false);
                setAddFilesObject(cloneDeep(defaultAddFilesObject));
                setPopupAddFilesVisible(false);

                NOTIFICATIONS.showNotification(
                  "success",
                  FDN.I18n.t("adminOrders.files.onRemove.success.title"),
                  FDN.I18n.t("adminOrders.files.onRemove.success.text")
                );
              }
            });
          },
        },
      ],
    });
  };

  const onSelectDriver = (identifier: string | undefined) => {
    if (!order) return;

    const cachedOrder = cloneDeep(order);
    cachedOrder.selectedDriverIdentifier = identifier;

    order.selectedDriverIdentifier = "_saving";
    setOrder(cloneDeep(order));

    NOTIFICATIONS.showSaving({ type: "save" });

    AdminApi.adminOrderAssignDriver(api, order.identifier, identifier).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const order = response?.body?.order as IOrder;
        setOrder(order);

        NOTIFICATIONS.hideSaving();
        NOTIFICATIONS.showNotification(
          "success",
          FDN.I18n.t("adminOrders.driver.select.onSave.success.title"),
          FDN.I18n.t("adminOrders.driver.select.onSave.success.text", { orderNr: order.orderNr })
        );
      }
    });
  };

  const onCancelOrder = () => {
    if (!order) return;

    const doCancelOrder = () => {
      NOTIFICATIONS.showSaving({ type: "save" });

      AdminApi.adminOrderCancel(api, order.identifier).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          const order = response?.body?.order as IOrder;
          setOrder(order);

          NOTIFICATIONS.hideSaving();
          NOTIFICATIONS.showNotification(
            "success",
            FDN.I18n.t("adminOrders.cancel.success.title"),
            FDN.I18n.t("adminOrders.cancel.success.text", { orderNr: order.orderNr })
          );
        }
      });
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("adminOrders.cancel.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => doCancelOrder(),
        },
      ],
    });
  };

  const onUncancelOrder = () => {
    if (!order) return;

    const doUncancelOrder = () => {
      NOTIFICATIONS.showSaving({ type: "save" });

      AdminApi.adminOrderUncancel(api, order.identifier).then((response) => {
        if (response?.statusCode === StatusCode.SUCCESS) {
          const order = response?.body?.order as IOrder;
          setOrder(order);

          NOTIFICATIONS.hideSaving();
          NOTIFICATIONS.showNotification(
            "success",
            FDN.I18n.t("adminOrders.uncancel.success.title"),
            FDN.I18n.t("adminOrders.uncancel.success.text", { orderNr: order.orderNr })
          );
        }
      });
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: FDN.I18n.t("adminOrders.uncancel.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => NOTIFICATIONS.hideDialog(),
        },
        {
          type: "ok",
          onClick: () => doUncancelOrder(),
        },
      ],
    });
  };

  const actions: TActionsAdminOrder = {
    onOrderFileUploadsCompleted,
    onEditAddedFile,
    onEditAddedFileObject,
    showPopupAddFiles,
    hidePopupAddFiles,
    onRemoveFile,
    onCancel,
    onSave,
    onResendMail,
    onSelectDriver,
    onCancelOrder,
    onUncancelOrder,
  };

  return {
    APP,
    order,
    orderTitle,
    adventure,
    reqInfo,
    reqInfoFiles,
    payments,
    tabs,
    selectedTab,
    setSelectedTab,
    popupAddFilesVisible,
    addFilesObject,
    drivers,
    actions,
  };
};

export default useServiceAdminOrder;

import Config from "src/core/Config";
import apiConfig from "src/config/api.json";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { IFatalError } from "src/components/context/NotificationsContext/types";
import LocalStorageService from "src/core/LocalStorageService";
import UrlService from "src/core/UrlService";
import StatusCode from "src/config/statuscodes";
import * as FDN from "src/core";
import { FileUploadFile } from "src/components/main/ImageUpload/types";
import { Logger } from "src/core";

/**
 * Every api call returns an api response objects like this
 */
export interface ApiResponse {
  httpCode?: number | null;
  statusCode?: string | null;
  app?: { [key: string]: unknown } | null;
  user?: { [key: string]: any } | null;
  body?: { [key: string]: any } | null;
}

export interface ApiRequestOptions {
  pass401?: boolean;
  pass403?: boolean;
  ignoreHandleApiResponse?: boolean;
  queryParams?: boolean;
}

export default class Api {
  private APP: any = null;
  private NOTIFICATIONS: any = null;

  constructor(APP?: any, NOTIFICATIONS?: any) {
    this.APP = APP;
    this.NOTIFICATIONS = NOTIFICATIONS;
  }

  /**
   * API GET Call
   *
   * @param endpoint Key for the endpoint
   * @param params Parameters for the api get url
   * @param options Possible API call options (see ApiRequestOptions interface)
   * @returns Promise<ApiResponse>
   */
  public async get(endpoint: string, params?: { [key: string]: any }, options?: ApiRequestOptions) {
    let apiUrl;

    // Add params as query param behind url
    if (options?.queryParams === true) apiUrl = this.getEndpoint(endpoint, params, params);
    else apiUrl = this.getEndpoint(endpoint, params);

    // TODO: Show error
    if (!apiUrl) return null;
    if (Config.isDebug()) Logger.info(`GET | Calling API at [${apiUrl}]...`);

    const apiOptions = this.getApiOptions();

    return await axios
      .get(apiUrl, apiOptions)
      .then((response) => {
        this.handleApiResponse(<AxiosResponse>response);

        const httpCode = response.status as number;
        const statusCode = response.data.status as string;
        const { app, user, body } = response.data;

        if (Config.isDebug()) Logger.success("RESPONSE BODY", body);

        return <ApiResponse>{ httpCode, statusCode, app, user, body };
      })
      .catch((error) => {
        if (!this.handleApiError(error)) return;

        const httpCode = error?.response?.status as number;
        const statusCode = error?.response?.data?.status as string;

        return <ApiResponse>{ httpCode, statusCode };
      });
  }

  /**
   * API POST Call
   *
   * @param endpoint Key for the endpoint
   * @param params Parameters for the api post url
   * @param formData Object that will be passed as body
   * @param options Possible API call options (see ApiRequestOptions interface)
   * @returns Promise<ApiResponse>
   */
  public async post(
    endpoint: string,
    params?: { [key: string]: any } | null,
    formData?: object,
    options?: ApiRequestOptions
  ) {
    const apiUrl = this.getEndpoint(endpoint, params);

    // TODO: Show error
    if (!apiUrl) return null;
    if (Config.isDebug()) Logger.info(`POST | Calling API at [${apiUrl}]...`);

    const apiOptions = this.getApiOptions();

    return await axios
      .post(apiUrl, formData, apiOptions)
      .then((response) => {
        if (options?.ignoreHandleApiResponse !== true)
          this.handleApiResponse(<AxiosResponse>response);

        const httpCode = response.status as number;
        const statusCode = response.data.status as string;
        const { app, user, body } = response.data;

        if (Config.isDebug()) Logger.success("RESPONSE BODY", body);

        return <ApiResponse>{ httpCode, statusCode, app, user, body };
      })
      .catch((error) => {
        if (!this.handleApiError(error, options)) return;

        const httpCode = error?.response?.status as number;
        const statusCode = error?.response?.data?.status as string;

        return <ApiResponse>{ httpCode, statusCode };
      });
  }

  /**
   * API DELETE Call
   *
   * @param endpoint Key for the endpoint
   * @param params Parameters for the api delete url
   * @returns Promise<ApiResponse>
   */
  public async delete(endpoint: string, params?: { [key: string]: any }) {
    const apiUrl = this.getEndpoint(endpoint, params);

    // TODO: Show error
    if (!apiUrl) return null;
    if (Config.isDebug()) Logger.info(`DELETE | Calling API at [${apiUrl}]...`);

    const apiOptions = this.getApiOptions();

    return await axios
      .delete(apiUrl, apiOptions)
      .then((response) => {
        this.handleApiResponse(<AxiosResponse>response);

        const httpCode = response.status as number;
        const statusCode = response.data.status as string;
        const { app, user, body } = response.data;

        if (Config.isDebug()) Logger.success("RESPONSE BODY", body);

        return <ApiResponse>{ httpCode, statusCode, app, user, body };
      })
      .catch((error) => {
        if (!this.handleApiError(error)) return;

        const httpCode = error?.response?.status as number;
        const statusCode = error?.response?.data?.status as string;

        return <ApiResponse>{ httpCode, statusCode };
      });
  }

  /**
   * API POST Call for uploading files
   *
   * @param endpoint Key for the endpoint
   * @param params Parameters for the api get url
   * @param files Array of FileUploadFiles
   * @returns Promise<ApiResponse>
   */
  public async uploadFiles(
    endpoint: string,
    params: { [key: string]: string | number } | null,
    files: [FileUploadFile]
  ) {
    const apiUrl = this.getEndpoint(endpoint, params) as string;

    const formData = new FormData();

    for (const file of files) {
      const fileContent: Blob = file.croppedImage ? file.croppedImage : file.original;
      formData.append("files", fileContent, file.name);
    }

    if (!apiUrl) return null;
    if (Config.isDebug()) Logger.info(`POST | Calling API at [${apiUrl}]...`);

    const apiOptions = this.getApiOptions();

    return await axios
      .post(apiUrl, formData, apiOptions)
      .then((response) => {
        this.handleApiResponse(<AxiosResponse>response);

        const httpCode = response.status as number;
        const statusCode = response.data.status as string;
        const { app, user, body } = response.data;

        if (Config.isDebug()) Logger.success("RESPONSE BODY", body);

        return <ApiResponse>{ httpCode, statusCode, app, user, body };
      })
      .catch((error) => {
        if (!this.handleApiError(error)) return;

        const httpCode = error?.response?.status as number;
        const statusCode = error?.response?.data?.status as string;

        return <ApiResponse>{ httpCode, statusCode };
      });
  }

  /**
   * API Post Call to attempt a login with email and password
   *
   * @param email
   * @param password
   * @returns Promise<ApiResponse>
   */
  public async attemptLogin(email: string, password: string) {
    const apiUrl = this.getEndpoint("auth.login");

    if (!apiUrl) return null;

    const formData = {
      email,
      password,
    };

    return await axios
      .post(apiUrl, formData)
      .then((response) => {
        this.handleApiResponse(<AxiosResponse>response);

        const httpCode = response.status as number;
        const statusCode = response.data.status as string;
        const { app, user, body } = response.data;

        if (Config.isDebug()) Logger.success("RESPONSE BODY", body);

        return <ApiResponse>{ httpCode, statusCode, app, user, body };
      })
      .catch((error) => {
        const httpCode = error?.response?.status as number;
        const statusCode = error?.response?.data?.status as string;

        return <ApiResponse>{ httpCode, statusCode };
      });
  }

  /**
   * Every API call calls this method to handle the api response
   * @param response AxiosResponse
   */
  private handleApiResponse(response: AxiosResponse) {
    const statusCode = response.status as number;
    if ((statusCode >= 200 && statusCode <= 299) || statusCode === 304)
      if (Config.isDebug()) Logger.success("HTTP RESPONSE STATUS CODE", statusCode);

    if (Config.isDebug()) Logger.info("API RESPONSE", response);

    const { app, user } = response.data;

    this.APP.handleApiResponse({ app, user });
  }

  /**
   * If an error occures while excuting the api call
   * this method will be called.
   * If the server is unreachable a fatal error will be thrown
   */
  private handleApiError(error: AxiosError, options?: { [key: string]: any }): boolean {
    if (!options) options = {};

    const fatalError: IFatalError = {
      title: "Unknown Error",
      text: "Sorry for the unconvinience, but an unknown error occured.",
    };

    if (error) {
      let returnValue = true;
      if (Config.isDebug()) Logger.error(error);

      if (error?.response?.status === 401 && !options.pass401) {
        returnValue = false;

        let isLogin = false;
        if (error?.response?.data) {
          const data: { [key: string]: any } = error.response.data;
          if (data.status && data.status === StatusCode.INVALID_LOGIN_DATA) isLogin = true;
        }
        if (!isLogin)
          window.location.href = UrlService.url(
            Config.get("auth.redirectIfUnauthorized") as string
          );

        return false;
      } else if (error?.response?.status === 403 && !options.pass403) {
        returnValue = false;

        window.location.href = UrlService.url(Config.get("auth.redirectIfForbidden") as string);
        return false;
      } else {
        if (this.NOTIFICATIONS) {
          if (error?.response?.data) {
            const data: { [key: string]: any } = error.response.data;
            this.NOTIFICATIONS.showSavingError({ statusCode: data.status || "ERROR_UNKNOWN" });
          }
        }
      }

      // If server is unreachable
      if (["ERR_NETWORK"].includes(error?.code as string)) {
        fatalError.title = FDN.I18n.t("errors.serverunreachable.title");
        fatalError.text = FDN.I18n.t("errors.serverunreachable.text");
        this.NOTIFICATIONS.fatalError(fatalError);
      }

      // If api middleware throws error
      if (error?.response?.data) {
        const data: { [key: string]: any } = error?.response?.data;
        if (data?.status === StatusCode.ERROR_FATAL || data?.status === StatusCode.ERROR_CRASH) {
          fatalError.title = FDN.I18n.t("errors.fatalerror.title");
          fatalError.text = FDN.I18n.t("errors.fatalerror.text");
          this.NOTIFICATIONS.fatalError(fatalError);
        }
      }

      return returnValue;
    }

    return true;
  }

  /**
   * Method to get the HTTP Url for given endpoint key
   * and parameters
   *
   * @param key
   * @param params
   * @returns Api Url or null
   */
  public getEndpoint(
    key: string,
    params?: { [key: string]: string | number } | null,
    queryParams?: { [key: string]: string | number } | null
  ): string | null {
    const environment = Config.detectEnvironment();

    try {
      let endpoint = `${this.getConfig(`hosts.${environment}`)}${
        this.getConfig(`baseUrl`) as string
      }${this.getConfig(`endpoints.${key}`) as string}`;

      if (params) {
        Object.keys(params).map((paramKey) => {
          if (endpoint !== null)
            endpoint = endpoint.replace(`{${paramKey}}`, params[paramKey] as string);
          return null;
        });
      }

      if (queryParams) {
        Object.keys(queryParams).map((paramKey, index) => {
          if (endpoint !== null)
            endpoint = `${endpoint}${index === 0 ? `?` : `&`}${paramKey}=${queryParams[paramKey]}`;
          return null;
        });
      }

      return endpoint;
    } catch (error) {
      if (Config.isDebug()) Logger.error(error);
      return null;
    }
  }

  /**
   * Check if the Websocket (socket.io) connection should be established
   */
  public isSocketEnabled(): boolean {
    return this.getConfig(`socket.enabled`) as boolean;
  }

  /**
   * Get the url to connect to the socket.io server
   */
  public getSocketHost(): string | null {
    const environment = Config.detectEnvironment();
    return (this.getConfig(`hosts.${environment}`) as string) || null;
  }

  /**
   * Read config from api.config.json
   */
  private getConfig(key: string): unknown {
    return Config.resolveDotNotation(apiConfig, key);
  }

  /**
   * Create Config Object that can be passed via Axios
   * with headers, Authorization etc.
   */
  public getApiOptions(): AxiosRequestConfig {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "",
      },
    };

    if (LocalStorageService.get("ACCESS_TOKEN")) {
      options.headers.Authorization = `Bearer ${LocalStorageService.get("ACCESS_TOKEN")}`;
    }

    return options;
  }
}

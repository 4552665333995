import * as React from "react";
import * as FDN from "src/core";
import AdminLayout from "../layouts/AdminLayout";
import AdminHeader from "../layouts/AdminHeader";
import useHotels from "src/services/admin/useHotels";
import { userHasPermission } from "src/core/AdminService/helpers";
import HotelsSearch from "./parts/HotelsSearch";
import HotelsList from "./parts/HotelsList";
import HotelForm from "./parts/HotelForm";

const AdminHotels: React.FunctionComponent = () => {
  const {
    APP,
    filteredHotels: hotels,
    selectedHotel,
    adventuresOfHotel,
    search,
    countries,
    locationCountries,
    cities,
    actions,
  } = useHotels();
  const user = APP.getUser();

  React.useEffect(() => {
    actions.init();
  }, []);

  if (!hotels) return <FDN.Loading />;

  return (
    <div className="__admin-hotels">
      <AdminLayout page="admin-hotels" selectedNav="hotels">
        <AdminHeader
          title={FDN.I18n.t("adminHotels.header.title")}
          buttons={[
            <>
              {userHasPermission(user, "adminAdventuresEdit") ? (
                <button className="primary button" onClick={() => actions.selectHotel("new")}>
                  <FDN.Icon icon="plus" left /> {FDN.I18n.t("adminHotels.buttons.add.label")}
                </button>
              ) : null}
            </>,
          ]}
        />

        <HotelsSearch search={search} countries={countries} cities={cities} actions={actions} />

        <HotelsList hotels={hotels} actions={actions} />

        {selectedHotel && (
          <HotelForm
            hotel={selectedHotel}
            adventures={adventuresOfHotel}
            locationCountries={locationCountries}
            actions={actions}
          />
        )}
      </AdminLayout>
    </div>
  );
};

export default AdminHotels;

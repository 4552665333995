import * as React from "react";
import * as FDN from "src/core";
import { cloneDeep } from "lodash";
import UrlService from "src/core/UrlService";
import { LocationCountries, LocationRegions, IOrders, IAdventures } from "src/types";
import {
  IAdminListBodyRow,
  IAdminListColumn,
  IAdminListSchema,
} from "src/components/main/AdminList/types";
import AdminList from "src/components/main/AdminList";
import Config from "src/core/Config";
import { TActionsAdminOrders } from "src/services/admin/OrdersService";
import { format } from "date-fns";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: { md: 4 },
    textAlign: "center",
    content: FDN.I18n.t("adminOrders.list.head.orderDate.title"),
  },
  {
    size: {
      md: 8,
    },
    content: FDN.I18n.t("adminOrders.list.head.title.title"),
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="users" />,
    tooltip: FDN.I18n.t("adminOrders.list.head.participants.tooltip"),
  },
  {
    size: { md: 2 },
    textAlign: "center",
    content: <FDN.Icon icon="list-alt" />,
    tooltip: FDN.I18n.t("adminOrders.list.head.reqInfo.tooltip"),
  },
  {
    size: { md: 3 },
    textAlign: "right",
    content: (
      <>
        <FDN.Icon icon="dollar" />
      </>
    ),
  },
  {
    size: { md: 6 },
    textAlign: "center",
    content: FDN.I18n.t("adminOrders.list.head.driver.title"),
  },
];

const listSchema: IAdminListSchema = {
  head: { columns: listColumnsSchema },
  body: { rows: [] },
};

const buildAdminList = (
  orders: IOrders,
  locationCountries: LocationCountries,
  adventures: IAdventures
) => {
  if (!orders) return;

  const adminListSchema = cloneDeep(listSchema);
  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const order of orders) {
    if (!order) return;

    const adventure = adventures.find((a) => a.identifier === order.adventureIdentifier);

    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    row.url = UrlService.url("admin.orders.show", { identifier: order.identifier });

    let titlePreTitle = <></>;
    if (locationCountries && adventure && adventure.country) {
      const country = locationCountries.find((c) => c.identifier === adventure.country);
      if (country) {
        titlePreTitle = <>{country.name}</>;

        if (adventure.region && country.regions) {
          const regions = country.regions as LocationRegions;
          const region = regions.find((r) => r.identifier === adventure.region);
          if (region)
            titlePreTitle = (
              <>
                {titlePreTitle} <FDN.Icon icon="angle-right" /> {region.name}
              </>
            );
        }
      }
    }

    let dateStr = "";
    if (order.nights && order.nights > 0 && order.endDate) {
      const startDate = new Date(order.startDate);
      const endDate = new Date(order.endDate);

      if (format(startDate, "yyyy") === format(endDate, "yyyy")) {
        // if (startDate.getMonth() == endDate.getMonth())
        //   dateStr = `${format(startDate, "dd.")} - ${format(endDate, "dd.MM.yyyy")}`;
        dateStr = `${format(startDate, "dd.MM.")} - ${format(endDate, "dd.MM.yyyy")}`;
      }
    } else dateStr = FDN.formatDate(order.startDate);

    row.columns[0].content = <>{FDN.formatDateTime(order.createdAt)}</>;

    row.columns[1].content = (
      <>
        <div className="__adminlist-name">
          <div className="__adminlist-name-pretitle">
            # <span className="color-lightpurple">{order.orderNr}</span> &nbsp;&nbsp;&nbsp;
            <FDN.Icon icon="calendar" left /> {dateStr}
          </div>
          <div className="__adminlist-name-plaintitle">
            {order.participants[0].salutation || null} {order.lastname}, {order.firstname}
          </div>
          <div className="__adminlist-name-subtitle">{order.adventureTitle}</div>
        </div>
      </>
    );

    row.columns[2].content = <>{order.participants.length}</>;

    let reqInfoClass = "";
    if (order.reqInfoCompleted !== undefined) {
      if (order.reqInfoCompleted === 100) reqInfoClass = "success-text";
      else if (order.reqInfoCompleted > 0 && order.reqInfoCompleted < 100)
        reqInfoClass = "warning-text";
      else if (order.reqInfoCompleted === 0) reqInfoClass = "error-text";
    }

    row.columns[3].content = (
      <span className={`${reqInfoClass}`}>
        {order.reqInfoCompleted === 101 ? <FDN.Icon icon="minus" /> : `${order.reqInfoCompleted} %`}
      </span>
    );

    let firstPaymentClass = "";
    if (
      order.paymentStatus === "NOW_PAID" ||
      order.paymentStatus === "LATER_REQUIRED" ||
      order.paymentStatus === "PAID"
    )
      firstPaymentClass = `success-text`;
    else if (order.paymentStatus === "NOW_REQUIRED" || order.paymentStatus === "NOW_PENDING")
      firstPaymentClass = `warning-text`;

    let secondPaymentClass = "";
    if (order.paymentStatus === "PAID") secondPaymentClass = `success-text`;
    else if (order.paymentStatus === "LATER_REQUIRED" || order.paymentStatus === "LATER_PENDING")
      secondPaymentClass = `warning-text`;

    let totalPaymentClass = "";
    if (order.paymentStatus === "PAID") totalPaymentClass = `success-text`;
    else totalPaymentClass = `warning-text`;

    row.columns[4].content = (
      <>
        {order.calculation.now ? (
          <div className={`admin-orders-list-price ${firstPaymentClass}`}>
            <FDN.ToolTip tooltip={FDN.I18n.t("adminOrders.list.head.firstpayment.tooltip")}>
              {Config.get("currency") as string} {FDN.formatNumber(order.calculation.now, 2, 2)}
            </FDN.ToolTip>
          </div>
        ) : null}
        {order.calculation.later ? (
          <div className={`admin-orders-list-price ${secondPaymentClass}`}>
            <FDN.ToolTip tooltip={FDN.I18n.t("adminOrders.list.head.secondpayment.tooltip")}>
              {Config.get("currency") as string} {FDN.formatNumber(order.calculation.later, 2, 2)}
            </FDN.ToolTip>
          </div>
        ) : null}
        {order.calculation.total && order.calculation.now && order.calculation.later ? (
          <div
            className={`admin-orders-list-price admin-orders-list-price-total ${totalPaymentClass}`}
          >
            <FDN.ToolTip tooltip={FDN.I18n.t("adminOrders.list.head.total.tooltip")}>
              {Config.get("currency") as string} {FDN.formatNumber(order.calculation.total, 2, 2)}
            </FDN.ToolTip>
          </div>
        ) : null}
      </>
    );
    row.columns[5].content = (
      <>
        {order.driver ? (
          <div className="admin-orders-list-driver-wrapper">
            <div className="admin-orders-list-driver">
              <div className="admin-orders-list-driver-name">{order.driver.displayname}</div>
              <div>{order.driver.whatsapp}</div>
            </div>
          </div>
        ) : (
          <FDN.Icon icon="minus" />
        )}
      </>
    );

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface IOrdersListProps {
  orders: IOrders;
  locationCountries?: LocationCountries;
  adventures: IAdventures;
  actions: TActionsAdminOrders;
}

const OrdersList: React.FunctionComponent<IOrdersListProps> = ({
  orders,
  locationCountries,
  adventures,
}) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  React.useEffect(() => {
    if (orders) {
      setAdminListSchema(
        buildAdminList(
          orders as IOrders,
          locationCountries as LocationCountries,
          adventures as IAdventures
        )
      );
      setListLoading(false);
    }
  }, [orders]);

  if (!orders) return <FDN.Loading box />;

  return <>{adminListSchema && <AdminList schema={adminListSchema} loading={listLoading} />}</>;
};

export default OrdersList;

import * as React from "react";
import * as FDN from "src/core";
import { IPasswordForgottenInputValues, TActions } from "src/types";
import useServicePasswordForgotten, {
  PageStatus,
} from "src/services/auth/PasswordForgottenService";
import { Link } from "react-router-dom";

const PasswordForgottenPage: React.FunctionComponent = () => {
  const { actions, logoLoginUrl, pageStatus, errorEmpty, errorInvalidEmail, inputValues } =
    useServicePasswordForgotten();

  const formErrors = {
    "login.emptyInput": errorEmpty,
    "login.invalidEmail": errorInvalidEmail,
  };

  return (
    <FDN.LayoutWithoutTopbar page="login">
      <FDN.Grid>
        <FDN.Row margin="xy">
          <FDN.Cell md={12} mdo={6}>
            {logoLoginUrl && (
              <div className="__login-logo">
                <Link to="/">
                  <img src={logoLoginUrl} alt="" />
                </Link>
              </div>
            )}
            <h1>{FDN.I18n.t("passwordreset.title")}</h1>
            <div className="__login-intro lead">{FDN.I18n.t("passwordreset.intro")}</div>
            {pageStatus === PageStatus.SUCCESS ? (
              <FDN.CalloutBox type="success">
                {FDN.I18n.t("passwordreset.pagestatus.success")}
              </FDN.CalloutBox>
            ) : (
              <>
                <FDN.FormErrors errors={formErrors} />
                <LoginBox inputValues={inputValues} actions={actions} />
              </>
            )}
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </FDN.LayoutWithoutTopbar>
  );
};

interface ILoginBoxProps {
  inputValues: IPasswordForgottenInputValues;
  actions: TActions;
}

const LoginBox = ({ inputValues, actions }: ILoginBoxProps) => {
  return (
    <div className="__login-box">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={24}>
            <FDN.Input
              type="email"
              editMode={true}
              required={true}
              value={inputValues.email}
              label={FDN.I18n.t("passwordreset.form.email.label")}
              placeholder={FDN.I18n.t("passwordreset.form.email.placeholder")}
              onUpdate={(value) => actions.onInput("email", value)}
              onKeyUp={actions.setPassword}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
      <FDN.FormButtons
        saveLabel={FDN.I18n.t("passwordreset.form.loginButton.label")}
        onSave={actions.attemptReset}
        marginBottom="20px"
      />
    </div>
  );
};

export default PasswordForgottenPage;

import * as React from "react";
import * as FDN from "src/core";
import AdminLayout from "../layouts/AdminLayout";
import AdminHeader from "../layouts/AdminHeader";
import useServiceAdminAdventure from "src/services/admin/AdventureService";
import { AppContext } from "src/components/context/AppContext/AppContext";
import FormButtons from "src/components/main/forms/FormButtons";
import UrlService from "src/core/UrlService";
import { useNavigate } from "react-router-dom";
import AdventureInfo from "./tabs/AdventureInfo";
import { userHasPermission } from "src/core/AdminService/helpers";
import AdventurePrices from "./tabs/AdventurePrices";
import AdventurePictures from "./tabs/AdventurePictures";
import AdventureUpgrades from "./tabs/AdventureUpgrades";
import AdventureHotelsListByNight from "./tabs/AdventureHotelsByNight";

const tabs: FDN.ITabs = {
  info: {
    label: FDN.I18n.t("adminAdventures.tabs.info.label"),
    icon: "info-circle",
  },
  pictures: {
    label: FDN.I18n.t("adminAdventures.tabs.pictures.label"),
    icon: "picture-o",
  },
  prices: {
    label: FDN.I18n.t("adminAdventures.tabs.prices.label"),
    icon: "dollar",
  },
  hotels: {
    label: FDN.I18n.t("adminAdventures.tabs.hotels.label"),
    icon: "bed",
  },
  upgrades: {
    label: FDN.I18n.t("adminAdventures.tabs.upgrades.label"),
    icon: "binoculars",
  },
};

const defaultTab = "info";

const AdminAdventure: React.FunctionComponent = () => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  const {
    actions,
    editMode,
    adventure,
    locationCountries,
    hotelAddPopupVisible,
    hotelEditPopupVisible,
    hotel,
    hotels,
    upgradeAddPopupVisible,
    upgradeEditPopupVisible,
    upgrade,
    upgrades,
  } = useServiceAdminAdventure();

  const navigate = useNavigate();

  const APP = React.useContext(AppContext);
  const user = APP.getUser();

  if (!tabs || !actions || !adventure) return <FDN.Loading />;

  let urlForAdventure = `${window.location.protocol}//${window.location.host}${UrlService.url("public.adventure", {
    slug: adventure.slug,
  })}`;

  if (adventure.isPrivate === true)
    urlForAdventure = `${window.location.protocol}//${window.location.host}${UrlService.url("public.privateAdventure", {
      slug: adventure.slug,
      token: adventure.privateToken || "",
    })}`;

  return (
    <AdminLayout page="admin-adventures" selectedNav="adventures">
      <AdminHeader
        title={adventure.identifier === "new" ? FDN.I18n.t("adminAdventures.adventure.new.title") : adventure.title}
        subTitle={
          adventure.slug ? (
            <>
              {FDN.I18n.t("adminAdventures.urlForAdventure")}:{" "}
              <a href={urlForAdventure} target="_blank" rel="noopener noreferrer">
                {urlForAdventure}
              </a>
            </>
          ) : undefined
        }
        backButton={{ url: UrlService.url("admin.adventures.index") }}
        buttons={[
          <>
            {!editMode && userHasPermission(user, "adminAdventuresEdit") && (
              <>
                <button onClick={() => actions.duplicateAdventure()} className="primary hollow button">
                  <FDN.Icon icon="clone" left /> {FDN.I18n.t("adminAdventures.buttons.duplicate.label")}
                </button>
              </>
            )}
            {!editMode && userHasPermission(user, "adminAdventuresEdit") && (
              <>
                <button onClick={() => actions.toggleEditMode(true)} className="primary button">
                  <FDN.Icon icon="edit" left /> {FDN.I18n.t("adminAdventures.buttons.edit.label")}
                </button>
              </>
            )}
          </>,
        ]}
      />
      <FDN.Tabs
        updateHistory={true}
        editMode={editMode}
        tabs={tabs}
        selected={selectedTab}
        onSelect={(tabKey) => setSelectedTab(tabKey)}
        onUpdateUrl={(params) => navigate({ search: params })}
      />
      {editMode === true && <FormButtons onCancel={actions.onCancel} onSave={actions.onSave} />}
      <FDN.TabsContent>
        {selectedTab === "info" && (
          <AdventureInfo adventure={adventure} editMode={editMode} actions={actions} countries={locationCountries} />
        )}
        {selectedTab === "prices" && <AdventurePrices adventure={adventure} editMode={editMode} actions={actions} />}
        {selectedTab === "pictures" && (
          <AdventurePictures adventure={adventure} editMode={editMode} actions={actions} />
        )}
        {selectedTab === "hotels" && (
          <AdventureHotelsListByNight adventure={adventure} editMode={editMode} adventureActions={actions} />
        )}
        {selectedTab === "upgrades" && (
          <AdventureUpgrades
            adventure={adventure}
            upgradeAddPopupVisible={upgradeAddPopupVisible}
            upgradeEditPopupVisible={upgradeEditPopupVisible}
            upgrade={upgrade}
            upgrades={upgrades}
            editMode={editMode}
            actions={actions}
          />
        )}
      </FDN.TabsContent>
      {editMode === true && <FormButtons onCancel={actions.onCancel} onSave={actions.onSave} marginBottom="20px" />}
    </AdminLayout>
  );
};

export default AdminAdventure;

import * as React from "react";
import * as FDN from "src/core";
import { LocationCountries, SearchAdventuresFilter, TActions } from "src/types";

const maxNights = 30;

interface IAdventuresSearchProps {
  search: SearchAdventuresFilter;
  locationCountries?: LocationCountries;
  actions: TActions;
}

const AdventuresSearch: React.FunctionComponent<IAdventuresSearchProps> = ({ search, locationCountries, actions }) => {
  if (!locationCountries) return <FDN.Loading box />;

  const selectValuesCountries: Record<string, string> = {};
  selectValuesCountries[""] = "- " + FDN.I18n.t("adventures.search.all") + " -";
  locationCountries.map((country) => {
    selectValuesCountries[country.identifier] = country.name;
  });

  const searchCountry = locationCountries.find((c) => c.identifier === search.country);

  const selectValuesRegions: Record<string, string> = {};
  selectValuesRegions[""] = "- " + FDN.I18n.t("adventures.search.all") + " -";

  if (searchCountry?.regions) {
    for (const region of searchCountry.regions) {
      selectValuesRegions[region.identifier] = region.name;
    }
  }

  const selectValuesNights: Record<string | number, string | number> = {};
  selectValuesNights[""] = "- " + FDN.I18n.t("adventures.search.all") + " -";

  for (let i = 0; i <= maxNights; i++) {
    selectValuesNights[`night_${i}`] = i;
  }

  return (
    <div className="admin-orders-search">
      <FDN.Box>
        <div className="admin-orders-search-title">
          <h3>{FDN.I18n.t("adminOrders.search.title")}</h3>
          <div className="admin-orders-search-title-buttons">
            <button className="small primary hollow button" onClick={actions.resetSearch}>
              {FDN.I18n.t("adminOrders.search.buttons.reset.label")}
            </button>
          </div>
        </div>

        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <FDN.Input
                type="text"
                value={search.text}
                label={FDN.I18n.t("adminAdventures.search.form.name.label")}
                onUpdate={(value) => actions.updateSearch("text", value)}
                editMode={true}
              />
            </FDN.Cell>
          </FDN.Row>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24} md={24} lg={8}>
              <FDN.Input
                type="select"
                selectValues={selectValuesCountries}
                value={search.country}
                label={FDN.I18n.t("adminAdventures.search.form.country.label")}
                onUpdate={(value) => actions.updateSearch("country", value)}
                editMode={true}
              />
            </FDN.Cell>
            <FDN.Cell sm={24} md={24} lg={8}>
              <FDN.Input
                type="select"
                selectValues={selectValuesRegions}
                value={search.region}
                label={FDN.I18n.t("adminAdventures.search.form.region.label")}
                onUpdate={(value) => actions.updateSearch("region", value)}
                editMode={true}
              />
            </FDN.Cell>
            <FDN.Cell sm={24} md={24} lg={8}>
              <FDN.Input
                type="select"
                selectValues={selectValuesNights}
                value={!search.nights && search.nights !== 0 ? "" : search.nights}
                label={FDN.I18n.t("adminAdventures.search.form.nights.label")}
                onUpdate={(value) => actions.updateSearch("nights", (value as string).replace("night_", ""))}
                editMode={true}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.Box>
    </div>
  );
};

export default AdventuresSearch;

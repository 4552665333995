import * as React from "react";
import * as FDN from "src/core";
import { cloneDeep } from "lodash";
import UrlService from "src/core/UrlService";
import { IAdventure, IAdventures, LocationCountries, LocationRegions, SearchSort } from "src/types";
import { IAdminListBodyRow, IAdminListColumn, IAdminListSchema } from "src/components/main/AdminList/types";
import AdminList from "src/components/main/AdminList";
import Config from "src/core/Config";
import { getAdventurePictureUrl } from "src/core/helpers/helpers";

const doAllNightsHaveHotels = (adventure: IAdventure): boolean => {
  if (!adventure.hotelsByNight) return false;

  let hotelsMissings = false;
  for (const night in adventure.hotelsByNight) {
    if (adventure.hotelsByNight[parseInt(night)].length === 0) {
      hotelsMissings = true;
      break;
    }
  }
  return !hotelsMissings;
};

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: {
      md: 12,
    },
    content: FDN.I18n.t("adminAdventures.list.head.title.title"),
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="unlock" />,
    tooltip: FDN.I18n.t("adminAdventures.list.head.published.title"),
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="moon-o" />,
    tooltip: FDN.I18n.t("adminAdventures.list.head.nights.title"),
  },
  {
    size: { md: 3 },
    textAlign: "right",
    content: <FDN.Icon icon="dollar" />,
    tooltip: FDN.I18n.t("adminAdventures.list.head.fullPrice.title"),
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="bed" />,
    tooltip: FDN.I18n.t("adminAdventures.list.head.hotel.title"),
  },
  {
    size: { md: 1 },
    textAlign: "center",
    content: <FDN.Icon icon="binoculars" />,
    tooltip: FDN.I18n.t("adminAdventures.list.head.upgrades.title"),
  },
];

const listSchema: IAdminListSchema = {
  head: { columns: listColumnsSchema },
  body: { rows: [] },
};

const buildAdminList = (adventures: IAdventures, locationCountries: LocationCountries) => {
  if (!adventures) return;

  const adminListSchema = cloneDeep(listSchema);
  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const adventure of adventures) {
    if (!adventure) return;

    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    row.url = UrlService.url("admin.adventures.show", { identifier: adventure.identifier });

    let titlePreTitle = <></>;
    if (locationCountries && adventure.country) {
      const country = locationCountries.find((c) => c.identifier === adventure.country);
      if (country) {
        titlePreTitle = <>{country.name}</>;

        if (adventure.region && country.regions) {
          const regions = country.regions as LocationRegions;
          const region = regions.find((r) => r.identifier === adventure.region);
          if (region)
            titlePreTitle = (
              <>
                {titlePreTitle} <FDN.Icon icon="angle-right" /> {region.name}
              </>
            );
        }
      }
    }

    const mainPicture = adventure.mainPicture ? getAdventurePictureUrl(adventure.mainPicture, "L") : undefined;

    row.columns[0].content = (
      <>
        <div className="__adminlist-avatar float-left">
          {adventure.mainPicture && (
            <div
              className="__adminlist-adventure-mainpicture"
              style={{ backgroundImage: `url("${mainPicture}")` }}
            ></div>
          )}
        </div>
        <div className="__adminlist-name">
          <div className="__adminlist-name-pretitle">{titlePreTitle}</div>
          <div className="__adminlist-name-title">{adventure.title}</div>
          <div className="__adminlist-name-subtitle">{adventure.description}</div>
        </div>
      </>
    );

    row.columns[1].content = (
      <>
        {adventure.published ? (
          <span className="success-text">
            <FDN.Icon icon="check" />
          </span>
        ) : (
          <span className="error-text">
            <FDN.Icon icon="minus" />
          </span>
        )}
      </>
    );

    row.columns[2].content = <>{adventure.nights}</>;

    row.columns[3].content = (
      <>
        {Config.get("currency")}
        {adventure.priceFull ? FDN.formatNumber(adventure.priceFull, 2, 2) : null}
      </>
    );

    // row.columns[4].content = <>{adventure.hotelPrices?.length}</>;
    row.columns[4].content = (
      <>
        {doAllNightsHaveHotels(adventure) || adventure.nights === 0 ? (
          <span className="text-green-500">
            <FDN.Icon icon="check" />
          </span>
        ) : (
          <span className="text-red-500">
            <FDN.Icon icon="exclamation-triangle" />
          </span>
        )}
      </>
    );
    row.columns[5].content = <>{adventure.upgradePrices?.length}</>;

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface IAdventuresListProps {
  adventures: IAdventures;
  locationCountries?: LocationCountries;
  sortBy?: SearchSort;
  sortOptions?: string[];
  sortOnSelect?: (option: string) => void;
}

const AdventuresList: React.FunctionComponent<IAdventuresListProps> = ({
  adventures,
  locationCountries,
  sortBy,
  sortOptions,
  sortOnSelect,
}) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  React.useEffect(() => {
    if (adventures) {
      setAdminListSchema(buildAdminList(adventures as IAdventures, locationCountries as LocationCountries));
      setListLoading(false);
    }
  }, [adventures]);

  if (!adventures) return <FDN.Loading box />;

  return (
    <>
      {adminListSchema && (
        <AdminList
          schema={adminListSchema}
          loading={listLoading}
          sortBy={sortBy}
          sortOptions={sortOptions}
          sortOnSelect={sortOnSelect}
        />
      )}
    </>
  );
};

export default AdventuresList;

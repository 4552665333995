import * as React from "react";
import * as FDN from "src/core";
import { ISavingInfo, ISavingError } from "src/components/context/NotificationsContext/types";
import SpinnerSvg from "src/assets/icons/Spinner-1s-200px-2.svg";
import { TFunction } from "src/types";

interface ISavingProps {
  savingInfo?: ISavingInfo;
  savingError?: ISavingError | null;
  onClose?: TFunction;
}

const Saving: React.FunctionComponent<ISavingProps> = ({ savingInfo, savingError, onClose }) => {
  if (!savingInfo) return null;

  if (!savingInfo.type) savingInfo.type = "save";

  let title = "";
  //let text = "";
  let icon: React.ReactNode;

  if (["save", "upload", "email"].includes(savingInfo.type)) {
    title = FDN.I18n.t(`main.saving.${savingInfo.type}.title`);
    //text = FDN.I18n.t(`main.saving.${savingInfo.type}.text`);
    icon = <img className="loading-spinner-svg" src={SpinnerSvg} alt="" />;
  }

  return (
    <div className="__saving">
      <div className="__saving-modal">
        <div className="__saving-box">
          {savingError ? (
            <SavingError savingError={savingError} onClose={onClose} />
          ) : (
            <div className="__saving-box-content">
              {icon}
              {title && <h3>{title}</h3>}
              {/*text && <div>{text}</div>*/}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const SavingError = ({ savingError, onClose }: { savingError?: ISavingError | null; onClose?: TFunction }) => {
  if (!savingError) return null;

  let calloutType = "alert";

  if (["EMAIL_EXISTS"].includes(savingError.statusCode as string)) calloutType = "warning";

  return (
    <div className={`__saving-error ${calloutType} callout`}>
      <p>
        <strong>{FDN.I18n.t(`errors.saving.${savingError.statusCode}.title`)}</strong>
      </p>
      <p>{FDN.I18n.t(`errors.saving.${savingError.statusCode}.text`)}</p>
      <div>
        <button
          className="__saving-error-close-message"
          onClick={() => {
            if (onClose) onClose();
          }}
        >
          <FDN.Icon icon="times" /> {FDN.I18n.t("errors.saving.close")}
        </button>
      </div>
    </div>
  );
};

export default Saving;

import * as React from "react";
import * as FDN from "src/core";
import useServiceAdminOrders from "src/services/admin/OrdersService";
import AdminLayout from "../layouts/AdminLayout";
import AdminHeader from "../layouts/AdminHeader";
import OrdersList from "./parts/OrdersList";
import OrdersSearch from "./parts/OrdersSearch";

const AdminOrders: React.FunctionComponent = () => {
  const {
    orders,
    adventures,
    locationCountries,
    tabs,
    selectedTab,
    setSelectedTab,
    filteredOrders,
    search,
    isLoading,
    actions,
  } = useServiceAdminOrders();

  if (!orders || !adventures || !tabs) return <FDN.Loading />;

  return (
    <div className="__admin-orders">
      <AdminLayout page="admin-orders" selectedNav="orders">
        <AdminHeader title={FDN.I18n.t("adminOrders.header.title")} />
        <div className="__admin-orders-list">
          <OrdersSearch search={search} actions={actions} />
          <FDN.Tabs tabs={tabs} selected={selectedTab} onSelect={(tabKey) => setSelectedTab(tabKey)}>
            {isLoading ? (
              <FDN.Loading box />
            ) : (
              <OrdersList
                orders={filteredOrders}
                adventures={adventures}
                locationCountries={locationCountries}
                actions={actions}
              />
            )}
          </FDN.Tabs>
        </div>
      </AdminLayout>
    </div>
  );
};

export default AdminOrders;

import { cloneDeep } from "lodash";
import React, { useState } from "react";
import { getAge, hasAdventureHotels, hasOwnProperty } from "src/core/helpers/helpers";
import {
  AdventureHotelRoom,
  FCalculatePrice,
  IAdventure,
  IAdventureHotel,
  IOrder,
  IOrderCalculation,
  IOrderHotel,
  IOrderParticipant,
  TFunction,
} from "src/types";
import add from "date-fns/add";
import localstorage from "src/config/localstorage.json";
import { I18n, toggleArrayItem } from "src/core";
import BookingApi from "src/api/BookingApi";
import Api from "src/api/Api";
import UrlService from "src/core/UrlService";
import { v4 } from "uuid";

export type BookingOrderPages =
  | "date"
  | "participants"
  | "personal"
  | "hotel"
  | "paymentPlan"
  | "upgrades"
  | "preview"
  | "payment"
  | "confirmation"
  | "message";

export const defaultOrderPages: BookingOrderPages[] = [
  "date",
  "participants",
  "hotel",
  "upgrades",
  "paymentPlan",
  "personal",
  "preview",
];

const requiredFields = {
  date: ["startDate"],
  personal: ["street", "zip", "city", "country", "email", "phone"],
  participants: ["firstname", "lastname", "salutation", "birthday"],
  paymentPlan: ["paymentPlan"],
  preview: ["termsAccepted"],
  hotel: [],
  upgrades: [],
};

interface IBookingContextProviderProps {
  children?: any;
}

interface IBookingContext {
  isSaving: boolean;
  adventure?: IAdventure;
  order?: IOrder;
  orderPage: BookingOrderPages;
  orderPages?: BookingOrderPages[];
  virginOrder?: IOrder;
  virginOrderParticipant?: IOrderParticipant;
  setVirginOrder: TFunction;
  selectedNight?: number;
  showUpgradesForNight: (night: number | undefined) => void;
  getSelectedHotelForNight: (night: number) => IOrderHotel | null;
  setAdventure: TFunction;
  missingFields?: string[];
  completedPages?: BookingOrderPages[];
  onEdit: TFunction;
  onEditParticipant: TFunction;
  onAddParticipant: TFunction;
  onRemoveParticipant: TFunction;
  isParticipantChild: TFunction;
  getPriceDifferenceForRoom: (room: AdventureHotelRoom, night: number) => number;
  selectRoom: (hotel: IAdventureHotel, room: AdventureHotelRoom, night: number) => void;
  setOrderPage: TFunction;
  initOrderPages: TFunction;
  goToPage: (toPage: BookingOrderPages) => void;
  onNextPage: () => boolean;
  onPrevPage: TFunction;
  calculatePrice: FCalculatePrice;
  bookNow: TFunction;
  clearCart: TFunction;
}

const initialContextValue: IBookingContext = {
  isSaving: false,
  orderPage: defaultOrderPages[0],
  orderPages: undefined,
  setVirginOrder: () => null,
  selectedNight: undefined,
  showUpgradesForNight: () => false,
  getSelectedHotelForNight: () => null,
  setAdventure: () => null,
  onEdit: () => null,
  onEditParticipant: () => null,
  onAddParticipant: () => null,
  onRemoveParticipant: () => null,
  isParticipantChild: () => null,
  getPriceDifferenceForRoom: () => 0,
  selectRoom: () => null,
  setOrderPage: () => null,
  initOrderPages: () => null,
  goToPage: () => null,
  onNextPage: () => false,
  onPrevPage: () => null,
  calculatePrice: () => null,
  bookNow: () => null,
  clearCart: () => null,
};

const initCalculation: IOrderCalculation = {
  participantsNow: 0.0,
  participantsLater: 0.0,
  hotelNow: 0.0,
  hotelLater: 0.0,
  upgradesNow: 0.0,
  upgradesLater: 0.0,
  total: 0.0,
  now: 0.0,
  later: 0.0,
};

const propertiesToOverride: (keyof IOrder)[] = [
  "adventureMainPicture",
  "adventureTitle",
  "adventureDescription",
  "adventurePriceFull",
  "adventurePriceDownPayment",
  "adventurePriceRemaining",
  "adventurePayRemainingDaysBefore",
  "adventurePriceSingleFull",
  "adventurePriceSingleDownPayment",
  "adventurePriceSingleRemaining",
  "adventurePriceChildren",
  "adventurePriceChildrenDownPayment",
  "adventurePriceChildrenRemaining",
  "adventureChildrenMaxAge",
  "adventurePaymentPolicy",
];

export const BookingContext = React.createContext<IBookingContext>(initialContextValue);

export const BookingContextProvider = (props: IBookingContextProviderProps) => {
  const [contextValue, setContextValue] = useState(initialContextValue);

  const setAdventure = (adventure: IAdventure) => {
    contextValue.adventure = adventure;
    setContextValue(cloneDeep(contextValue));
  };

  const setVirginOrder = (virginOrder: IOrder, virginOrderParticipant: IOrderParticipant, forceReset = false) => {
    virginOrder.createdAt = new Date();

    contextValue.virginOrderParticipant = virginOrderParticipant;
    contextValue.virginOrder = virginOrder;

    if (
      !contextValue.order ||
      contextValue.order.adventureIdentifier !== virginOrder.adventureIdentifier ||
      forceReset === true
    ) {
      const lsKey = `${localstorage.ADVENTURE_ORDER}_${virginOrder.adventureIdentifier}`;
      const lsOrderStr = window.localStorage.getItem(lsKey);

      if (lsOrderStr) {
        const lsOrder = JSON.parse(lsOrderStr) as IOrder;
        if (lsOrder && lsOrder.adventureIdentifier === virginOrder.adventureIdentifier) {
          let order = cloneDeep(lsOrder);

          if (order.startDate) order.startDate = new Date(order.startDate);

          if (order.participants) {
            for (const participant of order.participants) {
              if (participant.birthday) participant.birthday = new Date(participant.birthday);
            }
          }

          if (!order.hotelsByNight && virginOrder.hotelsByNight) order.hotelsByNight = virginOrder.hotelsByNight;

          let foundChangeInAdventure = false;

          for (const property of propertiesToOverride) {
            if (order[property] !== virginOrder[property]) {
              order = overrideProperties(order, property, virginOrder[property]);
              foundChangeInAdventure = true;
            }
          }

          if (foundChangeInAdventure === true) {
            order.participants = virginOrder.participants;
            order.calculation = cloneDeep(initCalculation);
          }

          contextValue.order = order;
          setContextValue(cloneDeep(contextValue));
          window.localStorage.setItem(lsKey, JSON.stringify(order));
        } else contextValue.order = cloneDeep(virginOrder);
      } else contextValue.order = cloneDeep(virginOrder);
      //contextValue.order = cloneDeep(virginOrder);
    }

    setContextValue(cloneDeep(contextValue));
    checkCompletedPages();
  };

  function overrideProperties<P extends keyof IOrder, V extends IOrder[P]>(
    order: IOrder,
    property: P,
    value: V
  ): IOrder {
    order[property] = value;
    return order;
  }

  function onEdit<P extends keyof IOrder, V extends IOrder[P]>(property: P, value: V) {
    if (!contextValue.order) return;
    const { order } = contextValue;

    if (property === "selectedUpgradePrices") {
      order.selectedUpgradePrices = toggleArrayItem(value, order.selectedUpgradePrices);
    } else {
      order[property] = value;
    }

    if (property === "startDate" && order.nights) {
      if (value && value instanceof Date) {
        const startDate = value;
        if (startDate) {
          order.endDate = add(startDate, { days: order.nights });
          order.participants = updateParticipantsPrices(order.participants);
        }
      } else {
        order.endDate = undefined;
      }
    } else if (property === "paymentPlan") {
      order.participants = updateParticipantsPrices(order.participants);
      const calculation = calculatePrice();
      if (calculation) order.calculation = calculation;
    } else if (property === "selectedHotelPrice") {
      const calculation = calculatePrice(order);
      if (calculation) order.calculation = calculation;
    } else if (property === "selectedUpgradePrices") {
      const calculation = calculatePrice(order);
      if (calculation) order.calculation = calculation;
    }

    contextValue.order = order;

    const lsKey = `${localstorage.ADVENTURE_ORDER}_${order.adventureIdentifier}`;
    window.localStorage.setItem(lsKey, JSON.stringify(order));

    checkCompletedPages();

    setContextValue(cloneDeep(contextValue));
  }

  function onEditParticipant<P extends keyof IOrderParticipant, V extends IOrderParticipant[P]>(
    index: number,
    property: P,
    value: V
  ) {
    if (!contextValue.order) return;
    const { order } = contextValue;
    const participants = order.participants || [];

    if (participants[index]) {
      participants[index][property] = value;

      if (index === 0 && property === "firstname") order.firstname = value as string;
      if (index === 0 && property === "lastname") order.lastname = value as string;
    }

    order.participants = updateParticipantsPrices(cloneDeep(participants));
    order.calculation = calculatePrice(order);
    contextValue.order = order;

    const lsKey = `${localstorage.ADVENTURE_ORDER}_${order.adventureIdentifier}`;
    window.localStorage.setItem(lsKey, JSON.stringify(order));

    checkCompletedPages();

    setContextValue(cloneDeep(contextValue));
  }

  const showUpgradesForNight = (night: number | undefined) => {
    setContextValue({ ...contextValue, selectedNight: night });
  };

  const getSelectedHotelForNight = (night: number): IOrderHotel | null => {
    const { order } = contextValue;
    if (!order || !order.hotelsByNight) return null;

    return order.hotelsByNight[night] ?? null;
  };

  const updateParticipantsPrices = (participants: IOrderParticipant[]): IOrderParticipant[] => {
    if (!participants) return [];
    if (!contextValue.order || !contextValue.order.startDate) return participants;

    const { order } = contextValue;

    for (const participant of participants) {
      if (participant.birthday) {
        if (isParticipantChild(participant)) {
          participant.priceNow = order.adventurePriceChildrenDownPayment
            ? order.adventurePriceChildrenDownPayment
            : order.adventurePriceDownPayment;
          participant.priceLater = order.adventurePriceChildrenRemaining
            ? order.adventurePriceChildrenRemaining
            : order.adventurePriceRemaining;
          participant.priceFull = order.adventurePriceChildren
            ? order.adventurePriceChildren
            : order.adventurePriceFull;
        } else {
          participant.priceNow =
            participants.length === 1 && order.adventurePriceSingleDownPayment
              ? order.adventurePriceSingleDownPayment
              : order.adventurePriceDownPayment;
          participant.priceLater =
            participants.length === 1 && order.adventurePriceSingleRemaining
              ? order.adventurePriceSingleRemaining
              : order.adventurePriceRemaining;
          participant.priceFull =
            participants.length === 1 && order.adventurePriceSingleFull
              ? order.adventurePriceSingleFull
              : order.adventurePriceFull;
        }
      }
    }

    return participants;
  };

  const isParticipantChild = (participant: IOrderParticipant): boolean => {
    if (!participant || !participant.birthday || !contextValue.order) return false;

    const childMaxAge = contextValue.order.adventureChildrenMaxAge || 0;
    const age = getAge(participant.birthday, contextValue.order.startDate);

    return age < childMaxAge;
  };

  const onAddParticipant = () => {
    if (!contextValue.order || !contextValue.virginOrderParticipant) return;

    const { order } = contextValue;
    const participants = order.participants || [];

    participants.push(cloneDeep(contextValue.virginOrderParticipant));

    order.participants = participants;
    contextValue.order = order;
    setContextValue(cloneDeep(contextValue));

    checkCompletedPages();
  };

  const onRemoveParticipant = (index: number) => {
    if (!contextValue.order) return;

    const { order } = contextValue;
    const participants = order.participants || [];

    if (participants[index]) {
      if (window.confirm(I18n.t("booking.form.group.participants.buttons.remove.confirm"))) {
        participants.splice(index, 1);
        order.participants = updateParticipantsPrices(participants);
        order.calculation = calculatePrice(order);
        contextValue.order = order;
        setContextValue(cloneDeep(contextValue));
      }
    }

    checkCompletedPages();
  };

  const getPriceDifferenceForRoom = (room: AdventureHotelRoom, night: number): number => {
    if (!contextValue.order) return 0;

    const priceType = contextValue.order.participants.length === 1 ? "single" : "default";

    const selectedHotel = getSelectedHotelForNight(night);

    const currentExtraCost = selectedHotel?.room
      ? priceType === "single"
        ? selectedHotel.room.pricePerNightSingleFull ?? 0
        : selectedHotel.room.pricePerNightFull ?? 0
      : 0;

    const newExtraCost = priceType === "single" ? room.pricePerNightSingleFull ?? 0 : room.pricePerNightFull ?? 0;

    return newExtraCost - currentExtraCost;
  };

  const selectRoom = (hotel: IAdventureHotel, room: AdventureHotelRoom, night: number) => {
    const { order } = contextValue;
    if (!order || !order.hotelsByNight) return;

    const newSelectedHotel: IOrderHotel = {
      identifier: `new_${v4()}`,
      orderIdentifier: order.identifier,
      adventureIdentifier: order.adventureIdentifier,
      hotelIdentifier: hotel.hotelIdentifier,
      room,
      nights: [night],
      info: hotel.info,
      pricePerNightSingleFull: room.pricePerNightSingleFull,
      pricePerNightFull: room.pricePerNightFull,
    };

    order.hotelsByNight[night] = newSelectedHotel;

    const calculation = calculatePrice();
    if (calculation) order.calculation = calculation;

    setContextValue({ ...contextValue, order });
  };

  const setOrderPage = (page: BookingOrderPages) => {
    contextValue.orderPage = page;
    contextValue.missingFields = [];

    setContextValue(cloneDeep(contextValue));
  };

  const initOrderPages = (adventure: IAdventure) => {
    const pages = cloneDeep(defaultOrderPages);

    if (
      !adventure.hotelsByNight ||
      Object.keys(adventure.hotelsByNight).length === 0 ||
      !hasAdventureHotels(adventure)
    ) {
      const hotelIndex = pages.indexOf("hotel");
      if (hotelIndex) pages.splice(hotelIndex, 1);
    }

    if (!adventure.upgrades || adventure.upgrades.length === 0) {
      const upgradeIndex = pages.indexOf("upgrades");
      if (upgradeIndex) pages.splice(upgradeIndex, 1);
    }

    contextValue.orderPages = pages;
    setContextValue(cloneDeep(contextValue));
  };

  const goToPage = (toPage: BookingOrderPages) => {
    if (toPage === "date") document.getElementById("booking")?.scrollIntoView();
    else document.getElementById(`booking_${toPage}`)?.scrollIntoView();
  };

  const onNextPage = (): boolean => {
    if (!contextValue.orderPages) return false;

    const missingFields: string[] = [];

    if (!contextValue.order) return false;

    const indexOfFromPage = contextValue.orderPages.indexOf(contextValue.orderPage);

    if (!indexOfFromPage && indexOfFromPage !== 0) return false;

    const nextPage = contextValue.orderPages[indexOfFromPage + 1];
    if (!nextPage) return false;

    if (hasOwnProperty(requiredFields, contextValue.orderPage)) {
      const reqFields = requiredFields[contextValue.orderPage] as string[];

      for (const reqField of reqFields) {
        if (!contextValue.order[reqField as keyof IOrder]) missingFields.push(reqField);
      }
    }

    if (missingFields && missingFields.length > 0) {
      contextValue.missingFields = missingFields;
      setContextValue(cloneDeep(contextValue));
      return false;
    }

    setOrderPage(nextPage);
    document.getElementById("bookingNav")?.scrollIntoView(true);
    return true;
  };

  const onPrevPage = (): boolean => {
    if (!contextValue.orderPages) return false;
    if (!contextValue.order) return false;

    const indexOfFromPage = contextValue.orderPages.indexOf(contextValue.orderPage);

    if (!indexOfFromPage && indexOfFromPage !== 0) return false;

    const prevPage = contextValue.orderPages[indexOfFromPage - 1];
    if (!prevPage) return false;

    setOrderPage(prevPage);
    document.getElementById("bookingNav")?.scrollIntoView(true);
    return true;
  };

  const calculatePrice = (order?: IOrder): IOrderCalculation => {
    if (!order) order = contextValue.order;
    if (!order) return cloneDeep(initCalculation);

    const calculation = cloneDeep(initCalculation);

    if (order.participants) {
      for (const participant of order.participants) {
        if (order.paymentPlan === "full") {
          calculation.participantsNow += participant.priceFull || 0.0;
        } else if (order.paymentPlan === "downpayment") {
          calculation.participantsNow += participant.priceNow || 0.0;
          calculation.participantsLater += participant.priceLater || 0.0;
        }
      }

      if (order.hotelsByNight) {
        for (const hotelByNight of Object.values(order.hotelsByNight)) {
          if (!hotelByNight) continue;

          if (hotelByNight.pricePerNightSingleFull) {
            if (order.participants.length === 1 && hotelByNight.pricePerNightSingleFull) {
              if (order.paymentPlan === "downpayment") calculation.hotelLater += hotelByNight.pricePerNightSingleFull;
              else calculation.hotelNow += hotelByNight.pricePerNightSingleFull;
            } else {
              if (order.paymentPlan === "downpayment")
                calculation.hotelLater += order.participants.length * (hotelByNight.pricePerNightFull || 0.0);
              else calculation.hotelNow += order.participants.length * (hotelByNight.pricePerNightFull || 0.0);
            }
          }
        }
      }

      // if (order.selectedHotelPrice && contextValue.adventure && contextValue.adventure.hotelPrices) {
      //   const hotelPrice = contextValue.adventure.hotelPrices.find((hp) => hp.identifier === order?.selectedHotelPrice);

      //   if (hotelPrice) {
      //     if (order.participants.length === 1 && hotelPrice.priceSingleFull) {
      //       if (order.paymentPlan === "downpayment") calculation.hotelLater = hotelPrice.priceSingleFull;
      //       else calculation.hotelNow = hotelPrice.priceSingleFull;
      //     } else {
      //       if (order.paymentPlan === "downpayment")
      //         calculation.hotelLater = order.participants.length * (hotelPrice.priceFull || 0.0);
      //       else calculation.hotelNow = order.participants.length * (hotelPrice.priceFull || 0.0);
      //     }
      //   }
      // }

      if (order.selectedUpgradePrices && contextValue.adventure && contextValue.adventure.upgradePrices) {
        for (const selectedUpgradePrice of order.selectedUpgradePrices) {
          const upgradePrice = contextValue.adventure.upgradePrices.find(
            (up) => up.identifier === selectedUpgradePrice
          );

          if (upgradePrice) {
            if (order.participants.length === 1 && upgradePrice.priceSingleFull) {
              if (order.paymentPlan === "downpayment") calculation.upgradesLater += upgradePrice.priceSingleFull;
              else calculation.upgradesNow += upgradePrice.priceSingleFull;
            } else {
              if (order.paymentPlan === "downpayment")
                calculation.upgradesLater += order.participants.length * (upgradePrice.priceFull || 0.0);
              else calculation.upgradesNow += order.participants.length * (upgradePrice.priceFull || 0.0);
            }
          }
        }
      }

      calculation.now = calculation.participantsNow + calculation.hotelNow + calculation.upgradesNow;
      calculation.later = calculation.participantsLater + calculation.hotelLater + calculation.upgradesLater;

      calculation.total = calculation.now + calculation.later;
    }

    //Logger.info("Order", order);
    //Logger.info("Calculation:", calculation);

    return calculation;
  };

  const checkCompletedPages = (showMissing = false): boolean => {
    let completedPages = contextValue.completedPages || [];
    let missingFields: string[] = [];

    for (const page of Object.keys(requiredFields)) {
      const pageIndex = page as BookingOrderPages;

      const pageMissingFields = getMissingFields(pageIndex);

      if (pageMissingFields !== false) {
        missingFields = [...missingFields, ...pageMissingFields];
        completedPages = completedPages.filter((item) => item !== pageIndex);
      } else {
        const { order } = contextValue;
        if (
          pageIndex === "participants" &&
          order?.participants.length === 1 &&
          isParticipantChild(order.participants[0])
        ) {
          // IS NOT COMPLETED!
        } else {
          if (!completedPages.includes(pageIndex)) completedPages.push(pageIndex);
        }
      }
    }

    if (showMissing) contextValue.missingFields = missingFields;

    contextValue.completedPages = completedPages;
    setContextValue(cloneDeep(contextValue));

    let completedPageCounter = 0;
    if (contextValue.orderPages) {
      for (const orderPage of contextValue.orderPages) {
        if (completedPages.includes(orderPage)) completedPageCounter++;
      }
    }

    //console.log("checkResult", completedPageCounter === (contextValue.orderPages?.length || 0));

    return completedPageCounter === (contextValue.orderPages?.length || 0);
  };

  const getMissingFields = (page: BookingOrderPages): string[] | false => {
    if (!contextValue.order) return false;

    const pageMissingFields: string[] = [];

    if (hasOwnProperty(requiredFields, page)) {
      const reqFields = requiredFields[page] as string[];

      if (page === "participants") {
        for (const participant of contextValue.order.participants) {
          for (const reqField of reqFields) {
            if (!participant[reqField as keyof IOrderParticipant]) pageMissingFields.push(reqField);
          }
        }
      } else {
        for (const reqField of reqFields) {
          if (!contextValue.order[reqField as keyof IOrder]) pageMissingFields.push(reqField);
        }
      }
    }
    if (pageMissingFields && pageMissingFields.length > 0) return pageMissingFields;
    else return false;
  };

  const bookNow = (api: Api) => {
    if (!contextValue.order) return;

    const checkResult = checkCompletedPages(true);

    if (checkResult === true) {
      if (!contextValue.order.calculation) contextValue.order.calculation = calculatePrice();

      if (contextValue.order.startDate) {
        const updatedStartDate = new Date(contextValue.order.startDate);
        updatedStartDate.setHours(12);
        contextValue.order.startDate = updatedStartDate;
      }
      if (contextValue.order.endDate) {
        const updatedEndDate = new Date(contextValue.order.endDate);
        updatedEndDate.setHours(12);
        contextValue.order.endDate = updatedEndDate;
      }

      if (contextValue.order.participants) {
        for (const participant of contextValue.order.participants) {
          if (participant.birthday) {
            const updatedBirthday = new Date(participant.birthday);
            updatedBirthday.setHours(12);
            participant.birthday = updatedBirthday;
          }
        }
      }

      contextValue.isSaving = true;
      setContextValue(cloneDeep(contextValue));
      BookingApi.createOrder(api, contextValue.order).then((response) => {
        const order = response?.body?.order as IOrder;
        if (order && order.orderNr) {
          const lsKey = `${localstorage.ADVENTURE_ORDER}_${order.adventureIdentifier}`;
          window.localStorage.removeItem(lsKey);

          if (order.paymentStatus === "PAID")
            window.location.href = UrlService.url("booking.confirmation", {
              orderNr: order.orderNr,
              orderToken: order.orderToken as string,
            });
          else
            window.location.href = UrlService.url("payment.orderPayment", {
              orderNr: order.orderNr,
              orderToken: order.orderToken as string,
            });
        }
      });
    }
  };

  const clearCart = () => {
    const { virginOrder, virginOrderParticipant } = contextValue;
    if (!virginOrder || !virginOrderParticipant) return;

    if (window.confirm(I18n.t("booking.buttons.onCancel.onfirm"))) {
      const lsKey = `${localstorage.ADVENTURE_ORDER}_${virginOrder.adventureIdentifier}`;
      window.localStorage.removeItem(lsKey);
      setVirginOrder(virginOrder, virginOrderParticipant, true);
    }
  };

  const context = {
    ...contextValue,
    setVirginOrder,
    getSelectedHotelForNight,
    setAdventure,
    onEdit,
    onEditParticipant,
    onAddParticipant,
    onRemoveParticipant,
    isParticipantChild,
    showUpgradesForNight,
    getPriceDifferenceForRoom,
    selectRoom,
    setOrderPage,
    initOrderPages,
    goToPage,
    onNextPage,
    onPrevPage,
    calculatePrice,
    bookNow,
    clearCart,
  };

  return <BookingContext.Provider value={context}>{props.children}</BookingContext.Provider>;
};

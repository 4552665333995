import Api from "./Api";

export default class AdventuresApi {
  public static getAdventure(api: Api, identifier: string, token?: string) {
    if (token) return api.get("public.adventures.private", { identifier, token });
    else return api.get("public.adventures.show", { identifier });
  }

  public static getCountries(api: Api) {
    return api.get("public.adventures.countries");
  }
}

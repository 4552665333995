import * as React from "react";
import * as FDN from "src/core";
import { FileUploadFile } from "../../ImageUpload/types";
import { TActions } from "src/types";

interface IUploadButtonsProps {
  files: FileUploadFile[];
  actions: TActions;
}

const UploadButtons: React.FunctionComponent<IUploadButtonsProps> = ({ files, actions }) => {
  return (
    <div className="__filemultiupload-buttons">
      <button className="small primary hollow button" onClick={actions.clearWaitingFiles}>
        <FDN.Icon icon="times" left /> {FDN.I18n.t("filemultiupload.filelist.buttons.clear.label")}
      </button>
      <button className="small primary button" onClick={() => actions.onUploadFiles()}>
        <FDN.Icon icon="upload" left />{" "}
        {FDN.I18n.t("filemultiupload.filelist.buttons.upload.label", {
          total: files.length,
          textFiles: FDN.I18n.t(
            `filemultiupload.filelist.text.${files.length === 1 ? `file` : `files`}`
          ),
        })}
      </button>
    </div>
  );
};

export default UploadButtons;

import * as React from "react";
import * as FDN from "src/core";

import { IAdventure } from "src/types";
import { BookingContext } from "src/components/context/BookingContext/BookingContext";
import BookingDate from "./pages/BookingDate";
import BookingPersonal from "./pages/BookingPersonal";
import BookingNavigation from "./parts/BookingNavigation";
import BookingSidebar from "./parts/BookingSidebar";
import BookingPaymentPlan from "./pages/BookingPaymentPlan";
import BookingPreview from "./pages/BookingPreview";
import PublicSection from "../../parts/PublicSection";
import BookingParticipants from "./pages/BookingParticipants";
import { ISavingInfo } from "src/components/context/NotificationsContext/types";
import BookingHotel from "./pages/BookingHotel";
import BookingUpgrade from "./pages/BookingUpgrades";
import BookingHotelByNight from "./pages/BookingHotelByNight";

interface IAdventureBookingProps {
  adventure: IAdventure;
}

const AdventureBooking: React.FunctionComponent<IAdventureBookingProps> = ({ adventure }) => {
  const BOOKING = React.useContext(BookingContext);
  const { order, orderPages, isSaving } = BOOKING;

  if (!order) return <FDN.Loading box />;

  const mapping: { [key: string]: React.ReactElement } = {
    date: <BookingDate adventure={adventure} />,
    participants: <BookingParticipants />,
    hotel: <BookingHotelByNight adventure={adventure} />,
    upgrades: <BookingUpgrade adventure={adventure} />,
    paymentPlan: <BookingPaymentPlan />,
    personal: <BookingPersonal />,
    //payment:
    //confirmation:
  };

  const savingInfo: ISavingInfo = {
    type: "save",
  };

  return (
    <div className="__public-adventure-booking">
      {isSaving === true ? <FDN.Saving savingInfo={savingInfo} /> : null}
      <PublicSection>
        <h2>{FDN.I18n.t("booking.form.title")}</h2>
        <div
          className="__public-section-intro"
          dangerouslySetInnerHTML={{ __html: FDN.I18n.t("booking.form.intro") }}
        />
      </PublicSection>

      {!order.startDate ? (
        <>
          <div className="__booking-form">
            <div className="__booking-form-navigation">{""}</div>
            <div className="__booking-form-sidebar">
              <BookingSidebar />
            </div>
            <div className="__booking-form-wrapper">
              <div className="__booking-form-content">
                <BookingDate adventure={adventure} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="__booking-form">
            <div className="__booking-form-navigation">
              <BookingNavigation />
            </div>
            <div className="__booking-form-sidebar">
              <BookingSidebar />
            </div>
            <div className="__booking-form-wrapper">
              <div className="__booking-form-content">
                {orderPages?.map((pageKey) => {
                  if (pageKey in mapping) {
                    return <React.Fragment key={pageKey}>{mapping[pageKey]}</React.Fragment>;
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
          <div className="__booking-form">
            <div className="__booking-form-navigation">{""}</div>
            <div className="__booking-form-sidebar">{""}</div>
            <div className="__booking-form-wrapper">
              <div className="__booking-form-content">
                <BookingPreview adventure={adventure} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AdventureBooking;

import * as React from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import useRichTextEditorService from "./RteService";
import { FOnUpdateRichTextEditor, ReturnContentType } from "./types";

const toolbarSettings = {
  options: ["inline", "textAlign", "emoji"],
};

interface IRichTextEditorProps {
  initialEditorState?: EditorState;
  initialHtml?: string;
  text?: string;
  size?: "small" | "medium" | "big";
  type?: "default" | "email";
  returnContentType?: ReturnContentType;
  onUpdate: FOnUpdateRichTextEditor;
}

const RichTextEditor: React.FunctionComponent<IRichTextEditorProps> = ({
  initialEditorState,
  initialHtml,
  size,
  type,
  returnContentType,
  onUpdate,
}) => {
  if (!size) size = "medium";
  if (!type) type = "default";
  if (!returnContentType) returnContentType = "html";

  if (type === "email") toolbarSettings.options = ["inline"];

  const { editorStatus, editorState, onChange } = useRichTextEditorService({
    initialEditorState,
    initialHtml,
    returnContentType,
    onUpdate,
  });

  if (editorStatus !== "LOADED") return null;

  return (
    <div className={`${size} __rte`}>
      <Editor
        editorState={editorState}
        wrapperClassName="__rte-wrapper"
        editorClassName="__rte-editor"
        toolbarClassName="__rte-toolbar"
        toolbar={toolbarSettings}
        onEditorStateChange={onChange}
      />
    </div>
  );
};

export default RichTextEditor;

import * as React from "react";
import { useParams } from "react-router-dom";
import BookingApi from "src/api/BookingApi";
import StatusCode from "src/config/statuscodes";
import * as FDN from "src/core";
import useServiceCore from "src/services/CoreService";
import { IAdventure, IOrder } from "src/types";
import ConfirmationReview from "../parts/ConfirmationReview";
import { nl2br } from "src/core/helpers/helpers";

const PdfBookingConfirmation: React.FunctionComponent = () => {
  const [order, setOrder] = React.useState<IOrder>();
  const [adventure, setAdventure] = React.useState<IAdventure>();

  const params = useParams();
  const orderNr = params.orderNr;
  const orderToken = params.orderToken;

  const { api, APP } = useServiceCore();

  React.useEffect(() => {
    if (!orderNr || !orderToken) return;

    BookingApi.getOrderForConfirmation(api, orderNr, orderToken).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        setOrder(response?.body?.order as IOrder);
        setAdventure(response?.body?.adventure as IAdventure);
      }
    });
  }, []);

  if (!order || !adventure) return <FDN.Loading />;

  return (
    <div className="__pdf-booking-confirmation">
      <div className="__booking-confirmation">
        <div className="pdf-logo">
          <img src={APP.getSetting("logoLoginUrl")} alt="Click2Adventure" />
        </div>
        <div className="__booking-confirmation-c2a-address">
          <strong>Click 2 Adventure LLC</strong>
          <br />
          2280 W Oakland Park Blvd
          <br />
          Oakland Park, 33311 Florida
          <br />
          Phone: +1 484 473-2040
          <br />
          Mail: service@click2adventure.com
        </div>
        <div className="__booking-confirmation-address">
          <div>
            {order.firstname} {order.lastname}
          </div>
          <div>{order.street}</div>
          {order.streetAdditional ? <div>{order.streetAdditional}</div> : null}
          <div>
            {order.zip} {order.city}
          </div>
          <div>{order.country}</div>
          <div style={{ marginTop: "0.2cm" }}>
            {FDN.I18n.t("booking.form.group.contact.email.label")}: {order.email}
          </div>
          <div>
            {FDN.I18n.t("booking.form.group.contact.phone.label")}: {order.phone}
          </div>
        </div>
        <FDN.CalloutBox type="success">
          <h1>{FDN.I18n.t("booking.confirmation.title")}</h1>
          <p dangerouslySetInnerHTML={{ __html: FDN.I18n.t("booking.pdf.confirmation.intro") }} />
        </FDN.CalloutBox>

        <ConfirmationReview location="pdf" order={order} adventure={adventure} />
        <AdventurePaymentPolicy adventure={adventure} />
        <div>&nbsp;</div>
      </div>
    </div>
  );
};

interface IAdventureBodyProps {
  adventure: IAdventure;
}

const AdventurePaymentPolicy: React.FunctionComponent<IAdventureBodyProps> = ({ adventure }) => {
  if (!adventure.paymentPolicy) return null;

  return (
    <div className="__public-adventure-paymentpolicy">
      <h3>{FDN.I18n.t("adventures.paymentPolicy.title")}</h3>
      <div
        className="__public-adventure-paymentpolicy-text"
        dangerouslySetInnerHTML={{ __html: nl2br(adventure.paymentPolicy || "") }}
      />
    </div>
  );
};

export default PdfBookingConfirmation;

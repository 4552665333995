import * as React from "react";
import * as FDN from "src/core";
import AdminHeader from "../layouts/AdminHeader";
import AdminLayout from "../layouts/AdminLayout";
import { userHasPermission } from "src/core/AdminService/helpers";
import AdminUserRole from "./AdminUserRole";
import { IUserroleState, IUserrolesState } from "src/types";
import useServiceAdminUserrolesService from "src/services/admin/UserrolesService";
import UserRolesList from "./parts/UserRolesList";
import { AppContext } from "src/components/context/AppContext/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import UrlService from "src/core/UrlService";

const AdminUserRoles: React.FunctionComponent = () => {
  const params = useParams();
  const navigate = useNavigate();
  const APP = React.useContext(AppContext);

  const [selectedUserrole, setSelectedUserrole] = React.useState<IUserroleState>();

  const { actions, userroles, user, virginUserrole } = useServiceAdminUserrolesService();

  React.useEffect(() => {
    APP.setPageTitle(FDN.I18n.t("adminUserroles.pageTitle"));
  }, []);

  React.useEffect(() => {
    if (
      params?.identifier &&
      (!selectedUserrole || params?.identifier !== selectedUserrole?.identifier)
    ) {
      const foundUserRole = userroles?.find((u) => u.identifier === params?.identifier);
      if (foundUserRole) selectUserrole(foundUserRole);
    }
  }, [userroles]);

  const selectUserrole = (userrole?: IUserroleState) => {
    setSelectedUserrole(userrole);
    if (userrole)
      navigate(UrlService.url(`admin.userroles.show`, { identifier: userrole.identifier }));
    else navigate(UrlService.url(`admin.userroles.index`));
  };

  const newUserrole = () => {
    setSelectedUserrole({ ...virginUserrole, id: "new" });
  };

  actions.selectUserrole = selectUserrole;

  if (!userroles) return <FDN.Loading />;

  return (
    <AdminLayout page="admin-userroles" selectedNav="userroles" hideEditLockMessages={true}>
      <AdminHeader
        title={FDN.I18n.t("adminUserroles.header.title")}
        buttons={[
          <>
            {userHasPermission(user, "adminUserRolesAdd") && newUserrole ? (
              <button
                onClick={() => {
                  newUserrole();
                }}
                className="primary button"
              >
                <FDN.Icon icon="plus" left /> {FDN.I18n.t("adminUserroles.buttons.add.label")}
              </button>
            ) : null}
          </>,
        ]}
      />
      <UserRolesList
        editable={true}
        showMembersCount={true}
        userroles={userroles}
        actions={actions}
      />
      {selectedUserrole && (
        <AdminUserRole
          userrole={selectedUserrole}
          onUpdate={(userroles: IUserrolesState) => {
            actions.refreshUserroles(userroles);
          }}
          onClose={() => selectUserrole(undefined)}
        />
      )}
    </AdminLayout>
  );
};

export default AdminUserRoles;

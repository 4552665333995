import * as React from "react";
import * as FDN from "src/core";
import { IAccountState, LoginAttemptsState } from "src/types";
import StatusCode from "src/config/statuscodes";
import {
  IAdminListBodyRow,
  IAdminListColumn,
  IAdminListSchema,
} from "src/components/main/AdminList/types";
import { cloneDeep } from "lodash";
import { formatDateTime } from "src/core/helpers/helpers";
import useServiceLoginAttempts from "src/services/admin/LoginAttempts";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: {
      md: 3,
    },
    textAlign: "right",
    content: null,
  },
  {
    size: {
      md: 11,
    },
    content: FDN.I18n.t("adminAccounts.loginAttempts.list.head.date.title"),
  },
  {
    size: { md: 10 },
    content: FDN.I18n.t("adminAccounts.loginAttempts.list.head.status.title"),
  },
];

const listSchema: IAdminListSchema = {
  rowMargin: "xy",
  head: { columns: listColumnsSchema },
  body: { rows: [] },
};

const buildAdminList = (loginAttempts: LoginAttemptsState) => {
  if (!loginAttempts) return;

  const adminListSchema = cloneDeep(listSchema);
  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const loginAttempt of loginAttempts) {
    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    if (loginAttempt.loginStatus === StatusCode.LOGIN_SUCCESSFUL) {
      row.className = `success-shallow-background`;
      row.columns[0].content = (
        <span className="success-text">
          <FDN.Icon icon="check-circle" />
        </span>
      );
    } else {
      row.className = `error-shallow-background`;
      row.columns[0].content = (
        <span className="error-text">
          <FDN.Icon icon="ban" />
        </span>
      );
    }

    row.columns[1].content = (
      <div className={`__admin-accounts-loginattempts-date`}>
        {formatDateTime(loginAttempt.loginDate, { seconds: true })}
      </div>
    );
    row.columns[2].content = (
      <>
        <div className={`__admin-accounts-loginattempts-status`}>
          {FDN.I18n.t(
            `adminAccounts.loginAttempts.list.body.status.${loginAttempt.loginStatus}.label`
          )}
        </div>
        <div className={`__admin-accounts-loginattempts-ipaddress`}>{loginAttempt.ipAddress}</div>
      </>
    );

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface IAccountLoginAttemptsProps {
  account: IAccountState;
}

const AccountLoginAttempts: React.FunctionComponent<IAccountLoginAttemptsProps> = ({ account }) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  const { loginAttempts } = useServiceLoginAttempts(account);

  React.useEffect(() => {
    if (loginAttempts) {
      setAdminListSchema(buildAdminList(loginAttempts as LoginAttemptsState));
      setListLoading(false);
    }
  }, [loginAttempts]);

  if (!loginAttempts || listLoading === true) return <FDN.Loading />;

  if (loginAttempts.length <= 0)
    return (
      <div className="text-center">{FDN.I18n.t("adminAccounts.loginAttempts.noLoginAttempts")}</div>
    );

  return (
    <>
      <FDN.AdminList schema={adminListSchema} loading={listLoading} />
    </>
  );
};

export default AccountLoginAttempts;

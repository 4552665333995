import * as React from "react";
import * as FDN from "src/core";
import { IAccountState, IUserPermissionsStatusState, IUserrolesState } from "src/types";
import AdminApi from "src/api/AdminApi";
import useServiceCore from "src/services/CoreService";
import UserRolesList from "../../userroles/parts/UserRolesList";
import AccountPermissionsPopup from "../parts/AccountPermissionsPopup";
import { userHasPermission } from "src/core/AdminService/helpers";
import { AppContext } from "src/components/context/AppContext/AppContext";

interface IAccountPermissionsProps {
  account: IAccountState;
  editMode: boolean;
  onUpdate: (account: IAccountState) => void;
}

const AccountPermissions: React.FunctionComponent<IAccountPermissionsProps> = ({ account }) => {
  const APP = React.useContext(AppContext);
  const user = APP.getUser();

  const [userPermissionsStatus, setUserPermissionsStatus] =
    React.useState<IUserPermissionsStatusState>();
  const [userroles, setUserroles] = React.useState<IUserrolesState>();
  const [popupUserrolesVisible, setPopupUserrolesVisible] = React.useState(false);

  const { api } = useServiceCore();

  React.useEffect(() => {
    if (!account || !account.identifier) return;
    AdminApi.adminAccountUserPermissionsStatus(api, account.identifier).then((response) => {
      setUserPermissionsStatus(
        response?.body?.userPermissionsStatus as IUserPermissionsStatusState
      );
      setUserroles(response?.body?.userroles as IUserrolesState);
    });
  }, [account]);

  const togglePopupUserroles = () => {
    setPopupUserrolesVisible(!popupUserrolesVisible);
  };

  const onCloseUserrolesPopup = () => {
    setPopupUserrolesVisible(false);
  };

  const actions = {
    togglePopupUserroles,
    onCloseUserrolesPopup,
  };

  if (!account || !userPermissionsStatus || !userroles) return <FDN.Loading box />;

  return (
    <div>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={24}>
            <FDN.Box>
              <h3>{FDN.I18n.t("adminAccounts.permissions.permissions.title")}</h3>
              <p>{FDN.I18n.t("adminAccounts.permissions.permissions.text")}</p>
              <UserRolesList
                editable={false}
                userroles={userPermissionsStatus?.permissions}
                hidePagination={true}
                actions={actions}
              />
            </FDN.Box>
            <FDN.Box>
              <h3>
                {FDN.I18n.t("adminAccounts.permissions.roles.title")}&nbsp;&nbsp;{" "}
                {userHasPermission(user, "adminAccountsEdit") ? (
                  <button
                    className="tiny primary hollow button"
                    onClick={actions.togglePopupUserroles}
                  >
                    {FDN.I18n.t("buttons.edit")}
                  </button>
                ) : null}
              </h3>
              <p>{FDN.I18n.t("adminAccounts.permissions.roles.text")}</p>
              <UserRolesList
                editable={false}
                userroles={userPermissionsStatus?.roles}
                hidePagination={true}
                actions={actions}
              />
            </FDN.Box>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
      {popupUserrolesVisible && (
        <AccountPermissionsPopup
          account={account}
          userPermissionsStatus={userPermissionsStatus}
          userroles={userroles}
          onClose={actions.onCloseUserrolesPopup}
        />
      )}
    </div>
  );
};

export default AccountPermissions;

import * as React from "react";
import * as FDN from "src/core";
import AdminLayout from "../layouts/AdminLayout";
import AdminHeader from "../layouts/AdminHeader";
import useServiceAdminAdventures from "src/services/admin/AdventuresService";
import AdventuresList from "./parts/AdventuresList";
import { Link, useNavigate } from "react-router-dom";
import UrlService from "src/core/UrlService";
import { userHasPermission } from "src/core/AdminService/helpers";
import AdventuresSearch from "./parts/AdventuresSearch";

const AdminAdventures: React.FunctionComponent = () => {
  const {
    actions,
    adventures,
    locationCountries,
    user,
    tabs,
    selectedTab,
    setSelectedTab,
    filteredAdventures,
    isLoading,
    search,
  } = useServiceAdminAdventures();

  const navigate = useNavigate();

  if (!actions || !adventures || !tabs) return <FDN.Loading />;

  return (
    <AdminLayout page="admin-adventures" selectedNav="adventures">
      <AdminHeader
        title={FDN.I18n.t("adminAdventures.header.title")}
        buttons={[
          <>
            {userHasPermission(user, "adminAdventuresAdd") && (
              <Link to={UrlService.url("admin.adventures.show", { identifier: "new" })} className="primary button">
                <FDN.Icon icon="plus" left /> {FDN.I18n.t("adminAdventures.buttons.add.label")}
              </Link>
            )}
          </>,
        ]}
      />
      <div className="__admin-adventures-list">
        <AdventuresSearch search={search} locationCountries={locationCountries} actions={actions} />
        <FDN.Tabs
          tabs={tabs}
          updateHistory={true}
          onUpdateUrl={(params) => navigate({ search: params })}
          selected={selectedTab}
          onSelect={(tabKey) => setSelectedTab(tabKey)}
        >
          {isLoading ? (
            <FDN.Loading box />
          ) : (
            <AdventuresList adventures={filteredAdventures} locationCountries={locationCountries} />
          )}
        </FDN.Tabs>
      </div>
    </AdminLayout>
  );
};

export default AdminAdventures;

import * as React from "react";
import * as FDN from "src/core";

interface IAdventuresLayoutProps {
  page: string;
  children: React.ReactNode;
}

const AdventuresLayout: React.FunctionComponent<IAdventuresLayoutProps> = ({ page, children }) => {
  return (
    <div className="__public-adventures">
      <FDN.LayoutWithTopbar page={`adventures-${page}`}>
        {<FDN.PageContent>{children}</FDN.PageContent>}
      </FDN.LayoutWithTopbar>
    </div>
  );
};

export default AdventuresLayout;

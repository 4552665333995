import * as React from "react";
import * as FDN from "src/core";
import { useStripe } from "@stripe/react-stripe-js";
import { PaymentStatusTypes } from "src/types";
import { Link, useParams } from "react-router-dom";
import UrlService from "src/core/UrlService";

interface IPaymentStatusProps {
  onStatusUpdate: (status: PaymentStatusTypes) => void;
}

const PaymentStatus: React.FunctionComponent<IPaymentStatusProps> = ({ onStatusUpdate }) => {
  const stripe = useStripe();

  const params = useParams();
  const orderNr = params.orderNr as string;
  const orderToken = params.orderToken as string;

  const [status, setStatus] = React.useState<PaymentStatusTypes>();

  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );

  React.useEffect(() => {
    if (!clientSecret || !stripe) setStatus("ERROR");
    else {
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        if (paymentIntent?.status === "succeeded") setStatus("SUCCESS");
        else if (
          paymentIntent?.status === "processing" ||
          paymentIntent?.status === "requires_action" ||
          paymentIntent?.status === "requires_capture" ||
          paymentIntent?.status === "requires_confirmation"
        )
          setStatus("PENDING");
        else setStatus("ERROR");
      });
    }
  }, []);

  React.useEffect(() => {
    if (!status) return;
    onStatusUpdate(status);
  }, [status]);

  if (!status) return <FDN.Loading />;

  return (
    <div className="__payment-status">
      {status === "ERROR" && (
        <div>
          <FDN.CalloutBox type="alert">
            <p>
              <strong>{FDN.I18n.t(`payment.completion.status.${status}.title`)}</strong>
            </p>
            <div>{FDN.I18n.t(`payment.completion.status.${status}.text`)}</div>
          </FDN.CalloutBox>
          <div className="text-center" style={{ fontSize: "0.85rem" }}>
            <Link to={UrlService.url("payment.orderPayment", { orderNr, orderToken })}>
              <FDN.Icon icon="angle-double-left" left />{" "}
              {FDN.I18n.t(`payment.completion.status.${status}.link`)}
            </Link>
          </div>
        </div>
      )}
      {status === "PENDING" && (
        <FDN.CalloutBox type="warning">
          <p>
            <strong>{FDN.I18n.t(`payment.completion.status.${status}.title`)}</strong>
          </p>
          <div>{FDN.I18n.t(`payment.completion.status.${status}.text`)}</div>
        </FDN.CalloutBox>
      )}
      {status === "SUCCESS" && (
        <FDN.CalloutBox type="success">
          <p>
            <strong>{FDN.I18n.t(`payment.completion.status.${status}.title`)}</strong>
          </p>
          <div>{FDN.I18n.t(`payment.completion.status.${status}.text`)}</div>
        </FDN.CalloutBox>
      )}
    </div>
  );
};

export default PaymentStatus;

import * as React from "react";
import { IAdventure, TActions } from "src/types";
import FileManager from "src/components/main/FileManager";

interface IAdventurePicturesProps {
  adventure: IAdventure;
  editMode: boolean;
  actions: TActions;
}

const AdventurePictures: React.FunctionComponent<IAdventurePicturesProps> = ({
  adventure,
  editMode,
  actions,
}) => {
  return (
    <div className="admin-adventure-tab-pictures">
      <FileManager
        allow={["images"]}
        parent="adventure"
        files={adventure.pictures || []}
        editMode={editMode}
        mainPictureIndex={0}
        allowEdit={false}
        allowRemove={true}
        actions={actions}
      />
    </div>
  );
};

export default AdventurePictures;

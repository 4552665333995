import * as React from "react";
import * as FDN from "src/core";
import { SearchSort } from "src/types";

interface ISortByProps {
  sort: SearchSort;
  options: string[];
  onSelect: (option: string) => void;
}

const SortBy: React.FunctionComponent<ISortByProps> = ({ sort, options, onSelect }) => {
  if (!sort) sort = options[0];

  return (
    <div className="__adminlist-sortby">
      <select value={sort} onChange={(e) => onSelect(e.target.value)}>
        <option disabled value="">
          {FDN.I18n.t("adminAdmin.list.sortby.sortby")}
        </option>
        {options.map((option) => {
          return (
            <option key={option} value={option}>
              {FDN.I18n.t(`adminAdmin.list.sortby.${option}.label`)}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default SortBy;

import * as React from "react";
import { IUpgrade, IPicture } from "src/types";
import { getAdventurePictureUrl } from "src/core/helpers/helpers";
import { Slide } from "react-slideshow-image";

interface IUpgradePictureSliderProps {
  upgrade: IUpgrade;
}

const UpgradePictureSlider: React.FunctionComponent<IUpgradePictureSliderProps> = ({ upgrade }) => {
  const pictures = upgrade.pictures as IPicture[];

  return (
    <div className="__public-upgrades-picture-slider">
      <Slide duration={0} transitionDuration={500} indicators={true} autoplay={false}>
        {pictures.map((picture, index) => {
          return <SlideWithContent key={index} picture={picture} />;
        })}
      </Slide>
    </div>
  );
};

interface IHeroSlideProps {
  picture: IPicture;
}

const SlideWithContent: React.FunctionComponent<IHeroSlideProps> = ({ picture }) => {
  const styles: React.CSSProperties = {
    backgroundImage: `url("${getAdventurePictureUrl(picture, "L")}")`,
  };

  return (
    <div className="each-slide-effect">
      <div style={styles} className="__public-upgrades-picture-slider-slide">
        <div className="__public-upgrades-picture-slider-slide-content">
          <div className="__public-upgrades-picture-slider-slide-text">&nbsp;</div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePictureSlider;

import React from "react";
import { IAdventure, IAdventureHotel } from "src/types";
import * as FDN from "src/core";
import HotelStars from "src/components/pages/admin/adventures/parts/hotels/HotelStars";
import HotelPreviewPopup from "./HotelPreviewPopup";

interface AdventureBodyHotelPreviewProps {
  adventure: IAdventure;
}

const AdventureBodyHotelPreview = ({ adventure }: AdventureBodyHotelPreviewProps) => {
  if (!adventure) return <FDN.Loading box />;
  if (!adventure.hotelsByNight) return null;

  let adventureHasNoHotels = true;
  for (const night in adventure.hotelsByNight) {
    if (adventure.hotelsByNight[parseInt(night)].length > 0) {
      adventureHasNoHotels = false;
      break;
    }
  }

  if (adventureHasNoHotels) return null;

  return (
    <FDN.Box>
      <h3>{FDN.I18n.t("adventures.hotels.title")}</h3>
      <div>{FDN.I18n.t("adventures.hotels.text")}</div>
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4 mt-4">
        {Object.keys(adventure.hotelsByNight ?? {}).map((night) => {
          return (
            <div key={night}>
              <DefaultHotelOfNight
                hotel={adventure.hotelsByNight[parseInt(night)][0]}
                night={parseInt(night)}
                hotelsForThisNight={adventure.hotelsByNight[parseInt(night)]}
              />
            </div>
          );
        })}
      </div>
    </FDN.Box>
  );
};

interface DefaultHotelOfNightProps {
  hotel: IAdventureHotel;
  night: number;
  hotelsForThisNight: IAdventureHotel[];
}

const DefaultHotelOfNight = ({ hotel, night, hotelsForThisNight }: DefaultHotelOfNightProps) => {
  const [showMore, setShowMore] = React.useState(false);

  return (
    <>
      <button className="block w-full cursor-pointer duration-200 hover:scale-105" onClick={() => setShowMore(true)}>
        <div className="border border-darkPurple-300">
          <div className="text-center text-brightPurple font-medium px-2 py-2">Night {night}</div>

          <div
            className="w-full h-28 bg-cover bg-center"
            style={{ backgroundImage: `url(${hotel?.info?.mainPicture?.S})` }}
          />

          {hotel && hotel.info && (
            <div className="py-2 px-2">
              <span className="font-medium">{hotel.info.name}</span>{" "}
              {hotel.info.stars && (
                <div>
                  <HotelStars stars={hotel.info.stars} />
                </div>
              )}
            </div>
          )}
        </div>
      </button>
      {showMore && (
        <HotelPreviewPopup
          hotel={hotel}
          night={night}
          hotelsForThisNight={hotelsForThisNight}
          onClose={() => setShowMore(false)}
        />
      )}
    </>
  );
};

export default AdventureBodyHotelPreview;

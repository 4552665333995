import React from "react";
import { IAdminListBodyRow, IAdminListColumn, IAdminListSchema } from "src/components/main/AdminList/types";
import { TActionsAdminHotels } from "src/services/admin/useHotels";
import { IHotel } from "src/types";
import * as FDN from "src/core";
import { cloneDeep } from "lodash";
import HotelStars from "../../adventures/parts/hotels/HotelStars";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: { md: 3 },
    textAlign: "left",
    content: "",
  },
  {
    size: { md: 11 },
    textAlign: "left",
    content: FDN.I18n.t("adminHotels.list.head.name.title"),
  },
  {
    size: { md: 2 },
    textAlign: "center",
    content: FDN.I18n.t("adminHotels.list.head.rooms.title"),
  },
  {
    size: { md: 2 },
    textAlign: "center",
    content: FDN.I18n.t("adminHotels.list.head.adventures.title"),
  },
  {
    size: { md: 6 },
    textAlign: "right",
    content: FDN.I18n.t("adminHotels.list.head.email.title"),
  },
];

const listSchema: IAdminListSchema = {
  head: { columns: listColumnsSchema },
  body: { rows: [] },
};

const buildAdminList = (hotels: IHotel[], actions: TActionsAdminHotels) => {
  if (!hotels) return;

  const adminListSchema = cloneDeep(listSchema);
  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const hotel of hotels) {
    if (!hotel) return;

    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    row.onClick = () => {
      actions.selectHotel(hotel.identifier);
    };

    row.columns[0].content = (
      <>{hotel.mainPicture?.S && <img src={hotel.mainPicture.S} alt={hotel.name} className="w-28 h-auto" />}</>
    );

    row.columns[1].content = (
      <>
        <div className="font-semibold text-primary">
          {hotel.name} {hotel.stars && <HotelStars stars={hotel.stars} />}
        </div>
        <div className="text-sm text-gray.500">
          {hotel.street ? <>{hotel.street},</> : null}{" "}
          {hotel.zip || hotel.city ? (
            <>
              {hotel.zip} {hotel.city},
            </>
          ) : null}{" "}
          {hotel.country}
        </div>
      </>
    );

    row.columns[2].content = <>{hotel.rooms?.length ?? 0}</>;

    row.columns[3].content = (
      <>
        {!hotel.adventuresAmount && hotel.adventuresAmount !== 0 ? (
          <FDN.Icon icon="spinner fa-spin" />
        ) : (
          hotel.adventuresAmount
        )}
      </>
    );

    row.columns[4].content = <>{hotel.email} </>;

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface HotelsListProps {
  hotels: IHotel[];
  actions: TActionsAdminHotels;
}

const HotelsList = ({ hotels, actions }: HotelsListProps) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  React.useEffect(() => {
    if (hotels) {
      setAdminListSchema(buildAdminList(hotels as IHotel[], actions));
      setListLoading(false);
    }
  }, [hotels]);

  if (!hotels) return <FDN.Loading box />;

  return <>{adminListSchema && <FDN.AdminList schema={adminListSchema} loading={listLoading} />}</>;
};

export default HotelsList;

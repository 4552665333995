import * as React from "react";
import * as FDN from "src/core";
import MyAccountHeader from "./myaccount/MyAccountHeader";
import AvatarUpload from "./AvatarUpload";
import useServiceMyAccount from "src/services/MyAccountService";
import { TActions } from "src/types";

type EditedProperties = string[];

const MyAccountPage: React.FunctionComponent = () => {
  const { actions, account, editMode, editedProperties } = useServiceMyAccount();

  if (!account || !account.identifier) return <FDN.Loading />;

  return (
    <FDN.LayoutWithTopbar page="myaccount">
      <FDN.PageContent>
        <MyAccountHeader page="myaccount" />
        <MyAccountForm
          account={account}
          editMode={editMode}
          editedProperties={editedProperties}
          actions={actions}
        />
        <FDN.FormButtons onSave={actions.onSave} onCancel={actions.onCancel} marginBottom="20px" />
      </FDN.PageContent>
    </FDN.LayoutWithTopbar>
  );
};

interface IMyAccountFormProps {
  account: { [key: string]: any };
  editMode: boolean;
  editedProperties: EditedProperties;
  actions: TActions;
}

const MyAccountForm: React.FunctionComponent<IMyAccountFormProps> = ({
  account,
  editMode,
  editedProperties,
  actions,
}) => {
  return (
    <div className="page-myaccount-form">
      <FDN.GridRow full margin="xy">
        <FDN.Cell md={14}>
          <FormGroupPersonalInfo
            account={account}
            editMode={editMode}
            editedProperties={editedProperties}
            actions={actions}
          />
          <FormGroupEmail
            account={account}
            editMode={editMode}
            editedProperties={editedProperties}
            actions={actions}
          />
          <FormGroupAddress
            account={account}
            editMode={editMode}
            editedProperties={editedProperties}
            actions={actions}
          />
        </FDN.Cell>
        <FDN.Cell md={10}>
          <FormGroupAvatar
            account={account}
            editMode={editMode}
            editedProperties={editedProperties}
            actions={actions}
          />
        </FDN.Cell>
      </FDN.GridRow>
    </div>
  );
};

const FormGroupPersonalInfo: React.FunctionComponent<IMyAccountFormProps> = ({
  account,
  editMode,
  actions,
}) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("myaccount.form.personalinfo.title")}>
      <FDN.SingleRowCell full margin="xy">
        <FDN.Input
          editable={true}
          editMode={editMode}
          value={account.firstname}
          label={FDN.I18n.t("myaccount.form.firstname.label")}
          placeholder={FDN.I18n.t("myaccount.form.firstname.placeholder")}
          onUpdate={(value) => actions.onEdit("firstname", value)}
        />
      </FDN.SingleRowCell>
      <FDN.SingleRowCell full margin="xy">
        <FDN.Input
          editable={true}
          editMode={editMode}
          value={account.lastname}
          label={FDN.I18n.t("myaccount.form.lastname.label")}
          placeholder={FDN.I18n.t("myaccount.form.lastname.placeholder")}
          onUpdate={(value) => actions.onEdit("lastname", value)}
        />
      </FDN.SingleRowCell>
    </FDN.FormGroup>
  );
};

const FormGroupAvatar: React.FunctionComponent<IMyAccountFormProps> = ({
  account,
  editMode,
  actions,
}) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("myaccount.form.avatar.title")}>
      {editMode === true ? (
        <AvatarUpload
          location="myaccount"
          account={account}
          size={150}
          vertical
          onUpdate={(imageUrl: string) => actions.onEdit("avatar", imageUrl)}
          onDelete={() => actions.onEdit("avatar", null)}
        />
      ) : (
        <FDN.Avatar user={account} size={150} />
      )}
    </FDN.FormGroup>
  );
};

const FormGroupEmail: React.FunctionComponent<IMyAccountFormProps> = ({
  account,
  editMode,
  editedProperties,
  actions,
}) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("myaccount.form.email.title")}>
      <FDN.SingleRowCell full margin="xy">
        <FDN.Input
          editable={true}
          editMode={editMode}
          value={account.email}
          label={FDN.I18n.t("myaccount.form.email.label")}
          placeholder={FDN.I18n.t("myaccount.form.email.placeholder")}
          onUpdate={(value) => actions.onEdit("email", value)}
        />
        {editedProperties.includes("email") && (
          <div className="tiny warning callout" style={{ marginTop: "10px" }}>
            <p>
              <strong>{FDN.I18n.t("myaccount.form.email.alert.title")}</strong>
              <br />
              {FDN.I18n.t("myaccount.form.email.alert.text")}
            </p>
          </div>
        )}
      </FDN.SingleRowCell>
    </FDN.FormGroup>
  );
};

const FormGroupAddress: React.FunctionComponent<IMyAccountFormProps> = ({
  account,
  editMode,
  actions,
}) => {
  return (
    <>
      {account.addresses.map((address: { [key: string]: any }, index: number) => {
        if (address?.type !== "billing") return null;

        return (
          <FDN.FormGroup title={FDN.I18n.t("myaccount.form.address.title")} key={index}>
            <FDN.SingleRowCell full margin="xy">
              <FDN.Input
                editable={true}
                editMode={editMode}
                value={address.street}
                label={FDN.I18n.t("myaccount.form.street.label")}
                placeholder={FDN.I18n.t("myaccount.form.street.placeholder")}
                onUpdate={(value) => actions.onEditAddress(address.identifier, "street", value)}
              />
            </FDN.SingleRowCell>
            <FDN.SingleRowCell full margin="xy">
              <FDN.Input
                editable={true}
                editMode={editMode}
                value={address.streetAdditional}
                label={FDN.I18n.t("myaccount.form.streetAdditional.label")}
                placeholder={FDN.I18n.t("myaccount.form.streetAdditional.placeholder")}
                onUpdate={(value) =>
                  actions.onEditAddress(address.identifier, "streetAdditional", value)
                }
              />
            </FDN.SingleRowCell>
            <FDN.GridRow full margin="xy">
              <FDN.Cell sm={24} md={8}>
                <FDN.Input
                  editable={true}
                  editMode={editMode}
                  value={address.zip}
                  label={FDN.I18n.t("myaccount.form.zip.label")}
                  placeholder={FDN.I18n.t("myaccount.form.zip.placeholder")}
                  onUpdate={(value) => actions.onEditAddress(address.identifier, "zip", value)}
                />
              </FDN.Cell>
              <FDN.Cell sm={24} md={16}>
                <FDN.Input
                  editable={true}
                  editMode={editMode}
                  value={address.city}
                  label={FDN.I18n.t("myaccount.form.city.label")}
                  placeholder={FDN.I18n.t("myaccount.form.city.placeholder")}
                  onUpdate={(value) => actions.onEditAddress(address.identifier, "city", value)}
                />
              </FDN.Cell>
            </FDN.GridRow>
            <FDN.SingleRowCell full margin="xy">
              <FDN.Input
                type="country"
                editable={true}
                editMode={editMode}
                value={address.country}
                options={{ locale: "en-US" }}
                label={FDN.I18n.t("myaccount.form.country.label")}
                placeholder={FDN.I18n.t("myaccount.form.country.placeholder")}
                onUpdate={(value) => actions.onEditAddress(address.identifier, "country", value)}
              />
            </FDN.SingleRowCell>
          </FDN.FormGroup>
        );
      })}
    </>
  );
};

export default MyAccountPage;

import { useEffect, useState } from "react";
import AdminApi from "src/api/AdminApi";
import { IAdventure, IAdventureHotel, IHotel, IHotelRoom, SearchHotelsFilter, TActions } from "src/types";
import useServiceCore from "../CoreService";
import StatusCode from "src/config/statuscodes";
import { v4 } from "uuid";
import { cloneDeep } from "lodash";
import { I18n } from "src/core";

const initSearch: SearchHotelsFilter = {};

type FnOpenAddHotelPopup = (night?: number) => void;
type FnSelectHotel = (hotel?: IHotel, night?: number) => void;
type FnToggleHotelRoom = (room: IHotelRoom) => void;
type FnEditSelectedHotelRoomPrice = (identifier: string, type: "default" | "single", price: number) => void;
type FnIsHotelAssignedToAdventure = (hotelIdentifier: string, night?: number) => boolean;
type FnGetHotelsForNight = (night: number) => IAdventureHotel[] | undefined;
type FnRemoveHotelFromNight = (hotel?: IAdventureHotel, night?: number) => void;
type FnGetNightsWithHotels = () => Record<string, IAdventureHotel[]>;
type FnAddHotelToNight = (hotel: IAdventureHotel, night: number) => void;

export interface AdventureHotelsActions {
  updateSearch: <P extends keyof SearchHotelsFilter, V extends SearchHotelsFilter[P]>(property: P, value: V) => void;
  loadHotels: () => void;
  openAddHotelPopup: FnOpenAddHotelPopup;
  closeAddHotelPopup: () => void;
  selectHotel: FnSelectHotel;
  toggleDefaultHotel: () => void;
  toggleHotelRoom: FnToggleHotelRoom;
  editSelectedHotelRoomPrice: FnEditSelectedHotelRoomPrice;
  isHotelAssignedToAdventure: FnIsHotelAssignedToAdventure;
  getHotelsForNight: FnGetHotelsForNight;
  removeHotelFromNight: FnRemoveHotelFromNight;
  getNightsWithHotels: FnGetNightsWithHotels;
  duplicateFromNight: (night: number) => void;
  addHotelToNight: FnAddHotelToNight;
}

interface UseAdventureHotelsProps {
  adventure: IAdventure;
  adventureActions: TActions;
}

const useAdventureHotels = ({ adventure, adventureActions }: UseAdventureHotelsProps) => {
  const [search, setSearch] = useState<SearchHotelsFilter>(cloneDeep(initSearch));
  const [cities, setCities] = useState<string[]>();
  const [countries, setCountries] = useState<string[]>();

  const [filteredHotels, setFilteredHotels] = useState<IHotel[]>();
  const [hotels, setHotels] = useState<IHotel[]>();

  const [selectedNight, setSelectedNight] = useState<number | undefined>();
  const [isAddHotelPopupVisible, setIsAddHotelPopupVisible] = useState(false);
  const [selectedHotel, setSelectedHotel] = useState<IAdventureHotel>();

  const { api, NOTIFICATIONS } = useServiceCore();

  useEffect(() => {
    updateFilteredHotels();
  }, [search, hotels]);

  useEffect(() => {
    getCountriesAndCitieas();
  }, [hotels, search.country]);

  function updateSearch<P extends keyof SearchHotelsFilter, V extends SearchHotelsFilter[P]>(property: P, value: V) {
    const updatedSearch = search ? cloneDeep(search) : cloneDeep(initSearch);

    updatedSearch[property] = value;

    setSearch(updatedSearch);
  }

  const loadHotels = () => {
    AdminApi.adminHotels(api).then((response) => {
      if (response?.statusCode === StatusCode.SUCCESS) {
        const hotels = response?.body?.hotels as IHotel[];
        setHotels(hotels);
      }
    });
  };

  const updateFilteredHotels = () => {
    if (!hotels) return;
    const filteredHotels = hotels.filter((hotel) => {
      let addToList = true;

      if (search.text) {
        if (
          !hotel.name.toLowerCase().includes(search.text.toLowerCase()) &&
          !hotel.street?.toLowerCase().includes(search.text.toLowerCase()) &&
          !hotel.city?.toLowerCase().includes(search.text.toLowerCase()) &&
          !hotel.email?.toLowerCase().includes(search.text.toLowerCase())
        )
          addToList = false;
      }

      if (search.country) {
        if (hotel.country !== search.country) addToList = false;
      }

      if (addToList === true) return hotel;
    });

    setFilteredHotels(filteredHotels);
  };

  const getCountriesAndCitieas = () => {
    const tmpCountries = [];
    tmpCountries.push("");

    const tmpCities = [];
    tmpCities.push("");

    for (const hotel of hotels ?? []) {
      if (hotel.country && !tmpCountries.includes(hotel.country)) tmpCountries.push(hotel.country);

      if (search.country && hotel.country !== search.country) continue;
      if (hotel.city && !tmpCities.includes(hotel.city)) tmpCities.push(hotel.city);
    }

    setCities(tmpCities);
    setCountries(tmpCountries);
  };

  const openAddHotelPopup: FnOpenAddHotelPopup = (night) => {
    if (night) setSelectedNight(night);
    setIsAddHotelPopupVisible(true);
  };

  const closeAddHotelPopup = () => {
    updateHotelOrderInNight(adventure.hotelsByNight[selectedNight ?? 0] ?? [], selectedNight ?? 0);

    setIsAddHotelPopupVisible(false);
    setSelectedHotel(undefined);
    setSelectedNight(undefined);
  };

  const selectHotel: FnSelectHotel = (hotel, night) => {
    if (!hotel) {
      setSelectedHotel(undefined);
      return;
    }

    let alreadySelectedHotel: IAdventureHotel | undefined = undefined;

    if (night) {
      const found = adventure.hotelsByNight?.[night]?.find((h) => h.hotelIdentifier === hotel.identifier);

      if (found) alreadySelectedHotel = found;
    }

    if (alreadySelectedHotel) {
      openAddHotelPopup(night);
      setSelectedHotel(alreadySelectedHotel);
    } else {
      const adventureHotel: IAdventureHotel = {
        identifier: `new_${v4()}`,
        adventureIdentifier: adventure.identifier,
        hotelIdentifier: hotel.identifier,
        nights: night ? [night] : selectedNight ? [selectedNight] : undefined,
        rooms: [],
        info: hotel,
        isDefault: adventure.hotelsByNight[selectedNight ?? 0]?.length === 0,
      };

      setSelectedHotel(adventureHotel);
    }
  };

  const toggleDefaultHotel = () => {
    if (!selectedHotel) return;

    selectedHotel.isDefault = !selectedHotel.isDefault;

    const updatedHotels = (adventure.hotelsByNight[selectedNight ?? 0] ?? []).map((hotel) => {
      if (hotel.hotelIdentifier === selectedHotel.hotelIdentifier) {
        hotel.isDefault = !hotel.isDefault;
      } else hotel.isDefault = false;

      return hotel;
    });

    let defaultHotel = updatedHotels.find((hotel) => hotel.isDefault);
    if (!defaultHotel && updatedHotels.length > 0) {
      defaultHotel = updatedHotels[0];
      defaultHotel.isDefault = true;
    }

    const hotelsByNight = adventure.hotelsByNight;
    hotelsByNight[selectedNight ?? 0] = updatedHotels;

    adventureActions.updateHotelsAssignedToAdventure(hotelsByNight);
  };

  const toggleHotelRoom: FnToggleHotelRoom = (room) => {
    if (!selectedHotel || !selectedNight) return;

    const updatedHotel = cloneDeep(selectedHotel);

    const index = updatedHotel.rooms?.findIndex((hotelRoom) => hotelRoom.roomIdentifier === room.identifier);

    if (index === -1) {
      updatedHotel.rooms?.push({
        roomIdentifier: room.identifier,
        title: room.title ?? I18n.t("adminHotels.rooms.default.label"),
        pricePerNightFull: room.priceFull ?? 0,
        pricePerNightSingleFull: room.priceSingleFull ?? 0,
      });

      updateHotel(updatedHotel, selectedNight);
    } else if (index !== undefined) {
      updatedHotel.rooms?.splice(index, 1);

      if (updatedHotel.rooms?.length === 0) removeHotel(updatedHotel, selectedNight);
    }

    updateHotel(updatedHotel, selectedNight);
    setSelectedHotel(updatedHotel);
  };

  const editSelectedHotelRoomPrice: FnEditSelectedHotelRoomPrice = (identifier, type, price) => {
    if (!selectedHotel || !selectedNight) return;

    const updatedHotel = cloneDeep(selectedHotel);

    const room = updatedHotel.rooms?.find((hotelRoom) => hotelRoom.roomIdentifier === identifier);
    if (!room) return;

    if (type === "default") {
      room.pricePerNightFull = price;
    } else {
      room.pricePerNightSingleFull = price;
    }

    setSelectedHotel(updatedHotel);
    updateHotel(updatedHotel, selectedNight);
  };

  const isHotelAssignedToAdventure: FnIsHotelAssignedToAdventure = (hotelIdentifier, night) => {
    night = night ?? selectedNight;
    if (!adventure.hotelsByNight || !night) return false;

    const hotelsForSelectedNight = getHotelsForNight(night);
    if (!hotelsForSelectedNight) return false;

    return hotelsForSelectedNight.some((hotel) => hotel.hotelIdentifier === hotelIdentifier);
  };

  const getHotelsForNight: FnGetHotelsForNight = (night) => {
    if (!adventure.hotelsByNight) return;

    return adventure.hotelsByNight[night];
  };

  const updateHotel = (hotel: IAdventureHotel, night: number) => {
    const hotelsByNight = adventure.hotelsByNight;
    if (!hotelsByNight[night]) hotelsByNight[night] = [];

    const index = hotelsByNight[night].findIndex((h) => h.hotelIdentifier === hotel.hotelIdentifier);

    if (index === -1) hotelsByNight[night].push(hotel);
    else hotelsByNight[night][index] = hotel;

    adventureActions.updateHotelsAssignedToAdventure(hotelsByNight);
  };

  const removeHotel = (hotel: IAdventureHotel, night: number) => {
    const hotelsByNight = adventure.hotelsByNight;
    if (!hotelsByNight[night]) hotelsByNight[night] = [];

    for (let i = 0; i < hotelsByNight[night].length; i++) {
      if (hotelsByNight[night][i].hotelIdentifier === hotel.hotelIdentifier) hotelsByNight[night].splice(i, 1);
    }

    let hasDefault = false;
    for (const hotel of hotelsByNight[night]) {
      if (hotel.isDefault) hasDefault = true;
    }

    if (!hasDefault && hotelsByNight[night].length > 0) hotelsByNight[night][0].isDefault = true;

    adventureActions.updateHotelsAssignedToAdventure(hotelsByNight);
  };

  const updateHotelOrderInNight = (hotels: IAdventureHotel[], night: number) => {
    for (const hotel of hotels) {
      if (!hotel.rooms) hotel.rooms = [];
      hotel.minPricePerNightFull = 0;
      hotel.minPricePerNightSingleFull = 0;
      hotel.maxPricePerNightFull = 0;
      hotel.maxPricePerNightSingleFull = 0;

      for (const room of hotel.rooms) {
        if (!room.pricePerNightFull) room.pricePerNightFull = 0;
        if (!room.pricePerNightSingleFull) room.pricePerNightSingleFull = 0;

        if (
          room.pricePerNightFull > 0 &&
          (!hotel.minPricePerNightFull || room.pricePerNightFull < hotel.minPricePerNightFull)
        )
          hotel.minPricePerNightFull = room.pricePerNightFull;

        if (room.pricePerNightFull > 0 && room.pricePerNightFull > hotel.maxPricePerNightFull)
          hotel.maxPricePerNightFull = room.pricePerNightFull;

        if (
          room.pricePerNightSingleFull > 0 &&
          (!hotel.minPricePerNightSingleFull || room.pricePerNightSingleFull < hotel.minPricePerNightSingleFull)
        )
          hotel.minPricePerNightSingleFull = room.pricePerNightSingleFull;

        if (room.pricePerNightSingleFull > 0 && room.pricePerNightSingleFull > hotel.maxPricePerNightSingleFull)
          hotel.maxPricePerNightSingleFull = room.pricePerNightSingleFull;
      }
    }

    hotels.sort((a, b) => {
      if (a.isDefault && !b.isDefault) return -1;
      if (!a.isDefault && b.isDefault) return 1;

      if ((a.minPricePerNightFull ?? 0) < (b.minPricePerNightFull ?? 0)) return -1;
      if ((a.minPricePerNightFull ?? 0) > (b.minPricePerNightFull ?? 0)) return 1;

      if ((a.minPricePerNightSingleFull ?? 0) < (b.minPricePerNightSingleFull ?? 0)) return -1;
      if ((a.minPricePerNightSingleFull ?? 0) > (b.minPricePerNightSingleFull ?? 0)) return 1;

      return 0;
    });

    const hotelsByNight = adventure.hotelsByNight;
    hotelsByNight[night] = hotels;

    adventureActions.updateHotelsAssignedToAdventure(hotelsByNight);
  };

  const removeHotelFromNight: FnRemoveHotelFromNight = (hotel, night) => {
    hotel = hotel ?? selectedHotel;
    night = night ?? selectedNight;

    if (!hotel || !night) return;

    const doRemove = () => {
      if (!hotel || !night) return;
      removeHotel(hotel, night);
      if (hotel.identifier === selectedHotel?.identifier && night === selectedNight) closeAddHotelPopup();
    };

    NOTIFICATIONS.showDialog({
      type: "yesno",
      message: I18n.t("adminHotels.adventure.addHotel.onRemoveHotel.confirm"),
      buttons: [
        {
          type: "cancel",
          onClick: () => {
            NOTIFICATIONS.hideDialog();
          },
        },
        {
          type: "ok",
          onClick: () => {
            if (doRemove) doRemove();
            NOTIFICATIONS.hideDialog();
          },
        },
      ],
    });
  };

  const getNightsWithHotels: FnGetNightsWithHotels = () => {
    const nightsWithHotels: Record<string, IAdventureHotel[]> = {};

    for (const night in adventure.hotelsByNight) {
      if (!adventure.hotelsByNight[night] || adventure.hotelsByNight[night].length === 0) continue;
      nightsWithHotels[night] = adventure.hotelsByNight[parseInt(night)];
    }

    return nightsWithHotels;
  };

  const duplicateFromNight = (night: number) => {
    if (!selectedNight) return;

    const hotelsForNight = getHotelsForNight(night);
    if (!hotelsForNight) return;

    for (const hotel of hotelsForNight) {
      hotel.identifier = `new_${v4()}`;
      hotel.nights = [night];
    }

    const hotelsByNight = adventure.hotelsByNight;
    hotelsByNight[selectedNight] = cloneDeep(hotelsForNight);

    adventureActions.updateHotelsAssignedToAdventure(hotelsByNight);
  };

  const addHotelToNight: FnAddHotelToNight = (hotel, night) => {
    const hotelsByNight = adventure.hotelsByNight;
    if (!hotelsByNight[night]) hotelsByNight[night] = [];

    const index = hotelsByNight[night].findIndex((h) => h.hotelIdentifier === hotel.hotelIdentifier);
    if (index === -1) {
      const newHotel = cloneDeep(hotel);
      newHotel.identifier = `new_${v4()}`;
      newHotel.isDefault = false;
      hotelsByNight[night].push(newHotel);
    }

    if (hotelsByNight[night].length === 1) hotelsByNight[night][0].isDefault = true;

    adventureActions.updateHotelsAssignedToAdventure(hotelsByNight);
  };

  const actions: AdventureHotelsActions = {
    updateSearch,
    loadHotels,
    openAddHotelPopup,
    closeAddHotelPopup,
    selectHotel,
    toggleDefaultHotel,
    toggleHotelRoom,
    editSelectedHotelRoomPrice,
    isHotelAssignedToAdventure,
    getHotelsForNight,
    removeHotelFromNight,
    getNightsWithHotels,
    duplicateFromNight,
    addHotelToNight,
  };

  return { filteredHotels, search, countries, cities, isAddHotelPopupVisible, selectedHotel, selectedNight, actions };
};

export default useAdventureHotels;

import * as React from "react";
import { BookingContext } from "src/components/context/BookingContext/BookingContext";
import * as FDN from "src/core";
import BookingPageTitle from "../parts/BookingPageTitle";
import { IAdventure } from "src/types";
import HotelStars from "src/components/pages/admin/adventures/parts/hotels/HotelStars";
import Config from "src/core/Config";
import { getAdventurePictureUrl, nl2br } from "src/core/helpers/helpers";
import HotelEntryPopup from "./HotelEntryPopup";

interface IBookingHotelByNightProps {
  adventure: IAdventure;
}

const BookingHotelByNight: React.FunctionComponent<IBookingHotelByNightProps> = ({ adventure }) => {
  const BOOKING = React.useContext(BookingContext);
  const { order, selectedNight, showUpgradesForNight } = BOOKING;

  if (!order || !adventure || !adventure.hotelsByNight) return <FDN.Loading box />;

  return (
    <div className="__booking-form-page-hotel" id="booking_hotel">
      <BookingPageTitle page="hotel" order={order} />

      {Object.keys(adventure.hotelsByNight).map((night) => {
        return (
          <div key={night}>
            <div className="font-medium text-brightPurple pb-1 pl-4">
              {FDN.I18n.t("adminHotels.night")} {night}
            </div>
            <HotelEntry night={parseInt(night)} />
          </div>
        );
      })}

      {selectedNight && (
        <HotelEntryPopup
          hotels={adventure.hotelsByNight[selectedNight]}
          night={selectedNight}
          onClose={() => showUpgradesForNight(undefined)}
        />
      )}
    </div>
  );
};

interface IHotelEntryProps {
  night: number;
}

const HotelEntry: React.FunctionComponent<IHotelEntryProps> = ({ night }) => {
  const BOOKING = React.useContext(BookingContext);
  const { order, getSelectedHotelForNight, showUpgradesForNight } = BOOKING;

  if (!order) return null;

  const selectedHotel = getSelectedHotelForNight(night);

  const descriptionPreview = selectedHotel?.info
    ? selectedHotel.info.description && selectedHotel.info.description.length > 200
      ? `${selectedHotel.info.description.slice(0, 200)}...`
      : undefined
    : null;

  const currency = Config.get("currency") as string;

  const styles: React.CSSProperties = {
    backgroundImage: selectedHotel?.info?.mainPicture
      ? `url("${getAdventurePictureUrl(selectedHotel.info.mainPicture, "L")}")`
      : undefined,
  };

  let selectedRoomPrice = <>{FDN.I18n.t("booking.form.group.hotel.roomIncluded.label")}</>;
  if (selectedHotel?.room) {
    const roomPrice =
      order.participants.length === 1
        ? selectedHotel.room.pricePerNightSingleFull ?? 0
        : selectedHotel.room.pricePerNightFull ?? 0;
    if (roomPrice !== 0)
      selectedRoomPrice = (
        <>
          {currency}
          {FDN.formatNumber(roomPrice, 2, 2)}
        </>
      );
  }

  return (
    <>
      <div className={"booking-hotel-entry"}>
        <FDN.Box onClick={() => showUpgradesForNight(night)} className="duration-300">
          {!selectedHotel?.info ? (
            <div className="text-center italic">{FDN.I18n.t("booking.form.group.hotel.noHotelSelected.label")}</div>
          ) : (
            <div className="booking-hotel-entry-box">
              <div className="booking-hotel-entry-content-wrapper">
                <div className="booking-hotel-entry-pictures" style={styles}></div>
                <div className="booking-hotel-entry-content">
                  <div className="booking-hotel-entry-name">
                    <span>{selectedHotel.info.name}</span>{" "}
                    {selectedHotel.info.stars && <HotelStars stars={selectedHotel.info.stars} />}
                  </div>
                  <div className="booking-hotel-entry-description">
                    {descriptionPreview ? (
                      <>
                        <div dangerouslySetInnerHTML={{ __html: nl2br(descriptionPreview, " | ") }} />
                        <div className="booking-hotel-entry-showmore pt-1 hover:underline">
                          {FDN.I18n.t("booking.form.group.hotel.showMore")}
                        </div>
                      </>
                    ) : (
                      <div dangerouslySetInnerHTML={{ __html: nl2br(selectedHotel.info.description || "", " | ") }} />
                    )}
                  </div>
                  {selectedHotel.room && (
                    <div className="border rounded mt-2 px-2 py-1 font-medium text-xs">
                      <div className="flex justify-between gap-1">
                        <div>
                          <FDN.Icon icon="bed" />
                        </div>
                        <div className="flex-grow">{selectedHotel.room.title}</div>
                        <div className="text-right">{selectedRoomPrice}</div>
                      </div>
                    </div>
                  )}
                  <div className="flex justify-center pt-3">
                    <button
                      className="font-medium text-xs text-brightPurple cursor-pointer duration-200 hover:underline"
                      onClick={() => showUpgradesForNight(night)}
                    >
                      <FDN.Icon icon="plus" />{" "}
                      {FDN.I18n.t("booking.form.group.hotel.buttons.showUpgrades.label", { night })}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </FDN.Box>
      </div>
    </>
  );
};

export default BookingHotelByNight;

import * as React from "react";
import FileManager from "src/components/main/FileManager";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { IAdventure, IUpgrade, TActions } from "src/types";

const tabs: FDN.ITabs = {
  info: {
    icon: "info-circle",
    label: FDN.I18n.t("adminAdventures.upgrades.editPopup.tabs.info.label"),
  },
  pictures: {
    icon: "picture-o",
    label: FDN.I18n.t("adminAdventures.upgrades.editPopup.tabs.pictures.label"),
  },
};

const defaultTab = "info";

interface IUpgradeFormProps {
  adventure: IAdventure;
  upgrade: IUpgrade;
  actions: TActions;
}

const UpgradeForm: React.FunctionComponent<IUpgradeFormProps> = ({
  adventure,
  upgrade,
  actions,
}) => {
  const [selectedTab, setSelectedTab] = React.useState(defaultTab);

  return (
    <div className="admin-adventure-upgrades-form">
      <FDN.Popup
        title={
          upgrade.identifier === "new"
            ? FDN.I18n.t("adminAdventures.upgrades.form.title")
            : upgrade.name
        }
        size="large"
        onClose={actions.hideUpgradeEditPopup}
      >
        <FDN.Tabs tabs={tabs} selected={selectedTab} onSelect={(tabKey) => setSelectedTab(tabKey)}>
          <p className="text-center warning-text">
            <FDN.Icon icon="exclamation-triangle" />{" "}
            {FDN.I18n.t("adminAdventures.upgrades.editPopup.intro")}
          </p>
          {selectedTab === "info" && (
            <TabContentInfo adventure={adventure} upgrade={upgrade} actions={actions} />
          )}
          {selectedTab === "pictures" && (
            <TabContentPictures adventure={adventure} upgrade={upgrade} actions={actions} />
          )}
        </FDN.Tabs>
        <FDN.FormButtons
          onCancel={actions.onCancelUpgrade}
          onSave={actions.onSaveUpgrade}
          saveLabel={
            upgrade.identifier === "new"
              ? FDN.I18n.t("adminAdventures.upgrades.editPopup.buttons.onAdd.label")
              : FDN.I18n.t("adminAdventures.upgrades.editPopup.buttons.onSave.label")
          }
        />
      </FDN.Popup>
    </div>
  );
};

const TabContentInfo: React.FunctionComponent<IUpgradeFormProps> = ({ upgrade, actions }) => {
  return (
    <FDN.Grid full>
      <FDN.Row margin="xy">
        <FDN.Cell md={12}>
          <FDN.FormGroup title={FDN.I18n.t("adminAdventures.upgrades.formgroup.name")}>
            <FDN.Grid full>
              <FDN.Row margin="xy">
                <FDN.Cell sm={24}>
                  <FDN.Input
                    type="text"
                    value={upgrade.name || ""}
                    label={FDN.I18n.t("adminAdventures.upgrades.form.name.label")}
                    editMode={true}
                    onUpdate={(value) => actions.onEditUpgrade("name", value)}
                  />
                </FDN.Cell>
              </FDN.Row>

              <FDN.Row margin="xy">
                <FDN.Cell sm={24}>
                  <FDN.Input
                    type="textarea"
                    rows={6}
                    value={upgrade.description || ""}
                    label={FDN.I18n.t("adminAdventures.upgrades.form.description.label")}
                    editMode={true}
                    onUpdate={(value) => actions.onEditUpgrade("description", value)}
                  />
                </FDN.Cell>
              </FDN.Row>
            </FDN.Grid>
          </FDN.FormGroup>
        </FDN.Cell>
        <FDN.Cell md={12}>
          <FDN.FormGroup title={FDN.I18n.t("adminAdventures.upgrades.formgroup.address")}>
            <FDN.SingleRowCell full margin="xy">
              <FDN.Input
                editable={true}
                editMode={true}
                value={upgrade.street}
                label={FDN.I18n.t("adminAccounts.form.street.label")}
                placeholder={FDN.I18n.t("adminAccounts.form.street.placeholder")}
                onUpdate={(value) => actions.onEditUpgrade("street", value)}
              />
            </FDN.SingleRowCell>
            <FDN.SingleRowCell full margin="xy">
              <FDN.Input
                editable={true}
                editMode={true}
                value={upgrade.streetAdditional}
                label={FDN.I18n.t("adminAccounts.form.streetAdditional.label")}
                placeholder={FDN.I18n.t("adminAccounts.form.streetAdditional.placeholder")}
                onUpdate={(value) => actions.onEditUpgrade("streetAdditional", value)}
              />
            </FDN.SingleRowCell>
            <FDN.GridRow full margin="xy">
              <FDN.Cell sm={24} md={8}>
                <FDN.Input
                  editable={true}
                  editMode={true}
                  value={upgrade.zip}
                  label={FDN.I18n.t("adminAccounts.form.zip.label")}
                  placeholder={FDN.I18n.t("adminAccounts.form.zip.placeholder")}
                  onUpdate={(value) => actions.onEditUpgrade("zip", value)}
                />
              </FDN.Cell>
              <FDN.Cell sm={24} md={16}>
                <FDN.Input
                  editable={true}
                  editMode={true}
                  value={upgrade.city}
                  label={FDN.I18n.t("adminAccounts.form.city.label")}
                  placeholder={FDN.I18n.t("adminAccounts.form.city.placeholder")}
                  onUpdate={(value) => actions.onEditUpgrade("city", value)}
                />
              </FDN.Cell>
            </FDN.GridRow>
            <FDN.SingleRowCell full margin="xy">
              <FDN.Input
                type="country"
                editable={true}
                editMode={true}
                options={{
                  locale: Config.get("currencyLocale") as string,
                }}
                value={upgrade.country}
                label={FDN.I18n.t("adminAccounts.form.country.label")}
                placeholder={FDN.I18n.t("adminAccounts.form.country.placeholder")}
                onUpdate={(value) => actions.onEditUpgrade("country", value)}
              />
            </FDN.SingleRowCell>
          </FDN.FormGroup>
          <FDN.FormGroup title={FDN.I18n.t("adminAdventures.upgrades.formgroup.price")}>
            <FDN.SingleRowCell full margin="xy">
              <FDN.Input
                type="currency"
                options={{
                  currency: Config.get("currency") as string,
                  locale: Config.get("currencyLocale") as string,
                }}
                editable={true}
                editMode={true}
                value={upgrade.priceFull}
                label={FDN.I18n.t("adminAdventures.upgrades.form.price.priceFull.label")}
                onUpdate={(value) => actions.onEditUpgrade("priceFull", value)}
              />
            </FDN.SingleRowCell>
            <FDN.SingleRowCell full margin="xy">
              <FDN.Input
                type="currency"
                options={{
                  currency: Config.get("currency") as string,
                  locale: Config.get("currencyLocale") as string,
                }}
                editable={true}
                editMode={true}
                value={upgrade.priceSingleFull}
                label={FDN.I18n.t("adminAdventures.upgrades.form.price.priceSingleFull.label")}
                onUpdate={(value) => actions.onEditUpgrade("priceSingleFull", value)}
              />
            </FDN.SingleRowCell>
          </FDN.FormGroup>
        </FDN.Cell>
      </FDN.Row>
    </FDN.Grid>
  );
};

const TabContentPictures: React.FunctionComponent<IUpgradeFormProps> = ({ upgrade, actions }) => {
  return (
    <div className="admin-adventure-tab-pictures">
      <FileManager
        allow={["images"]}
        parent="upgrade"
        files={upgrade.pictures || []}
        editMode={true}
        mainPictureIndex={0}
        allowEdit={false}
        allowRemove={true}
        actions={actions}
      />
    </div>
  );
};

export default UpgradeForm;

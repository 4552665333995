import * as React from "react";
import * as FDN from "src/core";
import { FSearchUpgrade, IUpgradeSearchParameters } from "./AddUpgradePopup";

interface IAddUpgradePopupSearchProps {
  searchParams: IUpgradeSearchParameters;
  searchUpgrade: FSearchUpgrade;
}

const AddUpgradePopupSearch: React.FunctionComponent<IAddUpgradePopupSearchProps> = ({
  searchParams,
  searchUpgrade,
}) => {
  return (
    <div className="admin-adventure-upgrades-add-popup-search">
      <FDN.Box noMargin>
        <FDN.Grid full>
          <FDN.Row margin="xy">
            <FDN.Cell sm={24}>
              <input
                type="text"
                value={searchParams.text}
                placeholder={FDN.I18n.t("adminAdventures.upgrades.search.placeholder")}
                onChange={(e) => {
                  searchParams.text = e.target.value;
                  searchUpgrade(searchParams);
                }}
                style={{ marginBottom: 0 }}
              />
            </FDN.Cell>
          </FDN.Row>
        </FDN.Grid>
      </FDN.Box>
    </div>
  );
};

export default AddUpgradePopupSearch;

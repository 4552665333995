import * as React from "react";
import * as FDN from "src/core";
import { IAccountState, EditedProperties, IMyAccountFormProps, TActions } from "src/types";
import AvatarUpload from "src/components/pages/user/AvatarUpload";
import Config from "src/core/Config";

interface IAccountUserInfoProps {
  account: IAccountState;
  editedProperties: EditedProperties;
  editMode: boolean;
  sendConfirmationMailAfterCreate: boolean;
  actions: TActions;
}

const AccountUserInfo: React.FunctionComponent<IAccountUserInfoProps> = ({
  account,
  editedProperties,
  editMode,
  sendConfirmationMailAfterCreate,
  actions,
}) => {
  return (
    <div>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24} md={14}>
            <FormGroupPersonalInfo
              account={account}
              editMode={editMode}
              editedProperties={editedProperties}
              actions={actions}
            />
            <FormGroupEmail
              account={account}
              editMode={editMode}
              editedProperties={editedProperties}
              actions={actions}
            />
            <FormGroupAddress
              account={account}
              editMode={editMode}
              editedProperties={editedProperties}
              actions={actions}
            />
            <FormGroupConfirmationMail
              account={account}
              editMode={editMode}
              editedProperties={editedProperties}
              sendConfirmationMailAfterCreate={sendConfirmationMailAfterCreate}
              actions={actions}
            />
          </FDN.Cell>
          <FDN.Cell sm={24} md={10}>
            <FormGroupAvatar
              account={account}
              editMode={editMode}
              editedProperties={editedProperties}
              actions={actions}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

const FormGroupPersonalInfo: React.FunctionComponent<IMyAccountFormProps> = ({
  account,
  editMode,
  actions,
}) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("adminAccounts.form.personalinfo.title")}>
      <FDN.SingleRowCell full margin="xy">
        <FDN.Input
          editable={true}
          editMode={editMode}
          value={account.firstname}
          label={FDN.I18n.t("adminAccounts.form.firstname.label")}
          placeholder={FDN.I18n.t("adminAccounts.form.firstname.placeholder")}
          onUpdate={(value) => actions.onEdit("firstname", value)}
        />
      </FDN.SingleRowCell>
      <FDN.SingleRowCell full margin="xy">
        <FDN.Input
          editable={true}
          editMode={editMode}
          value={account.lastname}
          label={FDN.I18n.t("adminAccounts.form.lastname.label")}
          placeholder={FDN.I18n.t("adminAccounts.form.lastname.placeholder")}
          onUpdate={(value) => actions.onEdit("lastname", value)}
        />
      </FDN.SingleRowCell>
    </FDN.FormGroup>
  );
};

const FormGroupEmail: React.FunctionComponent<IMyAccountFormProps> = ({
  account,
  editMode,
  editedProperties,
  actions,
}) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("adminAccounts.form.email.title")}>
      <FDN.SingleRowCell full margin="xy">
        <FDN.Input
          editable={true}
          editMode={editMode}
          value={account.email}
          label={FDN.I18n.t("adminAccounts.form.email.label")}
          placeholder={FDN.I18n.t("adminAccounts.form.email.placeholder")}
          onUpdate={(value) => actions.onEdit("email", value)}
        />
        {editedProperties.includes("email") && account.identifier !== "new" && (
          <div className="tiny warning callout" style={{ marginTop: "10px" }}>
            <p>
              <strong>{FDN.I18n.t("adminAccounts.form.email.alert.title")}</strong>
              <br />
              {FDN.I18n.t("adminAccounts.form.email.alert.text")}
            </p>
          </div>
        )}
      </FDN.SingleRowCell>
    </FDN.FormGroup>
  );
};

interface IFormGroupConfirmationMailProps extends IMyAccountFormProps {
  sendConfirmationMailAfterCreate: boolean;
}

const FormGroupConfirmationMail: React.FunctionComponent<IFormGroupConfirmationMailProps> = ({
  account,
  sendConfirmationMailAfterCreate,
  actions,
}) => {
  if (account.identifier !== "new") return null;

  return (
    <FDN.FormGroup title={FDN.I18n.t("adminAccounts.form.confirmationmail.title")}>
      <FDN.SingleRowCell full margin="xy">
        <div className="text-center">
          <FDN.Switch
            value={sendConfirmationMailAfterCreate}
            editMode={true}
            label={FDN.I18n.t("adminAccounts.form.sendconfirmationmail.label")}
            labelPosition="right"
            size="medium"
            onUpdate={(value) => actions.setSendConfirmationMailAfterCreate(value)}
          />
        </div>
      </FDN.SingleRowCell>
    </FDN.FormGroup>
  );
};

const FormGroupAvatar: React.FunctionComponent<IMyAccountFormProps> = ({
  account,
  editMode,
  actions,
}) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("adminAccounts.form.avatar.title")}>
      {editMode === true ? (
        <AvatarUpload
          location="adminAccounts"
          account={account}
          size={150}
          vertical
          onUpdate={(imageUrl: string) => actions.onEdit("avatar", imageUrl)}
          onDelete={() => actions.onEdit("avatar", null)}
        />
      ) : (
        <div className="text-center">
          <FDN.Avatar user={account} size={150} hideOnlineStatus={true} />
        </div>
      )}
    </FDN.FormGroup>
  );
};

const FormGroupAddress: React.FunctionComponent<IMyAccountFormProps> = ({
  account,
  editMode,
  actions,
}) => {
  return (
    <>
      {account.addresses.map((address: { [key: string]: any }, index: number) => {
        if (address?.type !== "billing") return null;

        return (
          <FDN.FormGroup title={FDN.I18n.t("adminAccounts.form.address.title")} key={index}>
            <FDN.SingleRowCell full margin="xy">
              <FDN.Input
                editable={true}
                editMode={editMode}
                value={address.street}
                label={FDN.I18n.t("adminAccounts.form.street.label")}
                placeholder={FDN.I18n.t("adminAccounts.form.street.placeholder")}
                onUpdate={(value) => actions.onEditAddress(address.identifier, "street", value)}
              />
            </FDN.SingleRowCell>
            <FDN.SingleRowCell full margin="xy">
              <FDN.Input
                editable={true}
                editMode={editMode}
                value={address.streetAdditional}
                label={FDN.I18n.t("adminAccounts.form.streetAdditional.label")}
                placeholder={FDN.I18n.t("adminAccounts.form.streetAdditional.placeholder")}
                onUpdate={(value) =>
                  actions.onEditAddress(address.identifier, "streetAdditional", value)
                }
              />
            </FDN.SingleRowCell>
            <FDN.GridRow full margin="xy">
              <FDN.Cell sm={24} md={8}>
                <FDN.Input
                  editable={true}
                  editMode={editMode}
                  value={address.zip}
                  label={FDN.I18n.t("adminAccounts.form.zip.label")}
                  placeholder={FDN.I18n.t("adminAccounts.form.zip.placeholder")}
                  onUpdate={(value) => actions.onEditAddress(address.identifier, "zip", value)}
                />
              </FDN.Cell>
              <FDN.Cell sm={24} md={16}>
                <FDN.Input
                  editable={true}
                  editMode={editMode}
                  value={address.city}
                  label={FDN.I18n.t("adminAccounts.form.city.label")}
                  placeholder={FDN.I18n.t("adminAccounts.form.city.placeholder")}
                  onUpdate={(value) => actions.onEditAddress(address.identifier, "city", value)}
                />
              </FDN.Cell>
            </FDN.GridRow>
            <FDN.SingleRowCell full margin="xy">
              <FDN.Input
                type="country"
                options={{
                  locale: Config.get("currencyLocale") as string,
                }}
                editable={true}
                editMode={editMode}
                value={address.country}
                label={FDN.I18n.t("adminAccounts.form.country.label")}
                placeholder={FDN.I18n.t("adminAccounts.form.country.placeholder")}
                onUpdate={(value) => actions.onEditAddress(address.identifier, "country", value)}
              />
            </FDN.SingleRowCell>
          </FDN.FormGroup>
        );
      })}
    </>
  );
};

export default AccountUserInfo;

import { INotificationsContext } from "src/components/context/NotificationsContext/NotificationsContext";
import { IEditLockStatus } from "src/components/context/NotificationsContext/types";
import StatusCode from "src/config/statuscodes";
import Config from "src/core/Config";
import {
  AccountsSearch,
  IAdventure,
  EmailTemplateState,
  IUserroleState,
  LocationCountry,
  LocationRegion,
  IHotel,
  IUpgrade,
  IDriver,
  OrdersListType,
  AdventuresListType,
} from "src/types";
import Api from "./Api";
import { Logger } from "src/core";
import { AddFilesObject } from "src/services/admin/OrderService";

export default class AdminApi {
  public static adminDashboard(api: Api) {
    return api.get("admin.dashboard.index");
  }

  public static adminSettings(api: Api) {
    return api.get("admin.settings.index");
  }

  public static updateAdminSettings(api: Api, settings: { [key: string]: any }) {
    return api.post("admin.settings.index", null, { settings });
  }

  public static adminEmailTemplates(api: Api) {
    return api.get("admin.emailtemplates.index");
  }

  public static adminEmailTemplate(api: Api, emailTemplateIdentifier: string) {
    return api.get("admin.emailtemplates.show", { identifier: emailTemplateIdentifier });
  }

  public static updateAdminEmailTemplate(api: Api, emailTemplateIdentifier: string, emailTemplate: EmailTemplateState) {
    return api.post("admin.emailtemplates.show", { identifier: emailTemplateIdentifier }, { emailTemplate });
  }

  public static adminAdventures(api: Api, type: AdventuresListType) {
    return api.get("admin.adventures.index", { type });
  }

  public static adminAdventure(api: Api, adventureIdentifier: string) {
    return api.get("admin.adventures.show", { identifier: adventureIdentifier });
  }

  public static duplicateAdventure(api: Api, identifier: string) {
    return api.post("admin.adventures.duplicate", { identifier });
  }

  public static saveAdminAdventure(api: Api, adventureIdentifier: string, adventure: IAdventure) {
    return api.post("admin.adventures.show", { identifier: adventureIdentifier }, { adventure });
  }

  public static adminHotels(api: Api) {
    return api.get("admin.hotels.all");
  }

  public static adminSaveHotel(api: Api, identifier: string, hotel: IHotel) {
    return api.post("admin.hotels.hotel", { identifier }, { hotel });
  }

  public static adminUpgrades(api: Api) {
    return api.get("admin.upgrades.all");
  }

  public static adminSaveUpgrade(api: Api, identifier: string, upgrade: IUpgrade) {
    return api.post("admin.upgrades.upgrade", { identifier }, { upgrade });
  }

  public static adminAdventureAddCountry(api: Api, country: LocationCountry) {
    return api.post("admin.adventures.addCountry", {}, { country });
  }

  public static adminAdventureAddRegion(api: Api, countryIdentifier: string, region: LocationRegion) {
    return api.post("admin.adventures.addRegion", { identifier: countryIdentifier }, { region });
  }

  public static adminDrivers(api: Api) {
    return api.get("admin.drivers.index");
  }

  public static adminSaveDriver(api: Api, identifier: string, driver: IDriver) {
    return api.post("admin.drivers.show", { identifier }, { driver });
  }

  public static adminOrders(api: Api, type: OrdersListType) {
    return api.get("admin.orders.index", { type });
  }

  public static adminOrder(api: Api, orderIdentifier: string) {
    return api.get("admin.orders.show", { identifier: orderIdentifier });
  }

  public static adminOrderAssignDriver(api: Api, orderIdentifier: string, driverIdentifier: string | undefined) {
    return api.post("admin.orders.assignDriver", { identifier: orderIdentifier }, { driver: driverIdentifier });
  }

  public static adminOrderCancel(api: Api, orderIdentifier: string) {
    return api.post("admin.orders.cancel", { identifier: orderIdentifier });
  }

  public static adminOrderUncancel(api: Api, orderIdentifier: string) {
    return api.post("admin.orders.uncancel", { identifier: orderIdentifier });
  }

  public static adminOrderAddFiles(api: Api, orderIdentifier: string, addFilesObject: AddFilesObject) {
    return api.post("admin.orders.files", { identifier: orderIdentifier }, { addFilesObject });
  }

  public static adminOrderRemoveFile(api: Api, orderIdentifier: string, fileIdentifier: string) {
    return api.delete("admin.orders.filesDelete", { orderIdentifier, fileIdentifier });
  }

  public static adminOrderFileSendMail(api: Api, orderIdentifier: string, fileIdentifier: string) {
    return api.post("admin.orders.filesSendMail", { orderIdentifier, fileIdentifier });
  }

  public static adminAccounts(api: Api, search: AccountsSearch) {
    return api.get("admin.accounts.index", search, { queryParams: true });
  }

  public static adminAccount(api: Api, identifier: string) {
    return api.get("admin.accounts.account", { identifier });
  }

  public static adminAccountLoginAttempts(api: Api, identifier: string) {
    return api.get("admin.accounts.loginattempts", { identifier });
  }

  public static adminAccountUserPermissionsStatus(api: Api, identifier: string) {
    return api.get("admin.accounts.userpermissionsstatus", { identifier });
  }

  public static updateAccountUserroles(api: Api, identifier: string, roles: string[]) {
    return api.post("admin.accounts.userpermissionsstatus", { identifier }, { roles });
  }

  public static updateAdminAccount(
    api: Api,
    account: { [key: string]: any },
    sendConfirmationMailAfterCreate?: boolean
  ) {
    return api.post(
      "admin.accounts.account",
      { identifier: account.identifier },
      {
        account,
        sendconfirmationmail: sendConfirmationMailAfterCreate || false,
      }
    );
  }

  public static adminAccountResendConfirmationMail(api: Api, accountIdentifier: string) {
    return api.post("admin.accounts.mails.resendconfirmationmail", {
      identifier: accountIdentifier,
    });
  }

  public static adminAccountSendPasswordForgottenMail(api: Api, accountIdentifier: string) {
    return api.post("admin.accounts.mails.sendpasswordforgottenmail", {
      identifier: accountIdentifier,
    });
  }

  public static adminUserroles(api: Api) {
    return api.get("admin.userroles.index");
  }

  public static updateAdminUserrole(api: Api, userroleIdentifier: string, userrole: IUserroleState) {
    return api.post("admin.userroles.userrole", { identifier: userroleIdentifier }, { userrole });
  }

  public static deleteAdminUserrole(api: Api, userroleIdentifier: string) {
    return api.delete("admin.userroles.userrole", { identifier: userroleIdentifier });
  }

  public static getLogs(api: Api, type: string, affectedIdentifier: string) {
    return api.get("admin.logs", { type, affectedIdentifier });
  }

  public static async requestEditLock(
    api: Api,
    NOTIFICATIONS: INotificationsContext,
    type: string,
    affectedIdentifier: string
  ) {
    if (Config.isDebug()) Logger.info(`Requesting edit lock for [${type}-${affectedIdentifier}] ...`);

    return api.get("admin.editLock", { type, affectedIdentifier }).then((response) => {
      if (!response) return;

      if (response.statusCode === StatusCode.EDITLOCK_LOCKED || response.statusCode === StatusCode.EDITLOCK_OWN) {
        NOTIFICATIONS.updateEditLock(response?.body?.editLockStatus as IEditLockStatus);
      }

      return response.statusCode;
    });
  }

  public static deleteEditLock(api: Api, type: string, affectedIdentifier: string) {
    return api.delete("admin.editLock", { type, affectedIdentifier });
  }
}

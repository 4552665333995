import React from "react";
import * as FDN from "src/core";
import usePageContent from "../pagecontent/usePageContent";
import PageContent from "../pagecontent/PageContent";

export const urlForTailorMadeInquiry = "mailto:service@click2adventure.com?subject=Inquiry%20tailor-made%20adventure";

const AboutUsPage = () => {
  const { pageContents, editMode, actions } = usePageContent();

  React.useEffect(() => {
    actions.init(["aboutus"]);
  }, []);

  if (!pageContents) return <FDN.Loading box />;

  return (
    <FDN.LayoutWithTopbar page="index">
      <FDN.PageContent noPadding>
        <div className="__content-page py-12 w-full md:w-2/3 mx-auto">
          <PageContent identifier="aboutus" pageContents={pageContents} editMode={editMode} actions={actions} />
          <div id="customvacations" />
          <FDN.Box>
            <h2>Interested in a tailor-made adventure or vacation?</h2>

            <p>
              We would be delighted to customize your next trip for you. Just hit the button below, send us an e-mail
              with a short description of your desired adventure and we will get in touch with you shortly.
            </p>

            <div className="text-center">
              <a className="primary button" href={urlForTailorMadeInquiry}>
                Request your customized offer now
              </a>
            </div>
          </FDN.Box>
        </div>
      </FDN.PageContent>
    </FDN.LayoutWithTopbar>
  );
};

export default AboutUsPage;

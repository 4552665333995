import * as React from "react";
import useServiceFileManager from "src/components/main/FileManager/services/FileManagerService";
import FileMultiUpload from "src/components/main/FileMultiUpload";
import * as FDN from "src/core";
import useServiceCore from "src/services/CoreService";
import { AddFilesObject, TActionsAdminOrder } from "src/services/admin/OrderService";
import { IFile, IOrder, TActions } from "src/types";

interface IOrderFilesProps {
  order: IOrder;
  popupAddFilesVisible: boolean;
  addFilesObject: AddFilesObject;
  actions: TActionsAdminOrder;
}

const OrderFiles: React.FunctionComponent<IOrderFilesProps> = ({
  order,
  popupAddFilesVisible,
  addFilesObject,
  actions,
}) => {
  const { api } = useServiceCore();

  return (
    <div className="__order-files">
      {!order.files || order.files.length === 0 ? (
        <p>
          <em>{FDN.I18n.t("adminOrders.files.noFiles")}</em>
        </p>
      ) : (
        <>
          {order.files.map((file) => {
            const downloadUrl = api.getEndpoint("files.download", {
              identifier: file.identifier,
            }) as string;

            return (
              <div className="order-files-file" key={file.identifier}>
                <FDN.Box>
                  <div className="order-files-file-wrapper">
                    <div className="order-files-file-content">
                      <h3>
                        <FileIcon file={file} />
                        &nbsp; {file.title || file.filename}
                      </h3>
                      {file.description ? (
                        <div className="order-files-file-description">{file.description}</div>
                      ) : null}
                    </div>
                    <div className="order-files-file-buttons">
                      <div>
                        <a
                          href={downloadUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="small primary button"
                        >
                          <FDN.Icon icon="download" left />{" "}
                          {FDN.I18n.t("adminOrders.files.buttons.download.label")}
                        </a>
                      </div>
                      {file.identifier === order.orderConfirmationFileIdentifier ? null : (
                        <div>
                          <button
                            className="small primary hollow button"
                            onClick={() => actions.onRemoveFile(file.identifier)}
                          >
                            <FDN.Icon icon="times" left />{" "}
                            {FDN.I18n.t("adminOrders.files.buttons.remove.label")}
                          </button>
                        </div>
                      )}
                      <div>
                        <button
                          className="small primary hollow button"
                          onClick={() => actions.onResendMail(file.identifier)}
                        >
                          <FDN.Icon icon="envelope-o" left />{" "}
                          {FDN.I18n.t("adminOrders.files.buttons.send.label")}
                        </button>
                      </div>
                    </div>
                  </div>
                </FDN.Box>
              </div>
            );
          })}
        </>
      )}
      <div className="order-files-addfile-button">
        <button onClick={() => actions.showPopupAddFiles()}>
          <FDN.Icon icon="plus-circle" left /> {FDN.I18n.t("adminOrders.files.buttons.add.label")}
        </button>
      </div>
      {popupAddFilesVisible === true ? (
        <PopupUpload
          order={order}
          popupAddFilesVisible={popupAddFilesVisible}
          addFilesObject={addFilesObject}
          actions={actions}
        />
      ) : null}
    </div>
  );
};

interface IFileIconProps {
  file: IFile;
}

const FileIcon: React.FunctionComponent<IFileIconProps> = ({ file }) => {
  if (file.filetype?.includes("pdf")) return <FDN.Icon icon="file-pdf-o" />;
  else return <FDN.Icon icon="file-o" />;
};

const PopupUpload: React.FunctionComponent<IOrderFilesProps> = (props) => {
  const parentActions: TActions = { ...props.actions };
  const { addFilesObject } = props;

  const { actions } = useServiceFileManager({ files: [], parentActions, parent: "order" });

  const { api } = useServiceCore();

  return (
    <FDN.Popup
      size="small"
      onClose={actions.hidePopupAddFiles}
      title={FDN.I18n.t("adminOrders.files.addpopup.title")}
    >
      {addFilesObject && addFilesObject.files && addFilesObject.files.length > 0 ? (
        <>
          {addFilesObject.files.map((file) => {
            return (
              <FDN.Box key={file.identifier}>
                <h3>
                  {file.filename}{" "}
                  <a
                    href={
                      api.getEndpoint("files.download", { identifier: file.identifier }) as string
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tiny primary button"
                  >
                    <FDN.Icon icon="download" left />{" "}
                    {FDN.I18n.t("adminOrders.files.file.download")}
                  </a>
                </h3>
                <FDN.Grid full>
                  <FDN.Row margin="xy">
                    <FDN.Cell sm={24}>
                      <FDN.Input
                        type="text"
                        editMode={true}
                        value={file.title}
                        label={FDN.I18n.t("adminOrders.files.addpopup.form.title.label")}
                        onUpdate={(value) =>
                          props.actions.onEditAddedFile(file.identifier, "title", value)
                        }
                      />
                    </FDN.Cell>
                  </FDN.Row>
                  <FDN.Row margin="xy">
                    <FDN.Cell sm={24}>
                      <FDN.Input
                        type="textarea"
                        rows={3}
                        editMode={true}
                        value={file.description}
                        label={FDN.I18n.t("adminOrders.files.addpopup.form.description.label")}
                        onUpdate={(value) =>
                          props.actions.onEditAddedFile(file.identifier, "description", value)
                        }
                      />
                    </FDN.Cell>
                  </FDN.Row>
                </FDN.Grid>
              </FDN.Box>
            );
          })}
          <div className="text-center" style={{ marginBottom: "20px" }}>
            <FDN.Input
              editMode={true}
              type="checkbox"
              label={FDN.I18n.t("adminOrders.files.addpopup.form.informClient.label")}
              value={addFilesObject.informClient}
              onUpdate={(value) => props.actions.onEditAddedFileObject("informClient", value)}
            />
          </div>
          <FDN.FormButtons
            onSave={props.actions.onSave}
            saveLabel={FDN.I18n.t(
              `adminOrders.files.addpopup.buttons.${
                addFilesObject.informClient === true ? `saveAndInform` : `save`
              }.label`
            )}
            onCancel={props.actions.onCancel}
          />
        </>
      ) : (
        <FileMultiUpload allow={["all"]} actions={actions} />
      )}
    </FDN.Popup>
  );
};

export default OrderFiles;

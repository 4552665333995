import * as React from "react";
import * as FDN from "src/core";
import { IEmailTemplatePlaceholder } from "src/types";

interface IEmailTemplatePlaceholdersProps {
  placeholders?: IEmailTemplatePlaceholder;
  identifier: string;
  language: string;
}

const EmailTemplatePlaceholders: React.FunctionComponent<IEmailTemplatePlaceholdersProps> = ({
  placeholders,
  identifier,
}) => {
  if (!placeholders) return <FDN.Loading box />;
  if (placeholders && !placeholders[identifier]) return null;

  return (
    <div className="__admin-emailtemplates-placeholders">
      <p>
        <strong>{FDN.I18n.t(`adminEmailTemplates.placeholder.title`)}</strong>
      </p>
      <p>{FDN.I18n.t(`adminEmailTemplates.placeholder.text`)}</p>

      <ul>
        {placeholders[identifier].map((key) => {
          return (
            <li key={key}>
              <button className="clickable" onClick={() => FDN.copyToClipboard(`%${key}%`)}>
                <code>%{key}%</code>
              </button>{" "}
              <span>{FDN.I18n.t(`adminEmailTemplates.placeholder.${identifier}.${key}`)}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default EmailTemplatePlaceholders;

import * as React from "react";
import * as FDN from "src/core";
import { IAdventure, IAdventureRequiredInformation, IIconsIncluded, LocationCountries, TActions } from "src/types";
import AdventureLocationSelector from "../parts/AdventureLocationSelector";
import Config from "src/core/Config";
import RichTextEditor from "src/components/main/RichTextEditor";

const maxNights = 30;
const maxLeadTime = 30;

interface IAdventureInfoProps {
  adventure: IAdventure;
  editMode: boolean;
  countries?: LocationCountries;
  actions: TActions;
}

const AdventureInfo: React.FunctionComponent<IAdventureInfoProps> = ({ adventure, editMode, countries, actions }) => {
  return (
    <div className="admin-adventure-tab-info">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell md={12}>
            <FormGroupTitle adventure={adventure} editMode={editMode} countries={countries} actions={actions} />
            <FormGroupContent adventure={adventure} editMode={editMode} countries={countries} actions={actions} />
            <FormGroupIncluded adventure={adventure} editMode={editMode} countries={countries} actions={actions} />
            <FormGroupPaymentPolicy adventure={adventure} editMode={editMode} countries={countries} actions={actions} />
          </FDN.Cell>
          <FDN.Cell md={12}>
            <FormGroupType adventure={adventure} editMode={editMode} countries={countries} actions={actions} />
            <FormGroupPublish adventure={adventure} editMode={editMode} countries={countries} actions={actions} />
            <FormGroupLocation adventure={adventure} editMode={editMode} countries={countries} actions={actions} />
            <FormGroupDuration adventure={adventure} editMode={editMode} countries={countries} actions={actions} />
            <FormGroupLeadTime adventure={adventure} editMode={editMode} countries={countries} actions={actions} />
            <FormGroupRequiredInformation
              adventure={adventure}
              editMode={editMode}
              countries={countries}
              actions={actions}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

interface IAdventureInfoFormGroupProps {
  adventure: IAdventure;
  editMode: boolean;
  countries?: LocationCountries;
  actions: TActions;
}

const FormGroupTitle: React.FunctionComponent<IAdventureInfoFormGroupProps> = ({ adventure, editMode, actions }) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("adminAdventures.info.formgroup.title.title")}>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              type="text"
              editMode={editMode}
              value={adventure.title}
              label={FDN.I18n.t("adminAdventures.info.form.title.label")}
              onUpdate={(value) => actions.onEdit("title", value)}
            />
          </FDN.Cell>
        </FDN.Row>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              type="textarea"
              rows={2}
              editMode={editMode}
              value={adventure.description}
              label={FDN.I18n.t("adminAdventures.info.form.description.label")}
              onUpdate={(value) => actions.onEdit("description", value)}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </FDN.FormGroup>
  );
};

const FormGroupContent: React.FunctionComponent<IAdventureInfoFormGroupProps> = ({ adventure, editMode, actions }) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("adminAdventures.info.formgroup.content.title")}>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            {editMode === true ? (
              <RichTextEditor
                returnContentType="html"
                initialHtml={adventure.content || ""}
                onUpdate={(editorState) => actions.onEdit("content", editorState)}
              />
            ) : (
              <FDN.Input
                type="textarea"
                rows={2}
                editMode={false}
                value={adventure.content}
                label={FDN.I18n.t("adminAdventures.info.form.content.label")}
              />
            )}
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </FDN.FormGroup>
  );
};

const FormGroupIncluded: React.FunctionComponent<IAdventureInfoFormGroupProps> = ({ adventure, editMode, actions }) => {
  const icons = Config.get("adventures.iconsIncluded") as { [key: string]: { [key: string]: any } };

  return (
    <FDN.FormGroup title={FDN.I18n.t("adminAdventures.info.formgroup.included.title")}>
      <h4>{FDN.I18n.t("adminAdventures.info.form.iconsincluded.label")}</h4>
      <FDN.Grid full>
        <FDN.Row margin="x">
          {Object.keys(icons).map((iconKey) => {
            return (
              <FDN.Cell sm={12} md={12} key={iconKey}>
                <FDN.Input
                  type="checkbox"
                  editMode={editMode}
                  value={adventure.iconsIncluded.includes(iconKey as IIconsIncluded)}
                  label={
                    <>
                      <FDN.Icon icon={icons[iconKey].icon} left />{" "}
                      {FDN.I18n.t(`adventures.iconsIncluded.${iconKey}.label`)}
                    </>
                  }
                  onUpdate={() => {
                    const updatedIconsIncluded = FDN.toggleArrayItem(iconKey, adventure.iconsIncluded);
                    actions.onEdit("iconsIncluded", updatedIconsIncluded);
                  }}
                />
              </FDN.Cell>
            );
          })}
        </FDN.Row>
      </FDN.Grid>
      <hr />
      <h4>{FDN.I18n.t("adminAdventures.info.form.included.label")}</h4>
      <div className="admin-adventure-included">
        {adventure.included.map((item, index) => {
          return <IncludedItem key={index} index={index} text={item} editMode={editMode} actions={actions} />;
        })}
      </div>
      {editMode === true && (
        <div className="admin-adventure-included-add">
          <button onClick={actions.onAddIncluded}>
            <FDN.Icon icon="plus-circle" left /> {FDN.I18n.t("adminAdventures.info.form.included.button.add.label")}
          </button>
        </div>
      )}
    </FDN.FormGroup>
  );
};

interface IIncludedItemProps {
  index: number;
  text: string;
  editMode: boolean;
  actions: TActions;
}

const IncludedItem: React.FunctionComponent<IIncludedItemProps> = ({ index, text, editMode, actions }) => {
  return (
    <div className="admin-adventure-included-item">
      {editMode === true ? (
        <>
          <div className="admin-adventure-included-item-number">{index + 1}.</div>
          <input type="text" value={text || ""} onChange={(e) => actions.onEditIncluded(index, e.target.value)} />
          <button className="admin-adventure-included-item-remove" onClick={() => actions.onRemoveIncluded(index)}>
            <FDN.Icon icon="times" />
          </button>
        </>
      ) : (
        <div className="admin-adventure-included-item-text">
          {index + 1}. {text}
        </div>
      )}
    </div>
  );
};

const FormGroupType: React.FunctionComponent<IAdventureInfoFormGroupProps> = ({ adventure, editMode, actions }) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("adminAdventures.info.formgroup.type.title")}>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            {editMode ? (
              <select value={adventure.type || "adventure"} onChange={(e) => actions.onEdit("type", e.target.value)}>
                <option value="adventure">{FDN.I18n.t("adventures.type.adventure")}</option>
                <option value="vacation">{FDN.I18n.t("adventures.type.vacation")}</option>
              </select>
            ) : (
              <div className="text-center">{FDN.I18n.t(`adventures.type.${adventure.type}`)}</div>
            )}
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </FDN.FormGroup>
  );
};

const FormGroupPublish: React.FunctionComponent<IAdventureInfoFormGroupProps> = ({ adventure, editMode, actions }) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("adminAdventures.info.formgroup.publish.title")}>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <div className={`text-center ${adventure.published === true ? `success-text` : `error-text`}`}>
              <FDN.Switch
                value={adventure.published}
                editMode={editMode}
                label={FDN.I18n.t(
                  `adminAdventures.info.form.${adventure.published === true ? `published` : `notpublished`}.label`
                )}
                onUpdate={(value) => actions.onEdit("published", value)}
              />
            </div>
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
      {adventure.published === true ? (
        <>
          <hr />
          <FDN.Grid full>
            <FDN.Row margin="xy">
              <FDN.Cell sm={24}>
                <div className={`text-center`}>
                  <FDN.Switch
                    value={adventure.isPrivate || false}
                    editMode={editMode}
                    label={FDN.I18n.t(
                      `adminAdventures.info.form.${adventure.isPrivate === true ? `private` : `notprivate`}.label`
                    )}
                    onUpdate={(value) => actions.onEdit("isPrivate", value)}
                  />
                </div>
              </FDN.Cell>
            </FDN.Row>
          </FDN.Grid>
        </>
      ) : null}
    </FDN.FormGroup>
  );
};

const FormGroupLocation: React.FunctionComponent<IAdventureInfoFormGroupProps> = ({
  adventure,
  editMode,
  countries,
  actions,
}) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("adminAdventures.info.formgroup.location.title")}>
      <AdventureLocationSelector adventure={adventure} editMode={editMode} countries={countries} actions={actions} />
    </FDN.FormGroup>
  );
};

const FormGroupDuration: React.FunctionComponent<IAdventureInfoFormGroupProps> = ({ adventure, editMode, actions }) => {
  const selectValuesNights: { [key: number]: number | string } = {
    0: "---",
  };
  for (let i = 1; i <= maxNights; i++) {
    selectValuesNights[i] = i;
  }

  return (
    <FDN.FormGroup
      title={FDN.I18n.t("adminAdventures.info.formgroup.duration.title")}
      info={FDN.I18n.t("adminAdventures.info.formgroup.duration.info")}
    >
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              type="select"
              selectValues={selectValuesNights}
              editMode={editMode}
              value={adventure.nights}
              label={FDN.I18n.t("adminAdventures.info.form.nights.label")}
              onUpdate={(value) => actions.onEdit("nights", value)}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </FDN.FormGroup>
  );
};

const FormGroupLeadTime: React.FunctionComponent<IAdventureInfoFormGroupProps> = ({ adventure, editMode, actions }) => {
  const selectValuesLeadTime: { [key: number]: number | string } = {
    0: "---",
  };
  for (let i = 1; i <= maxLeadTime; i++) {
    selectValuesLeadTime[i] = i;
  }

  return (
    <FDN.FormGroup
      title={FDN.I18n.t("adminAdventures.info.formgroup.leadTimeInDays.title")}
      info={FDN.I18n.t("adminAdventures.info.formgroup.leadTimeInDays.info")}
    >
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              type="select"
              selectValues={selectValuesLeadTime}
              editMode={editMode}
              value={adventure.leadTimeInDays}
              label={FDN.I18n.t("adminAdventures.info.form.leadTimeInDays.label")}
              onUpdate={(value) => actions.onEdit("leadTimeInDays", value)}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </FDN.FormGroup>
  );
};

const FormGroupRequiredInformation: React.FunctionComponent<IAdventureInfoFormGroupProps> = ({
  adventure,
  editMode,
  actions,
}) => {
  const requiredInformation = Config.get("adventures.requiredInformation") as {
    [key: string]: { [key: string]: any };
  };

  return (
    <FDN.FormGroup
      title={FDN.I18n.t("adminAdventures.info.formgroup.requiredInformation.title")}
      info={FDN.I18n.t("adminAdventures.info.formgroup.requiredInformation.info")}
    >
      <ul>
        {Object.keys(requiredInformation).map((infoKey) => {
          return (
            <li key={infoKey}>
              <FDN.Input
                type="checkbox"
                editMode={editMode}
                value={adventure.requiredInformation.includes(infoKey as IAdventureRequiredInformation)}
                label={
                  <>
                    {/*<FDN.Icon icon={requiredInformation[infoKey].icon} left />{" "}*/}
                    {FDN.I18n.t(`adventures.requiredInformation.${infoKey}.label`)}
                  </>
                }
                onUpdate={() => {
                  const updatedRequiredInformation = FDN.toggleArrayItem(infoKey, adventure.requiredInformation);
                  actions.onEdit("requiredInformation", updatedRequiredInformation);
                }}
              />
            </li>
          );
        })}
      </ul>
    </FDN.FormGroup>
  );
};

const FormGroupPaymentPolicy: React.FunctionComponent<IAdventureInfoFormGroupProps> = ({
  adventure,
  editMode,
  actions,
}) => {
  return (
    <FDN.FormGroup title={FDN.I18n.t("adminAdventures.info.formgroup.paymentPolicy.title")}>
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell sm={24}>
            <FDN.Input
              type="textarea"
              rows={8}
              editMode={editMode}
              value={adventure.paymentPolicy}
              label={FDN.I18n.t("adminAdventures.info.form.paymentPolicy.label")}
              onUpdate={(value) => actions.onEdit("paymentPolicy", value)}
            />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </FDN.FormGroup>
  );
};

export default AdventureInfo;

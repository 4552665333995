import * as React from "react";
import * as FDN from "src/core";
import {
  IAdventure,
  IFile,
  IOrderParticipant,
  IReqInfoByParticipantParticipant,
  IReqInfoFiles,
} from "src/types";
import useServiceReqInfo, { TActionsReqInfo } from "src/services/ReqInfoService";
import ProgressBar from "@ramonak/react-progress-bar";
import FileUpload from "src/components/main/FileUpload";
import { isArray } from "lodash";
import Config from "src/core/Config";

const BookingReqInfo: React.FunctionComponent = () => {
  const {
    APP,
    reqInfo,
    reqInfoFiles,
    order,
    adventure,
    orderNotFound,
    completion,
    unsavedChanges,
    actions,
    syncReqInfos,
  } = useServiceReqInfo();
  const supportEmail = APP.getSetting("supportEmail");

  if (orderNotFound === true)
    return (
      <div className="__payment">
        <FDN.LayoutWithTopbar page="payment">
          <FDN.PageContent>
            <FDN.Grid>
              <FDN.Row margin="xy">
                <FDN.Cell md={12} mdo={6}>
                  <FDN.CalloutBox type="alert">
                    <p>
                      <strong>{FDN.I18n.t("payment.orderNotFound.title")}</strong>
                    </p>
                    <div>{FDN.I18n.t("payment.orderNotFound.text")}</div>
                  </FDN.CalloutBox>
                </FDN.Cell>
              </FDN.Row>
            </FDN.Grid>
          </FDN.PageContent>
        </FDN.LayoutWithTopbar>
      </div>
    );

  if (!reqInfo || !reqInfoFiles || !order || !adventure) return <FDN.Loading />;

  const emailLink = `<a href="mailto:${supportEmail}">${supportEmail}</a>`;

  return (
    <FDN.LayoutWithTopbar page="booking-reqinfo">
      <FDN.PageContent>
        <div className="__booking-reqinfo">
          <FDN.Grid>
            <FDN.Row>
              <FDN.Cell sm={24} md={16} mdo={4}>
                <h1>{FDN.I18n.t("booking.reqinfo.header.title")}</h1>
                <p
                  className="lead"
                  dangerouslySetInnerHTML={{
                    __html: FDN.I18n.t("booking.reqinfo.header.intro", { email: emailLink }),
                  }}
                />
                <div className="__booking-reqinfo-orderinfo">
                  <FDN.Box>
                    <div className="__booking-reqinfo-orderinfo-content">
                      <div>
                        Order # <strong>{order.orderNr}</strong>
                      </div>
                      <div className="order-info-startdate">
                        <FDN.Icon icon="calendar-check-o" left /> {FDN.formatDate(order.startDate)}
                      </div>
                      <div className="order-info-nights">
                        <FDN.Icon icon="moon-o" left /> {order.nights}
                      </div>
                      <div className="order-info-participantcount">
                        <FDN.Icon icon="users" left /> {order.participants.length}
                      </div>
                      {/* <div className="order-info-totalcost">
                        <FDN.Icon icon="dollar" left />{" "}
                        {FDN.formatNumber(order.calculation.total, 2, 2)}
                      </div> */}
                    </div>
                  </FDN.Box>
                </div>

                <div className="__booking-reqinfo-completion">
                  <ProgressBar
                    completed={completion}
                    bgColor={completion < 100 ? "#ff7582" : "rgb(84, 198, 84)"}
                  />
                  {completion === 100 && !unsavedChanges ? (
                    <FDN.CalloutBox type="success">
                      <p>
                        <strong>{FDN.I18n.t("booking.reqinfo.completed.title")}</strong>
                      </p>
                      <div>{FDN.I18n.t("booking.reqinfo.completed.text")}</div>
                    </FDN.CalloutBox>
                  ) : null}
                </div>

                {Object.keys(reqInfo).map((participantIdentifier, index) => {
                  const participantReqInfos =
                    reqInfo[participantIdentifier as keyof typeof reqInfo];
                  return (
                    <ParticipantBox
                      index={index}
                      reqInfoFiles={reqInfoFiles}
                      key={index}
                      participant={order.participants.find(
                        (p) => p.identifier === participantIdentifier
                      )}
                      participantReqInfos={participantReqInfos}
                      adventure={adventure}
                      actions={actions}
                      syncReqInfos={syncReqInfos}
                    />
                  );
                })}

                <FDN.FormButtons
                  onSave={actions.onSave}
                  saveLabel={FDN.I18n.t("booking.reqinfo.buttons.onSave.label")}
                />
              </FDN.Cell>
            </FDN.Row>
          </FDN.Grid>
        </div>
      </FDN.PageContent>
    </FDN.LayoutWithTopbar>
  );
};

interface IParticipantBoxProps {
  index: number;
  reqInfoFiles: IReqInfoFiles;
  participant?: IOrderParticipant;
  participantReqInfos: IReqInfoByParticipantParticipant[];
  adventure: IAdventure;
  actions: TActionsReqInfo;
  syncReqInfos: string[];
}

const ParticipantBox: React.FunctionComponent<IParticipantBoxProps> = ({
  index,
  reqInfoFiles,
  participant,
  participantReqInfos,
  actions,
  syncReqInfos,
}) => {
  if (!participant) return null;

  const requiredInformationTypes = Config.get("adventures.requiredInformation") as {
    [key: string]: any;
  };

  return (
    <div className="__booking-reqinfo-participant">
      <h3>
        # {index + 1} {participant.firstname || ""} {participant.lastname || ""}
      </h3>
      <FDN.Box noPadding>
        {Object.keys(requiredInformationTypes).map((reqInfoType) => {
          const participantReqInfo = participantReqInfos.find((r) => r.type === reqInfoType);
          if (!participantReqInfo) return null;

          const alreadyUploadedFile: IFile[] = [];

          if (
            participantReqInfo.type === "passports" &&
            participantReqInfo.value &&
            isArray(participantReqInfo.value)
          ) {
            for (const fileIdentifier of participantReqInfo.value) {
              if (
                fileIdentifier in reqInfoFiles &&
                !alreadyUploadedFile.includes(reqInfoFiles[fileIdentifier])
              )
                alreadyUploadedFile.push(reqInfoFiles[fileIdentifier]);
            }
          }

          if (syncReqInfos.includes(participantReqInfo.type) && index !== 0) return null;

          return (
            <div className="__booking-reqinfo-box-content" key={participantReqInfo.identifier}>
              <h4>{FDN.I18n.t(`booking.reqinfo.types.${participantReqInfo.type}.title`)}</h4>
              {participantReqInfo.type === "pickuplocation" ? (
                <textarea
                  rows={4}
                  value={(participantReqInfo.value as string) || ""}
                  onChange={(e) => {
                    actions.onEdit(participant.identifier, participantReqInfo.type, e.target.value);
                  }}
                />
              ) : null}
              {participantReqInfo.type === "foodpreferences" ? (
                <textarea
                  rows={4}
                  value={(participantReqInfo.value as string) || ""}
                  onChange={(e) => {
                    actions.onEdit(participant.identifier, participantReqInfo.type, e.target.value);
                  }}
                />
              ) : null}
              {participantReqInfo.type === "passports" ? (
                <div className="text-center">
                  <FileUpload
                    uploadType="reqinfo"
                    maxFiles={4}
                    allowedFileTypes={["image", "pdf"]}
                    alreadyUploadedFiles={alreadyUploadedFile}
                    onUploadCompleted={(uploadedFiles) => {
                      actions.onEdit(
                        participant.identifier,
                        participantReqInfo.type,
                        uploadedFiles.map((file) => file.identifier)
                      );
                    }}
                  />
                </div>
              ) : null}
            </div>
          );
        })}
      </FDN.Box>
    </div>
  );
};

export default BookingReqInfo;

import Api from "src/api/Api";
import { PaymentStatusTypes } from "src/types";

export default class StripeApi {
  public static config(api: Api) {
    return api.get("stripe.config");
  }

  public static getPayment(api: Api, orderIdentifier: string) {
    return api.post("stripe.payments.get", { orderIdentifier });
  }

  public static updatePaymentStatus(
    api: Api,
    stripePaymentIdentifier: string,
    paymentStatus: PaymentStatusTypes
  ) {
    return api.post(
      "stripe.payments.updatepaymentstatus",
      { stripePaymentIdentifier },
      { paymentStatus }
    );
  }
}

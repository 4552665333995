import * as React from "react";
import * as FDN from "src/core";
import { formatDateTime } from "src/core/helpers/helpers";
import UrlService from "src/core/UrlService";
import { cloneDeep } from "lodash";
import {
  IAdminListBodyRow,
  IAdminListColumn,
  IAdminListSchema,
} from "src/components/main/AdminList/types";
import { LogsState } from "src/types";
import StatusCode from "src/config/statuscodes";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: {
      md: 2,
    },
    textAlign: "center",
    content: null,
  },
  {
    size: {
      md: 6,
    },
    content: FDN.I18n.t("adminLogs.list.head.date.title"),
  },
  {
    size: {
      md: 6,
    },
    content: FDN.I18n.t("adminLogs.list.head.status.title"),
  },
  {
    size: { md: 10 },
    content: FDN.I18n.t("adminLogs.list.head.message.title"),
  },
];

const listSchema: IAdminListSchema = {
  rowMargin: "xy",
  head: { columns: listColumnsSchema },
  body: { rows: [] },
};

const buildAdminList = (logs: LogsState) => {
  if (!logs) return;

  const adminListSchema = cloneDeep(listSchema);
  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const logEntry of logs) {
    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    let messageUser, messageObject, messageExtra;

    if (logEntry.userName) {
      messageUser = logEntry.userName;
      if (logEntry.userIdentifier)
        messageUser = `<a href="${UrlService.url("admin.accounts.account", {
          identifier: logEntry.userIdentifier,
        })}">${messageUser}</a>`;
    }

    if (logEntry.affectedName) {
      messageObject = logEntry.affectedName;
      if (logEntry.affectedIdentifier) {
        let route;
        if (logEntry.type === "user") route = "admin.accounts.account";

        if (route)
          messageObject = `<a href="${UrlService.url(route, {
            identifier: logEntry.affectedIdentifier,
          })}">${messageObject}</a>`;
      }
    }

    if (logEntry.extraIdentifier) {
      messageExtra = logEntry.extraInfo || logEntry.extraIdentifier;

      if (["userrole_added", "userrole_removed"].includes(logEntry.action))
        messageExtra = `<a href="${UrlService.url("admin.userroles.show", {
          identifier: logEntry.extraIdentifier,
        })}">${messageExtra}</a>`;
    }

    const message = FDN.I18n.t(`adminLogs.message.${logEntry.type}.${logEntry.action}`, {
      user: messageUser,
      object: messageObject,
      extra: messageExtra,
    });

    if (logEntry.status === StatusCode.SUCCESS) {
      row.className = `success-shallow-background`;
      row.columns[0].content = (
        <span className="success-text">
          <FDN.Icon icon="check-circle" />
        </span>
      );
    } else {
      row.className = `error-shallow-background`;
      row.columns[0].content = (
        <span className="error-text">
          <FDN.Icon icon="ban" />
        </span>
      );
    }

    row.columns[1].content = (
      <div className={`__admin-accounts-loginattempts-date`}>
        {formatDateTime(logEntry.logDate, { seconds: true })}
      </div>
    );

    row.columns[2].content = (
      <div className={`__admin-accounts-loginattempts-status`}>
        {FDN.I18n.t(`adminLogs.status.${logEntry.status}.label`)}
      </div>
    );

    row.columns[3].content = (
      <>
        <div
          className={`__admin-accounts-loginattempts-ipaddress`}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      </>
    );

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface ILogsListProps {
  logs: LogsState;
}

const LogsList: React.FunctionComponent<ILogsListProps> = ({ logs }) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  React.useEffect(() => {
    if (logs) {
      setAdminListSchema(buildAdminList(logs as LogsState));
      setListLoading(false);
    }
  }, [logs]);

  if (!logs || listLoading === true) return <FDN.Loading />;

  if (logs.length <= 0)
    return <div className="text-center">{FDN.I18n.t("adminLogs.noEntries")}</div>;

  return (
    <>
      <FDN.AdminList schema={adminListSchema} loading={listLoading} />
    </>
  );
};

export default LogsList;

import React, { useState } from "react";
import * as FDN from "src/core";
import { IFile, TActions } from "src/types";
import { AcceptedFileTypes } from "../types";
import { IFileManagerAllowedTypes } from "../../FileManager/types";
import { FileUploadFile } from "../../ImageUpload/types";
import { cloneDeep } from "lodash";
import useServiceCore from "src/services/CoreService";
import axios from "axios";
//import * as FDN from "src/core";
//import useServiceCore from "./CoreService";
//import StatusCode from "../config/statuscodes";

interface IServiceFileMultiUploadProps {
  parentActions: TActions;
}

const useServiceFileMultiUpload = (props: IServiceFileMultiUploadProps) => {
  const [acceptedFileTypes, setAcceptedFilesTypes] = useState<AcceptedFileTypes>({});
  const [dropAreaType, setDropAreaType] = useState<IFileManagerAllowedTypes>("all");
  const [waitingFiles, setWaitingFiles] = useState<FileUploadFile[]>([]);
  const [uploadProgresses, setUploadProgresses] = useState<{ [key: string]: number }>({});
  const [uploadStatus, setUploadStatus] = useState<"UPLOADING" | "FINISHED">();
  // const [uploadedFiles, setUploadedFiles] = useState<IFile[]>([]);

  const { NOTIFICATIONS, api } = useServiceCore();

  React.useEffect(() => {
    init();
  }, []);

  const init = () => {
    //
  };

  const detectAcceptedFileTypes = (allow: IFileManagerAllowedTypes[]) => {
    let acceptedFileTypes: AcceptedFileTypes = {};

    if (allow.includes("images")) acceptedFileTypes["image/*"] = [".png", ".jpg", ".jpeg", ".gif"];
    if (allow.includes("pdfs")) acceptedFileTypes["application/pdf"] = [".pdf"];
    if (allow.includes("audios")) acceptedFileTypes["audio/*"] = [".mp3", ".wav", ".ogg"];

    if (allow.includes("all")) acceptedFileTypes = {};

    setAcceptedFilesTypes(acceptedFileTypes);
    setDropAreaType(allow.length === 1 ? allow[0] : "all");
  };

  const onSelectFiles = (files: FileUploadFile[]) => {
    FDN.Logger.info("[FILES SELECTED]", files);

    const updatedFiles = waitingFiles ? [...waitingFiles, ...files] : [...files];
    setWaitingFiles(updatedFiles);
  };

  const clearWaitingFiles = () => {
    setWaitingFiles([]);
    setUploadProgresses({});
  };

  const removeFileFromList = (index: number) => {
    const updatedWaitingFiles = cloneDeep(waitingFiles);
    updatedWaitingFiles.splice(index, 1);
    setWaitingFiles(updatedWaitingFiles);
  };

  const onUploadFiles = (
    uploadedFiles: IFile[] = [],
    filesToBeUploaded: FileUploadFile[] = waitingFiles || []
  ) => {
    if (!filesToBeUploaded || filesToBeUploaded.length === 0) {
      onUploadComplete(uploadedFiles);
      return;
    }

    if (filesToBeUploaded.length > 0) {
      uploadFile(filesToBeUploaded[0], (uploadedFile) => {
        if (uploadedFile) {
          filesToBeUploaded = filesToBeUploaded.filter((f) => f.uuid !== uploadedFile.uploadUuid);
          //console.log("ipdated filetipbebolooaduged", uploadedFile.uploadUuid, filesToBeUploaded);

          uploadedFiles.push(uploadedFile);
          onUploadFiles(uploadedFiles, filesToBeUploaded);
        }
      });
    }
  };

  const onUploadComplete = (uploadedFiles: IFile[]) => {
    FDN.Logger.success(`Upload completed with ${uploadedFiles.length} files.`, uploadedFiles);

    setUploadStatus("FINISHED");
    clearWaitingFiles();

    for (const uploadedFile of uploadedFiles) {
      if (uploadedFile.imageS)
        uploadedFile.S = api.getEndpoint("files.image", {
          identifier: uploadedFile.identifier,
          size: "S",
        }) as string;
      if (uploadedFile.imageM)
        uploadedFile.M = api.getEndpoint("files.image", {
          identifier: uploadedFile.identifier,
          size: "M",
        }) as string;
      if (uploadedFile.imageL)
        uploadedFile.L = api.getEndpoint("files.image", {
          identifier: uploadedFile.identifier,
          size: "L",
        }) as string;
    }

    props.parentActions?.onUploadsFinished(uploadedFiles);

    NOTIFICATIONS.showNotification(
      "success",
      FDN.I18n.t("filemultiupload.onUploadFinished.title"),
      FDN.I18n.t("filemultiupload.onUploadFinished.text")
    );
  };

  const uploadFile = (
    file: FileUploadFile,
    callback: (uploadedFile: IFile | null | undefined) => void
  ) => {
    if (!file) return;

    const apiUrl = api.getEndpoint("files.upload", { type: "misc" });
    if (!apiUrl) return;

    const formData = new FormData();

    const fileContent: Blob = file.croppedImage ? file.croppedImage : file.original;
    formData.append("files", fileContent, file.name);

    const apiOptions = api.getApiOptions();
    apiOptions.onUploadProgress = (progressEvent) => {
      //console.log("UPLOADING", progressEvent);
      if (progressEvent?.progress) {
        uploadProgresses[file.uuid] = Math.floor(progressEvent.progress * 100);
        setUploadProgresses(cloneDeep(uploadProgresses));
      }
    };

    FDN.Logger.info("[UPLOAD]", "FileMultiUploadService: Uploading file...", file.name);
    setUploadStatus("UPLOADING");

    uploadProgresses[file.uuid] = 0;
    setUploadProgresses(cloneDeep(uploadProgresses));

    axios
      .post(apiUrl, formData, apiOptions)
      .then((response) => {
        if (response?.data?.body?.files && response.data.body.files.length > 0 && callback) {
          const uploadedFile = response?.data?.body?.files[0] as IFile;
          uploadedFile.uploadUuid = file.uuid;
          callback(uploadedFile);
        }
      })
      .catch((e) => {
        FDN.Logger.error("[UPLOAD]", e);
      });
  };

  const actions: TActions = {
    detectAcceptedFileTypes,
    onSelectFiles,
    clearWaitingFiles,
    removeFileFromList,
    onUploadFiles,
  };

  return { acceptedFileTypes, dropAreaType, waitingFiles, uploadProgresses, uploadStatus, actions };
};

export default useServiceFileMultiUpload;

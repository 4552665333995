import * as React from "react";
import { BookingOrderPages } from "src/components/context/BookingContext/BookingContext";
import * as FDN from "src/core";
import { bookingNavigationIcons } from "./BookingNavigation";
import { IOrder } from "src/types";

interface IBookingPageTitleProps {
  page: BookingOrderPages;
  order: IOrder;
}

const BookingPageTitle: React.FunctionComponent<IBookingPageTitleProps> = ({ page, order }) => {
  const i18nIntroKey = `booking.form.group.${page}.intro`;

  let introText = "";
  if (page === "participants") {
    if (order.adventureChildrenMaxAge)
      introText = `${FDN.I18n.t("booking.form.group.participants.intro", {
        days: order.adventurePayRemainingDaysBefore,
        age: order.adventureChildrenMaxAge,
      })} ${FDN.I18n.t("booking.form.group.participants.introWithMaxChildrenAge", {
        days: order.adventurePayRemainingDaysBefore,
        age: order.adventureChildrenMaxAge,
      })}`;
    else
      introText = FDN.I18n.t("booking.form.group.participants.intro", {
        days: order.adventurePayRemainingDaysBefore,
        age: order.adventureChildrenMaxAge,
      });
  } else introText = FDN.I18n.t(i18nIntroKey);

  return (
    <div className="__booking-form-page-title">
      <hr />
      <div className="__booking-form-page-icon">
        <FDN.Icon icon={bookingNavigationIcons[page]} />
      </div>
      <h2>{FDN.I18n.t(`booking.form.group.${page}.title`)}</h2>
      {i18nIntroKey !== FDN.I18n.t(i18nIntroKey) ? (
        <p className="text-center" dangerouslySetInnerHTML={{ __html: introText }} />
      ) : null}
    </div>
  );
};

export default BookingPageTitle;

import * as React from "react";
import * as FDN from "src/core";
import { IFile, TActions } from "src/types";
import FilesList from "./parts/FilesList";
import FileUpload from "./parts/FileUpload";
import useServiceFileManager from "./services/FileManagerService";
import { IFileManagerAllowedTypes, IFileManagerParent } from "./types";
import ImagesList from "./parts/ImagesList";

export type FOnPictureUploadsCompleted = (files: IFile[]) => void;

interface IFileManagerProps {
  parent: IFileManagerParent;
  allow: IFileManagerAllowedTypes[];
  files: IFile[];
  editMode: boolean;
  vertical?: boolean;
  mainPictureIndex?: number;
  allowEdit?: boolean;
  allowRemove?: boolean;
  actions: TActions;
}

const FileManager: React.FunctionComponent<IFileManagerProps> = (props) => {
  const { parent, allow, files, editMode, vertical, mainPictureIndex, allowEdit, allowRemove } =
    props;
  const parentActions = props.actions;

  const { actions } = useServiceFileManager({ files, parentActions, parent });

  return (
    <div className="__filemanager">
      <FDN.Grid full>
        <FDN.Row margin="xy">
          <FDN.Cell
            sm={24}
            md={vertical === true ? 24 : 12}
            style={{ order: vertical === true ? 2 : 1 }}
          >
            {allow.length === 1 && allow.includes("images") ? (
              <ImagesList
                parent={parent}
                editMode={editMode}
                files={files}
                actions={actions}
                mainPictureIndex={mainPictureIndex}
                allow={"images"}
                allowEdit={allowEdit}
                allowRemove={allowRemove}
              />
            ) : (
              <FilesList
                parent={parent}
                editMode={editMode}
                files={files}
                actions={actions}
                mainPictureIndex={mainPictureIndex}
                allow={allow}
                allowEdit={allowEdit}
                allowRemove={allowRemove}
              />
            )}
          </FDN.Cell>
          <FDN.Cell
            sm={24}
            md={vertical === true ? 24 : 12}
            style={{ order: vertical === true ? 1 : 2 }}
          >
            <FileUpload editMode={editMode} parent={parent} allow={allow} actions={actions} />
          </FDN.Cell>
        </FDN.Row>
      </FDN.Grid>
    </div>
  );
};

export default FileManager;

import * as React from "react";
import * as FDN from "src/core";
import { cloneDeep } from "lodash";
import { IDrivers } from "src/types";
import {
  IAdminListBodyRow,
  IAdminListColumn,
  IAdminListSchema,
} from "src/components/main/AdminList/types";
import AdminList from "src/components/main/AdminList";
import { TActionsAdminDrivers } from "src/services/admin/DriversService";

const listColumnsSchema: IAdminListColumn[] = [
  {
    size: { md: 6 },
    textAlign: "left",
    content: FDN.I18n.t("adminDrivers.list.head.name.title"),
  },
  {
    size: {
      md: 6,
    },
    content: FDN.I18n.t("adminDrivers.list.head.whatsapp.title"),
  },
  {
    size: { md: 6 },
    content: FDN.I18n.t("adminDrivers.list.head.country.title"),
  },
];

const listSchema: IAdminListSchema = {
  head: { columns: listColumnsSchema },
  body: { rows: [] },
};

const buildAdminList = (drivers: IDrivers, actions: TActionsAdminDrivers) => {
  if (!drivers) return;

  const adminListSchema = cloneDeep(listSchema);
  if (adminListSchema && adminListSchema.body) adminListSchema.body.rows = [];

  for (const driver of drivers) {
    if (!driver) return;

    const row: IAdminListBodyRow = {
      columns: cloneDeep(listColumnsSchema),
    };

    row.onClick = () => {
      actions.selectDriver(driver.identifier);
    };

    row.columns[0].content = <>{driver.displayname}</>;

    row.columns[1].content = <>{driver.whatsapp}</>;

    row.columns[2].content = <>{driver.country}</>;

    if (adminListSchema && adminListSchema.body) adminListSchema.body.rows.push(row);
  }

  return adminListSchema;
};

interface IDriversListProps {
  drivers: IDrivers;
  actions: TActionsAdminDrivers;
}

const DriversList: React.FunctionComponent<IDriversListProps> = ({ drivers, actions }) => {
  const [adminListSchema, setAdminListSchema] = React.useState<IAdminListSchema>();
  const [listLoading, setListLoading] = React.useState(true);

  React.useEffect(() => {
    if (drivers) {
      setAdminListSchema(buildAdminList(drivers as IDrivers, actions));
      setListLoading(false);
    }
  }, [drivers]);

  if (!drivers) return <FDN.Loading box />;

  return <>{adminListSchema && <AdminList schema={adminListSchema} loading={listLoading} />}</>;
};

export default DriversList;

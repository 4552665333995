import * as React from "react";
import * as FDN from "src/core";
import { IAdventure } from "src/types";

interface IAdventurePreTitleProps {
  adventure: IAdventure;
  block?: boolean;
}

const AdventurePreTitle: React.FunctionComponent<IAdventurePreTitleProps> = ({
  adventure,
  block,
}) => {
  const styles = {
    display: block === true ? `block` : `inline-block`,
  };

  let preTitle = <div style={styles}></div>;
  if (adventure.countryName) {
    preTitle = <>{adventure.countryName}</>;

    if (adventure.regionName)
      preTitle = (
        <div style={styles}>
          {preTitle} <FDN.Icon icon="angle-right" /> {adventure.regionName}
        </div>
      );
  }
  return preTitle;
};

export default AdventurePreTitle;

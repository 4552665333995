import * as React from "react";
import useServiceFileMultiUpload from "./services/FileMultiUploadService";
import { IFileManagerAllowedTypes } from "../FileManager/types";
import { TActions } from "src/types";
import DropArea from "./parts/DropArea";
import UploadFilesList from "./parts/UploadFilesList";

interface IMultiUploadProps {
  allow: IFileManagerAllowedTypes[];
  maxFiles?: number;
  actions: TActions;
}

const FileMultiUpload: React.FunctionComponent<IMultiUploadProps> = (props) => {
  const { allow } = props;

  const maxFiles = props.maxFiles || 20;

  const parentActions = props.actions;

  const { dropAreaType, acceptedFileTypes, waitingFiles, uploadProgresses, actions } =
    useServiceFileMultiUpload({ parentActions });

  const allActions = { ...actions, ...parentActions };

  React.useEffect(() => {
    actions.detectAcceptedFileTypes(allow);
  }, [allow]);

  return (
    <div className="__filemultiupload">
      <DropArea
        accept={acceptedFileTypes}
        type={dropAreaType}
        maxFiles={maxFiles}
        actions={actions}
      />
      <UploadFilesList
        files={waitingFiles}
        uploadProgresses={uploadProgresses}
        actions={allActions}
      />
    </div>
  );
};

export default FileMultiUpload;

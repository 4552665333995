import * as React from "react";
import * as FDN from "src/core";
import { BookingContext } from "src/components/context/BookingContext/BookingContext";
import { IOrder, TFunction } from "src/types";
import BookingPageTitle from "../parts/BookingPageTitle";
import BookingPaymentPlanSummary from "../parts/BookingPaymentPlanSummary";

const BookingPaymentPlan: React.FunctionComponent = () => {
  const BOOKING = React.useContext(BookingContext);
  const { order, adventure, onEdit } = BOOKING;

  if (!order || !adventure) return <FDN.Loading box />;

  return (
    <div className="__booking-form-page-paymentplan" id="booking_paymentPlan">
      <BookingPageTitle page="paymentPlan" order={order} />

      {order.adventurePriceDownPayment && order.adventurePriceRemaining ? (
        <div className="__booking-form-paymentplan-options">
          <Option option="full" order={order} onEdit={onEdit} />
          <Option option="downpayment" order={order} onEdit={onEdit} />
        </div>
      ) : null}

      <FDN.Box>
        <h3 className="text-center">
          {FDN.I18n.t("booking.form.group.paymentplan.summary.title")}
        </h3>
        <BookingPaymentPlanSummary
          order={order}
          calculation={order.calculation || BOOKING.calculatePrice()}
          adventure={adventure}
        />
      </FDN.Box>
    </div>
  );
};

interface IOptionProps {
  option: "full" | "downpayment";
  order: IOrder;
  onEdit: TFunction;
}

const Option: React.FunctionComponent<IOptionProps> = ({ option, order, onEdit }) => {
  return (
    <button
      className={`${
        order.paymentPlan === option ? `selected` : ``
      } __booking-form-paymentplan-option`}
      onClick={() => onEdit("paymentPlan", option)}
    >
      <div className="__booking-form-paymentplan-option-content">
        <div className="__booking-form-paymentplan-option-icon">
          <FDN.Icon icon={order.paymentPlan === option ? "check" : "square-o"} />
        </div>
        <div className="__booking-form-paymentplan-option-text">
          <h4>
            {option === "full" && "1. "}
            {option === "downpayment" && "2. "}
            {FDN.I18n.t(`booking.form.group.paymentplan.option.${option}.label`)}
          </h4>
          <div>
            {FDN.I18n.t(`booking.form.group.paymentplan.option.${option}.text`, {
              days: order.adventurePayRemainingDaysBefore,
            })}
          </div>
        </div>
      </div>
    </button>
  );
};

export default BookingPaymentPlan;

import React from "react";
import * as FDN from "src/core";
import Config from "src/core/Config";
import { getAdventurePictureUrl, nl2br } from "src/core/helpers/helpers";
import { IOrder } from "src/types";
import HotelStars from "../../adventures/parts/hotels/HotelStars";
import { classNames } from "src/core/helpers/design";
import useOrderHotelEmail from "src/services/admin/useOrderHotelEmail";
import PopupSendEmail from "./email/PopupSendEmail";

interface OrderHotelsProps {
  order: IOrder;
}

export const OrderHotels: React.FunctionComponent<OrderHotelsProps> = ({ order }) => {
  if (!order.hotelsByNight) return null;

  const { selectedHotel, emailToHotel, actions } = useOrderHotelEmail(order);

  const currency = Config.get("currency") as string;

  return (
    <>
      <h3>{FDN.I18n.t("booking.form.group.preview.box.hotel.title")}</h3>
      {Object.keys(order.hotelsByNight).map((night) => {
        if (!order.hotelsByNight) return null;

        const hotel = order.hotelsByNight[parseInt(night)];
        if (!hotel || !hotel.info) return null;

        const descriptionPreview =
          hotel.info.description && hotel.info.description.length > 120
            ? `${hotel.info.description.slice(0, 117)}...`
            : undefined;

        let extraCost = <>{FDN.I18n.t("booking.form.group.hotel.roomIncluded.label")}</>;
        if (order.participants.length === 1 && hotel.room?.pricePerNightSingleFull)
          extraCost = (
            <>
              {currency}
              {FDN.formatNumber(hotel.room.pricePerNightSingleFull, 2, 2)}
            </>
          );
        if (order.participants.length > 1 && hotel.room?.pricePerNightFull)
          extraCost = (
            <>
              {currency}
              {FDN.formatNumber(hotel.room.pricePerNightFull, 2, 2)}
            </>
          );

        const latestHotelMailSent = (hotel.emailSent ?? [])[0] ?? null;

        const styles: React.CSSProperties = {
          backgroundImage: hotel.info.mainPicture
            ? `url("${getAdventurePictureUrl(hotel.info.mainPicture, "L")}")`
            : undefined,
        };

        return (
          <div key={night} className={`booking-hotel-entry mb-1`}>
            <FDN.Box noMargin noPadding>
              <div className={classNames("booking-hotel-entry-box px-4 py-4", latestHotelMailSent && "bg-green-50")}>
                <div className="booking-hotel-entry-content-wrapper">
                  <div className="booking-hotel-entry-pictures" style={styles}></div>
                  <div className="booking-hotel-entry-content">
                    {latestHotelMailSent && (
                      <div className="flex justify-end">
                        <span className="bg-green-100 text-green-700 rounded-md shadow px-2 py-1 text-xs font-medium">
                          <FDN.Icon icon="check" /> {FDN.I18n.t("adminHotels.order.email.emailSent.label")}
                        </span>
                      </div>
                    )}
                    <div className="text-xs font-medium uppercase">
                      {FDN.I18n.t("adminHotels.night")} {night}
                    </div>
                    <div className="booking-hotel-entry-name">
                      <span>{hotel.info.name}</span>
                      {hotel.info.stars ? <HotelStars stars={hotel.info.stars} /> : null}
                    </div>
                    <div className="booking-hotel-entry-description">
                      {descriptionPreview ? (
                        <div dangerouslySetInnerHTML={{ __html: nl2br(descriptionPreview, " | ") }} />
                      ) : (
                        <div dangerouslySetInnerHTML={{ __html: nl2br(hotel.info.description || "", " | ") }} />
                      )}
                    </div>
                    {hotel.room && (
                      <div className="pt-2">
                        <div className="rounded-md border px-2 py-1 flex">
                          <div className="flex-grow font-medium text-xs">
                            <FDN.Icon icon="bed" /> {FDN.I18n.t("booking.form.group.hotel.selectedRoom.title")}:{" "}
                            {hotel.room.title}
                          </div>
                          <div className="text-right font-medium text-xs">{extraCost}</div>
                        </div>
                      </div>
                    )}
                    {!latestHotelMailSent && (
                      <div className="pt-4">
                        <button
                          className="tiny hollow primary button cursor-pointer duration-200 hover:bg-hover"
                          onClick={() => actions.selectHotel(hotel)}
                        >
                          <FDN.Icon icon="envelope-o" left />{" "}
                          {FDN.I18n.t("adminHotels.order.email.buttons.sendEmail.label")}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </FDN.Box>
          </div>
        );
      })}
      {selectedHotel && (
        <PopupSendEmail order={order} hotel={selectedHotel} emailToHotel={emailToHotel} actions={actions} />
      )}
    </>
  );
};

export default OrderHotels;
